import {Injectable} from '@angular/core';
import {AuthRedirectService} from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class AimoAuthRedirectService extends AuthRedirectService {
    protected init(): void {
        // do not store redirect URLS https://service.bilot.fi/browse/VAC-692
    }

    redirect(): void {
//redirection done in AimoLoginFormComponentService
    }
}
