export const environment = {
    production: true,
    backend: {
        occ: {
            baseUrl: null,
        },
    },
    googleManager: {
        id: 'GTM-WPGJN7D',
        url: 'https://www.googletagmanager.com/ns.html?id=',
    },
    recaptcha: {
        siteKey: '6LeH7UUoAAAAAAvZU_x0I-I1xzU6s6grTPfrM4pZ',
    },
};
