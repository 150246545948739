<div id="orderhistory-{{ order.code }}">
    <div class="bg-petrol-light row no-gutters cx-order-history-header">
        <h5 class="col-3 text-default text-bold mb-0 print-keep-font-size">
            {{ 'aimo.orderhistory.orderTitle' | cxTranslate : { orderNumber: order.code } }}
        </h5>
        <div class="col-3">
            <button *ngIf="'PAYMENT_OPEN' === order.statusDisplay" (click)="payNow(order.code)" class="text-red link">
                {{ 'aimo.orderhistory.payNow' | cxTranslate }}
            </button>
        </div>
        <div class="col-md-2 col-xl-1 d-none d-md-block ml-auto">
            {{ 'aimo.orderhistory.entries' | cxTranslate : { amount: order.entries.length } }}
        </div>
        <div class="col-md-3 col-lg-2 col-6 text-right">
            <span class="text-bold order-sub-total">{{ order.subTotal.formattedValue }}</span>
            {{ 'aimo.cart.item.notax' | cxTranslate }}
        </div>
    </div>

    <div class="row no-gutters bg-white cx-order-history-content">
        <div class="col-lg-3 col-md-4 col-6">
            <div class="text-bold mb-1">{{ 'aimo.orderhistory.ordered' | cxTranslate }}</div>
            <div>
                {{
                    'aimo.orderhistory.datetime'
                        | cxTranslate
                            : {
                                  date: convertDate(order.created) | cxDate : ('aimo.dateFormat' | cxTranslate),
                                  time: convertDate(order.created) | cxDate : 'HH:mm'
                              }
                }}
            </div>
            <div *ngIf="order.modified">
                ({{
                    'aimo.orderhistory.modifiedDatetime'
                        | cxTranslate
                            : {
                                  date: convertDate(order.modified) | cxDate : ('aimo.dateFormat' | cxTranslate),
                                  time: convertDate(order.modified) | cxDate : 'HH:mm'
                              }
                }})
            </div>
        </div>
        <div class="col-lg-3 col-md-4 col-6">
            <div class="text-bold mb-1">{{ 'aimo.cart.header.user' | cxTranslate }}</div>
            <div class="order-username">{{ order.user.name }}</div>
            <div class="order-useremail">{{ order.user.email }}</div>
        </div>
        <div class="col-lg-3 col-md-4 col-6 pt-3 pt-md-0">
            <div class="text-bold mb-1">{{ 'aimo.cart.header.poNumber' | cxTranslate }}</div>
            <div class="order-purchase-order-number">{{ order.purchaseOrderNumber }}</div>
        </div>
        <div class="col-lg-3 col-md-4 col-6 pt-3 pt-md-0">
            <div class="text-bold mb-1">{{ 'aimo.cart.header.paymentMode' | cxTranslate }}</div>
            <div class="order-payment-mode">{{ order.paymentMode.name }}</div>

            <button
                ngbDropdown
                ngbDropdownToggle
                role="button"
                [attr.aria-label]="'aimo.arialLabel.language.dropdown' | cxTranslate"
                class="btn-link"
                [id]="'delivery_notes'"
                *ngIf="invoices.length > 0 && order.paymentMode.credit">
                <span class="orderhistory-deliveryNotes">{{ 'aimo.orderhistory.invoice' | cxTranslate }}</span>

                <div ngbDropdownMenu aria-labelledby="invoicestoggle">
                    <div ngbDropdownItem *ngFor="let invoice of invoices">
                        <div (click)="downloadInvoice(invoice, 'aimo.orderhistory.invoiceName')">
                            <i class="aimo-icon icon-file-text icon-inline icon-sm"></i>
                            {{ 'aimo.orderhistory.invoiceName' | cxTranslate : { id: invoice } }}
                        </div>
                    </div>
                </div>
            </button>
        </div>
    </div>

    <ng-container *ngFor="let entry of order.entries; index as idx">
        <aimo-order-history-order-entry [order]="order" [entry]="entry" [index]="idx"></aimo-order-history-order-entry>
    </ng-container>
</div>
