import { Component, Input } from '@angular/core';

import { PasswordRequirementType } from '../../../model/misc.model';
@Component({
    selector: 'aimo-password-checks',
    templateUrl: './aimo-password-checks.component.html',
})
export class AimoPasswordChecksComponent {
    passwordRequirementType = PasswordRequirementType;

    @Input() value: string;

    checkRequirements(requirement: string) {
        if (this.value) {
            switch (requirement) {
                case PasswordRequirementType.UPPERCASE:
                    return /(?=.*[A-ZÖÄÅ])/.test(this.value);
                case PasswordRequirementType.LOWERCASE:
                    return /.[a-zöåä]./.test(this.value);
                case PasswordRequirementType.MINIMUMCHARACTERS:
                    return /.{10,128}/.test(this.value);
                case PasswordRequirementType.NUMBER:
                    return /.*[0-9].*/.test(this.value);
            }
        }
        return false;
    }
}
