import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CarouselComponent, CarouselService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoCMSBannerComponent } from '../../../model/cms.model';
import { GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoProductService } from '../../../service/product/aimo-product.service';

@Component({
    selector: 'aimo-cx-carousel',
    templateUrl: './aimo-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCarouselComponent extends CarouselComponent implements OnInit {
    @Output() slideChange = new EventEmitter<number>();
    @Output() scrollChange = new EventEmitter<number>();

    @Input() banners: Observable<AimoCMSBannerComponent[]>;
    @Input() showAllText: string;
    @Input() showAllUrl: string;
    @Input() idSuffix: string;
    @Input() carouselType: GTMItemListId;

    constructor(
        protected el: ElementRef,
        protected carouselService: CarouselService,
        protected productService: AimoProductService,
    ) {
        super(el, carouselService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.carouselType) {
            const products = [];
            this.items.forEach((item) => {
                item.subscribe((i) => products.push(i)).unsubscribe();
            });
            this.productService.fireProductListViewGtmEvent(products, this.carouselType);
        }
    }

    scrollContainer(divContainer: HTMLElement, isPrevious: boolean): void {
        if (isPrevious) {
            divContainer.scrollLeft -= divContainer.offsetWidth;
            this.slideChange.emit(-divContainer.offsetWidth);
        } else {
            divContainer.scrollLeft += divContainer.offsetWidth;
            this.slideChange.emit(divContainer.offsetWidth);
        }
    }

    showButton(divContainer: HTMLElement, isPrevious = false, divSlides?: HTMLElement): boolean {
        if (isPrevious) {
            return divContainer.scrollLeft > 0;
        } else {
            return divContainer.scrollLeft + divContainer.offsetWidth < divSlides.offsetWidth;
        }
    }

    showScroll(divContainer: HTMLElement, divSlides: HTMLElement): boolean {
        return divSlides.offsetWidth > divContainer.offsetWidth;
    }

    scroll(divContainer: HTMLElement): void {
        this.scrollChange.emit(divContainer.scrollLeft);
    }
}
