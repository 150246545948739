import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AimoSpinnerService } from '../../cms-components/shared/spinner/aimo-spinner.service';
import { isOpenSpinner } from '../../cms-components/shared/utils/spinner/aimo-spinner-utils';
import { AimoProduct } from '../../model/product.model';
import { AimoGTMProductAttributes } from '../../service/gtm/aimo-gtm.model';

const QTY_PRECISION = 2;

@Component({
    selector: 'aimo-item-counter',
    templateUrl: './aimo-item-counter.component.html',
    // do not use OnPush change detection strategy as we would not
    // get updates of other form control state (disabled). We want to have a
    // disabled state in order to ensure that the control cannot be used while
    // the cart is updated.
})
export class AimoItemCounterComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();

    originalQuantity: number = 0;
    qtyFormField: FormControl;
    @Input() showSpinner = true;
    @Input() id: string;
    @Input() autoAddToCart: boolean = false;
    qtyShown: number;

    @Input() gtmProductAttributes?: AimoGTMProductAttributes;
    @Input() addToCartCallBack: BehaviorSubject<number> = new BehaviorSubject(undefined);

    @Input() set control(control: FormControl) {
        if (control?.value) {
            this.originalQuantity = parseFloat(control.value);
            if (this.originalQuantity % 1 != 0) {
                // if decimal number
                control.setValue(this.originalQuantity.toFixed(QTY_PRECISION)); // show only 2 decimal
            }
        }
        this.qtyFormField = control;
        this.checkInputValue();
    }

    @Input() parentForm: FormGroup;
    @Input() parentFormGroupName: string;
    @Input() parentFormArrayName: string;
    @Input() unitName: string;
    @Input() product: AimoProduct;
    @Input() productPage = false;
    @Input() isPLP = false;
    @Input() cartCounter = false;
    @Output() afterChange = new EventEmitter<ProductEvent>();
    @Input() step: number = 1;
    @Input() min = 0;
    @Input() max: number;
    @Input() showQuantity: boolean = true;
    @Input() autofocus: boolean;
    @HostBinding('class.readonly') @Input() readonly = false;
    @HostBinding('class.active') inputActive: boolean = false;
    @Input() doNotUpdateCart = false;
    @ViewChild('spinnerDiv') spinnerDiv: ElementRef;
    @ViewChild('iconCart') iconCart: ElementRef;

    focused = false;
    buttonStep: number = 1;

    @ViewChild('qtyInput')
    qtyRef: ElementRef;

    @ViewChild('increaseButton')
    increaseButton: ElementRef;

    @Input() buttonsClass: string;
    isOpenedSpinner$: Observable<boolean> = this.spinnerService.isOpenedSpinner$;

    constructor(
        protected cdr: ChangeDetectorRef,
        private renderer: Renderer2,
        protected spinnerService: AimoSpinnerService,
    ) {}

    ngOnInit(): void {
        this.checkInputValue();
        if (
            this.qtyFormField.disabled ||
            (this.qtyFormField.value && this.qtyFormField.value <= this.min && this.qtyFormField.value >= this.max)
        ) {
            this.readonly = true;
        }
        this.qtyShown = this.qtyFormField.value;
        this.buttonStep = this.step <= 0.01 ? 1 : this.step;

        this.subscriptions.add(
            this.addToCartCallBack.subscribe((quantity) => {
                if (quantity) {
                    this.qtyShown = quantity;
                    this.qtyFormField.setValue(quantity);
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    private setAutoFocus(button: ElementRef): void {
        if (this.autofocus) {
            setTimeout(() => {
                button?.nativeElement?.focus();
            }, 100);
        }
    }

    changeCommaToDot($event): void {
        if (
            $event.target.getAttribute('type') === 'number' &&
            $event.key === ',' &&
            $event.target.value.indexOf('.') === -1 &&
            navigator.userAgent.search('Safari')
        ) {
            $event.preventDefault();
            if ($event.target) {
                $event.target?.setAttribute('type', 'text');
                $event.target.value = $event.target.value + '.0';
                $event.target.setSelectionRange($event.target.value.length - 1, $event.target.value.length);
                if (this.qtyRef?.nativeElement) {
                    this.renderer.setStyle(
                        this.qtyRef.nativeElement,
                        'width',
                        $event.target.value.length * 8 + 6 + 'px',
                    );
                }
                $event.target?.setAttribute('type', 'number');
            }
        }
    }

    inputChanged($event: Event, qty: HTMLInputElement, triggerChange: boolean): void {
        if (this.cartCounter && !qty.classList.contains('changed')) {
            qty.classList.add('changed');
        }
        const value = parseFloat(this.qtyRef.nativeElement.value);
        if (triggerChange) {
            this.triggerAfterChanged($event, value, false, false, false, triggerChange);
        }
    }

    enterPressed($event: Event): void {
        if (!this.autoAddToCart) {
            let value = parseFloat(this.qtyRef.nativeElement.value);
            if (!isNaN(value)) {
                if (this.step !== 0) {
                    value = this.step * Math.ceil(value / this.step); // round to lot size
                }
                $event.preventDefault();
                this.triggerAfterChanged($event, value, false, false, true, true);
                this.qtyRef.nativeElement.blur(); //force blur
                this.focused = false;
            }
        }
    }

    decrementEvent(event: Event): void {
        const qty = this.parseQuantity() - this.buttonStep;
        this.qtyFormField.setValue(qty);
        this.triggerAfterChanged(event, qty, true, false, false, true);
    }

    cancelEvent(): void {
        this.qtyFormField.setValue(this.originalQuantity);
        this.qtyFormField.markAsDirty();
        this.cdr.detectChanges();
        this.focused = false;
    }

    incrementEvent(event: Event, addStep = false, hideSpinner?: boolean, addOnQtyZero?: boolean): void {
        if (addOnQtyZero) {
            addStep = this.parseQuantity() === 0;
        }
        this.showQuantity = true;
        this.focused = false;
        const qty = addStep ? this.parseQuantity() + this.buttonStep : this.parseQuantity();
        this.qtyFormField.setValue(qty);
        this.triggerAfterChanged(event, qty, true, false, false, true, hideSpinner);
    }

    private parseQuantity(): number {
        if (!isNaN(this.qtyFormField.value) && !isNaN(parseFloat(this.qtyFormField.value))) {
            return parseFloat(this.qtyFormField.value);
        }
        return +this.qtyFormField.value;
    }

    private triggerAfterChanged(
        event: Event,
        qty: number,
        buttonClicked: boolean,
        fieldChanged: boolean,
        enterPressed: boolean,
        setFormValue: boolean,
        hideSpinner?: boolean,
    ): void {
        qty = parseFloat(qty.toFixed(QTY_PRECISION));
        if (qty < 0) {
            qty = 0;
        }
        if (setFormValue) {
            this.qtyFormField.setValue(qty);
            this.qtyFormField.markAsDirty();
        }
        if (this.showSpinner) {
            this.spinnerService.showInnerSpinner(this.spinnerDiv, this.iconCart, hideSpinner ? false : true);
        }
        this.qtyShown = qty;
        this.afterChange?.next({
            event,
            value: qty,
            product: this.product,
            buttonClicked,
            fieldChanged,
            enterPressed,
            showHideQuantity: this.showQuantity,
            gtmProductAttributes: this.gtmProductAttributes,
        } as ProductEvent);
        this.checkInputValue();
    }

    onClick(): void {
        if (!this.readonly) {
            this.focused = this.productPage;
            this.inputActive = this.cartCounter;
            this.qtyRef.nativeElement.select();
        }
    }

    checkInputValue(): void {
        if (this.qtyFormField.value === 0) {
            this.qtyFormField.setValue('');
            if (this.isPLP) {
                setTimeout(() => {
                    this.setAutoFocus(this.increaseButton);
                }, 1000);
            }
        }
    }

    isOpenedLocalSpinner(): boolean {
        return isOpenSpinner(this.spinnerDiv);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    onBlur($event: FocusEvent): void {
        if (this.autoAddToCart && this.originalQuantity !== this.qtyFormField.value) {
            this.enterPressed($event);
        }
    }
}

export interface ProductEvent {
    event?: Event;
    value?: number;
    product?: AimoProduct;
    buttonClicked?: boolean;
    fieldChanged?: boolean;
    enterPressed?: boolean;
    showHideQuantity?: boolean;
    gtmProductAttributes?: AimoGTMProductAttributes;
}
