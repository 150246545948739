<div class="input-group {{ cssClass }}">
    <div
        [formGroup]="parentForm"
        class="input-label"
        [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
        [ngClass]="hasValue() ? 'has-value' : ''">
        <input
            #d="ngbDatepicker"
            (change)="onChange($event)"
            (click)="disableDates(d); d.toggle()"
            (navigate)="navigate($event)"
            (ngModelChange)="onChange($event)"
            [ngModel]="selectedDate"
            [dayTemplate]="customDayTemplate"
            [formControlName]="nameForm"
            [id]="idName"
            [name]="nameForm"
            [navigation]="navigation"
            [ngClass]="{ required: isRequired() }"
            [placeholder]="
                getFormattedDate() ? getFormattedDate() : placeholder ? placeholder : ('aimo.dateSelect' | cxTranslate)
            "
            [type]="typeInput ? typeInput : 'text'"
            [value]="getFormattedDate()"
            class="aimo-input form-control"
            name="dp"
            ngbDatepicker
            readonly />
    </div>
    <div class="input-group-prepend input-calendar-icon" (click)="disableDates(d); d.toggle()">
        <i class="aimo-icon icon-inline icon-sm icon-petrol-blue icon-calendar-blank"></i>
    </div>
</div>

<div
    class="invalid-feedback d-block"
    *ngIf="(isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)) && errorMessage">
    <span>{{ errorMessage | cxTranslate }}</span>
</div>

<div class="extra-info text-right" *ngIf="extraInfoBottom">
    {{ extraInfoBottom | cxTranslate }}
</div>
