import { Injectable } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AimoI18nextTranslationService implements TranslationService {
    translate(
        key: string,
        // eslint-disable-next-line
        options: any = {},
        // eslint-disable-next-line
        whitespaceUntilLoaded: boolean = false,
    ): Observable<string> {
        return of('[' + key + ']');
    }

    // eslint-disable-next-line
    loadChunks(chunkNames: string | string[]): Promise<any> {
        return Promise.resolve(chunkNames);
    }
}
