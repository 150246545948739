import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';

import { environment } from '../../../../environments/environment';

import { AimoGtmEffects } from './aimo-gtm.effect';

@NgModule({
    providers: [{ provide: 'googleTagManagerId', useValue: environment.googleManager.id }],
    imports: [CommonModule, EffectsModule.forFeature([AimoGtmEffects])],
})
export class AimoGtmModule {}
