import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
    AuthService,
    CmsConfig,
    GlobalMessageService,
    I18nModule,
    NotAuthGuard,
    provideDefaultConfig,
    RoutingService,
} from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { ResetPasswordModule } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { AimoInputModule } from 'src/app/features/shared/input/aimo-input.module';

import { AimoPasswordChecksModule } from '../../shared/password-checks/aimo-password-checks.module';

import { AimoResetPasswordComponentService } from './aimo-reset-password-component.service';
import { AimoResetPasswordComponent } from './aimo-reset-password.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
        FormErrorsModule,
        SpinnerModule,
        NgbTooltipModule,
        AimoInputModule,
        AimoPasswordChecksModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                ResetPasswordComponent: {
                    component: AimoResetPasswordComponent,
                    guards: [NotAuthGuard],
                    providers: [
                        {
                            provide: AimoResetPasswordComponentService,
                            useClass: AimoResetPasswordComponentService,
                            deps: [UserPasswordFacade, RoutingService, GlobalMessageService, AuthService],
                        },
                    ],
                },
            },
        }),
    ],
    declarations: [AimoResetPasswordComponent],
})
export class AimoResetPasswordModule extends ResetPasswordModule {}
