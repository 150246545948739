<ng-container *ngIf="(items$ | async)?.length > 1 && (items$ | async) as items">
    <ng-container *ngIf="activeItem$ | async as activeItem">
        <div
            ngbDropdown
            class="aimo-dropdown text-uppercase d-inline-block"
            [tabindex]="0"
            role="button"
            [attr.aria-label]="'aimo.arialLabel.language.dropdown' | cxTranslate">
            <!-- language dropdown disabled until we have something to switch to -->
            <div id="dropdownLanguage" ngbDropdownToggle>
                <i class="aimo-icon icon-globe icon-petrol-blue icon-inline d-lg-none d-inline-block mr-2"></i>
                <span>{{ activeItem }}</span>
                <i class="aimo-icon icon-inline icon-sm icon-white icon-caret-down ml-2 d-lg-inline-block d-none"></i>
                <i class="aimo-icon icon-inline icon-petrol-blue icon-caret-down ml-2 d-inline-block d-lg-none"></i>
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownLanguage">
                <div
                    ngbDropdownItem
                    *ngFor="let item of items"
                    (click)="changeLanguage(item.isocode)"
                    [ngClass]="activeItem === item.isocode ? 'active' : ''">
                    {{ item.isocode }}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
