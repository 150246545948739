import { InjectionToken, Provider } from '@angular/core';
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { CartActions } from '@spartacus/cart/base/core';
import { StateUtils, UserActions } from '@spartacus/core';
import { LoaderAction } from '@spartacus/core/src/state/utils/loader/loader.action';

import {
    AIMO_ADD_MANY_PRODUCTS,
    AIMO_CART_UPDATE_HEADER_SUCCESS,
    AIMO_ORDER_TEMPLATE_IMPORT,
} from '../cart/aimo-cart.action';
import { AIMO_CART_ADD_ENTRIES } from '../cart/aimo-entry.action';
import { SELECT_UNIT } from '../user/aimo-user.action';

import {
    AimoDeliveryDatesState,
    AimoDeliveryDateState,
    AimoLoadDeliveryDateSuccess,
    LOAD_DELIVERY_DATE,
    LOAD_DELIVERY_DATE_FAIL,
    LOAD_DELIVERY_DATE_SUCCESS,
} from './aimo-deliverydate.action';

export const initialState: AimoDeliveryDateState = {
    date: null,
    loading: false,
};
// eslint-disable-next-line
export const metaReducers: MetaReducer<any>[] = [clearState];

export const reducerToken: InjectionToken<ActionReducerMap<AimoDeliveryDatesState>> = new InjectionToken<
    ActionReducerMap<AimoDeliveryDatesState>
>('DeliveryDateReducers');

export const aimoDeliveryDateReducerProvider: Provider = {
    provide: reducerToken,
    useFactory: getAimoDeliveryDateReducers,
};

// eslint-disable-next-line
export function clearState(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (
            action.type === AIMO_CART_UPDATE_HEADER_SUCCESS ||
            action.type === SELECT_UNIT ||
            action.type === CartActions.DELETE_CART_SUCCESS ||
            action.type === AIMO_ADD_MANY_PRODUCTS ||
            action.type === AIMO_CART_ADD_ENTRIES ||
            action.type === AIMO_ORDER_TEMPLATE_IMPORT ||
            action.type === UserActions.LOAD_USER_CONSENTS_SUCCESS
        ) {
            state = initialState;
        }
        //   console.log(action.type)
        return reducer(state, action);
    };
}

export function getAimoDeliveryDateReducers(): ActionReducerMap<AimoDeliveryDatesState> {
    return {
        active: StateUtils.loaderReducer<AimoDeliveryDateState>(LOAD_DELIVERY_DATE_SUCCESS, aimoDeliverydateReducer),
    };
}

export function aimoDeliverydateReducer(state = initialState, action: LoaderAction): AimoDeliveryDateState {
    switch (action.type) {
        case LOAD_DELIVERY_DATE: {
            return {
                ...initialState,
                loading: true,
            };
        }
        case LOAD_DELIVERY_DATE_SUCCESS: {
            return {
                ...state,
                date: (action as AimoLoadDeliveryDateSuccess).date,
            };
        }
        case LOAD_DELIVERY_DATE_FAIL: {
            return {
                ...initialState,
                loading: false,
            };
        }
    }
    return state;
}
