import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
    selector: 'aimo-two-column-content',
    templateUrl: './aimo-two-column-content.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoTwoColumnContentComponent {
    constructor() {}
}
