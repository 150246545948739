import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { LogoutGuard } from '@spartacus/storefront';
import { from, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/**
 * Guards the _logout_ route.
 *
 * After logging out, we want to be redirected to the login page.
 */
@Injectable({
    providedIn: 'root',
})
export class AimoLogoutGuard extends LogoutGuard {
    canActivate(): Observable<boolean | UrlTree> {
        return from(this.logout()).pipe(switchMap(() => of(this.getRedirectUrl())));
    }
}
