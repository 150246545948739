import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';

import { AimoLoginComponent } from './aimo-login.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                LoginComponent: {
                    component: AimoLoginComponent,
                },
            },
        }),
        I18nModule,
    ],
    declarations: [AimoLoginComponent],
    entryComponents: [AimoLoginComponent],
    exports: [AimoLoginComponent],
})
export class AimoLoginModule {}
