<div class="container" *ngIf="model$ | async as model; else loading">
    <h2 class="search-result-header">
        {{
            (model.pagination.totalResults === 0 ? 'productList.noResultsFor' : 'productList.resultsFor')
                | cxTranslate : { freeTextSearch: model?.freeTextSearch }
        }}
    </h2>

    <p *ngIf="model.pagination.totalResults === 0">
        {{ 'productList.noResults.text' | cxTranslate }}
    </p>

    <ng-container *ngIf="facets$ | async as facet">
        <div class="sub-categories-wrapper">
            <div class="sub-categories">
                <aimo-product-category-facets
                    [showAll]="showAllCategories"
                    (showAllAction)="toggleShowAllCategories($event)"
                    [values]="facet.values"
                    [link]="'/search'"></aimo-product-category-facets>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <div class="container">
        <h2 class="search-result-header">
            {{ 'productList.resultsFor' | cxTranslate : { freeTextSearch: '' } }}
        </h2>
    </div>
</ng-template>
