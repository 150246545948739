import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CartBaseModule } from '@spartacus/cart/base';
import { ProductModule } from '@spartacus/core';
import { FacetModule, SearchBoxModule } from '@spartacus/storefront';
import { UserAccountModule } from '@spartacus/user';
import { LoginFormModule, LoginModule } from '@spartacus/user/account/components';
import { RecaptchaV3Module } from 'ng-recaptcha';

import { SpartacusModule } from '../spartacus/spartacus.module';

import { AimoBaseOrderModule } from './cms-components/base-order/aimo-base-order.module';
import { AimoBulletinsModule } from './cms-components/bulletins/aimo-bulletins.module';
import { AimoCartDetailsModule } from './cms-components/cart-details/aimo-cart-details.module';
import { AimoAddToCartModule } from './cms-components/cart-shared/add-to-cart/aimo-add-to-cart.module';
import { AimoCartSharedModule } from './cms-components/cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from './cms-components/cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoMiniCartModule } from './cms-components/cart-shared/mini-cart/aimo-mini-cart.module';
import { AimoCartTotalsModule } from './cms-components/cart-totals/aimo-cart-totals.module';
import { AimoClaimsModule } from './cms-components/claims/aimo-claims.module';
import { AimoFooterModule } from './cms-components/footer/aimo-footer.module';
import { AimoCustomerSelectionModule } from './cms-components/navigation/customer-selection/aimo-customer-selection.module';
import { AimoMainNavigationModule } from './cms-components/navigation/main-navigation/aimo-main-navigation.module';
import { AimoNavigationModule } from './cms-components/navigation/navigation/aimo-navigation.module';
import { AimoSearchBoxModule } from './cms-components/navigation/search-box/aimo-search-box.module';
import { AimoTopHeaderModule } from './cms-components/navigation/top-header/aimo-top-header.module';
import { AimoOrderConfirmationModule } from './cms-components/order-confirmation/aimo-order-confirmation.module';
import { AimoOrderHistoryModule } from './cms-components/order-history/aimo-order-history.module';
import { AimoOrderTemplateModule } from './cms-components/order-template/aimo-order-template.module';
import { AimoProductComparisonModule } from './cms-components/product/comparison/aimo-product-comparison.module';
import { AimoProductFacetNavigationModule } from './cms-components/product/facet-navigation/aimo-product-facet-navigation.module';
import { AimoFacetModule } from './cms-components/product/facet-navigation/facet/aimo-facet.module';
import { AimoFacetListModule } from './cms-components/product/facet-navigation/facet-list/aimo-facet-list.module';
import { AimoProductListModule } from './cms-components/product/product-list/aimo-product-list.module';
import { AimoProductSummaryModule } from './cms-components/product/product-summary/aimo-product-summary.module';
import { AimoReportsModule } from './cms-components/reports/aimo-reports.module';
import { AimoBackgroundImageModule } from './cms-components/shared/background-image/aimo-background-image.module';
import { AimoBannerModule } from './cms-components/shared/banner/aimo-banner.module';
import { AimoBannerCarouselModule } from './cms-components/shared/banner-carousel/aimo-banner-carousel.module';
import { AimoCategoryListModule } from './cms-components/shared/category-list/aimo-category-list.module';
import { AimoContactItemModule } from './cms-components/shared/contact-item/aimo-contact-item.module';
import { AimoContentPageHeaderModule } from './cms-components/shared/content-page-header/aimo-content-page-header.module';
import { AimoFaqItemModule } from './cms-components/shared/faq-item/aimo-faq-item.module';
import { AimoFaqSectionHeaderModule } from './cms-components/shared/faq-section-header/aimo-faq-section-header.module';
import { CmsImageModule } from './cms-components/shared/image/image.module';
import { CmsParagraphModule } from './cms-components/shared/paragraph/paragraph.module';
import { AimoSpinnerModule } from './cms-components/shared/spinner/aimo-spinner.module';
import { AimoTwoColumnContentModule } from './cms-components/shared/two-column-content/aimo-two-column-content.module';
import { AimoSiteContextSelectorModule } from './cms-components/site-context-selector/aimo-site-context-selector.module';
import { AimoConsentModule } from './cms-components/user/consent/aimo-consent.module';
import { AimoForgotPasswordModule } from './cms-components/user/forgot-password/aimo-forgot-password.module';
import { AimoLoginModule } from './cms-components/user/login/aimo-login.module';
import { AimoLoginFormModule } from './cms-components/user/login-form/aimo-login-form.module';
import { AimoLoginRegisterModule } from './cms-components/user/login-register/aimo-login-register.module';
import { AimoMyAssortmentModule } from './cms-components/user/my-assortment/aimo-my-assortment.module';
import { AimoMyOrganizationModule } from './cms-components/user/my-organization/aimo-my-organization.module';
import { AimoProfileModule } from './cms-components/user/profile/aimo-profile.module';
import { AimoResetPasswordModule } from './cms-components/user/reset-password/aimo-reset-password.module';
import { AimoHamburgerMenuModule } from './layout/hamburger-menu/aimo-hamburger-menu.module';
import { AimoMainModule } from './layout/main/aimo-main.module';
import { AimoCartOccModule } from './service/cart/aimo-cart-occ.module';
import { AimoCMSServiceModule } from './service/cms/aimo-cms-service.module';
import { AimoGtmModule } from './service/gtm/aimo-gtm.module';
import { AimoProductOccModule } from './service/product/aimo-product-occ.module';
import { AimoDeliverydateModule } from './service/routecalendar/aimo-deliverydate.module';
import { AimoUserOccModule } from './service/user/aimo-user-occ.module';
import { AimoNotFoundModule } from './shared/not-found/aimo-not-found.module';
import { AimoSortingModule } from './shared/sorting/aimo-sorting.module';
import { SmartEditFeatureModule } from './smartedit/smart-edit-feature.module';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [],
    providers: [],
    exports: [
        //Spartacus
        SpartacusModule,
        SmartEditFeatureModule,
        // AsmFeatureModule,
        LoginModule,
        LoginFormModule,
        ProductModule,
        UserAccountModule,
        CartBaseModule,
        FacetModule,
        SearchBoxModule,
        //Aimo
        AimoLoginRegisterModule,
        AimoMainModule,
        AimoProductListModule,
        AimoFacetListModule,
        AimoFacetModule,
        AimoProductFacetNavigationModule,
        AimoProductOccModule,
        AimoCartOccModule,
        AimoUserOccModule,
        AimoProductSummaryModule,
        AimoSiteContextSelectorModule,
        AimoCartDetailsModule,
        AimoCartSharedModule,
        AimoCartTotalsModule,
        AimoFooterModule,
        AimoForgotPasswordModule,
        AimoResetPasswordModule,
        AimoMainNavigationModule,
        AimoNavigationModule,
        AimoTopHeaderModule,
        AimoCustomerSelectionModule,
        AimoLoginModule,
        AimoDeliveryDateModule,
        AimoBackgroundImageModule,
        AimoLoginFormModule,
        AimoSearchBoxModule,
        AimoMiniCartModule,
        AimoBannerModule,
        AimoBannerCarouselModule,
        AimoAddToCartModule,
        AimoDeliverydateModule,
        AimoProfileModule,
        AimoOrderConfirmationModule,
        AimoOrderHistoryModule,
        AimoHamburgerMenuModule,
        AimoMyOrganizationModule,
        AimoProductComparisonModule,
        AimoNotFoundModule,
        AimoSpinnerModule,
        AimoMyAssortmentModule,
        AimoSortingModule,
        AimoCategoryListModule,
        AimoGtmModule,
        AimoOrderTemplateModule,
        AimoBulletinsModule,
        AimoConsentModule,
        CmsParagraphModule,
        CmsImageModule,
        AimoTwoColumnContentModule,
        AimoContentPageHeaderModule,
        AimoFaqSectionHeaderModule,
        AimoFaqItemModule,
        AimoContactItemModule,
        RecaptchaV3Module,
        AimoClaimsModule,
        AimoBaseOrderModule,
        AimoCMSServiceModule,
        AimoReportsModule,
    ],
})
export class FeaturesModule {}
