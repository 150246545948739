import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoB2BUnitOption, AimoUser } from '../../../../model/user.model';
import { AimoUserService } from '../../../../service/user/aimo-user.service';

import { AimoEditB2BUnitData } from './aimo-edit-b2bunit.config';

@Component({
    selector: 'aimo-edit-b2bunitr',
    templateUrl: './aimo-edit-b2bunit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoEditB2bunitComponent {
    form: FormGroup;
    allUsers: AimoUser[];
    b2bUnit: AimoB2BUnitOption;

    constructor(
        protected cdr: ChangeDetectorRef,
        protected launchDialogService: LaunchDialogService,
        protected userService: AimoUserService,
    ) {
        this.launchDialogService.data$.subscribe((data: AimoEditB2BUnitData) => this.initForm(data)).unsubscribe();
    }

    initForm(data: AimoEditB2BUnitData): void {
        this.allUsers = data.allUsers;
        this.b2bUnit = data.b2bUnit;
        const controls = {};
        this.allUsers.forEach(
            (user) =>
                (controls[user.uid] = new FormControl(
                    this.b2bUnit.organizationUsers.filter((u) => u.uid === user.uid).length > 0,
                )),
        );
        this.form = new FormGroup(controls);
    }

    save(): void {
        if (this.form.valid) {
            this.userService.modifyB2BUnit({
                uid: this.b2bUnit.uid,
                organizationUsers: this.allUsers.filter((user) => this.form.controls[user.uid].value === true),
            });
            this.closeDialog();
        }
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ADD_NEW_USER);
        this.launchDialogService.closeDialog('closed');
    }
}
