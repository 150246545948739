<ng-container *ngIf="products$ | async as products">
    <div class="noPrint list" *ngIf="products.length > 0">
        <div class="list-content row">
            <div class="col d-flex list-products pl-0">
                <div class="item" *ngFor="let product of products; index as idx">
                    <div class="row no-gutters align-items-center">
                        <div class="col d-flex align-items-center">
                            <a
                                role="button"
                                [id]="'comparison_preview_product_image_' + idx"
                                class="cx-product-image-container mr-1 cursor-pointer"
                                (click)="openProductModal(product.code, idx, 'comparison_preview_product_image_' + idx)"
                                tabindex="0">
                                <cx-media
                                    class="cx-product-image"
                                    [container]="product.images?.PRIMARY"
                                    format="cartIcon"
                                    [alt]="product.name || product.summary"></cx-media>
                            </a>
                            <div class="product-name-wrapper">
                                <button
                                    [id]="'comparison_preview_product_name_' + idx"
                                    class="text-left btn-link text-bold"
                                    [attr.aria-label]="
                                        'aimo.product.openModal.arialLabel'
                                            | cxTranslate : { productName: product.name }
                                    "
                                    (click)="
                                        openProductModal(product.code, idx, 'comparison_preview_product_name_' + idx)
                                    "
                                    tabindex="0">
                                    {{ product.name }}
                                </button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <i
                                (click)="remove(product)"
                                class="aimo-icon icon-trash icon-cursor icon-inline icon-sm icon-petrol-blue mr-2">
                            </i>
                        </div>
                    </div>
                </div>
                <div class="fade-div">
                    <div class="fade-right"></div>
                </div>
            </div>
            <div class="col-auto ml-auto pr-0 d-flex align-items-center">
                <div class="row">
                    <div class="col-6">
                        <button (click)="clear()" class="btn btn-secondary btn-sm btn-block" type="submit">
                            {{ 'productList.filter.empty' | cxTranslate }}
                        </button>
                    </div>
                    <div class="col-6">
                        <button (click)="compare()" class="btn btn-primary btn-sm btn-block" type="submit">
                            {{ 'productList.filter.compare' | cxTranslate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
