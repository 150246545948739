import { Injectable } from '@angular/core';
import { CartModification } from '@spartacus/cart/base/root';
import { ConverterService, Occ, PRODUCT_NORMALIZER } from '@spartacus/core';

import { AimoCartModification } from '../../model/cart.model';

@Injectable({ providedIn: 'root' })
export class AimoOccCartModificationNormalizer {
    constructor(protected converter: ConverterService) {}

    convert(source: Occ.CartModification, target?: AimoCartModification): CartModification {
        if (Array.isArray(source)) {
            // eslint-disable-next-line
            return (source as []).map((s) => this.convertSingle(s, undefined)) as any;
        }
        return this.convertSingle(source, target);
    }

    convertSingle(source: Occ.CartModification, target?: AimoCartModification): CartModification {
        if (target === undefined) {
            // eslint-disable-next-line
            target = { ...(source as any) } as AimoCartModification;
        }
        if (target.entry) {
            // eslint-disable-next-line
            target.quantity = (target as any).doubleQuantity;
            // eslint-disable-next-line
            target.quantityAdded = (target as any).addedDoubleQuantity;
            // eslint-disable-next-line
            target.entry.quantity = (target.entry as any).doubleQuantity;
            target.entry.product = this.converter.convert(target.entry.product, PRODUCT_NORMALIZER);
        }
        return target;
    }
}
