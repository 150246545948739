/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { AtMessageModule, FacetModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';

import { AimoInputModule } from '../../../../shared/input/aimo-input.module';
import { AimoDatepickerModule } from '../../../shared/datepicker/aimo-datepicker.module';
import { AimoPipesModule } from '../../../shared/utils/safe-html/aimo-pipes.module';

import { AimoFacetComponent } from './aimo-facet.component';

@NgModule({
    imports: [
        AtMessageModule,
        CommonModule,
        I18nModule,
        IconModule,
        KeyboardFocusModule,
        RouterModule,
        UrlModule,
        AimoInputModule,
        AimoDatepickerModule,
        AimoPipesModule,
        FormsModule,
    ],
    declarations: [AimoFacetComponent],
    exports: [AimoFacetComponent],
})
export class AimoFacetModule extends FacetModule {}
