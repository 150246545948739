import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';

import { AimoCustomerSearchComponent } from './aimo-customer-search.component';
import { AimoCustomerSelectionComponent } from './aimo-customer-selection.component';

@NgModule({
    imports: [
        I18nModule,
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                CustomerSelectionComponent: {
                    component: AimoCustomerSelectionComponent,
                },
                CustomerSearchComponent: {
                    component: AimoCustomerSearchComponent,
                },
            },
        }),
        NgSelectModule,
        NgbDropdownModule,
        FormsModule,
        UrlModule,
        PageSlotModule,
    ],
    declarations: [AimoCustomerSelectionComponent, AimoCustomerSearchComponent],
    entryComponents: [AimoCustomerSelectionComponent, AimoCustomerSearchComponent],
    exports: [AimoCustomerSelectionComponent, AimoCustomerSearchComponent],
})
export class AimoCustomerSelectionModule {}
