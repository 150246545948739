import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

import { ImageComponent } from './image.component';

@NgModule({
    imports: [CommonModule, MediaModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSImageComponent: {
                    component: ImageComponent,
                },
            },
        }),
    ],
    declarations: [ImageComponent],
    exports: [ImageComponent],
    entryComponents: [ImageComponent],
})
export class CmsImageModule {}
