import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import { WindowRef } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';

import { AimoRegistration } from '../../../model/user.model';
import { REGISTER_FAILED, REGISTER_SUCCESS, RegisterFailed } from '../../../service/user/aimo-user.action';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { AimoCustomFormValidators } from '../../../shared/validators/aimo-custom-form-validators';

@Component({
    selector: 'aimo-login-register',
    templateUrl: './aimo-login-register.component.html',
})
export class AimoLoginRegisterComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    form: FormGroup;
    customerCounter = 0;
    registerDone = false;

    error: string;

    constructor(
        private actions$: Actions,
        protected cdr: ChangeDetectorRef,
        protected userService: AimoUserService,
        protected aWinRef: WindowRef,
        protected recaptchaV3Service: ReCaptchaV3Service,
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup(
            {
                name: new FormControl(null, [Validators.required]),
                email: new FormControl(null, [Validators.required, CustomFormValidators.emailValidator]),
                phone: new FormControl(null, [Validators.required, AimoCustomFormValidators.phoneNumberValidator]),
                password: new FormControl(null, [Validators.required, AimoCustomFormValidators.passwordValidator]),
                passwordConfirm: new FormControl(null, Validators.required),
                acceptTerms: new FormControl(null, Validators.required),
                customer: new FormControl(null, [
                    Validators.required,
                    CustomFormValidators.mustBePositive,
                    Validators.maxLength(10),
                ]),
            },
            {
                validators: [CustomFormValidators.passwordsMustMatch('password', 'passwordConfirm')],
            },
        );
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === REGISTER_SUCCESS) {
                    this.registerDone = true;
                    this.scrollToTop();
                    this.cdr.detectChanges();
                } else if (action.type === REGISTER_FAILED) {
                    this.error = (action as RegisterFailed).error;
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    scrollToTop(): void {
        this.aWinRef.document.querySelector('#register-top-element').scrollIntoView();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.error = undefined;
            this.subscription.add(
                this.recaptchaV3Service.execute('importantAction').subscribe((token: string) => {
                    this.userService.register({
                        name: this.form.controls['name'].value,
                        email: this.form.controls['email'].value,
                        phone: this.form.controls['phone'].value,
                        password: this.form.controls['password'].value,
                        companies: [
                            {
                                customerId: this.form.controls['customer'].value,
                            },
                        ],
                        captchaToken: token,
                    } as AimoRegistration);
                }),
            );
        }
    }
}
