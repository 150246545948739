import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacetComponent, FacetService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';

import { AimoFacet, AimoFacetValue } from '../../../../model/product.model';
import { AimoProductFacetService } from '../../../../service/product/aimo-product-facet.service';

@Component({
    selector: 'aimo-facet',
    templateUrl: './aimo-facet.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoFacetComponent extends FacetComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Input() mobileUI: boolean;

    @ViewChild('facetDiv', { static: false })
    facetDiv: ElementRef;

    @Input() closeFacet: Observable<string>;
    @Output() openFacet = new EventEmitter<string>();

    @Output() selectedQueryTerms = new EventEmitter<AimoFacetValue>();

    subscription = new Subscription();
    searchText = '';
    private facetOpened = false;

    facetsScrollChecked: boolean = false;

    constructor(
        protected facetService: FacetService,
        protected elementRef: ElementRef<HTMLElement>,
        protected cd: ChangeDetectorRef,
        protected eRef: ElementRef,
        protected route: ActivatedRoute,
        protected productFacetService: AimoProductFacetService,
        protected activatedRoute: ActivatedRoute,
    ) {
        super(facetService, elementRef, cd);
    }

    ngOnInit(): void {
        this.increaseVisibleValues();
        this.subscription.add(
            this.closeFacet.subscribe((name) => {
                if (name !== this.facet.name) {
                    this.closeFacetDiv();
                }
            }),
        );
        this.facet = {
            ...this.facet,
            values: this.facet.values
                ?.filter((v: AimoFacetValue) => v.selected || v.count > 0 || v.rangeMax !== undefined)
                .map((v) => ({ ...v })),
        };
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewChecked(): void {
        if (!this.facetsScrollChecked) {
            this.activatedRoute.fragment.subscribe((fragment: string) => {
                if (fragment) {
                    window.scrollTo(0, this.productFacetService.getScrollPosition());
                }
                this.facetsScrollChecked = true;
            });
        }
    }

    get aimoFacet(): AimoFacet {
        return this._facet;
    }

    // id for querySelector has to have escaped dots, otherwise will search by id and class.
    getLinkParamsForFacetRange(event: Event, element: Element, remove?: boolean): void {
        event?.preventDefault();
        let queryValue = this.productFacetService.initQueryValue(this.route);
        let currentQuery = this.productFacetService.getCurrentQuery(this.route);

        this.aimoFacet.values.forEach((facetValue) => {
            if (remove || !facetValue.selected) {
                facetValue.selected = false;
                currentQuery = currentQuery.replace(
                    ':' + facetValue.code + ':' + facetValue.rangeMin + ' - ' + facetValue.rangeMax,
                    '',
                );
            }
            if (facetValue.selected) {
                queryValue += this.productFacetService.getRangeFacetQuery(
                    this.getFacetRangeValue(element, facetValue, true, false),
                    this.getFacetRangeValue(element, facetValue, false, false),
                    facetValue,
                );
                currentQuery = this.productFacetService.removeRangeFacet(currentQuery, facetValue);
            }
        });
        this.productFacetService.navigateFacetToSolr(this.route, currentQuery + queryValue, this.facetService);
        this.productFacetService.setFacetClicked(true);
        this.productFacetService.setScrollPosition(window.pageYOffset);
        this.facetsScrollChecked = false;
    }

    getLinkParamsForFacet(event: Event, remove?: boolean): void {
        event.preventDefault();
        let queryValue = this.productFacetService.initQueryValue(this.route);
        let currentQuery = this.productFacetService.getCurrentQuery(this.route);
        if (queryValue.length > 1 && currentQuery.indexOf(queryValue) >= 0) {
            queryValue = '';
        }
        this.aimoFacet.values.forEach((facetValue) => {
            if (remove || !facetValue.selected) {
                facetValue.selected = false;
                const regexp = new RegExp('\\:' + this.aimoFacet.code + '\\:' + facetValue.code + '(\\:|$)', 'g');
                currentQuery = currentQuery.replace(regexp, ':');
            }
            let tail = this.productFacetService.getLinkParamForFacetValue(this.aimoFacet, facetValue, currentQuery);
            if (tail.startsWith(':') && queryValue.endsWith(':')) {
                tail = tail.substring(1);
            }
            queryValue += tail;
        });
        this.productFacetService.navigateFacetToSolr(
            this.route,
            currentQuery + queryValue,
            this.facetService,
            'facet_' + this.aimoFacet.code,
        );
        this.productFacetService.setFacetClicked(true);
        this.productFacetService.setScrollPosition(window.pageYOffset);
        this.facetsScrollChecked = false;
    }

    get isExpanded(): boolean {
        return this.values?.first?.nativeElement.offsetParent !== null;
    }

    toggleFacet(): void {
        this.facetDiv.nativeElement.classList.toggle('expanded');
        this.facetDiv.nativeElement.classList.toggle('collapsed');
        if (this.facetDiv.nativeElement.classList.contains('expanded')) {
            this.openFacet.emit(this.facet.name);
            this.facetOpened = true;
        } else {
            this.facetOpened = false;
        }
    }

    @HostListener('document:click', ['$event'])
    clickOut(event): void {
        if (!this.eRef?.nativeElement.contains(event.target) && this.facetDiv) {
            this.closeFacetDiv();
        }
    }

    closeFacetDiv(): void {
        this.facetDiv?.nativeElement.classList.remove('expanded');
        this.facetDiv?.nativeElement.classList.add('collapsed');
    }

    toggleSelected(facetValue: AimoFacetValue): void {
        facetValue.selected = !facetValue.selected;
        this.emitFacetChanges(facetValue);
    }

    emitFacetChanges(facetValue: AimoFacetValue, rangeElement?: Element): void {
        this.selectedQueryTerms.emit({
            ...facetValue,
            rangeMin: this.getFacetRangeValue(rangeElement, facetValue, true, false),
            rangeMax: this.getFacetRangeValue(rangeElement, facetValue, false, false),
            facetCode: this.aimoFacet.code,
        });
    }

    rangeIsNotValid(facetValue: AimoFacetValue, rangeElement: HTMLDivElement): boolean {
        const facetMin = this.getFacetRangeValue(rangeElement, facetValue, true, true);
        const facetMax = this.getFacetRangeValue(rangeElement, facetValue, false, true);
        return facetMin > facetMax;
    }

    getFacetRangeValue(
        rangeElement: Element,
        facetValue: AimoFacetValue,
        min: boolean,
        allowInvalidValue: boolean,
    ): number {
        let facetMin = rangeElement ? this.getRangeValue(rangeElement, facetValue, true) : facetValue.rangeMin;
        let facetMax = rangeElement ? this.getRangeValue(rangeElement, facetValue, false) : facetValue.rangeMax;
        if (!allowInvalidValue) {
            facetMin = facetMin <= facetMax ? facetMin : facetValue.rangeMin;
            facetMax = facetMax >= facetMin ? facetMax : facetValue.rangeMax;
        }
        return min ? facetMin : facetMax;
    }

    getRangeValue(element: Element, facetValue: AimoFacetValue, min: boolean): number {
        if (element) {
            const ret = parseFloat(
                (
                    element.querySelector(
                        (min ? '#minQty_' : '#maxQty_') + facetValue.code.replace('.', '\\.'),
                    ) as HTMLInputElement
                )?.value,
            );
            if (isNaN(ret)) {
                return min ? facetValue.rangeMin : facetValue.rangeMax;
            }
            return ret;
        }
        return null;
    }

    getSelectedFacet(values: AimoFacetValue[]): number {
        return values.filter((value) => value.selected).length;
    }
}
