import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FeaturesConfigModule, I18nModule } from '@spartacus/core';
import {
    AnonymousConsentsDialogModule,
    KeyboardFocusModule,
    OutletModule,
    OutletRefModule,
    PageLayoutModule,
    PageSlotModule,
    PwaModule,
    SeoModule,
    SkipLinkModule,
} from '@spartacus/storefront';

import { AimoGlobalMessageModule } from '../../cms-components/shared/global-message/aimo-global-message.module';

import { AimoStorefrontComponent } from './aimo-storefront.component';

@NgModule({
    imports: [
        AnonymousConsentsDialogModule,
        CommonModule,
        FeaturesConfigModule,
        I18nModule,
        KeyboardFocusModule,
        AimoGlobalMessageModule,
        OutletModule,
        OutletRefModule,
        PageLayoutModule,
        PageSlotModule,
        PwaModule,
        RouterModule,
        SeoModule,
        SkipLinkModule,
    ],
    declarations: [AimoStorefrontComponent],
    exports: [AimoStorefrontComponent],
})
export class AimoMainModule {}
