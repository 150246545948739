<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm" #modalContent>
        <div class="modal-header">
            <h2 class="mb-0" *ngIf="!modifyUser">{{ 'aimo.myorganization.users.createNew' | cxTranslate }}</h2>
            <h2 class="mb-0" *ngIf="modifyUser">{{ 'aimo.myorganization.users.modify' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button
                    [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate"
                    [title]="'aimo.arialLabel.close.modal' | cxTranslate"
                    (click)="closeDialog()"
                    class="btn-link">
                    <i class="aimo-icon icon-inline icon-x"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" [formGroup]="form">
            <ng-container *ngIf="!modifyUser; else editUserTemplate">
                <div class="field-content">
                    <aimo-cx-input
                        [autofocus]="true"
                        [required]="true"
                        [parentForm]="form"
                        [label]="'aimo.register.name.label'"
                        [nameForm]="'name'"
                        [labelClass]="'text-bold'"
                        idName="name"></aimo-cx-input>
                    <cx-form-errors
                        [control]="form.get('name')"
                        aria-atomic="true"
                        aria-live="assertive"></cx-form-errors>
                </div>

                <div class="field-content">
                    <aimo-cx-input
                        [required]="true"
                        [parentForm]="form"
                        [label]="'aimo.register.email.label'"
                        [nameForm]="'email'"
                        [labelClass]="'text-bold'"
                        idName="email"></aimo-cx-input>
                    <cx-form-errors
                        [control]="form.get('email')"
                        aria-atomic="true"
                        aria-live="assertive"></cx-form-errors>
                </div>
            </ng-container>
            <ng-template #editUserTemplate>
                <div class="bg-petrol-light p-3 mb-4" *ngIf="!userLocked; else unLockUser">
                    <span class="text-bold"> {{ user.name }}</span>
                    <br />
                    <span>{{ form.controls.email.value }}</span>
                </div>
                <ng-template #unLockUser>
                    <div class="bg-petrol-light p-3 mb-4">
                        <span class="text-bold">
                            {{ form.controls.name.value }}
                        </span>
                        <br />
                        <span>{{ user.uid }}</span>
                    </div>
                    <div class="messages mt-4 mb-4">
                        <div class="message message-WARNING">
                            <i class="left-side aimo-icon icon-inline icon-WARNING icon-red mr-2 align-self-start"></i>
                            <div class="ml-1">
                                <span [innerHTML]="'aimo.myorganization.users.islocked' | cxTranslate"></span>
                                &nbsp; <br />
                                <button class="btn-link text-default text-underline" (click)="openAccount()">
                                    {{ 'aimo.myorganization.userSelection.openAccount' | cxTranslate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <div class="form-check mb-4">
                    <label for="customerAdmin">
                        <input
                            (click)="accountClosable()"
                            id="customerAdmin"
                            type="checkbox"
                            formControlName="customerAdmin"
                            [checked]="form.controls.customerAdmin.value === true" />
                        <span innerHTML="{{ 'aimo.register.customerAdmin.label' | cxTranslate }}"></span>
                    </label>
                </div>

                <h3>{{ 'aimo.myorganization.users.customers' | cxTranslate }}</h3>

                <div class="mb-3 position-relative" *ngIf="b2bUnits.length > 10">
                    <aimo-cx-input
                        [autofocus]="true"
                        (afterInput)="changeSearch($event)"
                        [placeholder]="'aimo.myorganization.users.editUser.search'"
                        [nameForm]="'search'"
                        [inputValue]="searchUnits"
                        [label]="'aimo.myorganization.users.editUser.search'"
                        [labelClass]="'d-none'"
                        [idName]="'b2bUnitsSearch'"
                        [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                    </aimo-cx-input>
                    <button class="btn-link btn-close" *ngIf="searchUnits" (click)="changeSearch('')">
                        <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                    </button>
                </div>

                <div [ngClass]="b2bUnits.length < 10 ? '' : 'div-units'">
                    <fieldset class="div-units-scroll">
                        <legend class="d-none">{{ 'aimo.arialLabel.organization.customers' | cxTranslate }}</legend>
                        <div
                            class="form-check"
                            *ngFor="let b2bUnit of b2bUnits | aimoFilter : searchUnits : ['name', 'uid']">
                            <label>
                                <input
                                    type="checkbox"
                                    [formControlName]="b2bUnit.uid"
                                    [checked]="form.controls[b2bUnit.uid].value === true" />
                                <span class="text-bold">{{ b2bUnit.uid }}</span
                                >&nbsp;&nbsp;<span>{{ b2bUnit.name }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </ng-template>

            <div class="modal-actions row no-gutters align-items-center btn-actions">
                <div class="col-md-auto col-6 pr-1">
                    <button
                        [attr.aria-label]="'aimo.myorganization.users.save' | cxTranslate"
                        [title]="'aimo.myorganization.users.save' | cxTranslate"
                        class="btn btn-primary btn-sm"
                        (click)="save()">
                        {{ 'aimo.myorganization.users.save' | cxTranslate }}
                    </button>
                </div>
                <div class="col-md-auto col-6 pl-1">
                    <button
                        [attr.aria-label]="'aimo.myorganization.users.cancel' | cxTranslate"
                        [title]="'aimo.myorganization.users.cancel' | cxTranslate"
                        class="btn btn-secondary btn-sm"
                        (click)="closeDialog()">
                        {{ 'aimo.myorganization.users.cancel' | cxTranslate }}
                    </button>
                </div>
                <div class="col-md-auto ml-auto col-12 pt-md-0 pt-3 text-right">
                    <button
                        *ngIf="accountClosable()"
                        (click)="closeAccount()"
                        [attr.aria-label]="'aimo.myorganization.userSelection.closeAccount' | cxTranslate"
                        [title]="'aimo.myorganization.userSelection.closeAccount' | cxTranslate"
                        class="text-red btn-link">
                        <i class="aimo-icon icon-prohibit icon-red icon-inline"></i>
                        <span>{{ 'aimo.myorganization.userSelection.closeAccount' | cxTranslate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
