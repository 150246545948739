<div class="d-inline">
    <!--Here we could change the status color by setting new stock-status-{{stock.stockLevelStatus}}-->
    <div
        [ngClass]="{ 'stock-status-icon': stock.terminalProduct }"
        class="stock-status stock-status-{{ stock.stockLevelStatus.toLowerCase() }}">
        <i
            *ngIf="stock.terminalProduct"
            class="icon-inline aimo-icon icon-truck icon-sm"
            triggers="mouseenter:mouseleave"
            container="body"
            popoverClass="popover-default"
            [ngbPopover]="'aimo.product.terminalProduct' | cxTranslate"></i>
    </div>

    <span *ngIf="printStockName"> {{ stock.warehouse?.name }}: </span>
    <span class="d-none d-md-inline-block" *ngIf="!stock.terminalProduct">
        <ng-container *ngTemplateOutlet="stockLabel"> </ng-container>
    </span>
    <span class="d-none d-md-inline-block" *ngIf="stock.terminalProduct">
        {{ stock.text }}
    </span>
    <span class="d-inline-block d-md-none" *ngIf="stock.text">
        <ng-container *ngTemplateOutlet="stockLabel"> </ng-container>
    </span>
</div>

<ng-template #stockLabel>
    <ng-container *ngIf="outOfStock">
        {{ stock.text }}
    </ng-container>
    <ng-container *ngIf="!outOfStock">
        <ng-container *ngIf="!suggestiveMode">
            <ng-container *ngIf="!stock.terminalProduct">
                {{ 'aimo.stock.availability' | cxTranslate : { amount: stock.text } }}
            </ng-container>
            <ng-container *ngIf="stock.terminalProduct">
                {{ stock.text }}
            </ng-container>
        </ng-container>
        <ng-container *ngIf="suggestiveMode">
            {{ stock.text }}
        </ng-container>
    </ng-container>
</ng-template>
