import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AimoCategoryHierarchy } from '../../../../model/product.model';

export const MAX_CATEGORIES = 10;

@Component({
    selector: 'aimo-category-header-subcategory',
    templateUrl: './aimo-category-header-subcategory.component.html',
})
export class AimoCategoryHeaderSubcategoryComponent {
    @Input() values: AimoCategoryHierarchy[];
    @Input() link: string;
    @Input() showAll = false;

    @Output() showAllAction = new EventEmitter<boolean>();

    toggleShowAll(): void {
        this.showAll = !this.showAll;
        this.showAllAction.emit(this.showAll);
    }

    getCategories(values: AimoCategoryHierarchy[]): AimoCategoryHierarchy[] {
        return this.showAll ? values : values.slice(0, MAX_CATEGORIES);
    }

    showToggle(length: number): boolean {
        return length > MAX_CATEGORIES;
    }
}
