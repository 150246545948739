import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoCMSFaqSectionHeaderComponent } from '../../../model/cms.model';

@Component({
    selector: 'aimo-faq-section-header',
    templateUrl: './aimo-faq-section-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoFaqSectionHeaderComponent implements OnInit {
    data$: Observable<AimoCMSFaqSectionHeaderComponent>;

    constructor(protected componentData: CmsComponentData<AimoCMSFaqSectionHeaderComponent>) {}

    ngOnInit(): void {
        this.data$ = this.componentData.data$;
    }
}
