import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoFooter } from '../../model/cms.model';
import { OpeningHour } from '../../model/user.model';
import { AimoNavigationNode } from '../../service/navigation/aimo-navigation-node.model';
import { AimoNavigationService } from '../../service/navigation/aimo-navigation.service';
import { AimoUserService } from '../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-cx-footer',
    templateUrl: './aimo-footer.component.html',
})
export class AimoFooterComponent implements OnInit {
    data$: Observable<AimoFooter>;

    private componentData$: Observable<AimoFooter> = this.component.data$.pipe(filter(Boolean));

    someNavNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(
            map((data) => data.navigationNode.children.filter((node) => node.uid === 'SomeNavNode')[0]),
        ),
    );

    storeGroupNavNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(
            map((data) => data.navigationNode.children.filter((node) => node.uid === 'StoreGroupNavNode')[0]),
        ),
    );

    customerServiceGroupNavNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(
            map((data) => data.navigationNode.children.filter((node) => node.uid === 'CustomerServiceGroupNavNode')[0]),
        ),
    );

    valioAimoGroupNavNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(
            map((data) => data.navigationNode.children.filter((node) => node.uid === 'ValioAimoGroupNavNode')[0]),
        ),
    );

    legalNavNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(
            map((data) => data.navigationNode.children.filter((node) => node.uid === 'LegalNavNode')[0]),
        ),
    );

    openingHours$: Observable<OpeningHour[]>;

    constructor(
        public component: CmsComponentData<AimoFooter>,
        protected service: AimoNavigationService,
        protected aimoRoutingService: AimoRoutingService,
        protected userService: AimoUserService,
    ) {}

    ngOnInit(): void {
        this.data$ = this.component.data$;
        this.openingHours$ = this.userService.getStoreOpeningHours();
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    getWeekday(): string {
        const date = new Date();
        return date.getDay().toString();
    }
}
