import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { AimoRoutingService } from './features/cms-structure/routing/aimo-routing.service';
import { AimoHamburgerMenuService } from './features/layout/hamburger-menu/aimo-hamburger-menu.service';

@Component({
    selector: 'aimo-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'Aimo';
    isExpanded$: Observable<boolean> = this.hamburgerMenuService.isExpanded;

    constructor(
        private hamburgerMenuService: AimoHamburgerMenuService,
        protected aimoRoutingService: AimoRoutingService,
    ) {}

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
