import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { AimoCMSBannerComponent } from '../../../model/cms.model';
import { GTMContentType, GTMCreativeSlot, GTMEventCode } from '../../../service/gtm/aimo-gtm.model';
import { AimoGtmService } from '../../../service/gtm/aimo-gtm.service';

@Component({
    selector: 'aimo-banner',
    templateUrl: './aimo-banner.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBannerComponent implements OnInit {
    @Input() banner: AimoCMSBannerComponent;

    componentData$: Observable<AimoCMSBannerComponent>;

    constructor(protected component: CmsComponentData<AimoCMSBannerComponent>, protected gtmService: AimoGtmService) {
        this.componentData$ = this.component.data$.pipe(
            filter(Boolean),
            tap((banner) => {
                this.gtmEvent(banner, GTMEventCode.ViewPromotion);
            }),
        );
    }

    ngOnInit(): void {
        if (this.banner) {
            this.gtmEvent(this.banner, GTMEventCode.ViewPromotion);
        }
    }

    buttonClicked(banner: AimoCMSBannerComponent): void {
        this.gtmEvent(banner, GTMEventCode.SelectPromotion);
    }

    private gtmEvent(banner: AimoCMSBannerComponent, event: GTMEventCode): void {
        this.gtmService.pushContentEvent(
            this.defineCreativeSlot(banner),
            banner.name,
            GTMContentType.aimo,
            banner.gtmCustomer?.code,
            event,
        );
    }

    private defineCreativeSlot(banner: AimoCMSBannerComponent): GTMCreativeSlot {
        if (banner.bannerType === 'HERO') {
            return GTMCreativeSlot.front_page_hero_banner;
        } else if (banner.bannerType === 'LARGE') {
            return GTMCreativeSlot.front_page_large_banner;
        }
        return GTMCreativeSlot.category_page_vendor_promotion;
    }

    isExternal(data: AimoCMSBannerComponent): boolean | null {
        return data.external === 'true' || data.external === true;
    }

    getTarget(data: AimoCMSBannerComponent): string | null {
        return this.isExternal(data) ? '_blank' : null;
    }
}
