<aimo-breadcrumb
    *ngIf="breadcrumbs$ | async as breadcrumbs"
    [hideTitle]="true"
    [showBackLink]="false"
    [normalBreadCrumbs]="breadcrumbs">
</aimo-breadcrumb>
<h1 class="page-title" tabindex="0" [attr.aria-label]="'aimo.reports.title' | cxTranslate">
    {{ 'aimo.reports.title' | cxTranslate }}
</h1>

<p>
    {{ 'aimo.reports.description1' | cxTranslate }}
</p>

<div class="noPrint" *ngIf="false">
    <ng-container
        *ngTemplateOutlet="
            notification;
            context: { level: 'INFO', textKey: 'aimo.reports.pageDescription' }
        "></ng-container>
</div>

<div class="aimo-tab-wrapper">
    <div class="aimo-tab-border">
        <div class="tab-items">
            <div class="tab-item cursor-pointer active">
                {{ 'aimo.reports.purchaseReport' | cxTranslate }}
            </div>
        </div>
    </div>
</div>

<div class="row cx-search-criteria noPrint" [formGroup]="searchForm">
    <div class="col-12 col-md-6 col-lg-4 order-lg-1">
        <aimo-cx-input
            (afterChange)="fetchReports()"
            [placeholder]="'aimo.reports.searchTitle'"
            [parentForm]="searchForm"
            [nameForm]="'searchTerm'"
            [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
        </aimo-cx-input>
    </div>
    <div class="col-12 col-md-6 col-lg-4 order-lg-3 range-dates pt-4 pt-md-0">
        <aimo-datepicker-input
            class="date-from"
            [idName]="'delivery_date_from'"
            (afterChange)="fetchReports()"
            [placeholder]="'aimo.orderhistory.dateFrom' | cxTranslate"
            [parentForm]="searchForm"
            [customDayTemplate]="customDay"
            [nameForm]="'from'"></aimo-datepicker-input>
        <span class="date-divider"> - </span>
        <aimo-datepicker-input
            class="date-to"
            [idName]="'delivery_date_to'"
            (afterChange)="fetchReports()"
            [placeholder]="'aimo.orderhistory.dateTo' | cxTranslate"
            [parentForm]="searchForm"
            [customDayTemplate]="customDay"
            [nameForm]="'to'"></aimo-datepicker-input>
    </div>
    <div class="col-12 col-lg-4 order-lg-2">
        <div class="cart-functions float-lg-right h-100">
            <button [id]="'to_excel'" class="btn-link order-5 d-md-inline-block" (click)="exportCartToExcel()">
                <i class="aimo-icon icon-file-arrow-down icon-inline icon-sm"></i>
                <span>{{ 'aimo.cart.export.excel' | cxTranslate }}</span>
            </button>

            <button [id]="'print'" class="btn-link order-6 d-md-inline-block" (click)="print()">
                <i class="aimo-icon icon-printer icon-inline icon-sm"></i>
                <span>{{ 'aimo.cart.print' | cxTranslate }}</span>
            </button>
        </div>
    </div>
</div>

<ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-today="today"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">
    <button
        class="calendar-cell calendar-day link"
        [ngClass]="{ focused: focused, selected: selected, disabled: disabled }">
        {{ date.day }}
    </button>
</ng-template>

<div *ngIf="cart$ | async as cart" class="aimo-order-templates-table">
    <div class="sub-header sub-header-line col-12 row bg-petrol-light p-2 text-bold no-gutters d-none d-lg-flex">
        <div class="col-12 order-1 col-lg-8">
            {{ 'aimo.cart.item.product' | cxTranslate }}
        </div>
        <div class="col-6 order-2 col-lg-2">
            {{ 'aimo.cart.item.quantity' | cxTranslate }}
        </div>
        <div class="col-6 order-3 col-lg-2">
            {{ 'aimo.cart.item.total' | cxTranslate }}
        </div>
    </div>
    <ng-container *ngIf="!cart.categoryGroupedEntries">
        <ng-container
            *ngTemplateOutlet="
                notification;
                context: { level: 'WARNING', textKey: 'aimo.reports.tooManyResults', excel: true }
            "></ng-container>
    </ng-container>
    <ng-container *ngIf="cart.categoryGroupedEntries?.length === 0">
        <ng-container
            *ngTemplateOutlet="
                notification;
                context: { level: 'MESSAGE', textKey: 'aimo.reports.noResults' }
            "></ng-container>
    </ng-container>
    <ng-container *ngFor="let categoryGroupedEntry of cart.categoryGroupedEntries; index as idx">
        <div
            class="col-12 sub-header sub-header-line bg-light-grey p-2 text-bold"
            *ngIf="categoryGroupedEntry.title !== null">
            {{ categoryGroupedEntry.title !== '' ? categoryGroupedEntry.title : '&nbsp;' }}
        </div>
        <ng-container *ngFor="let entry of categoryGroupedEntry.entries; index as idx">
            <div
                class="row no-gutters cart-item reports-item align-center product_code_{{ entry.product.code }}"
                id="cart_item_{{ entry.entryNumber }}">
                <div class="col-10 order-1 col-lg-8 pb-2 pb-lg-0" id="product_{{ entry.product.code }}">
                    <div class="row no-gutters">
                        <div class="col-12 align-center">
                            <a
                                *ngIf="entry.product.purchasable"
                                class="cx-product-image-container mr-1 cursor-pointer"
                                role="button"
                                [id]="'product_image_' + idx"
                                (click)="openProductModal(entry.product.code, 'product_image_' + idx)"
                                tabindex="0">
                                <cx-media
                                    class="cx-product-image"
                                    [container]="entry.product?.images?.PRIMARY"
                                    format="cartIcon"></cx-media>
                            </a>

                            <div class="product-name-wrapper-wide-mobile" *ngIf="!entry.product.purchasable">
                                <div>{{ entry.product.name }}</div>
                                <div class="product-code">
                                    <span>{{ entry.product.code }} {{ entry.product.lotSizeInfo }}</span>
                                </div>
                            </div>

                            <div class="product-name-wrapper-wide-mobile" *ngIf="entry.product.purchasable">
                                <button
                                    [id]="'product_name_' + idx"
                                    class="btn-link text-left text-bold"
                                    [attr.aria-label]="
                                        'aimo.product.openModal.arialLabel'
                                            | cxTranslate : { productName: entry.product.name }
                                    "
                                    (click)="openProductModal(entry.product.code, 'product_name_' + idx)"
                                    tabindex="0">
                                    {{ entry.product.name }}
                                </button>

                                <div class="product-code">
                                    <span>{{ entry.product.code }} {{ entry.product.lotSizeInfo }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 order-2 col-lg-2 d-none d-md-flex">
                    <span>{{ entry.deliveredQuantity }} {{ entry.unit.name }}</span>
                </div>
                <div class="col-2 order-2 col-lg-2 text-right align-text-top d-block d-md-none">
                    <span>{{ entry.deliveredQuantity }} {{ entry.unit.name }}</span>
                </div>
                <div class="col-12 order-3 col-lg-2 text-right text-lg-left">
                    <div>
                        <span class="text-bold d-inline-block mr-1">{{
                            entry.deliveredTotalPrice?.formattedValue
                        }}</span>
                        <span class="d-inline-block">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                    </div>
                    <div class="text-lowercase">
                        <span class="d-inline-block mr-1">{{ entry.deliveredTotalPriceWithTax?.formattedValue }}</span>
                        <span class="d-inline-block">
                            ({{ 'aimo.cart.totals.vat' | cxTranslate : { tax: entry.tax?.rate } }})
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>

<ng-template #notification let-level="level" let-textKey="textKey" let-excel="excel">
    <div
        class="notification"
        [ngClass]="level === 'INFO' ? 'notification-MESSAGE' : 'notification-' + level"
        *ngIf="!isMessageHidden(textKey)">
        <div class="left-side">
            <i class="aimo-icon icon-inline icon-{{ level }} mr-2"></i>
        </div>
        <div class="d-inline text-sm middle">
            <span [innerHTML]="textKey | cxTranslate"></span>
            <button class="btn-link text-underline text-left-space" (click)="exportCartToExcel()" *ngIf="excel">
                {{ 'aimo.cart.export.excel' | cxTranslate }}
            </button>
        </div>
        <div class="right-side">
            <button class="btn-link" (click)="hideMessage(textKey)">
                <i class="aimo-icon icon-petrol-blue icon-inline icon-x float-right"></i>
            </button>
        </div>
    </div>
</ng-template>
