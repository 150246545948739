import { CartActions, MULTI_CART_DATA } from '@spartacus/cart/base/core';
import { CartModification, OrderEntry } from '@spartacus/cart/base/root';
import { StateUtils } from '@spartacus/core';

import { AimoCart, AimoOrderEntry } from '../../model/cart.model';
import { AimoGTMProductAttributes, GTMCartType } from '../gtm/aimo-gtm.model';

export const AIMO_CART_ADD_ENTRY = 'AIMO_[Cart-entry] Add Entry';

export class AimoCartAddEntry extends StateUtils.EntityProcessesIncrementAction {
    readonly type = AIMO_CART_ADD_ENTRY;

    constructor(
        public userId: string,
        public cartId: string,
        public entry: AimoOrderEntry,
        public gtmProductAttributes?: AimoGTMProductAttributes,
    ) {
        super(MULTI_CART_DATA, cartId);
    }
}

export const AIMO_CART_ADD_ENTRIES = 'AIMO_[Cart-entry] Add Entries';

export class AimoCartAddEntries extends StateUtils.EntityProcessesIncrementAction {
    readonly type = AIMO_CART_ADD_ENTRIES;

    constructor(
        public userId: string,
        public cartId: string,
        public entries: AimoOrderEntry[],
        public origin: GTMCartType,
        public searchTerm: string,
    ) {
        super(MULTI_CART_DATA, cartId);
    }
}

export const AIMO_CART_UPDATE_ENTRY = 'AIMO_[Cart-entry] Update Entry';

export class AimoCartUpdateEntry extends StateUtils.EntityProcessesIncrementAction {
    readonly type = AIMO_CART_UPDATE_ENTRY;

    constructor(
        public userId: string,
        public cartId: string,
        public entry: AimoOrderEntry,
        public gtmProductAttributes: AimoGTMProductAttributes,
    ) {
        super(MULTI_CART_DATA, cartId);
    }
}

export class AimoCartUpdateEntrySuccess extends CartActions.CartUpdateEntrySuccess {
    constructor(
        public payload: {
            userId: string;
            cartId: string;
            productCode: string;
            entryNumber: string;
            entry?: OrderEntry;
            quantity?: number;
            quantityAdded?: number;
        },
    ) {
        super(payload);
    }
}

export class AimoCartAddEntrySuccess extends CartActions.CartAddEntrySuccess {
    constructor(
        public payload: {
            userId: string;
            cartId: string;
            productCode: string;
            quantity: number;
            deliveryModeChanged?: boolean;
            entry?: OrderEntry;
            quantityAdded?: number;
            statusCode?: string;
            statusMessage?: string;
            modifications?: CartModification[];
        },
    ) {
        super(payload);
    }
}

export const AIMO_CART_ADD_ENTRIES_SUCCESS = '[Aimo-Cart-entry] Add Entries Success';

export class AimoCartAddEntriesSuccess extends StateUtils.EntityProcessesDecrementAction {
    readonly type = AIMO_CART_ADD_ENTRIES_SUCCESS;

    constructor(
        public payload: {
            cartId: string;
            userId: string;
            cart: AimoCart;
        },
    ) {
        super(MULTI_CART_DATA, payload.cartId);
    }
}

export const AIMO_CART_ADD_ENTRIES_FAIL = '[Aimo-Cart-entry] Add Entries Fail';

export class AimoCartAddEntriesFail extends StateUtils.EntityProcessesDecrementAction {
    readonly type = AIMO_CART_ADD_ENTRIES_FAIL;

    // eslint-disable-next-line
    constructor(public payload: { userId: string; cartId: string; productCodes: string[]; error: any }) {
        super(MULTI_CART_DATA, payload.cartId);
    }
}
