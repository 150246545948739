import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, ForbiddenHandler, GlobalMessageService, OccEndpointsService } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class AimoForbiddenHandler extends ForbiddenHandler {
    constructor(
        protected globalMessageService: GlobalMessageService,
        protected authService: AuthService,
        protected occEndpoints: OccEndpointsService,
    ) {
        super(globalMessageService, authService, occEndpoints);
    }

    handleError(request: HttpRequest<unknown>): void {
        if (
            request.url.endsWith(
                this.occEndpoints.buildUrl('user', {
                    urlParams: { userId: 'current' },
                }),
            )
        ) {
            this.authService.logout();
        }
    }
}
