<ng-container *ngIf="message$ | async as message">
    <ng-container *ngIf="message?.message?.length > 0">
        <div class="notification notification-WARNING" id="service-notification" *ngIf="!isMessageHidden(message)">
            <i class="left-side aimo-icon icon-inline icon-WARNING"></i>
            <span class="middle" [innerHTML]="message.message"></span>
            <div class="right-side">
                <i class="aimo-icon icon-petrol-blue icon-inline icon-x float-right" (click)="hideMessage(message)"></i>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-container *ngIf="customerBlocked$ | async as block">
    <div class="notification notification-ERROR" id="customer-blocked">
        <i class="left-side aimo-icon icon-inline icon-ERROR"></i>
        <span class="middle">
            {{ 'order.error.customerBlocked' | cxTranslate }}
            <a class="link" [routerLink]="'/contact'">{{ 'order.error.customerBlockedContact' | cxTranslate }}</a>
        </span>
    </div>
</ng-container>
