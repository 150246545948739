import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    // tslint:disable-next-line:typedef
    transform(value): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    // tslint:disable-next-line:typedef
    transform(url): SafeUrl {
        return this.sanitized.bypassSecurityTrustResourceUrl(url);
    }
}
