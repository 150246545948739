<div class="customer-search" [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
    <label class="searchbox" [class.dirty]="!!searchInput.value">
        <div role="presentation" class="search-icon">
            <i class="aimo-icon icon-sm icon-magnifying-glass"></i>
        </div>

        <input
            #searchInput
            [id]="'customer_search'"
            [placeholder]="'aimo.b2bunit.searchBox.placeholder' | cxTranslate"
            autocomplete="off"
            aria-describedby="initialDescription"
            aria-controls="results"
            [attr.aria-label]="'aimo.b2bunit.searchBox.placeholder' | cxTranslate"
            (focus)="open()"
            (click)="open()"
            (input)="searchB2BUnits(searchInput.value)"
            (keydown.escape)="close($any($event))"
            (keydown.arrowDown)="nextFocus($event, -1, 1)"
            (keydown.enter)="close($any($event), true); onlyClear()"
            value="{{ chosenWord }}" />

        <button
            [attr.aria-label]="'common.reset' | cxTranslate"
            (mousedown)="onlyClear()"
            (keydown.enter)="onlyClear()"
            class="reset">
            <i class="aimo-icon icon-sm icon-x"></i>
        </button>
    </label>
</div>

<div
    *ngIf="searchResult$ | async as results"
    class="results"
    id="results"
    (click)="close($any($event), true)"
    role="dialog">
    <ul class="b2bunits" role="listbox">
        <li
            [ngClass]="{ 'credit-card-user': !b2bUnit.allowInvoices }"
            *ngFor="let b2bUnit of results; index as idx"
            [attr.aria-label]="'aimo.arialLabel.customer.dropdown' | cxTranslate : { customer: b2bUnit.name }">
            <button
                [id]="'customer_search_' + idx"
                (keydown.arrowDown)="nextFocus($event, idx, results.length)"
                (keydown.arrowUp)="previousFocus($event, idx)"
                class="unit-button"
                (click)="selectUnit($event, b2bUnit.uid)">
                <div class="unit-number">
                    <div>{{ b2bUnit.uid }}</div>
                    <i class="aimo-icon icon-credit-card icon-sm icon-red" *ngIf="!b2bUnit.allowInvoices"></i>
                </div>
                <div class="unit-name" (click)="selectUnit($event, b2bUnit.uid)">
                    <div class="text-bold" [innerHTML]="b2bUnit.name"></div>
                    <ng-container *ngIf="b2bUnit.addresses?.length > 0">
                        <div [innerHTML]="b2bUnit.addresses[0].line1"></div>
                        <div>
                            <div class="d-inline-block mr-1" [innerHTML]="b2bUnit.addresses[0].postalCode"></div>
                            <div class="d-inline-block" [innerHTML]="b2bUnit.addresses[0].town"></div>
                        </div>
                    </ng-container>
                </div>
            </button>
        </li>
    </ul>
</div>
