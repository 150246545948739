import { formatDate } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, TranslationService } from '@spartacus/core';
import { Subscription } from 'rxjs';

@Injectable()
export class AimoNgbDatepickerI18n extends NgbDatepickerI18n implements OnDestroy {
    monthNames: string[] = [];
    shortMonthNames: string[] = [];
    weekdayNames: string[] = [];
    language: string;
    weekHeader: string;
    dateFormat: string;

    private subscriptions: Subscription[] = [];

    constructor(private translation: TranslationService, private languageService: LanguageService) {
        super();
        this.populateMonths();
        this.populateWeekDays();
        this.populateLanguage();
        this.populateWeekHeader();
    }

    populateWeekHeader(): void {
        this.subscriptions.push(
            this.translation.translate('aimo.calendar.week').subscribe(
                (header) => (this.weekHeader = header),
                // eslint-disable-next-line
                (err) => console.error(err),
            ),
        );
        this.subscriptions.push(
            this.translation.translate('aimo.dateFormat').subscribe(
                (format) => (this.dateFormat = format),
                // eslint-disable-next-line
                (err) => console.error(err),
            ),
        );
    }

    /**
     * Populates month names from localized properties
     */
    populateMonths(): void {
        for (let i = 0; i <= 11; i++) {
            this.subscriptions.push(
                this.translation.translate('aimo.calendar.month.' + i).subscribe(
                    (month) => {
                        this.monthNames[i + 1] = month;
                        this.shortMonthNames[i + 1] = month.substr(0, 3);
                    },
                    // eslint-disable-next-line
                    (err) => console.error(err),
                ),
            );
        }
    }

    /**
     * Populates weekday names from localized properties
     */
    populateWeekDays(): void {
        const weekdayMapKeys: string[] = ['2', '3', '4', '5', '6', '7', '1'];
        for (let i = 0; i < weekdayMapKeys.length; i++) {
            this.subscriptions.push(
                this.translation.translate('aimo.calendar.weekday.short.' + weekdayMapKeys[i]).subscribe(
                    (t) => {
                        this.weekdayNames[i] = t;
                    },
                    // eslint-disable-next-line
                    (err) => console.error(err),
                ),
            );
        }
    }

    /**
     * Reads language code
     */
    populateLanguage(): void {
        this.subscriptions.push(
            this.languageService.getActive().subscribe(
                (lang) => (this.language = lang),
                // eslint-disable-next-line
                (err) => console.error(err),
            ),
        );
    }

    ngOnDestroy(): void {
        /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    getWeekdayShortName(weekday: number): string {
        return this.weekdayNames[weekday - 1];
    }

    getMonthShortName(month: number): string {
        return this.shortMonthNames[month];
    }

    getMonthFullName(month: number): string {
        return this.monthNames[month];
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        const date1: Date = new Date(date.year, date.month - 1, date.day);
        return formatDate(date1, this.dateFormat, this.language);
    }

    getWeekdayLabel(weekday: number): string {
        return this.weekdayNames[weekday - 1];
    }
}
