<div class="faq-item" *ngIf="data$ | async as data">
    <button
        type="button"
        class="faq-item-question"
        (click)="collapse.toggle()"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample">
        {{ data.title }}
        <i class="aimo-icon icon-sm icon-petrol-blue icon-plus-bold" *ngIf="isCollapsed"></i>
        <i class="aimo-icon icon-sm icon-petrol-blue icon-minus-bold" *ngIf="!isCollapsed"></i>
    </button>
    <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
        <div class="faq-item-answer" [innerHTML]="data.text"></div>
        <div class="faq-item-media">
            <cx-media [container]="data.image" *ngIf="data.image?.url"></cx-media>
            <youtube-player videoId="{{ data.youtubeId }}" *ngIf="data.youtubeId"></youtube-player>
        </div>
    </div>
</div>
