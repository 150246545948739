import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';

import { AimoInputComponent } from './aimo-input.component';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbTooltipModule, RouterModule, I18nModule],
    declarations: [AimoInputComponent],
    exports: [AimoInputComponent],
    entryComponents: [AimoInputComponent],
})
export class AimoInputModule {}
