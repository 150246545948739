import { Action } from '@ngrx/store';

export const LOGINFAIL = '[Auth] Login Fail';
export const LOGOUTFAIL = '[Auth] Logout fail';

export class LoginFail implements Action {
    readonly type = LOGINFAIL;
}

export class LogoutFail implements Action {
    readonly type = LOGOUTFAIL;
}

// action types
export type LoginLogoutActionFail = LoginFail | LogoutFail;
