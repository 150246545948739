import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoCart } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';

import { AimoOrderTemplateDeleteDialogData } from './aimo-order-template-layout.config';

@Component({
    selector: 'aimo-order-template-delete-modal',
    templateUrl: './aimo-order-template-delete-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateDeleteModalComponent implements OnDestroy, OnInit {
    subscription: Subscription = new Subscription();
    template: AimoCart;
    baseOrder: boolean;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
    ) {
        this.launchDialogService.data$
            .subscribe((dialogData: AimoOrderTemplateDeleteDialogData) => {
                this.template = dialogData.template;
                this.baseOrder = dialogData.baseOrder;
            })
            .unsubscribe();
    }

    closeDialog(reason?: string): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ORDER_TEMPLATE);
        this.launchDialogService.closeDialog(reason ? reason : 'closed');
    }

    submit(): void {
        this.activeCartService.deleteCart(this.template.code, 'current');
        this.closeDialog('deleted');
    }

    ngOnInit(): void {
        setTimeout(() => this.getTheFocusBack(null), 100);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event: Event): void {
        event?.preventDefault();
        this.closeButton.nativeElement.focus();
    }
}
