import { Injectable } from '@angular/core';
import { Converter, ConverterService } from '@spartacus/core';

import { AimoCart } from '../../model/cart.model';

import { AimoOccCartNormalizer } from './aimo-occ-cart-normalizer';

@Injectable({ providedIn: 'root' })
export class AimoOccOrderNormalizer implements Converter<AimoCart, AimoCart> {
    constructor(private converter: ConverterService, private cartNormalizar: AimoOccCartNormalizer) {}

    convert(source: AimoCart, target?: AimoCart): AimoCart {
        if (target === undefined) {
            target = {
                ...source,
            };
        }
        this.cartNormalizar.convert(source as undefined, target);
        target.entries?.forEach((e) => {
            // eslint-disable-next-line
            e.quantity = (e as any).doubleQuantity;
        });
        return target;
    }
}
