import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';

import { KitchenAlcoholInfo } from '../../../../model/user.model';
import { AimoUserService } from '../../../../service/user/aimo-user.service';

/* eslint-disable */
@Component({
    selector: 'aimo-alcohol',
    templateUrl: './aimo-alcohol.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoAlcoholComponent implements OnInit {
    alcoholInfo$: Observable<KitchenAlcoholInfo>;

    constructor(protected userService: AimoUserService, protected winRef: WindowRef) {
        this.alcoholInfo$ = userService.getAlchoholQuotaInfo();
    }

    ngOnInit(): void {}
}
/* eslint-enable */
