import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Directive({ selector: '[aimoCopyClipboard]' })
export class CopyClipboardDirective {
    @Input('aimoCopyClipboard')
    public payload: string;

    @Output()
    public copied: EventEmitter<string> = new EventEmitter<string>();

    constructor(protected winRef: WindowRef) {}

    @HostListener('click', ['$event'])
    public onClick(event: MouseEvent): void {
        event.preventDefault();
        if (!this.payload) return;
        navigator.clipboard.writeText(this.payload);
        this.copied.emit(this.payload);
    }
}
