import { ProductScope } from '@spartacus/core';

const productFieldsEssentials =
    'pk,code,url,name,icons,similarProducts,allowedLotSize,sizeInBaseUnits,myAssortment,frozenProduct,favorite,novelty,campaign,unit,summary,categories(FULL,level),comparisonPrice(FULL),comparisonUnit(FULL),ean,lotSizeInfo,inOrderTemplate,baseUnit(FULL),discount(FULL),strikeThroughPrice(FULL),price(FULL),priceWithTax(FULL),taxPercentage,images(FULL),stock(FULL),purchasable,pikatukkuWebShopProduct';
const productFields =
    productFieldsEssentials +
    ',pikatukkuStock(FULL),nutritionalContentBasisQuantity,nutritionalContentBasisQuantityUnit(FULL),manualKeyIngredients,keyIngredients,description,unitConversions(FULL),tags,classifications(FULL),vendor(FULL),countriesOfOrigin(FULL),lastPurchased,preparationInstructions,usageInstructions,fishingInformation(FULL),storageInstructions,disposalInformations,klevuBannerPageType,klevuBannerType,productReferences(preselected,referenceType,description,target(' +
    productFieldsEssentials +
    '),quantity)';

export const aimoOccProductConfig = {
    backend: {
        occ: {
            endpoints: {
                product: {
                    default: 'users/${userId}/products/${productCode}?fields=' + productFields,
                    list: 'users/${userId}/products/${productCode}?fields=' + productFields,
                    details: 'users/${userId}/products/${productCode}?fields=' + productFields,
                },
                loadMany: 'users/${userId}/products/loadMany?fields=' + productFields,
                excelExportProducts: 'users/${userId}/products/excelExport?spinner=true',
                productSearch:
                    'users/${userId}/products/search?fields=products(' +
                    productFields +
                    '),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery,suggestiveMode,keywordRedirectUrl',
                productSuggestions: 'users/${userId}/products/suggestions',
            },
        },
        loadingScopes: {
            product: {
                details: {
                    include: [ProductScope.LIST, ProductScope.VARIANTS],
                },
            },
        },
    },
};
