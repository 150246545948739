/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { FacetListModule, FacetModule, IconModule, KeyboardFocusTestingModule } from '@spartacus/storefront';

import { AimoFacetModule } from '../facet/aimo-facet.module';

import { AimoFacetListComponent } from './aimo-facet-list.component';

@NgModule({
    imports: [KeyboardFocusTestingModule, I18nModule, CommonModule, IconModule, AimoFacetModule, FacetModule],
    declarations: [AimoFacetListComponent],
    exports: [AimoFacetListComponent],
})
export class AimoFacetListModule extends FacetListModule {}
