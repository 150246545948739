import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CartCouponModule, CartSharedModule } from '@spartacus/cart/base/components';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { CartOutlets } from '@spartacus/cart/base/root';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ProductImageZoomModule } from '@spartacus/product/image-zoom/components';
import {
    AtMessageModule,
    IconModule,
    ItemCounterModule,
    MediaModule,
    OutletModule,
    PageSlotModule,
    PromotionsModule,
    provideOutlet,
} from '@spartacus/storefront';

import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoItemCounterModule } from '../../shared/item-counter/aimo-item-counter.module';
import { SharedModule } from '../../shared/shared.module';
import { AimoCartTotalsModule } from '../cart-totals/aimo-cart-totals.module';
import { AimoProductListModule } from '../product/product-list/aimo-product-list.module';

import { AimoCartItemComponent } from './cart-item/aimo-cart-item.component';
import { AimoCartItemDepositsComponent } from './cart-item/item-deposits/aimo-cart-item-deposits.component';
import { AimoCartItemErrorsComponent } from './cart-item/item-errors/aimo-cart-item-errors.component';
import { AimoItemMessageComponent } from './cart-item/item-messages/aimo-item-message.component';
import { AimoCartItemListComponent } from './cart-item-list/aimo-cart-item-list.component';

@NgModule({
    imports: [
        AtMessageModule,
        CartCouponModule,
        CommonModule,
        FeaturesConfigModule,
        I18nModule,
        IconModule,
        ItemCounterModule,
        MediaModule,
        OutletModule,
        PromotionsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        AddToCartModule,
        ProductImageZoomModule,
        PageSlotModule,
        AimoItemCounterModule,
        AimoInputModule,
        NgbPopoverModule,
        SharedModule,
        AimoCartTotalsModule,
        AimoProductListModule,
    ],
    providers: [
        provideOutlet({
            id: CartOutlets.CART_ITEM_LIST,
            component: AimoCartItemListComponent,
        }),
    ],
    declarations: [
        AimoCartItemComponent,
        AimoCartItemListComponent,
        AimoItemMessageComponent,
        AimoCartItemErrorsComponent,
        AimoCartItemDepositsComponent,
    ],
    exports: [
        AimoCartItemComponent,
        AimoCartItemListComponent,
        AimoItemMessageComponent,
        AimoCartItemErrorsComponent,
        AimoCartItemDepositsComponent,
    ],
})
export class AimoCartSharedModule extends CartSharedModule {}
