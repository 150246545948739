<ng-container *ngIf="templateId$ | async as templateId; else templates">
    <aimo-order-template-details [orderTemplateId]="templateId"></aimo-order-template-details>
</ng-container>

<ng-template #templates>
    <aimo-breadcrumb
        *ngIf="breadcrumbs$ | async as breadcrumbs"
        [hideTitle]="true"
        [showBackLink]="false"
        [normalBreadCrumbs]="breadcrumbs">
    </aimo-breadcrumb>
    <ng-container *ngIf="cartList$ | async as templates">
        <h1 class="page-title" tabindex="0" [attr.aria-label]="'aimo.ordertemplates.title' | cxTranslate">
            {{ 'aimo.ordertemplates.title' | cxTranslate }}
        </h1>
        <div class="templates-search" [formGroup]="searchForm">
            <div class="row no-gutters">
                <div class="col-lg-9 d-md-block">
                    <p class="mb-4" [innerHTML]="'aimo.ordertemplates.description' | cxTranslate"></p>
                </div>
            </div>

            <div class="form-check" *ngIf="customerAdmin">
                <label>
                    <input
                        type="checkbox"
                        (change)="setHideNormalOrderTemplatesFromBasicUsers()"
                        [formControlName]="'hideNormalOrderTemplatesFromBasicUsers'" />
                    <span>{{ 'aimo.ordertemplates.isHideNormalOrderTemplatesFromBasicUsers' | cxTranslate }}</span>
                </label>
                <br />
            </div>

            <div class="row no-gutters">
                <div class="col-lg-3 col-md-6 col-12 cx-search-criteria-find order-md-1 order-3">
                    <aimo-cx-input
                        [inputClass]="'input-md mt-0'"
                        (input)="search()"
                        [placeholder]="'aimo.ordertemplates.search'"
                        [parentForm]="searchForm"
                        [nameForm]="'search'"
                        [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                    </aimo-cx-input>
                </div>

                <div
                    class="col-12 col-lg-5 col-xl-6 pl-md-2 pl-0 order-lg-2 order-last mt-3 mt-lg-0"
                    *ngIf="publicTemplatesVisible()">
                    <div class="facet-wrapper fade-white" *ngIf="templates.facets?.length > 1">
                        <div class="facets">
                            <div class="aimo-facet" *ngFor="let facet of templates.facets">
                                <button
                                    class="heading btn-facet btn-sm"
                                    [ngClass]="
                                        selectedFacet?.code === facet.code ? { selected: true } : { selected: false }
                                    "
                                    (click)="toggleFacet(facet); pushGtmEvent('ordertemplates-facets')">
                                    {{ 'aimo.ordertemplates.privacy.' + facet.code | cxTranslate }}
                                    <span class="amount">{{ getFacetHits(facet) }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4 col-xl-3 col-md-6 order-lg-last order-md-2 order-2 pb-3 pb-md-0 text-right">
                    <button
                        class="btn btn-sm btn-primary"
                        (click)="openCreateTemplateModal(); pushGtmEvent('ordertemplates-create-new')"
                        *ngIf="publicTemplatesVisible()">
                        <i class="icon-plus-circle icon-white icon-inline aimo-icon mr-2 mb-1"></i>
                        {{ 'aimo.ordertemplates.button.create' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="aimo-order-templates-table">
            <ng-container *ngFor="let template of templates.carts">
                <aimo-order-template-row [orderTemplate]="template"></aimo-order-template-row>
            </ng-container>
        </div>
    </ng-container>
</ng-template>
