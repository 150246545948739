import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';

import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoGTMProductAttributes, GTMCartType, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';

@Component({
    selector: 'aimo-order-template-item-list',
    templateUrl: './aimo-order-template-item-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateItemListComponent {
    @Input()
    orderTemplate: AimoCart;

    @Input()
    cart: AimoCart;

    @Input()
    baseOrder: boolean = false;

    constructor(protected winRef: WindowRef, protected launchDialogService: LaunchDialogService) {}

    // eslint-disable-next-line
    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            item_list_id: GTMItemListId.order_template,
            cart_type: this.baseOrder ? GTMCartType.baseOrder : GTMCartType.orderTemplate,
        };
    }

    getAlreadyAddedToCart(cart: AimoCart, entry: AimoOrderEntry): number {
        return cart?.dayGroupedEntries
            ?.filter((g) => g.active)
            .flatMap((g) => g.entries)
            .find((e) => e.product.code === entry.product.code)?.quantity;
    }
}
