import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoCMSContactItemComponent } from '../../../model/cms.model';
import { OpeningHour } from '../../../model/user.model';
import { AimoUserService } from '../../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-contact-item',
    templateUrl: './aimo-contact-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoContactItemComponent implements OnInit {
    data$: Observable<AimoCMSContactItemComponent>;
    openingHours$: Observable<OpeningHour[]>;

    constructor(
        protected componentData: CmsComponentData<AimoCMSContactItemComponent>,
        protected userService: AimoUserService,
        protected aimoRoutingService: AimoRoutingService,
    ) {}

    ngOnInit(): void {
        this.data$ = this.componentData.data$;
        this.openingHours$ = this.userService.getStoreOpeningHours();
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
