import { NgModule } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { translationChunksConfig } from '@spartacus/assets';
import {
    FeaturesConfig,
    OccConfig,
    provideConfig,
    provideConfigFactory,
    provideDefaultConfig,
    TranslationService,
} from '@spartacus/core';
import { defaultCmsContentProviders, LogoutGuard, mediaConfig } from '@spartacus/storefront';
import { UserAccountConnector } from '@spartacus/user/account/core';

import { environment } from '../../environments/environment';
import { aimoLayoutConfig } from '../features/cms/layout/config/aimo-layout-config';
import { aimoHandlers } from '../features/cms-components/shared/utils/handler';
import { aimoInterceptors } from '../features/cms-components/shared/utils/interceptor';
import { AimoLogoutGuard } from '../features/cms-components/user/logout/aimo-logout.guard';
import { aimoB2bOccConfig } from '../features/service/general/aimo-b2b-occ-config';
import { aimoDefaultCmsModuleConfig } from '../features/service/general/aimo-default-cms-config';
import { AimoI18nextTranslationServiceStrategy } from '../features/service/i18n/aimo-i18next-translation-strategy.service';
import { AimoUserAccountConnector } from '../features/shared/connectors/aimo-user-account.connector';

const occConfig: OccConfig = {
    backend: {
        occ: {
            prefix: '/rest/v2/',
        },
    },
};

// eslint-disable-next-line
const initializeBackendUrl = (meta: Meta) => {
    const backendUrlFromEnvConfig = environment.backend.occ.baseUrl; // local
    const backendUrlFromMetaTag = meta.getTag('name=occ-backend-base-url').content; // ccv2
    const backendUrlToUse = backendUrlFromEnvConfig ? backendUrlFromEnvConfig : backendUrlFromMetaTag; // env config has priority
    const baseUrl = backendUrlToUse.replace('https://int-', 'https://');
    i18Backend.loadPath = baseUrl + '/rest/v2/toimitustukku/translations/{{ns}}.json?lang={{lng}}';
    return {
        backend: {
            occ: {
                baseUrl: baseUrl,
            },
        },
    };
};

const i18Backend = {
    loadPath: '',
};

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        provideConfigFactory(initializeBackendUrl, [Meta]),
        provideConfig(aimoLayoutConfig),
        provideConfig(mediaConfig),
        ...defaultCmsContentProviders,
        provideConfig({
            backend: occConfig.backend,
            i18n: {
                backend: i18Backend,
                chunks: {
                    ...translationChunksConfig,
                    custom: ['aimo'],
                },
                fallbackLang: 'fi',
            },
            breakpoints: {
                xs: 576,
                sm: 768,
                md: 992,
                lg: 1200,
                xl: 1640,
            },
            pagination: {
                rangeCount: 4,
                addNext: true,
                addPrevious: true,
            },
        }),
        provideDefaultConfig(aimoB2bOccConfig),
        provideDefaultConfig(aimoDefaultCmsModuleConfig),
        aimoInterceptors,
        aimoHandlers,
        { provide: TranslationService, useExisting: AimoI18nextTranslationServiceStrategy },
        { provide: LogoutGuard, useClass: AimoLogoutGuard },
        {
            provide: UserAccountConnector,
            useClass: AimoUserAccountConnector,
        },
        provideConfig(<FeaturesConfig>{
            features: {
                level: '5.0',
            },
        }),
    ],
})
export class SpartacusConfigurationModule {}
