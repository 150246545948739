import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { ProductImageZoomModule } from '@spartacus/product/image-zoom/components';
import {
    CarouselModule,
    IconModule,
    KeyboardFocusTestingModule,
    MediaModule,
    OutletModule,
    PageSlotModule,
    ProductImagesModule,
    ProductSummaryModule,
} from '@spartacus/storefront';

import { SharedModule } from '../../../shared/shared.module';
import { AimoAddToCartModule } from '../../cart-shared/add-to-cart/aimo-add-to-cart.module';
import { AimoBreadcrumbModule } from '../../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoCarouselModule } from '../../shared/carousel/aimo-carousel.module';
import { AimoProductListModule } from '../product-list/aimo-product-list.module';

import { productImageZoomLayoutConfig } from './aimo-product-image-zoom-layout.config';
import { AimoProductSummaryComponent } from './aimo-product-summary.component';
import { AimoProductImageZoomDialogComponent } from './product-image-zoom-dialog/aimo-product-image-zoom-dialog.component';
import { AimoProductImageZoomProductImagesComponent } from './product-image-zoom-product-images/aimo-product-image-zoom-product-images.component';
import { AimoProductImageZoomTriggerComponent } from './product-image-zoom-trigger/aimo-product-image-zoom-trigger.component';
import { productSummaryLayout } from './product-summary-layout.config';

@NgModule({
    imports: [
        CommonModule,
        OutletModule,
        I18nModule,
        MediaModule,
        ProductImagesModule,
        AimoAddToCartModule,
        AimoBreadcrumbModule,
        ProductImageZoomModule,
        AimoCarouselModule,
        IconModule,
        PageSlotModule,
        AimoProductListModule,
        UrlModule,
        RouterModule,
        SharedModule,
        KeyboardFocusTestingModule,
        CarouselModule,
        NgbModule,
    ],
    providers: [
        provideDefaultConfig(productImageZoomLayoutConfig),
        provideDefaultConfig(productSummaryLayout),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                ProductSummaryComponent: {
                    component: AimoProductSummaryComponent,
                },
            },
        }),
    ],
    declarations: [
        AimoProductSummaryComponent,
        AimoProductImageZoomProductImagesComponent,
        AimoProductImageZoomDialogComponent,
        AimoProductImageZoomTriggerComponent,
    ],
    exports: [
        AimoProductSummaryComponent,
        AimoProductImageZoomProductImagesComponent,
        AimoProductImageZoomDialogComponent,
        AimoProductImageZoomTriggerComponent,
    ],
})
export class AimoProductSummaryModule extends ProductSummaryModule {}
