import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, LanguageService } from '@spartacus/core';
import { Translatable } from '@spartacus/core/src/i18n/translatable';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoDayGroupedOrderHistory } from '../../../model/order.model';
import { AimoProduct } from '../../../model/product.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

import { AimoClaimsDialogData } from './aimo-claims-layout.config';

@Component({
    selector: 'aimo-claims-modal',
    templateUrl: './aimo-claims-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoClaimsModalComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    selectableProducts: ProductKeyValue[] = [];
    date: string;

    reasons = ['spoiled', 'missing', 'quality', 'return', 'other'];
    contactTypes = ['email', 'phone', 'notneeded'];

    form: FormGroup;
    editable: boolean = false;
    existingClaims$: Observable<AimoCart[]>;
    day: AimoDayGroupedOrderHistory;

    submitted: boolean = false;
    errorMessage: string;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected eRef: ElementRef,
        protected fb: FormBuilder,
        protected spinnerService: AimoSpinnerService,
        protected cdr: ChangeDetectorRef,
        protected globalMessage: GlobalMessageService,
        protected language: LanguageService,
        protected userService: AimoUserService,
    ) {
        this.subscription.add(
            this.launchDialogService.data$.subscribe((dialogData: AimoClaimsDialogData) => {
                this.day = dialogData.day;
                this.date = dialogData.day?.date;
                this.editable = dialogData.editable;

                this.existingClaims$ = this.activeCartService
                    .getClaims(this.date, this.date, true)
                    .pipe(map((claim) => claim.carts));

                this.selectableProducts = this.day?.orders?.flatMap((o) =>
                    o.entries
                        .filter((e) => e.invoiceDocumentNumber !== undefined)
                        .map(
                            (e) =>
                                new ProductKeyValue(o.code, e.product.code, e.product.name, e.quantity, {
                                    ...e.product,
                                    unit: e.unit,
                                }),
                        ),
                );
                this.cdr.detectChanges();
            }),
        );
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            header: this.fb.group({
                remarks: new FormControl(null),
                contactType: new FormControl('email'),
            }),
            items: this.fb.array([]),
        });
        this.addItem();
    }

    addItem(): void {
        const items = this.form.controls.items as FormArray;
        items.push(
            this.fb.group({
                product: new FormControl(null, [Validators.required]),
                quantity: new FormControl(0, [Validators.required]),
                dateOrBatch: new FormControl(null, [Validators.required]),
                reason: new FormControl(null, [Validators.required]),
                orderNumber: new FormControl(null, [Validators.required]),
            }),
        );
        /* this.selectableProducts = this.allProducts.filter(p =>
            this.getItemsControl().find(i =>
                i.get('product').value === p.code
            ) === undefined
        );*/
        // this.clearFormValidation();
    }

    removeItem(idx: number): void {
        const items = this.getItemsControl();
        /*  this.selectableProducts.push(
            this.allProducts.find(p =>
                p === this.getProduct(idx)
            )
        );*/
        delete items[idx];
        //   this.clearFormValidation();
        this.cdr.detectChanges();
    }

    clearFormValidation(): void {
        this.getItemsControl().forEach((item: FormGroup) => {
            Object.keys(item.controls).forEach((ctrl) => item.controls[ctrl].setErrors(null));
        });
    }

    getItemsControl(): AbstractControl[] {
        const itemGroup = <FormArray>this.form.controls['items'];
        return itemGroup.controls;
    }

    // eslint-disable-next-line
    getHeaderControl(): any {
        const headerGroup = this.form.controls['header'] as FormGroup;
        return headerGroup.controls;
    }

    closeDialog(reason = 'closed'): void {
        this.launchDialogService.clear(LAUNCH_CALLER.CLAIMS);
        this.launchDialogService.closeDialog(reason);
    }

    getProduct(idx: number): AimoProduct {
        return this.getProductKeyValue(idx)?.product;
    }

    getProductKeyValue(idx: number): ProductKeyValue {
        const control = this.getItemsControl()[idx]?.get('product');
        if (control?.value) {
            return this.selectableProducts.find((p) => p.code === control.value);
        }
    }

    submit(): void {
        this.submitted = true;
        if (this.isValid()) {
            this.activeCartService
                .createClaim(
                    this.date,
                    this.getHeaderControl().remarks.value,
                    this.getHeaderControl().contactType.value,
                    this.getItemsControl().map(
                        (item) =>
                            ({
                                quantity: item.get('quantity').value,
                                product: { code: item.get('product').value },
                                reason: item.get('reason').value,
                                dateOrBatch: item.get('dateOrBatch').value,
                                externalOrderNumber: item.get('orderNumber').value,
                            } as AimoOrderEntry),
                    ),
                )
                .subscribe(() => {
                    this.day.claimCreated = true;
                    this.globalMessage.add(
                        {
                            key: 'aimo.claims.submitted',
                            params: {
                                date: DateUtils.convertUIDate(this.language, '', new Date(this.date)),
                            },
                        } as Translatable,
                        GlobalMessageType.MSG_TYPE_INFO,
                        8000,
                    );
                    this.closeDialog('reload');
                });
        } else {
            this.cdr.detectChanges();
        }
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    isValid(): boolean {
        for (let i = 0; i < this.getItemsControl().length; ++i) {
            if (this.isInvalidItem(i, 'product')) {
                return false;
            }
        }
        return this.form.valid;
    }

    isInvalidItem(idx: number, fieldName: string): boolean {
        const value = this.getItemsControl()[idx]?.get(fieldName).value;
        if (
            fieldName === 'product' &&
            this.getItemsControl().filter((c) => c.get(fieldName).value === value).length > 1
        ) {
            return true;
        }
        if (this.submitted) {
            return this.getItemsControl()[idx]?.get(fieldName).invalid;
        }
        return false;
    }

    refresh(idx: number): void {
        this.getItemsControl()[idx]?.get('quantity').setValue(0);
        this.getItemsControl()[idx]?.get('orderNumber').setValue(this.getProductKeyValue(idx).orderNumber);
        this.cdr.detectChanges();
    }

    createClaim(): void {
        this.closeDialog();
        this.userService
            .getOrderHistoryDay(this.convertDate(this.date))
            .pipe(take(1))
            .subscribe((orders) =>
                this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLAIMS, undefined, {
                    day: { ...orders.dayGroupedOrders[0], date: this.date },
                    editable: true,
                } as AimoClaimsDialogData),
            );
    }
}

export class ProductKeyValue {
    constructor(
        public orderNumber: string,
        public code: string,
        public name: string,
        public quantity: number,
        public product: AimoProduct,
    ) {}
}
