import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { AimoCartConnector } from '../cart/aimo-cart.connector';

import {
    LOAD_DELIVERY_DATE,
    AimoLoadDeliveryDate,
    AimoLoadDeliveryDateFail,
    AimoLoadDeliveryDateSuccess,
} from './aimo-deliverydate.action';

@Injectable()
export class AimoDeliveryDateEffects {
    constructor(protected actions$: Actions, protected cartConnector: AimoCartConnector) {}

    loadDeliveryDate$: Observable<AimoLoadDeliveryDateSuccess | AimoLoadDeliveryDateFail> = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_DELIVERY_DATE),
            map((action: AimoLoadDeliveryDate) => action),
            concatMap((action) => {
                if (action.userId !== OCC_USER_ID_ANONYMOUS) {
                    return this.cartConnector
                        .getDeliveryDate(action.userId, action.cartId)
                        .pipe(map((date) => new AimoLoadDeliveryDateSuccess(date)));
                }
                const date = new Date();
                date.setHours(0, 0, 0, 0);
                return of(new AimoLoadDeliveryDateSuccess(date.toISOString()));
            }),
        ),
    );
}
