import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, LanguageService, OccEndpointsService } from '@spartacus/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import { ORDER_NORMALIZER } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoOrder } from '../../model/cart.model';
import { AimoOrderHistory, AimoOrderHistoryRequest } from '../../model/order.model';
import { DateUtils } from '../../shared/util/date-utils';
import { AimoOccOrderNormalizer } from '../cart/aimo-order.normalizer';

@Injectable({
    providedIn: 'root',
})
export class AimoOrderHistoryAdapter extends OccOrderHistoryAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected converter: ConverterService,
        protected orderNormalizer: AimoOccOrderNormalizer,
        protected language: LanguageService,
    ) {
        super(http, occEndpoints, converter);
    }

    public exportOrderToExcel(userId: string, orderNumber: string): Observable<ArrayBuffer> {
        const url = this.occEndpoints.buildUrl('excelExportOrder', {
            urlParams: { userId, orderNumber },
        });
        // eslint-disable-next-line
        return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as any });
    }

    public loadOrderHistory(userId: string, params: AimoOrderHistoryRequest): Observable<AimoOrderHistory> {
        const url = this.occEndpoints.buildUrl('orderHistory', { urlParams: { userId: userId } });
        return this.http
            .patch<AimoOrderHistory>(url, {
                ...params,
                deliveryDate: {
                    from: DateUtils.convertDateToOccString(this.language, params.deliveryDate.from),
                    to: DateUtils.convertDateToOccString(this.language, params.deliveryDate.to),
                },
            })
            .pipe(
                map((history) => ({
                    ...history,
                    dayGroupedOrders: history.dayGroupedOrders.map((day) => ({
                        ...day,
                        orders: day.orders
                            // eslint-disable-next-line
                            .map((order) => this.converter.convert(order, ORDER_NORMALIZER) as any),
                    })),
                })),
            );
    }

    public getOrderToBePaid(userId: string, orderId: string): Observable<AimoOrder> {
        const url = this.occEndpoints.buildUrl('getOrderToBePaid', {
            urlParams: { userId, orderId },
        });
        return this.http.get<AimoOrder>(url);
    }
}
