import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { CartConfigService } from '@spartacus/cart/base/core';
import { SelectiveCartFacade } from '@spartacus/cart/base/root';
import { AuthService, EventService, RoutingService, WindowRef } from '@spartacus/core';
import { OrderPlacedEvent } from '@spartacus/order/root';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { BehaviorSubject, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoCart, AimoDayGroupedEntries, AimoOrder, AimoPickupSlot } from '../../model/cart.model';
import { AimoUser } from '../../model/user.model';
import { AimoActiveCartService } from '../../service/cart/aimo-active-cart.service';
import { AIMO_CART_UPDATE_HEADER_SUCCESS, AimoUpdateCartHeaderSuccess } from '../../service/cart/aimo-cart.action';
import { GTMCalendarSource } from '../../service/gtm/aimo-gtm.model';
import { AimoGtmService } from '../../service/gtm/aimo-gtm.service';
import { AimoUserService } from '../../service/user/aimo-user.service';
import { AimoOrderTemplateDialogData } from '../order-template/order-template-modal/aimo-order-template-layout.config';
import { toggleFunctions } from '../shared/utils/spinner/aimo-spinner-utils';

import { AimoCartDialogData } from './aimo-cart-layout.config';
import { AimoSwitchDateHistoryDialogData } from './switch-date-modal/aimo-switch-cart-date-layout.config';

const ALREADY_OPEN_EXTERNAL_ORDERS = {};

@Component({
    selector: 'aimo-cart-details',
    templateUrl: './aimo-cart-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCartDetailsComponent extends CartDetailsComponent implements OnDestroy, OnInit {
    calendarSource = GTMCalendarSource.calendar;

    subscription = new Subscription();
    calendarVisible = false;
    protected orderNumbers$: BehaviorSubject<OrderNumbers>;
    private payNow$: BehaviorSubject<string>;
    paymentErrorStatus: string;
    listOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    listAlreadyOrderedOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    alreadyOrderedEntriesOpen = ALREADY_OPEN_EXTERNAL_ORDERS;
    @ViewChild('paymentForm') paymentForm: ElementRef;
    failedOrder$ = new BehaviorSubject<AimoCart>(undefined);
    closingTimeMessage$: BehaviorSubject<ClosingTimeMessage>;
    user$: Observable<AimoUser>;

    constructor(
        private actions$: Actions,
        protected activeCartService: AimoActiveCartService,
        protected selectiveCartService: SelectiveCartFacade,
        protected authService: AuthService,
        protected routingService: RoutingService,
        protected cartConfig: CartConfigService,
        protected windowRef: WindowRef,
        protected launchDialogService: LaunchDialogService,
        protected eventService: EventService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected activatedRoute: ActivatedRoute,
        protected userService: AimoUserService,
        protected aimoRoutingService: AimoRoutingService,
        protected gtmService: AimoGtmService,
    ) {
        super(activeCartService, selectiveCartService, authService, routingService, cartConfig);
        this.activeCartService.triggerGTMCartPageVisit();
        this.user$ = this.userService.get();
    }

    ngOnInit(): void {
        this.orderNumbers$ = new BehaviorSubject<OrderNumbers>(undefined);
        this.payNow$ = new BehaviorSubject<string>(undefined);
        super.ngOnInit();
        this.cart$ = merge(this.activeCartService.getActive(), this.failedOrder$).pipe(filter((o) => o !== undefined));

        this.subscription.add(
            this.eventService.get(OrderPlacedEvent).subscribe((event) => {
                const order = event?.order as AimoOrder;
                if (order.validForOrdering) {
                    if (order.paymentUrl) {
                        this.cart$ = of(event?.order);
                        this.cdr.detectChanges();
                        this.paymentForm.nativeElement.submit();
                    } else {
                        this.router.navigate([], {
                            relativeTo: this.activatedRoute,
                            queryParams: {
                                orderNumber: event.order.code,
                                date: new Date(order.requestedDeliveryDate).getTime(),
                                time: order.pickupTime,
                            },
                            queryParamsHandling: 'merge',
                        });
                        this.orderNumbers$.next(
                            this.splitOrderNumbers(order.code, new Date(order.requestedDeliveryDate), order.pickupTime),
                        );
                        setTimeout(() => this.activeCartService.reloadActiveCart(), 1500); // make sure that order history is updated
                    }
                } else {
                    this.failedOrder$.next({ ...event.order, validForOrdering: true } as undefined);
                    // scroll up for messages
                    setTimeout(
                        () => this.windowRef.document.querySelector('#cartDetailsWrapper')?.scrollIntoView(),
                        1500,
                    );
                }
                this.cdr.detectChanges();
            }),
        );
        this.subscription.add(
            this.actions$
                .pipe(
                    ofType(AIMO_CART_UPDATE_HEADER_SUCCESS),
                    map((action) => action as AimoUpdateCartHeaderSuccess),
                )
                .subscribe(() => {
                    this.router.navigate([], {
                        relativeTo: this.activatedRoute,
                        queryParams: { orderNumber: null },
                        queryParamsHandling: 'merge',
                    });
                }),
        );
        this.paymentErrorStatus = this.activatedRoute.snapshot.queryParams.paymentErrorStatus;
        this.subscription.add(
            this.activatedRoute.queryParams.subscribe((params) => {
                if (params.orderNumber) {
                    const orderNumbers = this.splitOrderNumbers(
                        params.orderNumber,
                        new Date(parseInt(params.date)),
                        params.time,
                    );
                    this.orderNumbers$.next(orderNumbers);
                }
                if (params['orderNumber'] === undefined) {
                    this.orderNumbers$.next(undefined);
                }
                if (params['payNow']) {
                    this.payNow$.next(params['payNow']);
                }
                if (params['openOrders']) {
                    params['openOrders'].split(',').forEach((order) => (this.alreadyOrderedEntriesOpen[order] = true));
                }
            }),
        );

        this.subscription.add(
            this.payNow$.subscribe((code) => {
                if (code) {
                    const sub = this.userService.getOrderToBePaid(code).subscribe((c) => {
                        this.cart$ = of(c);
                        this.cdr.detectChanges();
                        sub.unsubscribe();
                    });
                }
            }),
        );

        this.setMessages();
    }

    private splitOrderNumbers(code: string, date: Date, time: string): OrderNumbers {
        const split = code.split(';');
        return {
            createOrderNumber: split[0],
            changeOrderNumbers: split.length === 1 ? [''] : split[1].split(','),
            date: date ?? null,
            time: time,
        };
    }

    setMessages(): void {
        let closingTimeMessage = undefined;
        this.closingTimeMessage$ = new BehaviorSubject<ClosingTimeMessage>(undefined);
        setInterval(() => {
            this.cart$
                .subscribe((cart) => {
                    const closingEntries = (cart as AimoCart).dayGroupedEntries
                        ?.filter((g) => g.active)
                        .flatMap((g) => g.entries)
                        .filter((e) => e.closingTimeWarningDate?.getTime() <= new Date().getTime());
                    if (closingEntries && closingEntries.length > 0) {
                        const warningTime = closingEntries[0].closingTimeWarningDate;
                        const closingTime = closingEntries[0].closingTime;
                        const timeLeft = Math.round(
                            (closingEntries[0].closingTime.getTime() - new Date().getTime()) / 60000,
                        );
                        if (timeLeft >= 0 && warningTime && closingTime) {
                            closingTimeMessage = {
                                lines: closingEntries.length,
                                time: closingTime,
                                timeLeft: timeLeft,
                            };
                        } else {
                            if (closingTimeMessage) {
                                setTimeout(() => this.activeCartService.reloadActiveCart(), 2000);
                            }
                            closingTimeMessage = undefined;
                        }
                    } else {
                        closingTimeMessage = undefined;
                    }
                    this.closingTimeMessage$.next(closingTimeMessage);
                })
                .unsubscribe();
        }, 3000);
    }

    getCart(): Observable<AimoCart> {
        return this.cart$ as Observable<AimoCart>;
    }

    showMoreFunctions(showHide: HTMLElement): void {
        toggleFunctions(showHide, '.cart-functions', '.btn-link');
    }

    toggleCartItemList(isOpen: boolean): void {
        this.listOpen.next(isOpen);
    }

    toggleAlreadyOrderedCartItemList(isOpen: boolean): void {
        this.listAlreadyOrderedOpen.next(isOpen);
    }

    print(): void {
        this.windowRef.nativeWindow.print();
    }

    clearCart(): void {
        this.activeCartService.deleteCart();
    }

    selectDate(date: string): void {
        this.activeCartService.updateRequestedDate(date, GTMCalendarSource.cart);
    }

    updatePurchaseOrderNumber(event: Event, cart: AimoCart): void {
        const value = (event.target as HTMLInputElement).value;
        if (cart.purchaseOrderNumber !== value) {
            this.activeCartService.updateCartHeader({
                ...cart,
                purchaseOrderNumber: value,
            });
        }
    }

    updatePickupSlot(slot: AimoPickupSlot, cart: AimoCart): void {
        this.activeCartService.updateCartHeader({
            ...cart,
            pickupSlotId: slot.id,
        });
    }

    updateDeliveryInstructions(event: Event, cart: AimoCart): void {
        this.activeCartService.updateCartHeader({
            ...cart,
            deliveryInstructions: (event.target as HTMLInputElement).value,
        });
    }

    exportCartToExcel(): void {
        this.activeCartService.exportCartToExcel();
    }

    hasEntries(dayGroupedEntries: AimoDayGroupedEntries[]): boolean {
        let hasEntries = false;
        if (dayGroupedEntries) {
            dayGroupedEntries
                .filter((group) => group.active)
                .forEach((group) => {
                    if (group.entries && (group.entries.length > 0 || group.alreadyOrdered.entries.length > 0)) {
                        hasEntries = true;
                    }
                });
        }
        return hasEntries;
    }

    hasModifiedEntries(dayGroupedEntries: AimoDayGroupedEntries[]): boolean {
        return (
            dayGroupedEntries
                .filter((d) => d.active)
                .flatMap((d) => d.alreadyOrdered)
                .flatMap((e) => e.entries)
                .find((e) => e.modified) !== undefined
        );
    }

    public openRouteInfoModal(cart: AimoCart): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ROUTE_INFOS, undefined, {
            cart: cart,
        } as AimoCartDialogData);
    }

    public openExcelImportModal(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.EXCEL_IMPORT, undefined);
    }

    ngOnDestroy(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ROUTE_INFOS);
        this.subscription.unsubscribe();
        this.orderNumbers$.next(undefined);
    }

    backToCart(): void {
        this.routingService.go('/cart');
        this.activeCartService.reloadActiveCart();
        const split = this.splitOrderNumbers(this.activatedRoute.snapshot.queryParams.orderNumber, null, null);
        this.alreadyOrderedEntriesOpen[split.createOrderNumber] = true;
        split.changeOrderNumbers?.forEach((order) => (this.alreadyOrderedEntriesOpen[order] = true));
        this.orderNumbers$.next(undefined);
    }

    goToOrder(orderNumber: string): void {
        this.router.navigate(['/my-account/orders'], { queryParams: { searchTerm: orderNumber } });
    }

    toggleCalendar(): void {
        this.calendarVisible = !this.calendarVisible;
    }

    selectCalendarDay(date: string): void {
        this.activeCartService.updateRequestedDate(date, GTMCalendarSource.cart);
        this.toggleCalendar();
    }

    switchDate(cart: AimoCart): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SWITCH_CART_DATE, undefined, {
            cart: cart,
            allowPastDates: false,
        } as AimoSwitchDateHistoryDialogData);
    }

    addToOrderTemplate(cart: AimoCart, fromOrder: boolean, sourceId?: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            oldCartId: fromOrder ? undefined : cart.code,
            orderHistoryDay: fromOrder ? cart.requestedDeliveryDate : undefined,
            createNew: true,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    importFromOrderTemplate(sourceId: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            importFrom: true,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    switchDateEnabled(dayGroupedEntries: AimoDayGroupedEntries[]): boolean {
        return this.hasEntries(dayGroupedEntries) && !this.hasModifiedEntries(dayGroupedEntries);
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}

class ClosingTimeMessage {
    constructor(public lines: number, public time: Date, public timeLeft: number) {}
}

class OrderNumbers {
    constructor(
        public createOrderNumber: string,
        public changeOrderNumbers: string[],
        public date: Date,
        public time: string,
    ) {}
}
