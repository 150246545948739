import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoProductImageZoomDialogComponent } from './product-image-zoom-dialog/aimo-product-image-zoom-dialog.component';

export const productImageZoomLayoutConfig: LayoutConfig = {
    launch: {
        PRODUCT_IMAGE_ZOOM: {
            inline: true,
            component: AimoProductImageZoomDialogComponent,
            dialogType: DIALOG_TYPE.DIALOG,
            multi: true,
        },
    },
};
