import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoFaqItemComponent } from './aimo-faq-item.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        MediaModule,
        UrlModule,
        I18nModule,
        NgbCollapseModule,
        YouTubePlayerModule,
    ],
    declarations: [AimoFaqItemComponent],
    exports: [AimoFaqItemComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoFaqItemComponent: {
                    component: AimoFaqItemComponent,
                },
            },
        }),
    ],
})
export class AimoFaqItemModule {}
