import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoCMSFaqItemComponent } from '../../../model/cms.model';

@Component({
    selector: 'aimo-faq-item',
    templateUrl: './aimo-faq-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoFaqItemComponent implements OnInit {
    public isCollapsed = true;
    data$: Observable<AimoCMSFaqItemComponent>;

    constructor(protected componentData: CmsComponentData<AimoCMSFaqItemComponent>) {}

    ngOnInit(): void {
        this.data$ = this.componentData.data$;
    }
}
