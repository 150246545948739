<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">{{ 'aimo.cart.routeInfos' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <ng-container *ngFor="let info of routeInfos">
                <p class="mb-2">{{ info.scheduleId }} {{ info.scheduleName }}</p>
            </ng-container>

            <div class="modal-actions">
                <button class="btn btn-sm btn-primary" (click)="closeDialog()">
                    {{ 'common.close' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
