import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { AuthService, RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoUserService } from '../../../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-close-account-reorder',
    templateUrl: './aimo-close-account.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCloseAccountComponent implements OnDestroy {
    private subscription = new Subscription();

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected routingService: RoutingService,
        protected authService: AuthService,
        protected userService: AimoUserService,
    ) {}

    submit(): void {
        this.subscription.add(
            this.userService.closeUserAccount().subscribe(() =>
                this.authService.coreLogout().finally(() => {
                    this.routingService.goByUrl('/login');
                    this.closeDialog();
                }),
            ),
        );
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.CLOSE_ACCOUNT);
        this.launchDialogService.closeDialog('closed');
    }

    ngOnDestroy(): void {
        this.closeDialog();
        this.subscription.unsubscribe();
    }
}
