<div class="active-facets-wrapper fade-white" *ngIf="facetList$ | async as facetList">
    <div class="active-facets">
        <button
            *ngIf="isMyAssortmentSelected() | async"
            (click)="removeMyAssortment()"
            class="btn-facet btn-sm d-inline">
            <span [innerHTML]="'aimo.user.myAssortment' | cxTranslate"></span>
            <i class="aimo-icon icon-inline icon-x icon-sm ml-2"></i>
        </button>
        <ng-container *ngIf="activeFacetList$ | async as activeFacets">
            <button
                class="btn-facet btn-sm d-inline"
                *ngFor="let facet of activeFacets"
                routerLink="./"
                [queryParams]="getLinkParams(facet)"
                [cxFocus]="{ key: getFocusKey(facetList, facet) }"
                (keydown.space)="removeFilterWithSpacebar($event)"
                [attr.aria-label]="'productList.activeFilter' | cxTranslate : { filter: facet.facetValueName }">
                <span [innerHTML]="facet.facetValueName"></span>
                <i class="aimo-icon icon-inline icon-x icon-sm ml-2"></i>
            </button>
            <button
                *ngIf="activeFacets?.length > 0"
                class="btn-facet btn-sm d-inline"
                routerLink="./"
                [queryParams]="clearUrlParams"
                (keydown.space)="removeFilterWithSpacebar($event)"
                [attr.aria-label]="'productList.faceets.empty' | cxTranslate">
                <span>{{ 'productList.faceets.empty' | cxTranslate }}</span>
                <i class="aimo-icon icon-inline icon-x icon-sm ml-2"></i>
            </button>
        </ng-container>
    </div>
</div>
