<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-route-calendar">
        <div class="modal-header">
            <h3 class="mb-0">
                <span>{{
                    (createClaim
                        ? 'aimo.cart.chooseDate'
                        : orderTemplateCode
                        ? 'aimo.cart.chooseDate'
                        : 'aimo.cart.switchDate'
                    ) | cxTranslate
                }}</span>
            </h3>
            <div class="modal-action col-auto">
                <button #closeButton class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <aimo-cx-delivery-date-component
                [claimsMode]="createClaim"
                [allowPastDates]="allowPastDates"
                [autofocus]="true"
                [modal]="true"
                (dayClicked)="selectDate($event)"
                [calendarSource]="calendarSource">
            </aimo-cx-delivery-date-component>

            <div class="modal-actions flex-start">
                <button (click)="submit()" [disabled]="submitDisabled()" class="btn btn-primary btn-sm btn-inline mr-2">
                    {{ 'aimo.orderhistory.selectDate' | cxTranslate }}
                </button>
                <button
                    class="btn btn-secondary btn-sm btn-inline"
                    (keydown.tab)="getTheFocusBack($event)"
                    (click)="closeDialog()">
                    {{ 'aimo.orderhistory.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
