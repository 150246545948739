import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

import { AimoBreadcrumbModule } from '../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoSpinnerModule } from '../shared/spinner/aimo-spinner.module';

import { AimoBulletinHeaderComponent } from './aimo-bulletin-header.component';
import { AimoBulletinListComponent } from './aimo-bulletin-list.component';
import { AimoBulletinComponent } from './aimo-bulletin.component';

@NgModule({
    imports: [
        I18nModule,
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                BulletinListComponent: {
                    component: AimoBulletinListComponent,
                },
                AimoBulletinListComponent: {
                    component: AimoBulletinListComponent,
                },
                AimoBulletinHeaderComponent: {
                    component: AimoBulletinHeaderComponent,
                },
                BulletinComponent: {
                    component: AimoBulletinComponent,
                },
                AimoBulletinComponent: {
                    component: AimoBulletinComponent,
                },
            },
        }),
        MediaModule,
        NgbPopoverModule,
        AimoSpinnerModule,
        AimoBreadcrumbModule,
    ],
    declarations: [AimoBulletinListComponent, AimoBulletinHeaderComponent, AimoBulletinComponent],
    entryComponents: [AimoBulletinListComponent, AimoBulletinHeaderComponent, AimoBulletinComponent],
    exports: [AimoBulletinListComponent, AimoBulletinHeaderComponent, AimoBulletinComponent],
})
export class AimoBulletinsModule {}
