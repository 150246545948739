import { Injectable } from '@angular/core';
import { Converter, Occ, OccConfig, Product, ProductImageNormalizer, ProductReferences } from '@spartacus/core';

import { AimoProduct } from '../../model/product.model';

@Injectable({ providedIn: 'root' })
export class AimoProductNormalizer implements Converter<Occ.Product, Product> {
    constructor(protected config: OccConfig, protected productImageNormalizer: ProductImageNormalizer) {}

    convert(source: Occ.Product, target?: AimoProduct): AimoProduct {
        if (target === undefined) {
            // eslint-disable-next-line
            target = { ...(source as any) };
        }
        target = { ...target, stock: { ...target.stock } };
        if (target.stock) {
            // eslint-disable-next-line
            target.stock.stockLevel = (target.stock as any)?.stockLevelDouble;
        }
        if (target.pikatukkuStock) {
            // eslint-disable-next-line
            target.pikatukkuStock?.forEach((ptStock) => (ptStock.stockLevel = (ptStock as any)?.stockLevelDouble));
        }
        if (source.productReferences) {
            target.productReferences = this.normalizeProductReferences(source.productReferences);
        }
        target.nameHtml = source.name;
        return target;
    }

    protected normalizeProductReferences(source: Occ.ProductReference[]): ProductReferences {
        const references: ProductReferences = {};

        if (source) {
            for (const reference of source) {
                if (reference.referenceType) {
                    if (!references.hasOwnProperty(reference.referenceType)) {
                        references[reference.referenceType] = [];
                    }
                    let referenceTarget = this.convert(reference.target);
                    referenceTarget = this.productImageNormalizer.convert(reference.target, referenceTarget);
                    references[reference.referenceType].push({
                        ...reference,
                        target: referenceTarget,
                    });
                }
            }
        }
        return references;
    }
}
