import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { LanguageService, RoutingService, TranslationService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { AimoCartList } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGtmService } from '../../../service/gtm/aimo-gtm.service';
import { SELECT_UNIT_SUCCESS } from '../../../service/user/aimo-user.action';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { AimoMessageService } from '../../../service/util/aimo-message-service';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoSwitchDateHistoryDialogData } from '../../cart-details/switch-date-modal/aimo-switch-cart-date-layout.config';
import { AimoNgbDateParserFormatter } from '../../shared/datepicker/aimo-datepicker-parser-formatter';
import { AimoClaimsDialogData } from '../claims-modal/aimo-claims-layout.config';

@Component({
    selector: 'aimo-claims-list',
    templateUrl: './aimo-claims.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AimoClaimsComponent implements OnInit, OnDestroy {
    cartList$: Observable<AimoCartList>;
    searchForm: FormGroup;
    subscriptions: Subscription = new Subscription();

    // eslint-disable-next-line
    breadcrumbs$: Observable<any[]> = this.lTranslation.translate('aimo.claimslist.title').pipe(
        filter(Boolean),
        map((translation) => [{ label: translation, link: '/claims' }]),
    );

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected activeCartService: AimoActiveCartService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
        protected language: LanguageService,
        protected actions$: Actions,
        protected messageService: AimoMessageService,
        protected userService: AimoUserService,
        private lTranslation: TranslationService,
        protected gtmService: AimoGtmService,
    ) {}

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            from: new FormControl(),
            to: new FormControl(),
        });
        this.fetchClaims();

        this.subscriptions.add(
            this.actions$.subscribe((action) => {
                if (action.type === SELECT_UNIT_SUCCESS) {
                    this.fetchClaims();
                    this.cdr.detectChanges();
                }
            }),
        );

        this.subscriptions.add(
            this.launchDialogService.dialogClose.subscribe((reason) => {
                if (reason == 'reload') {
                    this.fetchClaims();
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    openClaim(date: string): void {
        this.launchDialogService.closeDialog('closed');
        this.userService
            .getOrderHistoryDay(this.convertDate(date))
            .pipe(take(1))
            .subscribe((orders) =>
                this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLAIMS, undefined, {
                    day: { ...orders.dayGroupedOrders[0], date: date },
                    editable: false,
                } as AimoClaimsDialogData),
            );
    }

    convertNgbDate(d: string): NgbDate {
        return d ? this.ngbDateParserFormatter.parse(new Date(d)) : null;
    }

    fetchClaims(): void {
        this.cartList$ = this.activeCartService.getClaims(
            this.searchForm.controls.from.value
                ? DateUtils.convertDateToOccString(
                      this.language,
                      this.ngbDateParserFormatter.toDate(this.searchForm.controls.from.value),
                  )
                : null,
            this.searchForm.controls.to.value
                ? DateUtils.convertDateToOccString(
                      this.language,
                      this.ngbDateParserFormatter.toDate(this.searchForm.controls.to.value),
                  )
                : null,
        );
    }

    createClaim(): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SWITCH_CART_DATE, undefined, {
            allowPastDates: true,
            createClaim: true,
        } as AimoSwitchDateHistoryDialogData);
    }

    hideMessage(id: string): void {
        this.messageService.hideMessage(id);
        this.cdr.detectChanges();
    }

    isMessageHidden(id: string): boolean {
        return this.messageService.isMessageHidden(id);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}
