import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomFormValidators, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoB2BUnitOption, AimoUser } from '../../../../model/user.model';
import { AimoUserService } from '../../../../service/user/aimo-user.service';

import { AddNewUserData } from './aimo-new-user.config';

@Component({
    selector: 'aimo-add-new-user',
    templateUrl: './aimo-add-new-user.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoAddNewUserComponent {
    form: FormGroup;
    modifyUser: boolean;
    userLocked: boolean;
    currentUser: boolean;
    user: AimoUser;
    b2bUnits: AimoB2BUnitOption[];

    searchUnits: string;
    @ViewChild('modalContent') modalContent: ElementRef;

    constructor(
        protected cdr: ChangeDetectorRef,
        protected launchDialogService: LaunchDialogService,
        protected userService: AimoUserService,
        protected renderer: Renderer2,
    ) {
        this.launchDialogService.data$.subscribe((data: AddNewUserData) => this.initForm(data)).unsubscribe();
    }

    initForm(data: AddNewUserData): void {
        this.currentUser = data.currentUser.uid === data.user.uid;
        this.user = data.user;
        this.b2bUnits = data.b2bUnits;
        this.modifyUser = data.user !== undefined;
        this.userLocked = !data.user.active;
        const controls = {
            uid: new FormControl(data.user.uid),
            name: new FormControl(data.user ? data.user.name : null, [Validators.required]),
            email: new FormControl(data.user ? data.user.uid : null, [
                Validators.required,
                CustomFormValidators.emailValidator,
            ]),
            closeAccount: new FormControl(data.user ? !data.user.active : false),
            customerAdmin: new FormControl(data.user ? data.user.customerAdmin : false),
        };
        data.b2bUnits.forEach(
            (b2bUnit) =>
                (controls[b2bUnit.uid] = new FormControl(
                    this.user.availableUnits.find((a) => a.uid === b2bUnit.uid) !== undefined,
                )),
        );
        this.form = new FormGroup(controls);
    }

    save(): void {
        if (this.form.valid) {
            this.userService.addOrModifyUser({
                name: this.form.controls.name.value,
                uid: this.form.controls.email.value,
                customerAdmin: this.form.controls.customerAdmin.value,
                closeAccount: this.form.controls.closeAccount.value,
                b2bUnits: this.b2bUnits.map((u) => u.uid).filter((uid) => this.form.controls[uid].value === true),
            });
            this.closeDialog();
        }
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ADD_NEW_USER);
        this.launchDialogService.closeDialog('closed');
    }

    changeSearch(search: string): void {
        this.searchUnits = search ? search : '';
        this.cdr.detectChanges();
    }

    closeAccount(): void {
        this.form.controls.closeAccount.setValue(true);
        this.save();
    }

    openAccount(): void {
        this.form.controls.closeAccount.setValue(false);
        this.save();
    }

    accountClosable(): boolean {
        return !this.currentUser;
    }
}
