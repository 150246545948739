<ng-template cxOutlet="cx-storefront" cxPageTemplateStyle>
    <ng-template cxOutlet="cx-notification">
        <cx-page-slot position="Notification"></cx-page-slot>
    </ng-template>
    <ng-template cxOutlet="cx-header">
        <header
            class="sticky-header"
            cxSkipLink="cx-header"
            [cxFocus]="{ disableMouseFocus: true }"
            [class.is-expanded]="isExpanded$ | async">
            <div class="top-header">
                <cx-page-layout section="topHeader"></cx-page-layout>
            </div>
            <div class="middle-header">
                <cx-page-layout section="header"></cx-page-layout>
            </div>
            <cx-page-layout section="navigation"></cx-page-layout>
        </header>
        <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
        <aimo-cx-global-message aria-atomic="true" aria-live="assertive"></aimo-cx-global-message>
    </ng-template>

    <main cxSkipLink="cx-main" [cxFocus]="{ disableMouseFocus: true }" [class.menu-expanded]="isExpanded$ | async">
        <router-outlet></router-outlet>
    </main>

    <ng-template cxOutlet="cx-footer">
        <footer cxSkipLink="cx-footer" [cxFocus]="{ disableMouseFocus: true }">
            <cx-page-layout section="footer"></cx-page-layout>
        </footer>
    </ng-template>
</ng-template>
