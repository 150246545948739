import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { AimoRoutingService } from '../../cms-structure/routing/aimo-routing.service';
import { AimoCart } from '../../model/cart.model';
import { AimoOrderHistory, AimoOrderHistoryRequest } from '../../model/order.model';
import { AimoFacet, AimoFacetValue } from '../../model/product.model';
import { AimoGtmService } from '../../service/gtm/aimo-gtm.service';
import { AimoUserService } from '../../service/user/aimo-user.service';
import { AimoMessageService } from '../../service/util/aimo-message-service';
import { AimoNgbDateParserFormatter } from '../shared/datepicker/aimo-datepicker-parser-formatter';

@Component({
    selector: 'aimo-order-history',
    templateUrl: './aimo-order-history.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistoryComponent implements OnInit {
    orders$: Observable<AimoOrderHistory>;

    facets: AimoFacet[] = [];
    searchForm: FormGroup;

    constructor(
        protected routing: RoutingService,
        protected userService: AimoUserService,
        protected language: LanguageService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
        protected activatedRoute: ActivatedRoute,
        protected messageService: AimoMessageService,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected aimoRoutingService: AimoRoutingService,
        protected gtmService: AimoGtmService,
    ) {}

    ngOnInit(): void {
        const searchTerm = this.activatedRoute.snapshot.queryParams?.searchTerm;
        this.searchForm = new FormGroup({
            search: new FormControl(searchTerm),
            from: new FormControl(),
            to: new FormControl(),
        });
        this.fetchOrders();
    }

    fetchOrders(): void {
        this.orders$ = this.userService.getOrderHistory({
            from: 0,
            sorts: [],
            facets: this.facets,
            deliveryDate: {
                from: this.searchForm.controls.from.value
                    ? this.ngbDateParserFormatter.toDate(this.searchForm.controls.from.value)
                    : null,
                to: this.searchForm.controls.to.value
                    ? this.ngbDateParserFormatter.toDate(this.searchForm.controls.to.value)
                    : null,
            },
            searchTerm: this.searchForm.controls['search'].value,
        } as AimoOrderHistoryRequest);
    }

    convertDate(d: string): NgbDate {
        return d ? this.ngbDateParserFormatter.parse(new Date(d)) : null;
    }

    toggleFacet(facet: AimoFacet, facetValue: AimoFacetValue): void {
        //multiselect disabled
        /*     const existingFacet = facet && this.facets.find((f) => f.code === facet.code);
        if (existingFacet) {
            const existingValue = existingFacet.values.find(
                (val) => val.code === facetValue.code && facetValue.selected,
            );
            if (existingValue) {
                existingFacet.values = existingFacet.values.filter((val) => val.code !== facetValue.code);
            } else {
                existingFacet.values.push(facetValue);
            }
        } else*/ if (facetValue) {
            this.facets = [{ ...facet, values: [facetValue] }];
        } else {
            this.facets = [];
        }
        this.fetchOrders();
    }

    getTotalOrders(facets: AimoFacet[]): number {
        return facets.flatMap((f) => f.values).reduce((sum, facetValue) => sum + facetValue.count, 0);
    }

    hideMessage(id: string, code: string): void {
        this.messageService.hideMessage(id + code);
        this.cdr.detectChanges();
    }

    isMessageHidden(id: string, code: string): boolean {
        return this.messageService.isMessageHidden(id + code);
    }

    unpaidOrders: AimoCart[];

    getUnPaidOrders(orderHistory: AimoOrderHistory): AimoCart[] {
        if (!this.unpaidOrders) {
            this.unpaidOrders = orderHistory.dayGroupedOrders
                .flatMap((o) => o.orders)
                .filter((o) => o.statusDisplay === 'PAYMENT_OPEN');
        }
        return this.unpaidOrders;
    }

    payNow(code: string): void {
        if (code) {
            this.routingService.go('/cart', { queryParams: { payNow: code } });
        }
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}
