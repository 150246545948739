<ng-container *ngIf="showBackLink; else normalBreadcrumb">
    <div class="row align-items-center aimo-breadcrumb-backlink-wrapper">
        <div class="col-auto backlink">
            <a role="button" class="btn-link" (click)="back()" *ngIf="showBackLink">
                <i class="aimo-icon icon-inline icon-arrow-left icon-sm"></i>
                <span>{{ 'common.back' | cxTranslate }}</span>
            </a>
        </div>
        <div class="col-md-auto col-9">
            <ng-container *ngTemplateOutlet="normalBreadcrumb; context: { styleClass: 'fade-white' }"></ng-container>
        </div>
    </div>
</ng-container>

<ng-template #normalBreadcrumb let-style="styleClass">
    <div class="aimo-breadcrumb-wrapper" [ngClass]="style">
        <div #breadcrumb class="aimo-breadcrumb">
            <nav class="d-inline" [attr.aria-label]="'common.breadcrumbs' | cxTranslate" tabindex="-1">
                <ol>
                    <!-- Loop for normal page breadcrumbs -->
                    <li *ngFor="let crumb of crumbs$ | async">
                        <a
                            class="d-inline"
                            [attr.aria-label]="'navigation.navigateTo' | cxTranslate : { nav: crumb.label }"
                            [routerLink]="crumb.link"
                            [innerHTML]="crumb.label"></a>
                        <i class="aimo-icon icon-inline icon-caret-right icon-mid-grey icon-sm"></i>
                    </li>
                    <li *ngIf="contentPageTitle">
                        <span>{{ contentPageTitle }}</span>
                    </li>
                    <!-- Loop for category and product page breadcrumbs -->
                    <li *ngFor="let crumb of breadCrumbs; index as idx; last as isLast">
                        <ng-container *ngIf="idx === 0">
                            <a [routerLink]="'/'">
                                <ng-container *ngIf="crumb.facetValueName">
                                    {{ crumb.facetValueName }}
                                </ng-container>
                                <ng-container *ngIf="!crumb.facetValueName">
                                    {{ 'common.home' | cxTranslate }}
                                </ng-container>
                            </a>
                        </ng-container>
                        <ng-container *ngIf="idx !== 0">
                            <ng-container *ngIf="idx < 3">
                                <ng-container
                                    *ngIf="
                                        idx === 2 ? setSecondLevelCategory(crumb.facetValueCode) : ''
                                    "></ng-container>
                                <a routerLink="/category/{{ crumb.facetValueCode }}">
                                    {{ crumb.facetValueName }}
                                </a>
                            </ng-container>
                            <ng-container *ngIf="idx >= 3">
                                <ng-container *ngIf="!isLast">
                                    <a
                                        routerLink="/category/{{ secondLevelCategory }}"
                                        [queryParams]="{
                                            query:
                                                ':relevance:allCategories:' +
                                                secondLevelCategory +
                                                ':category:' +
                                                crumb.facetValueCode
                                        }">
                                        {{ crumb.facetValueName }}
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="isLast">
                                    <span #breadcrumbLastItem>{{ crumb.facetValueName }}</span>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <i class="aimo-icon icon-inline icon-caret-right icon-mid-grey icon-sm"></i>
                    </li>
                </ol>
            </nav>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="!isProductBreadcrumb && !hideTitle">
    <!-- Hidden page title for Screen Reader initialized after view to avoid old values -->
    <span class="cx-visually-hidden" aria-live="polite" aria-atomic="true">
        {{ lastestTitle$ | async }}
    </span>
    <h1>{{ title$ | async }}</h1>
</ng-container>
