<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<h1 class="text-primary page-title" tabindex="0" [attr.aria-label]="'updatePasswordForm.title' | cxTranslate">
    {{ 'updatePasswordForm.title' | cxTranslate }}
</h1>

<form (ngSubmit)="onSubmit()" [formGroup]="form" class="row">
    <div class="col-12 col-md-6 mb-4">
        <aimo-cx-input
            [autofocus]="true"
            [label]="'updatePasswordForm.oldPassword.label'"
            [labelClass]="'text-bold'"
            [nameForm]="'oldPassword'"
            [parentForm]="form"
            [typeInput]="'password'"
            idName="oldPassword"></aimo-cx-input>
        <cx-form-errors [control]="form.get('oldPassword')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
    </div>

    <div class="w-100"></div>

    <div class="col-12 col-md-6 mb-4">
        <aimo-cx-input
            [label]="'updatePasswordForm.newPassword.label'"
            [labelClass]="'text-bold'"
            [nameForm]="'newPassword'"
            [parentForm]="form"
            [typeInput]="'password'"
            idName="newPassword"></aimo-cx-input>
        <aimo-password-checks [value]="form.get('newPassword').value"></aimo-password-checks>
    </div>

    <div class="w-100"></div>

    <div class="col-12 col-md-6 mb-4">
        <aimo-cx-input
            [label]="'updatePasswordForm.confirmPassword.label'"
            [labelClass]="'text-bold'"
            [nameForm]="'newPasswordConfirm'"
            [parentForm]="form"
            [typeInput]="'password'"
            idName="newPasswordConfirm"></aimo-cx-input>
        <cx-form-errors
            [control]="form.get('newPasswordConfirm')"
            aria-atomic="true"
            aria-live="assertive"></cx-form-errors>
    </div>

    <div class="w-100"></div>

    <div class="col-12 col-md-6">
        <button class="btn btn-primary" [disabled]="form.disabled">
            {{ 'updatePasswordForm.saveChanges' | cxTranslate }}
        </button>
    </div>
</form>
