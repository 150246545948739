import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoBaseOrder } from '../../../model/cart.model';

import { AimoBaseOrderBlockModalComponent } from './aimo-base-order-block-modal.component';
import { AimoBaseOrderBlockRemoveModalComponent } from './aimo-base-order-block-remove-modal.component';
import { AimoBaseOrderModalComponent } from './aimo-base-order-modal.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        CREATE_BASE_ORDER = 'CREATE_BASE_ORDER',
        BLOCK_BASE_ORDER = 'BLOCK_BASE_ORDER',
        BLOCK_REMOVE_BASE_ORDER = 'BLOCK_REMOVE_BASE_ORDER',
    }
}

export const baseOrderLayout: LayoutConfig = {
    launch: {
        BLOCK_BASE_ORDER: {
            inlineRoot: true,
            component: AimoBaseOrderBlockModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
        BLOCK_REMOVE_BASE_ORDER: {
            inlineRoot: true,
            component: AimoBaseOrderBlockRemoveModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
        CREATE_BASE_ORDER: {
            inlineRoot: true,
            component: AimoBaseOrderModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoBaseOrderDialogData {
    existingBaseOrder?: AimoBaseOrder;
}
