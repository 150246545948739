import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoProduct } from '../../../model/product.model';
import { AimoGTMProductAttributes, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { AimoProductComparisonService } from '../../../service/user/aimo-product-comparison.service';

@Component({
    selector: 'aimo-product-comparison-preview',
    templateUrl: './aimo-product-comparison-preview.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductComparisonPreviewComponent implements OnInit {
    products$: Observable<AimoProduct[]>;

    constructor(
        protected productComparisonService: AimoProductComparisonService,
        protected launchDialogService: LaunchDialogService,
        protected currentProductService: CurrentProductService,
    ) {}

    ngOnInit(): void {
        this.products$ = this.productComparisonService.getCompareProducts(0, 9999);
    }

    remove(product: AimoProduct): void {
        this.productComparisonService.removeProductFromComparison(product.code);
    }

    clear(): void {
        this.productComparisonService.clear();
        this.launchDialogService.closeDialog(null);
    }

    compare(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRODUCT_COMPARISON, undefined);
    }

    openProductModal(productCode: string, idx: number, sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            productCode,
            this.createGtmAttributes(idx),
            null,
            null,
            null,
            sourceId,
        );
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: GTMItemListId.product_comparison,
        } as AimoGTMProductAttributes;
    }
}
