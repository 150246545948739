import { Injectable, OnDestroy } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { AimoUserService } from '../user/aimo-user.service';

const STORAGE_KEY = 'aimo.recentProducts.';
const DEFAULT_VALUE: string[] = [];
const SEPARATOR = '|';

@Injectable({
    providedIn: 'root',
})
export class AimoRecentProductsService implements OnDestroy {
    subscription: Subscription = new Subscription();
    currentUnit: string;

    constructor(protected userService: AimoUserService, protected winRef: WindowRef) {
        setTimeout(() => {
            this.userService.getCurrentUnit().subscribe((unit) => (this.currentUnit = unit?.uid));
        }, 5000);
    }

    add(codes: string[]): void {
        this.setStorageValue([...codes, ...this.get()]);
    }

    get(maxLength?: number): string[] {
        const value = this.winRef.sessionStorage.getItem(this.getStorageKey());
        if (value) {
            const ret = value.split(SEPARATOR);
            return maxLength ? ret.slice(0, maxLength) : ret;
        }
        return DEFAULT_VALUE;
    }

    clear(): void {
        this.setStorageValue(DEFAULT_VALUE);
    }

    private setStorageValue(value: string[]): void {
        this.winRef.sessionStorage.setItem(this.getStorageKey(), value.join(SEPARATOR));
    }

    private getStorageKey(): string {
        return STORAGE_KEY + this.currentUnit;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
