import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ConverterService,
    OccEndpointsService,
    OccProductAdapter,
    OccRequestsOptimizerService,
    PRODUCT_NORMALIZER,
    ScopedProductData,
    UserIdService,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { AimoProduct } from '../../model/product.model';
import { AimoActiveCartService } from '../cart/aimo-active-cart.service';
import { KlevuParams } from '../gtm/aimo-gtm.model';
import { AimoDeliveryDateService } from '../routecalendar/aimo-delivery-date.service';

import { AimoAssortmentService } from './aimo-assortment.service';

@Injectable({
    providedIn: 'root',
})
export class AimoOccProductAdapter extends OccProductAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected converter: ConverterService,
        protected requestsOptimizer: OccRequestsOptimizerService,
        protected activeCartService: AimoActiveCartService,
        protected userIdService: UserIdService,
        protected deliveryDateService: AimoDeliveryDateService,
        protected assortmentService: AimoAssortmentService,
    ) {
        super(http, occEndpoints, converter, requestsOptimizer);
    }

    // eslint-disable-next-line
    loadAimo(
        productCode: string,
        categoryCode: string,
        searchTerm: string,
        klevuParams: KlevuParams,
    ): Observable<AimoProduct> {
        return this.getEndpoint2(productCode, categoryCode, searchTerm, klevuParams).pipe(
            mergeMap((url) => this.http.get(url).pipe(this.converter.pipeable(PRODUCT_NORMALIZER))),
        );
    }

    loadManyProducts(userId: string, products: string[]): Observable<AimoProduct[]> {
        return this.assortmentService.isOnlyMyAssortment().pipe(
            switchMap((onlyMyAssortment) =>
                this.deliveryDateService.getDeliveryDate().pipe(
                    filter(Boolean),
                    mergeMap((requestedDeliveryDate) =>
                        this.http
                            .post<AimoProduct[]>(
                                this.occEndpoints.buildUrl('loadMany', {
                                    urlParams: { userId: userId },
                                    queryParams: {
                                        requestedDeliveryDate,
                                        onlyMyAssortment,
                                    },
                                }),
                                products,
                            )
                            .pipe(this.converter.pipeableMany(PRODUCT_NORMALIZER)),
                    ),
                ),
            ),
        );
    }

    // eslint-disable-next-line
    loadMany(products: ScopedProductData[]): ScopedProductData[] {
        throw new Error('use load() method, not this one');
    }

    protected getEndpoint2(
        code: string,
        categoryCode: string,
        searchTerm: string,
        klevuParams?: KlevuParams,
    ): Observable<string> {
        return this.userIdService.getUserId().pipe(
            take(1),
            mergeMap((userId) =>
                this.deliveryDateService.getDeliveryDate().pipe(
                    filter(Boolean),
                    map((requestedDeliveryDate) =>
                        this.occEndpoints.buildUrl('product', {
                            urlParams: { productCode: code, userId: userId },
                            queryParams: {
                                requestedDeliveryDate: requestedDeliveryDate,
                                categoryCode: categoryCode,
                                searchTerm: searchTerm,
                                klevuBannerId: klevuParams?.klevuBannerId,
                                klevuBannerTitle: klevuParams?.klevuBannerTitle,
                                klevuBannerPageType: klevuParams?.klevuBannerPageType,
                                klevuBannerType: klevuParams?.klevuBannerType,
                            },
                        }),
                    ),
                ),
            ),
        );
    }
}
