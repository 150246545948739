<div class="modal-dialog modal-dialog-centered" *ngIf="form">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0" id="template-modal-title">
                {{ (existingBaseOrder ? 'aimo.baseOrders.editTitle' : 'aimo.baseOrders.button.create') | cxTranslate }}
            </h2>
            <div class="modal-action col-auto">
                <button #closeButton class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" [formGroup]="form">
            <div class="mb-4">
                <aimo-cx-input
                    [autofocus]="true"
                    [labelClass]="'text-bold pt-0'"
                    [parentForm]="form"
                    [required]="true"
                    [label]="'aimo.baseOrders.name.label'"
                    [nameForm]="'name'"
                    [idName]="'name'"></aimo-cx-input>
                <cx-form-errors [control]="form.get('name')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
            </div>

            <div class="mb-4">
                <div class="input-label-value required text-bold mb-3 d-inline-block">
                    {{ 'aimo.baseOrders.weekdays' | cxTranslate }}
                </div>
                <i
                    class="icon-inline aimo-icon icon-info icon-petrol-blue icon-sm ml-2"
                    [ngbPopover]="'aimo.baseOrders.weekdaysTooltip' | cxTranslate"
                    placement="right"
                    container="body"
                    triggers="mouseenter:mouseleave"></i>
                <div class="form-check" *ngFor="let weekday of weekDays">
                    <label>
                        <input type="checkbox" [formControlName]="'weekday_' + weekday" />
                        <span>{{ 'aimo.calendar.weekday.' + weekday | cxTranslate }}</span>
                    </label>
                </div>
            </div>

            <div class="mb-4">
                <aimo-cx-input-select
                    [bindValue]="'id'"
                    [items]="evenOdd"
                    [labelClass]="'text-bold pt-0 mb-2'"
                    [label]="'aimo.baseOrders.evenOdd.label'"
                    [nameForm]="'evenOdd'"
                    [parentForm]="form"
                    [translateByCode]="true"
                    [required]="true"
                    idName="evenOdd"></aimo-cx-input-select>
                <cx-form-errors
                    [control]="form.get('evenOdd')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>

            <div class="mb-4">
                <aimo-cx-input
                    [labelClass]="'text-bold pt-0'"
                    [parentForm]="form"
                    [label]="'aimo.baseOrders.purchaseOrderNumber.label'"
                    [nameForm]="'purchaseOrderNumber'"
                    [idName]="'purchaseOrderNumber'"
                    [maxlength]="20"></aimo-cx-input>
                <cx-form-errors
                    [control]="form.get('purchaseOrderNumber')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>

            <div class="modal-actions flex-start">
                <button
                    class="btn btn-primary btn-sm btn-inline mr-2"
                    [attr.aria-label]="'common.save' | cxTranslate"
                    [title]="'common.save' | cxTranslate"
                    (click)="submit(); pushGtmEvent('baseorders-create-new-modal-save')"
                    [disabled]="!isValid()">
                    {{ 'common.save' | cxTranslate }}
                </button>

                <button
                    class="btn btn-secondary btn-sm btn-inline"
                    [attr.aria-label]="'common.cancel' | cxTranslate"
                    [title]="'common.cancel' | cxTranslate"
                    (click)="closeDialog(); pushGtmEvent('baseorders-create-new-modal-cancel')">
                    {{ 'common.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
