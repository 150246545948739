<div class="modal-dialog modal-xl">
    <div class="modal-content">
        <div class="modal-header mb-0">
            <h2 class="mb-0">{{ 'aimo.product.comparison.title' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" *ngIf="allProducts$ | async as products">
            <aimo-cx-carousel
                (slideChange)="scrollChange($event, true)"
                (scrollChange)="scrollChange($event, false)"
                class="carousel-products mb-4"
                role="region"
                tabindex="-1"
                itemWidth="10px"
                [hideIndicators]="true"
                [items]="getCarouselProducts(products)"
                [template]="carouselItem"
                [idSuffix]="'product_comparison'">
            </aimo-cx-carousel>

            <div class="data-table" *ngIf="products" #dataTable>
                <div class="table-title cursor-pointer" (click)="openCloseInfo(iconNutritional, nutritionalDivContent)">
                    <h3 class="mb-0 text-primary">{{ 'productDetails.nutritional.content' | cxTranslate }}</h3>
                    <i class="aimo-icon icon-cursor icon-petrol-blue icon-caret-up ml-auto" #iconNutritional></i>
                </div>

                <div #nutritionalDivContent>
                    <div class="comparison-content" *ngFor="let uiAttribute of getUiAttributes(products)">
                        <div class="text-bold table-subTitle col-12">
                            {{ uiAttribute.title }}
                        </div>
                        <div class="comparison-data" #comparisonData>
                            <div class="table-content text-center" *ngFor="let product of products">
                                {{ getValue(uiAttribute, product) }}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="table-title cursor-pointer"
                    (click)="openCloseInfo(iconSpecification, specificationDivContent)">
                    <h3 class="mb-0 text-primary">{{ 'productDetails.specification' | cxTranslate }}</h3>
                    <i class="aimo-icon icon-cursor icon-petrol-blue icon-caret-up ml-auto" #iconSpecification></i>
                </div>

                <div #specificationDivContent>
                    <ng-container
                        *ngTemplateOutlet="productInfo; context: { products: products, property: 'keyIngredients' }">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="nutritionalContent; context: { products: products, property: 'allergen' }">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="
                            nutritionalContent;
                            context: { products: products, property: 'nutritionalClaim' }
                        ">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="
                            productInfo;
                            context: { products: products, property: 'preparationInstructions' }
                        ">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="productInfo; context: { products: products, property: 'usageInstructions' }">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="
                            productInfo;
                            context: { products: products, property: 'storageInstructions' }
                        ">
                    </ng-container>

                    <ng-container
                        *ngTemplateOutlet="productInfo; context: { products: products, property: 'countriesOfOrigin' }">
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #carouselItem let-item="item" let-index="index">
    <aimo-product-grid-item
        [openModalAfterClose]="MODAL_CALLER"
        [product]="item"
        [idSuffix]="'comparison_product_' + index"
        [gtmProductAttributes]="createGtmAttributes(index)"
        [openProductPageFromLink]="false"
        [isComparisonProduct]="true">
    </aimo-product-grid-item>
</ng-template>

<ng-template #productInfo let-products="products" let-property="property">
    <div class="comparison-content">
        <div class="text-bold table-subTitle">
            {{ 'aimo.product.' + property | cxTranslate }}
        </div>
        <div class="comparison-data">
            <div class="table-content" *ngFor="let product of products">
                <ng-container *ngIf="product[property]">
                    {{ getPropery(product[property]) }}
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #nutritionalContent let-products="products" let-property="property">
    <div class="comparison-content">
        <div class="text-bold table-subTitle">
            {{ 'aimo.product.' + property | cxTranslate }}
        </div>
        <div class="comparison-data">
            <div class="table-content" *ngFor="let product of products">
                <ng-container *ngIf="getNutritionalContent(product, property)">
                    {{ getClassificationNameList(product, property) }}
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>
