import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    AuthActions,
    normalizeHttpError,
    ProductActions,
    ProductSearchConnector,
    SiteContextActions,
    WindowRef,
    withdrawOn,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AimoProductSearchPage } from '../../model/product.model';

import {
    AIMO_CLOSE_QUICK_SEARCH,
    AIMO_QUICK_SEARCH_PRODUCTS,
    AIMO_SEARCH_PRODUCTS,
    AimoSearchProducts,
    AimoSearchProductsSuccess,
} from './search/aimo-product-search.action';

@Injectable()
export class AimoProductSearchEffects {
    constructor(
        protected actions$: Actions,
        protected productSearchConnector: ProductSearchConnector,
        protected winRef: WindowRef,
        protected router: Router,
    ) {}

    private contextChangeQuickSearch$ = this.actions$.pipe(
        ofType(
            SiteContextActions.CURRENCY_CHANGE,
            SiteContextActions.LANGUAGE_CHANGE,
            AuthActions.LOGOUT,
            AIMO_CLOSE_QUICK_SEARCH,
        ),
    );

    private contextChangeSearch$ = this.actions$.pipe(
        ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE, AuthActions.LOGOUT),
    );

    quickSearchProducts$: Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail> = createEffect(() =>
        this.actions$.pipe(
            ofType(AIMO_QUICK_SEARCH_PRODUCTS),
            switchMap((action: AimoSearchProducts) => this.doSearch(action)),
            withdrawOn(this.contextChangeQuickSearch$),
        ),
    );

    searchProducts$: Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail> = createEffect(() =>
        this.actions$.pipe(
            ofType(AIMO_SEARCH_PRODUCTS),
            switchMap((action: AimoSearchProducts) => this.doSearch(action)),
            withdrawOn(this.contextChangeSearch$),
        ),
    );

    private doSearch(
        action: AimoSearchProducts,
    ): Observable<AimoSearchProductsSuccess | ProductActions.SearchProductsFail> {
        return this.productSearchConnector.search(action.payload.queryText, action.payload.searchConfig).pipe(
            withdrawOn(this.actions$.pipe(ofType(AIMO_SEARCH_PRODUCTS))),
            map((data) => {
                if (data.keywordRedirectUrl !== undefined && action.payload.searchConfig?.followRedirects) {
                    this.router
                        .navigate([], {
                            queryParams: { followRedirects: false },
                            queryParamsHandling: 'merge',
                        })
                        .then(() => {
                            if (data.keywordRedirectUrl.startsWith('http')) {
                                this.winRef.document.location.href = data.keywordRedirectUrl;
                            } else {
                                this.router.navigate([data.keywordRedirectUrl]);
                            }
                        });
                }
                return new AimoSearchProductsSuccess(
                    {
                        ...data,
                    } as AimoProductSearchPage,
                    action.auxiliary,
                    action.origin,
                );
            }),
            catchError((error) =>
                of(new ProductActions.SearchProductsFail(normalizeHttpError(error), action.auxiliary)),
            ),
        );
    }
}
