<div class="modal-dialog modal-dialog-centered">
    <ng-container *ngIf="editable; else notEditable">
        <div class="modal-content modal-lg">
            <div class="modal-header">
                <h2 class="mb-0">{{ 'aimo.claims.new.title' | cxTranslate }}</h2>
                <div class="modal-action col-auto">
                    <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                        <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body" [formGroup]="form">
                <p class="text-md">{{ 'aimo.claims.description' | cxTranslate }}</p>
                <div class="row mb-4">
                    <div class="col-12 col-md-4">
                        <label [for]="'date'" class="input-label-value required text-bold mb-2">
                            {{ 'aimo.cart.totals.requestedDeliveryDate' | cxTranslate }}
                        </label>
                        <input
                            [readOnly]="true"
                            [id]="'date'"
                            [class]="'form-control aimo-input'"
                            [value]="date | cxDate : ('aimo.dateFormat' | cxTranslate)" />
                    </div>
                </div>

                <div class="claims-content" [ngClass]="{ 'min-height': selectableProducts?.length > 4 }">
                    <ng-container *ngFor="let item of getItemsControl(); let idx = index" formArrayName="items">
                        <div class="claims-item" [formGroupName]="idx" *ngIf="item">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-12 col-sm-8 col-md-8">
                                            <div
                                                class="mb-4 mb-sm-2 input-label"
                                                [class.is-invalid]="isInvalidItem(idx, 'product')">
                                                <label
                                                    [for]="'product'"
                                                    class="input-label-value required text-bold mb-2"
                                                    [ngClass]="{ 'd-sm-none': idx !== 0 }">
                                                    {{ 'aimo.claims.products.label' | cxTranslate }}
                                                </label>
                                                <ng-select
                                                    [id]="'product'"
                                                    [bindLabel]="'name'"
                                                    [bindValue]="'code'"
                                                    [clearable]="false"
                                                    [closeOnSelect]="true"
                                                    [formControlName]="'product'"
                                                    [items]="selectableProducts"
                                                    [multiple]="false"
                                                    [placeholder]="'aimo.claims.products.placeholder' | cxTranslate"
                                                    [searchable]="true"
                                                    (change)="refresh(idx)">
                                                </ng-select>
                                                <div
                                                    class="invalid-feedback d-block"
                                                    *ngIf="isInvalidItem(idx, 'product')">
                                                    <span *ngIf="!getItemsControl()[idx].get('product').value">{{
                                                        'formErrors.required' | cxTranslate
                                                    }}</span>
                                                    <span *ngIf="getItemsControl()[idx].get('product').value">{{
                                                        'aimo.claims.products.duplicate' | cxTranslate
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-sm-4 col-md-4">
                                            <div
                                                class="mb-4 mb-sm-2 input-label"
                                                [class.is-invalid]="isInvalidItem(idx, 'quantity')">
                                                <label
                                                    [for]="'quantity'"
                                                    class="input-label-value required text-bold mb-2"
                                                    [ngClass]="{ 'd-sm-none': idx !== 0 }">
                                                    {{ 'aimo.claims.quantity' | cxTranslate }}
                                                </label>
                                                <aimo-item-counter
                                                    *ngIf="getProductKeyValue(idx) as productKeyValue"
                                                    class="cart-item-counter cart-item-counter-md noPrint"
                                                    [min]="0"
                                                    [doNotUpdateCart]="true"
                                                    [showQuantity]="true"
                                                    [showSpinner]="false"
                                                    [id]="'quantity'"
                                                    [max]="productKeyValue.quantity"
                                                    [step]="productKeyValue.product.allowedLotSize"
                                                    [control]="getItemsControl()[idx].get('quantity')"
                                                    [parentForm]="form"
                                                    [parentFormGroupName]="idx + ''"
                                                    [parentFormArrayName]="'items'"
                                                    [unitName]="productKeyValue.product.unit.name"
                                                    [cartCounter]="true"
                                                    [autoAddToCart]="true"
                                                    [product]="productKeyValue.product">
                                                </aimo-item-counter>
                                                <div
                                                    class="invalid-feedback d-block"
                                                    *ngIf="isInvalidItem(idx, 'quantity')">
                                                    <span *ngIf="getItemsControl()[idx].get('quantity').value">{{
                                                        'aimo.claims.quantityError' | cxTranslate
                                                    }}</span>
                                                    <span *ngIf="!getItemsControl()[idx].get('quantity').value">{{
                                                        'formErrors.required' | cxTranslate
                                                    }}</span>
                                                </div>
                                                <input
                                                    *ngIf="getProductKeyValue(idx) as productKeyValue"
                                                    type="hidden"
                                                    [formControlName]="'orderNumber'" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-12 col-sm-4 col-md-4">
                                            <div
                                                class="mb-4 mb-sm-2 input-label"
                                                [class.is-invalid]="isInvalidItem(idx, 'dateOrBatch')">
                                                <label
                                                    [for]="'dateOrBatch'"
                                                    class="input-label-value required text-bold mb-2"
                                                    [ngClass]="{ 'd-sm-none': idx !== 0 }">
                                                    {{ 'aimo.claims.dateOrBatch.label' | cxTranslate }}
                                                </label>
                                                <input
                                                    [required]="true"
                                                    [formControlName]="'dateOrBatch'"
                                                    [class]="'form-control aimo-input'"
                                                    idName="dateOrBatch" />
                                                <div
                                                    class="invalid-feedback d-block"
                                                    *ngIf="isInvalidItem(idx, 'dateOrBatch')">
                                                    <span *ngIf="!getItemsControl()[idx].get('dateOrBatch').value">{{
                                                        'formErrors.required' | cxTranslate
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 col-md-6" [ngClass]="idx !== 0 ? 'col-10' : 'col-12'">
                                            <div
                                                class="mb-4 mb-sm-2 input-label"
                                                [class.is-invalid]="isInvalidItem(idx, 'reason')">
                                                <label
                                                    [for]="'reason'"
                                                    class="input-label-value required text-bold mb-2"
                                                    [ngClass]="{ 'd-sm-none': idx !== 0 }">
                                                    {{ 'aimo.claims.reason.label' | cxTranslate }}
                                                </label>
                                                <ng-select
                                                    [id]="'reason'"
                                                    [clearable]="false"
                                                    [closeOnSelect]="true"
                                                    [formControlName]="'reason'"
                                                    [items]="reasons"
                                                    [multiple]="false"
                                                    [placeholder]="'aimo.claims.reason.placeholder' | cxTranslate"
                                                    [searchable]="true">
                                                    <ng-template ng-option-tmp let-item="item">
                                                        {{ 'aimo.select.reason.' + item | cxTranslate }}
                                                    </ng-template>
                                                    <ng-template ng-label-tmp let-item="item">
                                                        {{ 'aimo.select.reason.' + item | cxTranslate }}
                                                    </ng-template>
                                                </ng-select>
                                                <div
                                                    class="invalid-feedback d-block"
                                                    *ngIf="isInvalidItem(idx, 'reason')">
                                                    <span *ngIf="!getItemsControl()[idx].get('reason').value">{{
                                                        'formErrors.required' | cxTranslate
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="col-2 col-sm-2 col-md-2 text-center"
                                            [ngClass]="{ 'd-none d-md-block': idx === 0 }">
                                            <div class="mb-4 mb-sm-2 mt-4" [ngClass]="{ 'mt-sm-0': idx !== 0 }">
                                                <a
                                                    class="btn btn-icon btn-sm show-all mt-2"
                                                    (click)="addItem()"
                                                    *ngIf="idx === 0 && selectableProducts?.length > 0">
                                                    <i
                                                        class="aimo-icon icon-sm icon-inline icon-petrol-blue icon-plus-bold"></i>
                                                </a>
                                                <a
                                                    class="btn btn-icon btn-sm show-all mt-2"
                                                    (click)="removeItem(idx)"
                                                    *ngIf="idx !== 0">
                                                    <i
                                                        class="aimo-icon icon-sm icon-inline icon-petrol-blue icon-minus-bold"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="d-block d-md-none mb-2">
                        <a
                            class="btn btn-secondary btn-block btn-sm show-all mt-2 mb-4"
                            (click)="addItem()"
                            *ngIf="selectableProducts?.length > 0">
                            <i class="aimo-icon icon-sm icon-inline icon-petrol-blue icon-plus-circle mr-2"></i>
                            {{ 'aimo.claims.addProduct' | cxTranslate }}
                        </a>
                    </div>

                    <div class="row mt-0 mt-sm-2" formGroupName="header">
                        <div class="col-12 col-md-6">
                            <label [for]="'remarks'" class="input-label-value text-bold mb-2">
                                {{ 'aimo.claims.remarks.label' | cxTranslate }}
                            </label>
                            <textarea
                                [class]="'form-control input-sm'"
                                [cols]="45"
                                [rows]="3"
                                [formControlName]="'remarks'"
                                [placeholder]="'aimo.claims.remarks.placeholder' | cxTranslate"
                                [id]="'remarks'">
                            </textarea>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="mt-4">
                                <fieldset id="contactType">
                                    <ng-container *ngFor="let ct of contactTypes; index as idx">
                                        <label [for]="'contactType_' + idx" class="field-content mb-2">
                                            <input
                                                [id]="'contactType_' + idx"
                                                type="radio"
                                                name="contactType"
                                                [value]="ct"
                                                [formControlName]="'contactType'" />
                                            <span>{{ 'aimo.claims.contactType.' + ct | cxTranslate }}</span>
                                        </label>
                                    </ng-container>
                                </fieldset>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="messages" *ngIf="errorMessage">
                    <div class="message message-ERROR">{{ 'aimo.claims.sent.error' | cxTranslate }}</div>
                </div>

                <div class="modal-actions flex-start">
                    <button class="btn btn-primary btn-sm btn-inline mr-2" (click)="submit()">
                        {{ 'common.send' | cxTranslate }}
                    </button>
                    <button class="btn btn-secondary btn-sm btn-inline" (click)="closeDialog()">
                        {{ 'common.cancel' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #notEditable>
    <div class="modal-content modal-md not-editable">
        <div class="modal-header">
            <ng-container *ngIf="existingClaims$ | async as claims">
                <h2 class="mb-0">
                    {{
                        'aimo.claims.sent.title'
                            | cxTranslate
                                : {
                                      date:
                                          convertDate(claims[0].requestedDeliveryDate)
                                          | cxDate : ('aimo.dateFormat' | cxTranslate)
                                  }
                    }}
                </h2>
            </ng-container>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" [formGroup]="form">
            <ng-container *ngIf="existingClaims$ | async as claims">
                <ng-container *ngFor="let claim of claims; index as idx">
                    <h4 class="mb-4" [ngClass]="{ 'mt-4': idx !== 0 }">
                        {{ 'aimo.claims.subtitle' | cxTranslate : { id: 1 + idx } }}
                    </h4>
                    <div class="claims-table">
                        <div class="claims-table-header d-none d-md-block">
                            <div class="row align-items-center">
                                <div class="col-12 col-sm-4">
                                    {{ 'aimo.claims.products.label' | cxTranslate }}
                                </div>
                                <div class="col-12 col-sm-2">
                                    {{ 'aimo.claims.quantity' | cxTranslate }}
                                </div>
                                <div class="col-12 col-sm-3">
                                    {{ 'aimo.claims.dateOrBatch.label' | cxTranslate }}
                                </div>
                                <div class="col-12 col-sm-3">
                                    {{ 'aimo.claims.reason.label' | cxTranslate }}
                                </div>
                            </div>
                        </div>

                        <ng-container *ngFor="let entry of claim.entries">
                            <div class="claims-table-item">
                                <div class="row align-items-center">
                                    <div class="col-12 col-sm-4 mb-3 mb-md-0">
                                        <span class="d-none d-md-inline-block">{{ entry.product.name }}</span>
                                        <b class="d-inline-block d-md-none">{{ entry.product.name }}</b>
                                    </div>
                                    <div class="col-12 col-sm-2 mb-3 mb-md-0">
                                        <div class="row">
                                            <div class="col-6 d-md-none">
                                                <b>{{ 'aimo.claims.quantity' | cxTranslate }}</b>
                                            </div>
                                            <div class="col-6 col-md-12 text-right text-md-left">
                                                {{ entry.quantity }} {{ entry.product.unit.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-3 mb-3 mb-md-0">
                                        <div class="row">
                                            <div class="col-6 d-md-none">
                                                <b>{{ 'aimo.claims.dateOrBatch.label' | cxTranslate }}</b>
                                            </div>
                                            <div class="col-6 col-md-12 text-right text-md-left">
                                                {{ entry.dateOrBatch }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-3 mb-3 mb-md-0">
                                        <div class="row">
                                            <div class="col-6 d-md-none">
                                                <b>{{ 'aimo.claims.reason.label' | cxTranslate }}</b>
                                            </div>
                                            <div class="col-6 col-md-12 text-right text-md-left">
                                                {{ 'aimo.select.reason.' + entry.reason | cxTranslate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row mt-4">
                        <div class="col-12 col-md-5 mb-4 mb-md-0">
                            <div class="input-label-value text-bold mb-2">
                                {{ 'aimo.claims.remarks.label' | cxTranslate }}
                            </div>
                            <div class="text-break-word">{{ claim.remark }}</div>
                        </div>
                        <div class="col-12 col-md-4 mb-4 mb-md-0">
                            <div class="input-label-value text-bold mb-2">
                                {{ 'aimo.claims.contactType.label' | cxTranslate }}
                            </div>
                            {{ 'aimo.claims.contactType.' + claim.contactType | cxTranslate }}
                        </div>
                        <div class="col-12 col-md-3 mb-4 mb-md-0">
                            <div class="input-label-value text-bold mb-2">
                                {{ 'aimo.claims.created.label' | cxTranslate }}
                            </div>
                            {{ convertDate(claim.created) | cxDate : ('aimo.dateFormat' | cxTranslate) }}
                            {{ convertDate(claim.created) | cxDate : ('aimo.timeFormat' | cxTranslate) }}
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>

        <div class="modal-actions flex-start modal-actions-sm-down-block">
            <button class="btn btn-primary btn-sm btn-inline btn-block-sm-down mb-3 mb-md-0" (click)="closeDialog()">
                {{ 'common.close' | cxTranslate }}
            </button>

            <button class="btn btn-secondary btn-sm btn-inline btn-block-sm-down ml-md-2" (click)="createClaim()">
                {{ 'aimo.claims.new.anotherone' | cxTranslate }}
            </button>
        </div>
    </div>
</ng-template>
