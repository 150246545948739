import { Component } from '@angular/core';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';

@Component({
    selector: 'aimo-cx-top-header-component',
    templateUrl: './aimo-top-header.component.html',
})
export class AimoTopHeaderComponent {
    constructor(protected aimoRoutingService: AimoRoutingService) {}

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    gotoPikatukku(): void {
        this.aimoRoutingService.gotoPikatukku();
    }

    gotoToimitustukku(): void {
        this.aimoRoutingService.gotoToimitustukku();
    }
}
