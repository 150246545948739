/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CartCouponModule, CartSharedModule, CartValidationWarningsModule } from '@spartacus/cart/base/components';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { PromotionsModule } from '@spartacus/storefront';

import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoInputSelectModule } from '../../shared/input-select/aimo-input-select.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from '../cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoSearchBoxModule } from '../navigation/search-box/aimo-search-box.module';

import { AimoCartDetailsComponent } from './aimo-cart-details.component';
import { cartPageLayout } from './aimo-cart-layout.config';
import { AimoExcelImportComponent } from './excel-import/aimo-excel-import.component';
import { AimoRouteSchedulesComponent } from './route-schedules/aimo-route-schedules.component';
import { switchDateHistoryLayout } from './switch-date-modal/aimo-switch-cart-date-layout.config';
import { AimoOrderHistorySwitchDateComponent } from './switch-date-modal/aimo-switch-cart-date.component';

@NgModule({
    imports: [
        CartSharedModule,
        CommonModule,
        CartCouponModule,
        RouterModule,
        UrlModule,
        PromotionsModule,
        FeaturesConfigModule,
        I18nModule,
        CartValidationWarningsModule,
        AimoCartSharedModule,
        AimoSearchBoxModule,
        AimoInputModule,
        ReactiveFormsModule,
        AimoDeliveryDateModule,
        AimoInputSelectModule,
    ],
    providers: [
        provideDefaultConfig(cartPageLayout),
        provideDefaultConfig(switchDateHistoryLayout),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CartComponent: {
                    component: AimoCartDetailsComponent,
                },
            },
        }),
    ],
    declarations: [
        AimoCartDetailsComponent,
        AimoRouteSchedulesComponent,
        AimoExcelImportComponent,
        AimoOrderHistorySwitchDateComponent,
    ],
    exports: [
        AimoCartDetailsComponent,
        AimoRouteSchedulesComponent,
        AimoExcelImportComponent,
        AimoOrderHistorySwitchDateComponent,
    ],
})
export class AimoCartDetailsModule {}
