<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<ng-container *ngIf="form !== null">
    <h1 class="text-primary page-title" tabindex="0" [attr.aria-label]="'updateProfileForm.title' | cxTranslate">
        {{ 'updateProfileForm.title' | cxTranslate }}
    </h1>

    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="row">
        <ng-container *ngIf="!isPikatukku()">
            <div class="col-12 text-bold label">
                {{ 'aimo.cart.totals.deliveryCost' | cxTranslate }}
            </div>

            <div class="col-12 delivery-cost">
                {{ user.deliveryCost ? user.deliveryCost.formattedValue : '0,00 €' }}
            </div>
        </ng-container>

        <div class="col-12 mb-4">
            <aimo-cx-input
                [autofocus]="true"
                [label]="'updateProfileForm.name.label'"
                [labelClass]="'text-bold'"
                [nameForm]="'name'"
                [parentForm]="form"
                [typeInput]="'text'"
                idName="name"></aimo-cx-input>
            <cx-form-errors [control]="form.get('name')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
        </div>

        <div class="col-12 col-md-6 mb-4">
            <aimo-cx-input
                [label]="'updateProfileForm.email.label'"
                [labelClass]="'text-bold'"
                [nameForm]="'email'"
                [parentForm]="form"
                [typeInput]="'email'"
                idName="email"></aimo-cx-input>
            <cx-form-errors [control]="form.get('email')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
        </div>

        <div class="col-12 col-md-6 mb-4">
            <aimo-cx-input
                [label]="'updateProfileForm.telephone.label'"
                [labelClass]="'text-bold'"
                [nameForm]="'phone'"
                [parentForm]="form"
                [typeInput]="'tel'"
                idName="telephone"></aimo-cx-input>
            <cx-form-errors [control]="form.get('phone')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
        </div>

        <div class="col-12 col-md-6 mb-4">
            <aimo-cx-input
                [label]="'updateProfileForm.userName.label'"
                [labelClass]="'text-bold'"
                [readOnly]="true"
                [nameForm]="'uid'"
                [parentForm]="form"
                [typeInput]="'text'"
                idName="userName"></aimo-cx-input>
            <cx-form-errors [control]="form.get('uid')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
        </div>

        <div class="col-12 col-md-6 mb-4">
            <aimo-cx-input
                [label]="'updateProfileForm.password.label'"
                [labelClass]="'text-bold'"
                [nameForm]="'password'"
                [parentForm]="form"
                [typeInput]="'password'"
                [disabledIcon]="'icon-pencil-simple icon-petrol-blue'"
                [isLeftIcon]="false"
                [disabled]="true"
                (disabledIconAction)="openUpdatePassword()"
                idName="password"></aimo-cx-input>
            <cx-form-errors [control]="form.get('password')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
        </div>

        <div class="col-12">
            <div class="form-check">
                <label for="orderConfirmationsInEmail">
                    <input type="checkbox" [formControlName]="'orderConfirmationsInEmail'" />
                    {{ 'updateProfileForm.orderConfirmationsInEmail' | cxTranslate }}
                </label>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <button class="btn btn-primary" [disabled]="form.disabled || !checkForChanges()">
                {{ 'updateProfileForm.saveChanges' | cxTranslate }}
            </button>
        </div>

        <div class="col-12 col-md-6 d-flex align-items-center justify-content-md-end mt-5 mt-md-0">
            <i class="aimo-icon icon-inline icon-prohibit icon-red mr-3"></i>
            <a class="text-red text-bold cursor-pointer" (click)="closeUserAccount()">
                {{ 'updateProfileForm.closeUser' | cxTranslate }}
            </a>
        </div>
    </form>
</ng-container>
