<section
    class="inner"
    *ngIf="(facetList$ | async)?.facets as facets"
    [attr.aria-label]="'productFacetNavigation.filterBy.facet' | cxTranslate"
    [cxFocus]="isDialog ? dialogFocusConfig : {}"
    [tabindex]="-1"
    (click)="block($event)">
    <div class="bg-petrol-light d-block d-md-none p-3">
        <i class="aimo-icon icon-petrol-blue icon-inline icon-arrow-left mr-3 icon-cursor" (click)="close()"> </i>
        <h4 class="text-primary mb-0 d-inline">{{ 'productList.filter.button' | cxTranslate }}</h4>
    </div>
    <!--
        Here we'd like to introduce configurable facet components,
        either by using specific configuration or generic sproutlets
    -->
    <ng-container *ngFor="let facet of facets">
        <aimo-facet
            [mobileUI]="mobileUI"
            (selectedQueryTerms)="setFacetTermsForMobile($event)"
            [closeFacet]="openFacet.asObservable()"
            (openFacet)="triggerOpenFacet($event)"
            *ngIf="facet.code !== 'category' && facet.values?.length > 0"
            #facetRef
            [facet]="facet"
            class="collapsed"
            role="group"
            attr.aria-label="{{
                'productFacetNavigation.ariaLabelItemsAvailable'
                    | cxTranslate
                        : {
                              name: facet.name,
                              count: facet?.values?.length
                          }
            }}"></aimo-facet>
    </ng-container>
</section>
