<div *ngFor="let wrapper of messages" class="messages">
    <div
        class="message message-sm message-{{ wrapper.message.severity.toUpperCase() }} {{ messageClass }}"
        *ngIf="!isMessageHidden()">
        <div class="text-break-word">
            <span [innerHTML]="wrapper.message.message || (wrapper.message.messageKey | cxTranslate)"></span>
            <ng-container *ngIf="wrapper.message.actionParams?.length > 0">
                <ng-container *ngIf="wrapper.message.action === 'moveToDate'">
                    &nbsp;<button
                        class="btn-link text-underline text-default"
                        (click)="changeDate(wrapper.message.actionParams[1])">
                        {{ wrapper.message.actionParams[0] }}
                    </button>
                </ng-container>

                <ng-container *ngIf="wrapper.message.action === 'changeQuantity'">
                    &nbsp;<button
                        class="btn-link text-underline text-default"
                        (click)="updateQuantity(wrapper.message.actionParams[0])">
                        {{
                            'order.warning.fullPackageSizeAction'
                                | cxTranslate
                                    : {
                                          quantity: wrapper.message.actionParams[0],
                                          unit: entry.unit.name
                                      }
                        }}
                    </button>
                </ng-container>

                <ng-container *ngIf="wrapper.message.action === 'alternatives'">
                    <ng-container *ngFor="let param of wrapper.split; let i = index">
                        <button
                            class="btn-link btn-link-wrap text-bold ml-1"
                            (click)="openProductModal(param.params[0])">
                            {{ param.params[1] }}
                        </button>
                        <button class="btn-link ml-1" (click)="replaceProduct(param.params[0])">
                            <span class="text-underline text-default">{{ param.params[2] }}</span>
                        </button>
                        <span class="ml-1" *ngIf="i !== wrapper.message.actionParams.length / 3 - 1">/</span>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="wrapper.message.action === 'remove'">
                    &nbsp;<button class="btn-link text-underline text-default" (click)="updateQuantity('0')">
                        {{ 'aimo.cart.removeProduct' | cxTranslate }}
                    </button>
                </ng-container>

                <ng-container *ngIf="wrapper.message.action === 'orderTemplateAdded'">
                    &nbsp;<a class="link" (click)="openProductModal(wrapper.message.actionParams[1])">{{
                        wrapper.message.actionParams[0]
                    }}</a>
                    &nbsp;<span>({{ wrapper.message.actionParams[2] }} {{ wrapper.message.actionParams[3] }})</span>
                    &nbsp;<span>{{ 'aimo.ordertemplate.addedToTemplateSuccessfully2' | cxTranslate }}</span>
                    &nbsp;<button
                        class="btn-link"
                        [routerLink]="'/my-account/order-template/' + wrapper.message.actionParams[5]">
                        {{ wrapper.message.actionParams[4] }}
                    </button>
                </ng-container>

                <span *ngIf="wrapper.message.similarProducts">
                    <span>&nbsp;{{ 'common.or' | cxTranslate }}&nbsp;</span>
                    <button
                        class="btn-link"
                        (click)="openProductModal(entry.product.code, 'similar-products-container')">
                        <span class="text-underline">{{
                            'aimo.product.lookSimilarProducts' | cxTranslate | lowercase
                        }}</span>
                    </button>
                </span>
            </ng-container>

            <span
                *ngIf="
                    wrapper.message.similarProducts &&
                    (!wrapper.message.actionParams || wrapper.message.actionParams?.length === 0)
                ">
                &nbsp;
                <button class="btn-link" (click)="openProductModal(entry.product.code, 'similar-products-container')">
                    <span class="text-underline">{{ 'aimo.product.lookSimilarProducts' | cxTranslate }}</span>
                </button>
            </span>
        </div>
        <i
            *ngIf="isWarningMessage(wrapper.message.severity) && showCloseIcon"
            (click)="hideMessage()"
            class="aimo-icon icon-x icon-inline ml-auto icon-cursor icon-sm"></i>
    </div>
</div>
