<div class="no-gutters login-content-lg">
    <div class="consent-body" *ngIf="user$ | async as user">

        <h1>{{ user.consentTitle }}</h1>
        <div class="consent-description" *ngIf="user.consentText">
            <div [innerHTML]="user.consentText"></div>
        </div>
        <br />
        <button class="btn btn-primary btn-sm" (click)="approve()">
            {{ 'aimo.consent.approve' | cxTranslate }}
        </button>

        <button class="btn btn-secondary btn-sm ml-2" (click)="deny()">
            {{ 'aimo.consent.deny' | cxTranslate }}
        </button>
    </div>
</div>
