import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ContextServiceMap, I18nModule, provideDefaultConfig, SiteContextModule } from '@spartacus/core';
import {
    CmsComponentData,
    IconModule,
    LanguageCurrencyComponent,
    SiteContextComponentService,
} from '@spartacus/storefront';

import { AimoSiteContextSelectorComponent } from './aimo-site-context-selector.component';

@NgModule({
    imports: [CommonModule, RouterModule, SiteContextModule, I18nModule, IconModule, NgbDropdownModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSSiteContextComponent: {
                    component: AimoSiteContextSelectorComponent,
                    providers: [
                        {
                            provide: SiteContextComponentService,
                            useClass: SiteContextComponentService,
                            deps: [CmsComponentData, ContextServiceMap, Injector],
                        },
                    ],
                },
                LanguageCurrencyComponent: {
                    component: LanguageCurrencyComponent,
                },
            },
        }),
        SiteContextComponentService,
    ],
    declarations: [AimoSiteContextSelectorComponent],
    exports: [AimoSiteContextSelectorComponent],
})
export class AimoSiteContextSelectorModule {}
