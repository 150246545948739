import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
    AuthConfigService,
    GlobalMessageService,
    GlobalMessageType,
    HttpErrorModel,
    OAuthFlow,
    RoutingService,
} from '@spartacus/core';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AimoCustomFormValidators } from '../../../shared/validators/aimo-custom-form-validators';

@Injectable()
export class AimoForgotPasswordComponentService extends ForgotPasswordComponentService implements OnDestroy {
    constructor(
        protected userPasswordService: UserPasswordFacade,
        protected routingService: RoutingService,
        protected authConfigService: AuthConfigService,
        protected globalMessage: GlobalMessageService,
    ) {
        super(userPasswordService, routingService, authConfigService, globalMessage);
    }

    subscription: Subscription = new Subscription();

    protected busy$ = new BehaviorSubject(false);
    private isSuccess: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isSuccess$ = this.isSuccess.asObservable();

    isUpdating$ = this.busy$.pipe(tap((state) => (state === true ? this.form.disable() : this.form.enable())));

    form: UntypedFormGroup = new UntypedFormGroup({
        userEmail: new UntypedFormControl(null, [Validators.required, AimoCustomFormValidators.emailValidator]),
    });

    /**
     * Sends an email to the user to reset the password.
     *
     * When the `ResourceOwnerPasswordFlow` is used, the user is routed
     * to the login page.
     */
    requestEmail(): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.busy$.next(true);
        this.subscription.add(
            this.userPasswordService.requestForgotPasswordEmail(this.form.value.userEmail).subscribe({
                next: () => this.onSuccess(),
                error: (e) => this.onError(e),
            }),
        );
    }

    clear(): void {
        this.busy$.next(false);
        this.form.reset();
        this.isSuccess.next(false);
    }

    protected onSuccess(): void {
        this.busy$.next(false);
        this.form.reset();
        this.isSuccess.next(true);
    }

    protected onError(_error: HttpErrorModel): void {
        this.globalMessage.add(_error.details[0].message, GlobalMessageType.MSG_TYPE_ERROR, 8000);
        this.busy$.next(false);
    }

    /**
     * Redirects the user back to the login page.
     *
     * This only happens in case of the `ResourceOwnerPasswordFlow` OAuth flow.
     */
    protected redirect(): void {
        if (this.authConfigService.getOAuthFlow() === OAuthFlow.ResourceOwnerPasswordFlow) {
            this.routingService.go({ cxRoute: 'login' });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
