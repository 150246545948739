import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoDayGroupedOrderHistory } from '../../../model/order.model';

import { AimoOrderHistoryReorderComponent } from './aimo-order-history-reorder.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        REORDER_HISTORY = 'REORDER_HISTORY',
    }
}

export const reorderHistoryLayout: LayoutConfig = {
    launch: {
        REORDER_HISTORY: {
            inlineRoot: true,
            component: AimoOrderHistoryReorderComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoReorderHistoryDialogData {
    day: AimoDayGroupedOrderHistory;
}
