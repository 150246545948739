import {Injectable} from '@angular/core';
import {BasePageMetaResolver, RoutingService, SearchPageMetaResolver, TranslationService} from '@spartacus/core';
import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap, switchMap} from 'rxjs/operators';

import {AimoProductSearchService} from './aimo-product-search.service';
import {AimoRoutingService} from "../../../cms-structure/routing/aimo-routing.service";

@Injectable({
    providedIn: 'root',
})
export class AimoSearchPageMetaResolver extends SearchPageMetaResolver {
    constructor(
        protected routingService: RoutingService,
        protected productSearchService: AimoProductSearchService,
        protected translation: TranslationService,
        protected basePageMetaResolver: BasePageMetaResolver,
        protected aimoRoutingService: AimoRoutingService,
    ) {
        super(routingService, productSearchService, translation, basePageMetaResolver);
        this.pageTemplate = 'SearchResultsGridPageTemplate';
    }

    protected query$: Observable<string> = this.routingService
        .getRouterState()
        .pipe(
            map((state) =>
                state.state.params['query']
                    ? state.state.params['query']
                    : state.state.queryParams.query?.replace(/\:.*/, ''),
            ),
        );

    resolveTitle(): Observable<string> {
        const sources = [this.total$, this.query$];
        return combineLatest(sources).pipe(
            switchMap(([count, query]) =>
                this.translation.translate('pageMetaResolver.search.default_title.' + (this.isPikatukku() ? 'pt' : 'tt')).pipe(
                    mergeMap((defaultQuery) =>
                        this.translation.translate('pageMetaResolver.search.title.' + (this.isPikatukku() ? 'pt' : 'tt'), {
                            count,
                            query: query || defaultQuery,
                        }),
                    ),
                ),
            ),
        );
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
