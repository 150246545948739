import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';

import { AimoHamburgerMenuComponent } from './aimo-hamburger-menu.component';
@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                HamburgerMenuComponent: {
                    component: AimoHamburgerMenuComponent,
                },
            },
        }),
    ],
    declarations: [AimoHamburgerMenuComponent],
    exports: [AimoHamburgerMenuComponent],
    entryComponents: [AimoHamburgerMenuComponent],
})
export class AimoHamburgerMenuModule {}
