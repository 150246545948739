import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LanguageService } from '@spartacus/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { AimoCartEntryDialogData } from '../../cart-details/aimo-cart-layout.config';

@Component({
    selector: 'aimo-cart-item',
    templateUrl: './aimo-cart-item.component.html',
})
export class AimoCartItemComponent implements OnInit {
    @Input() cart: AimoCart;
    @Input() entry: AimoOrderEntry;
    @Input() readonly = false;
    @Input() gtmProductAttributes?: AimoGTMProductAttributes;
    @Input() alreadyOrdered = false;

    numberOfMessages: number;

    addToCartForm: UntypedFormGroup;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected currentProductService: CurrentProductService,
        protected launchDialogService: LaunchDialogService,
        protected languageService: LanguageService,
    ) {}

    ngOnInit(): void {
        this.addToCartForm = new UntypedFormGroup({
            quantity: new UntypedFormControl(this.entry.quantity, { updateOn: 'blur' }),
        });
        this.numberOfMessages = this.getNumberOfMessages(this.entry);
    }

    deleteEntry(entry: AimoOrderEntry): void {
        this.activeCartService.removeAimoEntry(entry, null, this.gtmProductAttributes);
    }

    changeQuantity(): void {
        const quantity = this.addToCartForm.get('quantity')?.value;
        this.activeCartService.updateAimoEntry(
            {
                entryNumber: this.entry.entryNumber,
                quantity,
                product: { code: this.entry.product.code },
            },
            null,
            this.gtmProductAttributes,
        );
    }

    openProductModal(sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            this.entry.product.code,
            this.gtmProductAttributes,
            null,
            null,
            null,
            sourceId,
        );
    }

    openEntryMessagesModal(entry: AimoOrderEntry): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ITEM_MESSAGES, undefined, {
            entry,
        } as AimoCartEntryDialogData);
    }

    private getNumberOfMessages(entry: AimoOrderEntry): number {
        return [
            entry.messageToCustomer !== undefined && entry.messageToCustomer !== '',
            entry.messageToPickup !== undefined && entry.messageToPickup !== '',
        ].filter(Boolean).length;
    }
}
