import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AuthHttpHeaderService, provideDefaultConfig, UserOccModule, UserService } from '@spartacus/core';
import { OrderHistoryAdapter } from '@spartacus/order/core';
import { UserAccountCoreModule, UserAccountService } from '@spartacus/user/account/core';
import { UserAccountOccModule } from '@spartacus/user/account/occ';
import { UserProfileAdapter, UserProfileConnector } from '@spartacus/user/profile/core';

import { AimoAuthHttpHeaderService } from './aimo-auth-http-header.service';
import { aimoOccUserConfig } from './aimo-occ-user-config';
import { AimoOrderHistoryAdapter } from './aimo-occ-user-order.adapter';
import { AimoOccUserProfileAdapter } from './aimo-occ-user-profile.adapter';
import { AimoUserAccountService } from './aimo-user-account.service';
import { AimoUserPasswordFacade } from './aimo-user-password.facade';
import { AimoUserPasswordService } from './aimo-user-password.service';
import { AimoUserProfileConnector } from './aimo-user-profile.connector';
import { AimoUserEffects } from './aimo-user.effect';
import { AimoUserService } from './aimo-user.service';

@NgModule({
    imports: [CommonModule, UserAccountCoreModule, UserAccountOccModule, EffectsModule.forFeature([AimoUserEffects])],
    providers: [
        provideDefaultConfig(aimoOccUserConfig),
        {
            provide: AuthHttpHeaderService,
            useExisting: AimoAuthHttpHeaderService,
        },
        {
            provide: UserService,
            useClass: AimoUserService,
        },
        {
            provide: UserAccountService,
            useClass: AimoUserAccountService,
        },

        {
            provide: UserProfileAdapter,
            useClass: AimoOccUserProfileAdapter,
        },

        {
            provide: UserProfileConnector,
            useClass: AimoUserProfileConnector,
        },
        {
            provide: AimoUserPasswordFacade,
            useClass: AimoUserPasswordService,
        },
        {
            provide: OrderHistoryAdapter,
            useClass: AimoOrderHistoryAdapter,
        },
    ],
})
export class AimoUserOccModule extends UserOccModule {}
