import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LanguageService } from '@spartacus/core';

import { AimoMessage, AimoMessageSeverity, AimoOrderEntry } from '../../../../model/cart.model';
import { AimoNotificationType } from '../../../../model/misc.model';
import { AimoActiveCartService } from '../../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes } from '../../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../../service/product/aimo-current-product.service';
import { AimoMessageService } from '../../../../service/util/aimo-message-service';
import { DateUtils } from '../../../../shared/util/date-utils';

const WARNING_MESSAGE_ID = 'cart-warning-';

@Component({
    selector: 'aimo-cart-item-errors',
    templateUrl: './aimo-cart-item-errors.component.html',
})
export class AimoCartItemErrorsComponent implements OnInit {
    @Input() entry: AimoOrderEntry;
    @Input() messageClass: string;
    @Input() showCloseIcon: boolean = false;
    @Input() gtmProductAttributes: AimoGTMProductAttributes;
    @Input() cartCode: string;

    messages?: MessageWrapper[];

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected currentProductService: AimoCurrentProductService,
        protected languageService: LanguageService,
        protected cdr: ChangeDetectorRef,
        protected messageService: AimoMessageService,
    ) {}

    ngOnInit(): void {
        this.messages = this.entry.messages ? this.entry.messages.map((m) => this.createWrapper(m)) : [];
        if (this.entry.closingTimeWarningDate) {
            const intervalId = setInterval(() => {
                if (this.entry.closingTimeWarningDate?.getTime() <= new Date().getTime()) {
                    this.messages.push(
                        this.createWrapper({
                            messageKey: 'aimo.cart.closingTimeWarning',
                            severity: AimoMessageSeverity.WARNING,
                        } as AimoMessage),
                    );
                    this.cdr.detectChanges();
                    clearInterval(intervalId);
                } else {
                    this.messages = this.messages.filter(
                        (mes) => mes.message.messageKey !== 'aimo.cart.closingTimeWarning',
                    );
                }
            }, 1000);
        }
    }

    private createWrapper(message: AimoMessage): MessageWrapper {
        return {
            message,
            split: this.split(message.actionParams, 3),
        } as MessageWrapper;
    }

    changeDate(date: string): void {
        this.activeCartService.updateAimoEntry(
            {
                ...this.entry,
                product: { code: this.entry.product.code },
                requestedDeliveryDate: DateUtils.convertDateToOccString(this.languageService, new Date(parseInt(date))),
            },
            this.cartCode,
            this.gtmProductAttributes,
        );
    }

    updateQuantity(quantity: string): void {
        this.activeCartService.updateAimoEntry(
            {
                entryNumber: this.entry.entryNumber,
                product: { code: this.entry.product.code },
                quantity: parseFloat(quantity),
            },
            this.cartCode,
            this.gtmProductAttributes,
        );
    }

    isWarningMessage(message: string): boolean {
        return message === AimoNotificationType.WARNING;
    }

    openProductModal(productCode: string, scrollToId?: string): void {
        this.currentProductService.openProductModal(productCode, this.gtmProductAttributes, scrollToId);
    }

    replaceProduct(code: string): void {
        this.activeCartService.updateAimoEntry(
            { ...this.entry, quantity: 1, product: { ...this.entry.product, code: code } },
            this.cartCode,
            this.gtmProductAttributes,
        );
    }

    private split(actionParams: string[], groupSize: number): SplitParams[] {
        const ret: SplitParams[] = [];
        for (let i = 0; i < actionParams?.length; ++i) {
            const splitParam: string[] = [];
            for (let j = 0; j < groupSize; ++j) {
                splitParam.push(actionParams[i + j]);
            }
            i += groupSize - 1;
            ret.push({ params: splitParam } as SplitParams);
        }
        return ret;
    }

    hideMessage(): void {
        this.messageService.hideMessage(this.getMessageId());
    }

    isMessageHidden(): boolean {
        return this.messageService.isMessageHidden(this.getMessageId());
    }

    private getMessageId(): string {
        return WARNING_MESSAGE_ID + '_' + this.entry.pk + '_' + this.entry.quantity;
    }
}

class SplitParams {
    constructor(public params: string[]) {}
}

class MessageWrapper {
    constructor(public message: AimoMessage, public split: SplitParams[]) {}
}
