import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, PageSlotModule } from '@spartacus/storefront';

import { AimoProductComparisonModule } from '../product/comparison/aimo-product-comparison.module';

import { AimoFooterComponent } from './aimo-footer.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        GenericLinkModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                AimoFooterComponent: {
                    component: AimoFooterComponent,
                },
            },
        }),
        PageSlotModule,
        AimoProductComparisonModule,
        I18nModule,
    ],
    declarations: [AimoFooterComponent],
    entryComponents: [AimoFooterComponent],
    exports: [AimoFooterComponent],
})
export class AimoFooterModule {}
