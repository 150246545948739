import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

import { AimoSpinnerModule } from '../spinner/aimo-spinner.module';

import { AimoPageSizeComponent } from './aimo-page-size.component';

@NgModule({
    declarations: [AimoPageSizeComponent],
    imports: [CommonModule, I18nModule, RouterModule, IconModule, NgSelectModule, FormsModule, AimoSpinnerModule],
    exports: [AimoPageSizeComponent],
})
export class AimoPageSizeModule {}
