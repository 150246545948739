import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoOrderEntry } from '../../../../model/cart.model';
import { AimoActiveCartService } from '../../../../service/cart/aimo-active-cart.service';
import { AimoCartEntryDialogData } from '../../../cart-details/aimo-cart-layout.config';

@Component({
    selector: 'aimo-item-messages',
    templateUrl: './aimo-item-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoItemMessageComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    entry: AimoOrderEntry;
    form: UntypedFormGroup;
    editable: boolean;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
    ) {}

    ngOnInit(): void {
        this.launchDialogService.data$
            .subscribe((dialogData: AimoCartEntryDialogData) => {
                this.entry = dialogData.entry;
                this.editable = this.entry.editable;
                this.form = new UntypedFormGroup({
                    messageToCustomer: new UntypedFormControl(this.entry.messageToCustomer),
                    messageToPickup: new UntypedFormControl(this.entry.messageToPickup),
                });
            })
            .unsubscribe();
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ITEM_MESSAGES);
        this.launchDialogService.closeDialog('closed');
    }

    updateEntry(): void {
        if (this.editable) {
            this.activeCartService.updateAimoEntry({
                entryNumber: this.entry.entryNumber,
                quantity: this.entry.quantity,
                product: { code: this.entry.product.code },
                messageToCustomer: this.form.controls['messageToCustomer'].value,
                messageToPickup: this.form.controls['messageToPickup'].value,
            });
            this.closeDialog();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
