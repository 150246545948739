import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivatedRouterStateSnapshot, PageType, ProductSearchPage, RoutingService } from '@spartacus/core';
import { FacetService, ProductFacetService } from '@spartacus/storefront';
import { BehaviorSubject } from 'rxjs';

import { AimoFacet, AimoFacetValue } from '../../model/product.model';

import { AimoProductListComponentService } from './aimo-product-list-component.service';

@Injectable({
    providedIn: 'root',
})
export class AimoProductFacetService extends ProductFacetService {
    scrollPosition: number = 0;
    constructor(
        protected routingService: RoutingService,
        protected productListComponentService: AimoProductListComponentService,
    ) {
        super(routingService, productListComponentService);
    }

    protected filterForPage(state: ActivatedRouterStateSnapshot, page: ProductSearchPage): boolean {
        if (!page.currentQuery?.query?.value) {
            return false;
        }
        if (state.context.type === PageType.CATEGORY_PAGE) {
            return true;
        }
        if (
            state.context.type === PageType.CONTENT_PAGE &&
            (state.context.id === 'search' || state.context.id === '/search')
        ) {
            return (
                page.freeTextSearch === state.params.query ||
                page.freeTextSearch === state.queryParams?.query?.split(':')[0]
            );
        }
        return false;
    }

    getRangeFacetQuery(facetMin: number, facetMax: number, facetValue: AimoFacetValue): string {
        return ':' + facetValue.code + ':' + facetMin + ' - ' + facetMax;
    }

    getLinkParamForFacetValue(facet: AimoFacet, facetValue: AimoFacetValue, currentQuery): string {
        const facetQuery = facet.code + ':' + facetValue.code;
        if (
            facetValue.selected &&
            !(currentQuery?.indexOf(facetQuery + ':') >= 0) &&
            !currentQuery?.endsWith(facetQuery)
        ) {
            return ':' + facetQuery;
        }
        return '';
    }

    initQueryValue(route: ActivatedRoute): string {
        if (
            route.snapshot.url.length > 1 &&
            (route.snapshot.url[0].path === 'category' || route.snapshot.url[0].path === 'c')
        ) {
            const categoryCode = route.snapshot.url[1].path;
            if (this.productListComponentService.getDummyCategoryParam(categoryCode) !== undefined) {
                return ':' + this.productListComponentService.getDummyCategoryParam(categoryCode);
            } else if (
                route.snapshot.queryParams.query === undefined ||
                route.snapshot.queryParams.query.indexOf('allCategories') < 0
            ) {
                return ':allCategories:' + route.snapshot.url[1].path;
            }
        }
        return '';
    }

    public getCurrentQuery(route: ActivatedRoute): string {
        if (route.snapshot.queryParams.query) {
            if (route.snapshot.queryParams.query.endsWith(':')) {
                return route.snapshot.queryParams.query.substring(0, route.snapshot.queryParams.query.length - 1);
            }
            return route.snapshot.queryParams.query;
        } else if (route.snapshot.url.length > 1 && route.snapshot.url[0].path === 'search') {
            return route.snapshot.url[1].path + ':relevance';
        } else {
            return '*:relevance';
        }
    }

    public removeRangeFacet(currentQuery: string, facet: AimoFacetValue): string {
        const rexEx = new RegExp('\\:' + facet.code + '\\:[0-9,\\-,\\. ,;]*', 'g');
        return currentQuery.replace(rexEx, '');
    }

    public navigateFacetToSolr(
        route: ActivatedRoute,
        queryValue: string,
        facetService: FacetService,
        scrollId?: string,
    ): void {
        this.routingService.go(['./'], {
            queryParams: {
                ...facetService.getLinkParams(queryValue.replace('::', ':')), // fix faulty URL,
            },
            fragment: scrollId,
            relativeTo: route,
        });
    }

    private clicked$ = new BehaviorSubject<boolean>(false);
    facetClicked$ = this.clicked$.asObservable();

    public setFacetClicked(clicked: boolean): void {
        this.clicked$.next(clicked);
    }

    public getScrollPosition(): number {
        return this.scrollPosition;
    }

    public setScrollPosition(value: number): void {
        this.scrollPosition = value;
    }
}
