import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, provideDefaultConfig } from '@spartacus/core';

import { AimoPipesModule } from '../utils/safe-html/aimo-pipes.module';

import { ParagraphComponent } from './paragraph.component';

@NgModule({
    imports: [CommonModule, AimoPipesModule],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSParagraphComponent: {
                    component: ParagraphComponent,
                },
                CMSTabParagraphComponent: {
                    component: ParagraphComponent,
                },
            },
        }),
    ],
    declarations: [ParagraphComponent],
    exports: [ParagraphComponent],
    entryComponents: [ParagraphComponent],
})
export class CmsParagraphModule {}
