import { Injectable } from '@angular/core';
import { OrderHistoryConnector } from '@spartacus/order/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AimoCart, AimoOrder } from '../../model/cart.model';
import { FileExportWrapper } from '../../model/misc.model';
import { AimoOrderHistory, AimoOrderHistoryRequest } from '../../model/order.model';

import { AimoOrderHistoryAdapter } from './aimo-occ-user-order.adapter';

@Injectable({
    providedIn: 'root',
})
export class AimoUserOrderConnector extends OrderHistoryConnector {
    constructor(protected adapter: AimoOrderHistoryAdapter) {
        super(adapter);
    }

    public getOrder(userId: string, code: string): Observable<AimoCart> {
        return this.adapter
            .loadOrderHistory(userId, {
                deliveryDate: {
                    from: null,
                    to: null,
                },
                searchTerm: code,
            })
            .pipe(
                switchMap((h) => h.dayGroupedOrders),
                map((d) => (d.orders?.length === 1 ? d.orders[0] : undefined)),
            );
    }

    public getOrderHistory(userId: string, params: AimoOrderHistoryRequest): Observable<AimoOrderHistory> {
        return this.adapter.loadOrderHistory(userId, params);
    }

    public exportOrderToExcel(userId: string, orderNumber: string): Observable<FileExportWrapper> {
        return this.adapter
            .exportOrderToExcel(userId, orderNumber)
            .pipe(map((buffer) => ({ name: orderNumber + '.xlsx', data: buffer } as FileExportWrapper)));
    }

    getOrderToBePaid(userId: string, orderId: string): Observable<AimoOrder> {
        return this.adapter.getOrderToBePaid(userId, orderId);
    }
}
