import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoDayGroupedOrderHistory } from '../../../model/order.model';

import { AimoClaimsModalComponent } from './aimo-claims-modal.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        CLAIMS = 'CLAIMS',
    }
}

export const claimsLayout: LayoutConfig = {
    launch: {
        CLAIMS: {
            inlineRoot: true,
            component: AimoClaimsModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoClaimsDialogData {
    day: AimoDayGroupedOrderHistory;
    editable: boolean;
}
