import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
    selector: 'aimo-paragraph',
    templateUrl: './paragraph.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParagraphComponent {
    constructor(public component: CmsComponentData<CmsParagraphComponent>) {}
}
