import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AimoCategory, AimoCategoryHierarchy } from '../../model/product.model';

import { AimoCategoryAdapter } from './aimo-category.adapter';

@Injectable({
    providedIn: 'root',
})
export class AimoCategoryConnector {
    constructor(protected adapter: AimoCategoryAdapter) {}

    getCategoryById(userId: string, categoryId: string): Observable<AimoCategory> {
        return this.adapter.getCategoryById(userId, categoryId);
    }

    getCategoryHierarchyById(userId: string, categoryId: string): Observable<AimoCategoryHierarchy> {
        return this.adapter.getCategoryHierarchyById(userId, categoryId);
    }
}
