import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import { ListNavigationModule, MediaModule, PageSlotModule } from '@spartacus/storefront';

import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoInputSelectModule } from '../../shared/input-select/aimo-input-select.module';
import { AimoItemCounterModule } from '../../shared/item-counter/aimo-item-counter.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from '../cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoBreadcrumbModule } from '../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoDatepickerModule } from '../shared/datepicker/aimo-datepicker.module';

import { AimoClaimsComponent } from './claims-list/aimo-claims.component';
import { claimsLayout } from './claims-modal/aimo-claims-layout.config';
import { AimoClaimsModalComponent } from './claims-modal/aimo-claims-modal.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        AimoInputModule,
        AimoDatepickerModule,
        ReactiveFormsModule,
        MediaModule,
        AimoDeliveryDateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        NgbDropdownModule,
        AimoCartSharedModule,
        PageSlotModule,
        AimoInputSelectModule,
        AimoItemCounterModule,
        AimoBreadcrumbModule,
    ],
    declarations: [AimoClaimsModalComponent, AimoClaimsComponent],
    exports: [AimoClaimsModalComponent, AimoClaimsComponent],
    providers: [
        provideDefaultConfig(claimsLayout),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AccountOrderClaimsComponent: {
                    component: AimoClaimsComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        provideDefaultConfig(<RoutingConfig>{
            routing: {
                routes: {
                    orderTemplates: {
                        paths: ['claims'],
                    },
                    orderTemplateDetails: {
                        paths: ['claims/:claimId'],
                        paramsMapping: { claimId: 'claimId' },
                    },
                },
            },
        }),
    ],
})
export class AimoClaimsModule {}
