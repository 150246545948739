import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    TemplateRef,
} from '@angular/core';
import { NgbDate, NgbDatepicker, NgbDatepickerConfig, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AimoActiveCartService } from 'src/app/features/service/cart/aimo-active-cart.service';

import { CalendarNavigationTypes } from '../../../model/misc.model';
import { AimoInputComponent } from '../../../shared/input/aimo-input.component';
import { FormUtils } from '../../../shared/util/form-utils';

import { AimoNgbDatepickerI18n } from './aimo-datepicker-i18n';
import { AimoNgbDateParserFormatter } from './aimo-datepicker-parser-formatter';

@Component({
    selector: 'aimo-datepicker-input',
    templateUrl: './aimo-datepicker-input.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoDatepickerInputComponent extends AimoInputComponent implements OnDestroy {
    @Input() firstDayOfWeek = 2;

    @Input() selectedDate: NgbDate;

    // eslint-disable-next-line
    @Input() customDayTemplate: TemplateRef<any>;

    @Output()
    navigationArrowClickEvent = new EventEmitter<Date>();

    @Input() placeholder: string;
    @Input() navigation: string = CalendarNavigationTypes.Arrows;

    @Input() allowedDates;

    @Input() firstAllowedDate: Date;

    @Input() cssClass: string;

    constructor(
        config: NgbDatepickerConfig,
        protected cdr: ChangeDetectorRef,
        protected elRef: ElementRef,
        protected ngbDatepickerI18n: NgbDatepickerI18n,
        protected activeCartService: AimoActiveCartService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
    ) {
        super(elRef);
        config.navigation = 'arrows';
        // days that don't belong to current month are not visible and the space should be collapsed
        config.showWeekNumbers = false;
        config.outsideDays = 'collapsed';
    }

    hasValue(): boolean {
        if (this.parentForm) {
            // eslint-disable-next-line
            const input: any = this.parentForm.get(this.nameForm);
            return input && input.value && (this.isNotEmptyString(input) || this.isNgbDateStruct(input));
        }
        return false;
    }

    // eslint-disable-next-line
    isNotEmptyString(object: any): boolean {
        // eslint-disable-next-line
        const value: any = object.value;
        return value && typeof value === 'string' && value !== '';
    }

    // eslint-disable-next-line
    isNgbDateStruct(object: any): object is NgbDateStruct {
        // eslint-disable-next-line
        const value: any = object.value;
        return 'year' in value && 'month' in value && 'day' in value;
    }

    getFormattedDate(): string {
        if (this.selectedDate) {
            return this.ngbDateParserFormatter.format(this.selectedDate);
        }
        return null;
    }

    isNotValid(formControlName: string): boolean {
        return !this.parentForm || FormUtils.isNotValidField(this.parentForm, formControlName, this.submitClicked);
    }

    ngOnDestroy(): void {
        // Providers should be destroyed manually
        if (this.ngbDatepickerI18n instanceof AimoNgbDatepickerI18n) {
            this.ngbDatepickerI18n.ngOnDestroy();
        }
        if (this.ngbDateParserFormatter instanceof AimoNgbDateParserFormatter) {
            this.ngbDateParserFormatter.ngOnDestroy();
        }
    }

    navigate(date: NgbDate): void {
        this.navigationArrowClickEvent.emit(this.ngbDateParserFormatter.toDate(date));
    }

    disableDates(dp: NgbDatepicker): void {
        if (this.firstAllowedDate === undefined && (!this.allowedDates || Object.keys(this.allowedDates).length <= 0)) {
            return; // all dates are allowed
        }

        // eslint-disable-next-line
        dp.markDisabled = (date: NgbDate) => {
            if (this.firstAllowedDate) {
                return this.firstAllowedDate.getTime() > this.ngbDateParserFormatter.toDate(date).getTime();
            } else {
                return this.allowedDates[this.ngbDateParserFormatter.toString(date)] == null;
            }
        };
    }

    onChange(date: NgbDate): void {
        if (date && date !== this.selectedDate) {
            this.afterChange.emit(this.ngbDateParserFormatter.toDate(date));
        }
    }
}
