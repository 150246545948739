import { Injectable } from '@angular/core';
import { CmsNavigationComponent, CmsService, SemanticPathService } from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { AimoNavigationNode } from './aimo-navigation-node.model';

@Injectable({
    providedIn: 'root',
})
export class AimoNavigationService {
    constructor(protected cmsService: CmsService, protected semanticPathService: SemanticPathService) {}

    createNavigation(data$: Observable<CmsNavigationComponent>): Observable<AimoNavigationNode> {
        return combineLatest([data$, this.getAimoNavigationNode(data$)]).pipe(
            filter((data) => data != null),
            map(([data, nav]) => {
                return {
                    title: data?.name,
                    uid: data?.uid,
                    children: [nav],
                };
            }),
        );
    }

    getAimoNavigationNode(data$: Observable<CmsNavigationComponent>): Observable<AimoNavigationNode> {
        if (!data$) {
            return of();
        }
        return data$.pipe(
            filter((data) => !!data),
            switchMap((data) => {
                const navigation = data.navigationNode ? data.navigationNode : data;
                return this.cmsService.getNavigationEntryItems(navigation.uid).pipe(
                    tap((items) => {
                        if (items === undefined) {
                            this.getNavigationEntryItems(navigation, true);
                        }
                    }),
                    filter(Boolean),
                    map((items) => this.createNode(navigation, items)),
                );
            }),
        );
    }

    // eslint-disable-next-line
    private getNavigationEntryItems(nodeData: any, root: boolean, itemsList = []): void {
        if (nodeData.entries && nodeData.entries.length > 0) {
            nodeData.entries.forEach((entry) => {
                itemsList.push({
                    superType: entry.itemSuperType,
                    id: entry.itemId,
                });
            });
        }

        if (nodeData.children && nodeData.children.length > 0) {
            this.processChildren(nodeData, itemsList);
        }

        if (root) {
            const rootUid = nodeData.uid;
            this.cmsService.loadNavigationItems(rootUid, itemsList);
        }
    }

    private processChildren(node, itemsList): void {
        for (const child of node.children) {
            this.getNavigationEntryItems(child, false, itemsList);
        }
    }

    // eslint-disable-next-line
    private createNode(nodeData: any, items: any): AimoNavigationNode {
        const node: AimoNavigationNode = {};

        node.title = nodeData.title;
        node.uid = nodeData.uid;

        if (nodeData.entries && nodeData.entries.length > 0) {
            this.addLinkToNode(node, nodeData.entries[0], items);
        }

        if (nodeData.children && nodeData.children.length > 0) {
            const children = this.createChildren(nodeData, items);
            node.children = children;
        }

        return node;
    }

    private addLinkToNode(node: AimoNavigationNode, entry, items): void {
        const item = items[`${entry.itemId}_${entry.itemSuperType}`];

        // now we only consider CMSLinkComponent
        if (entry.itemType === 'CMSLinkComponent' && item !== undefined) {
            if (item.linkName) {
                node.title = item.linkName;
            }

            node.url = this.getLink(item);

            // if "NEWWINDOW", target is true
            node.target = item.target == 'true' ? '_blank' : '_self';

            node.styleClasses = item.styleClasses;
        }
    }

    private getLink(item): string | string[] {
        if (item.url) {
            return item.url;
        } else if (item.categoryCode) {
            return this.semanticPathService.transform({
                cxRoute: 'category',
                params: {
                    code: item.categoryCode,
                    name: item.name,
                },
            });
        }
    }

    // eslint-disable-next-line
    private createChildren(node, items): any[] {
        const children = [];

        for (const child of node.children) {
            const childNode = this.createNode(child, items);
            children.push(childNode);
        }

        return children;
    }
}
