import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AimoCMSBannerCarouselComponent, AimoCMSBannerComponent } from '../../../model/cms.model';

@Component({
    selector: 'aimo-banner-carousel',
    templateUrl: './aimo-banner-carousel.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBannerCarouselComponent {
    componentData$: Observable<AimoCMSBannerCarouselComponent> = this.component.data$.pipe(filter(Boolean));

    banners$: Observable<AimoCMSBannerComponent[]> = this.componentData$.pipe(
        filter((data) => data != null),
        switchMap((data) =>
            combineLatest(
                data?.banners
                    ?.split(' ')
                    .sort(() => (data.randomSort === 'true' ? (Math.random() > 0.5 ? 1 : -1) : 1))
                    .map((component) => this.cmsService.getComponentData<AimoCMSBannerComponent>(component)),
            ),
        ),
    );

    constructor(
        protected component: CmsComponentData<AimoCMSBannerCarouselComponent>,
        protected cmsService: CmsService,
    ) {}
}
