<button #trigger class="btn-facet d-block d-md-none" (click)="launch()">
    {{ 'productList.filter.button' | cxTranslate }}
    <i class="aimo-icon icon-sm icon-inline icon-caret-down icon-petrol-blue"></i>
</button>

<aimo-facet-list
    [mobileUI]="mobileUI"
    *ngIf="isOpen$ | async"
    [isDialog]="hasTrigger"
    (closeList)="close()"
    [class.active]="isActive$ | async"
    [class.dialog]="hasTrigger"></aimo-facet-list>
