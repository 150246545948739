import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import {
    AtMessageModule,
    IconModule,
    ItemCounterModule,
    ListNavigationModule,
    MediaModule,
    OutletModule,
    PageComponentModule,
    PageSlotModule,
    ProductListModule,
    SpinnerModule,
    StarRatingModule,
} from '@spartacus/storefront';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SharedModule } from '../../../shared/shared.module';
import { AimoSortingModule } from '../../../shared/sorting/aimo-sorting.module';
import { AimoAddToCartModule } from '../../cart-shared/add-to-cart/aimo-add-to-cart.module';
import { AimoBreadcrumbModule } from '../../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoPageSizeModule } from '../../shared/page-size/aimo-page-size.module';
import { AimoSpinnerModule } from '../../shared/spinner/aimo-spinner.module';
import { AimoActiveFacetsModule } from '../facet-navigation/active-facets/aimo-active-facets.module';
import { AimoProductFacetNavigationModule } from '../facet-navigation/aimo-product-facet-navigation.module';

import { AimoCategoryHeaderSubcategoryComponent } from './category-header/aimo-category-header-subcategory.component';
import { AimoCategoryHeaderComponent } from './category-header/aimo-category-header.component';
import { AimoProductListComponent } from './container/aimo-product-list.component';
import { AimoProductCategoriesComponent } from './product-categories/aimo-product-categories.component';
import { AimoProductCategoryFacetsComponent } from './product-categories/aimo-product-category-facets.component';
import { AimoProductAvailabilityItemComponent } from './product-grid-item/aimo-product-availability.component';
import { AimoProductCodeComponent } from './product-grid-item/aimo-product-code.component';
import { AimoProductGridItemComponent } from './product-grid-item/aimo-product-grid-item.component';
import { AimoProductTagComponent } from './product-grid-item/aimo-product-tag.component';
import { AimoProductListItemComponent } from './product-list-item/aimo-product-list-item.component';
import { AimoProductScrollComponent } from './product-scroll/aimo-product-scroll.component';

@NgModule({
    imports: [
        AtMessageModule,
        CommonModule,
        FeaturesConfigModule,
        I18nModule,
        IconModule,
        InfiniteScrollModule,
        ItemCounterModule,
        ListNavigationModule,
        MediaModule,
        PageComponentModule,
        RouterModule,
        SpinnerModule,
        StarRatingModule,
        UrlModule,
        ProductListModule,
        OutletModule,
        AimoAddToCartModule,
        AimoPageSizeModule,
        AimoActiveFacetsModule,
        AimoProductFacetNavigationModule,
        NgbPopoverModule,
        NgbTooltipModule,
        AimoBreadcrumbModule,
        AimoSpinnerModule,
        AimoSortingModule,
        SharedModule,
        PageSlotModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                CMSProductListComponent: {
                    component: AimoProductListComponent,
                    data: {
                        composition: {
                            inner: ['ProductAddToCartComponent'],
                        },
                    },
                },
                ProductGridComponent: {
                    component: AimoProductListComponent,
                    data: {
                        composition: {
                            inner: ['ProductAddToCartComponent'],
                        },
                    },
                },
                SearchResultsGridComponent: {
                    component: AimoProductListComponent,
                    data: {
                        composition: {
                            inner: ['ProductAddToCartComponent'],
                        },
                    },
                },
                SearchResultsListComponent: {
                    component: AimoProductListComponent,
                    data: {
                        composition: {
                            inner: ['ProductAddToCartComponent'],
                        },
                    },
                },
                AimoProductCategoriesComponent: {
                    component: AimoProductCategoriesComponent,
                },
                AimoCategoryHeaderComponent: {
                    component: AimoCategoryHeaderComponent,
                },
                AimoCategoryHeaderSubcategoryComponent: {
                    component: AimoCategoryHeaderSubcategoryComponent,
                },
            },
        }),
    ],
    declarations: [
        AimoProductListComponent,
        AimoProductListItemComponent,
        AimoProductGridItemComponent,
        AimoProductCategoriesComponent,
        AimoCategoryHeaderComponent,
        AimoProductScrollComponent,
        AimoProductCategoryFacetsComponent,
        AimoProductAvailabilityItemComponent,
        AimoCategoryHeaderSubcategoryComponent,
        AimoProductCodeComponent,
        AimoProductTagComponent,
    ],
    exports: [
        AimoProductListComponent,
        AimoProductListItemComponent,
        AimoProductGridItemComponent,
        AimoProductCategoriesComponent,
        AimoCategoryHeaderComponent,
        AimoProductScrollComponent,
        AimoProductCategoryFacetsComponent,
        AimoProductAvailabilityItemComponent,
        AimoCategoryHeaderSubcategoryComponent,
        AimoProductCodeComponent,
        AimoProductTagComponent,
    ],
})
export class AimoProductListModule extends ProductListModule {}
