import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoHamburgerMenuService } from '../../../layout/hamburger-menu/aimo-hamburger-menu.service';
import { AimoMainNavigation } from '../../../model/cms.model';
import { AimoCategoryHierarchy } from '../../../model/product.model';
import { AimoCategoryService } from '../../../service/category/aimo-category.service';
import { AimoNavigationNode } from '../../../service/navigation/aimo-navigation-node.model';
import { AimoNavigationService } from '../../../service/navigation/aimo-navigation.service';

@Component({
    selector: 'aimo-cx-main-navigation',
    templateUrl: './aimo-main-navigation.component.html',
})
export class AimoMainNavigationComponent implements OnInit {
    data$: Observable<AimoMainNavigation>;
    mainCategories$: Observable<AimoCategoryHierarchy>;
    childCategories$: Observable<AimoCategoryHierarchy>;
    lastSelectedCategory: string;
    lastSelectedCategoryName: string;
    lastSelectedCategoryTotal: number;
    lastSelectedCategoryUrl: string;
    navigationOpened: boolean = false;

    private componentData$: Observable<AimoMainNavigation> = this.component.data$.pipe(filter(Boolean));

    navigationNode$: Observable<AimoNavigationNode> = this.service.createNavigation(
        this.componentData$.pipe(map((data) => data.navigationNode)),
    );

    constructor(
        public component: CmsComponentData<AimoMainNavigation>,
        protected service: AimoNavigationService,
        protected categoryService: AimoCategoryService,
        protected cdr: ChangeDetectorRef,
        protected winRef: WindowRef,
        private hamburgerMenuService: AimoHamburgerMenuService,
    ) {}

    ngOnInit(): void {
        this.data$ = this.component.data$;
        this.mainCategories$ = this.categoryService.getCategoryHierarchyById('0');
    }

    openNavigationUI(el: HTMLElement): void {
        el.classList.toggle('is-expanded');
        if (el.classList.contains('is-expanded')) {
            this.navigationOpened = true;
        } else {
            this.navigationOpened = false;
        }
    }

    hideShowMainTitle(mainTitle: HTMLElement, navChildren: HTMLElement): void {
        this.openNavigationUI(navChildren);
        navChildren.classList.toggle('mobile-menu');

        const header = this.winRef.document.getElementsByTagName('header')[0];
        header.classList.toggle('category-expanded');
    }

    hideShowChildCategory(categoryTitle: HTMLElement, mainTitle: HTMLElement, navChildren: HTMLElement): void {
        if (navChildren.classList.contains('child-category-open')) {
            navChildren.classList.remove('child-category-open');
            mainTitle.classList.add('d-block');
            mainTitle.classList.remove('d-none');
            categoryTitle.classList.remove('d-block');
            categoryTitle.classList.add('d-none');
        } else {
            navChildren.classList.add('child-category-open');
            mainTitle.classList.remove('d-block');
            mainTitle.classList.add('d-none');
            categoryTitle.classList.add('d-block');
            categoryTitle.classList.remove('d-none');
        }
    }

    clickout(el: HTMLElement): void {
        if (!el?.classList.contains('mobile-menu')) {
            el.classList.remove('is-expanded');
        }
    }

    toggleChildCategory(
        id: string,
        name: string,
        totalNumber: number,
        url: string,
        categoryTitle: HTMLElement,
        mainTitle: HTMLElement,
        navChildren: HTMLElement,
        event: MouseEvent,
    ): void {
        event.stopImmediatePropagation();
        event.stopPropagation();
        if (navChildren.classList.contains('mobile-menu') || this.lastSelectedCategory !== id) {
            this.lastSelectedCategory = id;
            this.lastSelectedCategoryName = name;
            this.lastSelectedCategoryTotal = totalNumber;
            this.lastSelectedCategoryUrl = url;
            this.childCategories$ = this.categoryService.getCategoryHierarchyById(id);
            this.hideShowChildCategory(categoryTitle, mainTitle, navChildren);
        }
        this.cdr.detectChanges();
    }

    nodeVisible(node: AimoNavigationNode): boolean {
        return node.children.filter((c) => c.url !== undefined).length > 0;
    }

    closeIfClickedTheSameLink(url: string): void {
        if (typeof url === 'string' && this.winRef.nativeWindow?.location.href.includes(url)) {
            this.hamburgerMenuService.toggle(true);
        }
    }
}
