import { ChangeDetectionStrategy, Component, ComponentRef, ViewContainerRef } from '@angular/core';
import { ProductImageZoomTriggerComponent } from '@spartacus/product/image-zoom/components';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AimoProductImageZoomDialogComponent } from '../product-image-zoom-dialog/aimo-product-image-zoom-dialog.component';

@Component({
    selector: 'aimo-product-image-zoom-trigger',
    templateUrl: 'aimo-product-image-zoom-trigger.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductImageZoomTriggerComponent extends ProductImageZoomTriggerComponent {
    constructor(protected launchDialogService: LaunchDialogService, protected vcr: ViewContainerRef) {
        super(launchDialogService, vcr);
    }

    triggerZoom(): void {
        const onClose = new Subject<void>();
        const component = this.launchDialogService.launch(LAUNCH_CALLER.PRODUCT_IMAGE_ZOOM, this.vcr, {
            onClose: onClose,
        });
        if (component) {
            this.subscriptions.add(
                onClose.subscribe(() => {
                    this.dialogClose.emit();
                    this.subscriptions.add(component.subscribe((comp) => comp.destroy()));
                    this.expandButton.nativeElement.focus();
                }),
            );
            this.subscriptions.add(
                component
                    .pipe(
                        tap((comp) => {
                            if (this.galleryIndex) {
                                (comp as ComponentRef<AimoProductImageZoomDialogComponent>).instance.galleryIndex =
                                    this.galleryIndex;
                            }
                        }),
                    )
                    .subscribe(),
            );
        }
    }
}
