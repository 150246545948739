import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';

const PASSWORD_PATTERN = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=\S+$).{10,}$/;
export const EMAIL_PATTERN =
    /^$|^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

export class AimoCustomFormValidators extends CustomFormValidators {
    /**
     * Overridden PASSWORD_PATTERN
     *
     * Checks control's value with predefined password regexps, max 1 can fail.
     *
     * NOTE: Use it as a control validator
     *
     * @static
     * @param {AbstractControl} control
     * @returns {(ValidationErrors | null)} Uses 'cxInvalidPassword' validator error
     * @memberof AimoCustomFormValidators
     */
    static passwordValidator(control: AbstractControl): ValidationErrors | null {
        const password = control.value as string;

        return password && (!password.length || password.match(PASSWORD_PATTERN)) ? null : { cxInvalidPassword: true };
    }

    /**
     * Doesn't check when field is empty, so it marks empty email field as valid.
     *
     * @static
     * @param {AbstractControl} control
     * @returns {(ValidationErrors | null)} Uses 'cxInvalidEmail' validator error
     * @memberof AimoCustomFormValidators
     */
    static emptyEmailValidator(control: AbstractControl): ValidationErrors | null {
        const email = control.value as string;
        if (!email) {
            return null;
        }
        return super.emailValidator(control);
    }

    public static phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
        const phone = control.value as string;
        // eslint-disable-next-line
        const strippedPhone: any = phone?.replace('+', '').replace('-', '').replace(/ /g, '');
        return phone && phone.length >= 9 && phone.length <= 30 && !isNaN(strippedPhone)
            ? null
            : { InvalidPhone: true };
    }

    static vatIdLengthValidator(control: AbstractControl): ValidationErrors | null {
        const value = control.value as string;
        return value && value.length === 9 && value.indexOf('-') === 7 ? null : { invalidVatId: true };
    }

    static bothRequiredOrEmptyValidator(prefix1: string, prefix2: string, startFromIdx: number): unknown {
        // eslint-disable-next-line
        return (formGroup: FormGroup) =>
            Object.keys(formGroup.controls)
                .filter((key) => key.indexOf(prefix1) === 0 || key.indexOf(prefix2) === 0)
                .forEach((key) => {
                    const idx = parseInt(key.substring(key.indexOf('_') + 1));
                    if (idx >= startFromIdx) {
                        const f1 = formGroup.controls[prefix1 + idx];
                        const f2 = formGroup.controls[prefix2 + idx];
                        if (f1 && f2) {
                            const inValid = (!f1.value && f2.value) || (f1.value && !f2.value);
                            f1.setErrors(inValid ? AimoCustomFormValidators.vatIdLengthValidator(f1) : null);
                            f2.setErrors(inValid ? { ['required']: true } : null);
                        }
                    }
                });
    }

    static emailValidator(control: AbstractControl): ValidationErrors | null {
        const email = control.value as string;

        return email?.length == 0 || email?.match(EMAIL_PATTERN) ? null : { cxInvalidEmail: true };
    }
}
