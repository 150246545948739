import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { OccFunctionRequestInterceptor } from './occ-function-request.interceptor';

export const aimoInterceptors: Provider[] = [
    {
        provide: HTTP_INTERCEPTORS,
        useExisting: OccFunctionRequestInterceptor,
        multi: true,
    },
];
