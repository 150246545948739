<div class="field-value">
    <label
        [for]="idName + '_input'"
        *ngIf="label"
        class="input-label-value {{ labelClass }}"
        [ngClass]="{ required: isRequired() }">
        {{ label | cxTranslate }}
        <span
            *ngIf="labelTooltip ? (labelTooltip | cxTranslate) : false"
            class="aimo-icon icon-inline icon-info icon-sm"
            placement="top"
            ngbTooltip="{{ labelTooltip | cxTranslate }}"
            role="button"></span>
    </label>
    <ng-container *ngIf="parentForm">
        <div
            [formGroup]="parentForm"
            [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
            [ngClass]="hasValue() ? 'has-value' : ''">
            <ng-select
                #selectElement
                [id]="idName"
                [labelForId]="idName + '_input'"
                (change)="onChange($event)"
                [bindLabel]="bindLabel"
                [bindValue]="bindValue"
                [class]="cssClass"
                [clearable]="clearable"
                [closeOnSelect]="true"
                [formControlName]="nameForm"
                [items]="items"
                [multiple]="false"
                [placeholder]="placeholder ? (placeholder | cxTranslate) : ''"
                [searchable]="false"
                [notFoundText]="'aimo.select.noItemsFound' | cxTranslate">
                <ng-template ng-option-tmp let-item="item" *ngIf="showTemplateIcon">
                    <i
                        *ngIf="item.visibility"
                        class="aimo-icon icon-sm icon-petrol-blue icon-inline mr-2"
                        [ngClass]="{
                    'icon-user': item.visibility === 'private',
                    'icon-users': item.visibility === 'public',
                    'icon-house-line': item.visibility === 'central',
                    }">
                    </i>
                    <span [ngClass]="{ 'text-bold': item.code === 'new_template' }">{{ item.name }}</span>
                </ng-template>

                <ng-template ng-label-tmp let-item="item" *ngIf="showTemplateIcon">
                    <i
                        *ngIf="item.visibility"
                        class="aimo-icon icon-sm icon-petrol-blue icon-inline mr-2"
                        [ngClass]="{
                    'icon-user': item.visibility === 'private',
                    'icon-users': item.visibility === 'public',
                    'icon-house-line': item.visibility === 'central',
                    }">
                    </i>
                    <span>{{ item.name }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" *ngIf="savedFilters">
                    <div class="saved-facet-item">
                        {{ item.name }}
                        <i (click)="onClick($event, item)" class="aimo-icon icon-inline icon-sm icon-trash"></i>
                    </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" *ngIf="translateByCode">
                    {{ 'aimo.select.' + nameForm + '.' + item[bindValue] | cxTranslate }}
                </ng-template>
                <ng-template ng-label-tmp let-item="item" *ngIf="translateByCode">
                    {{ 'aimo.select.' + nameForm + '.' + item[bindValue] | cxTranslate }}
                </ng-template>
            </ng-select>
        </div>
    </ng-container>

    <ng-container *ngIf="!parentForm">
        <ng-select
            #selectElement
            [id]="idName"
            [labelForId]="idName + '_input'"
            (change)="onChange($event)"
            [bindLabel]="bindLabel"
            [bindValue]="bindValue"
            [class]="cssClass"
            [clearable]="clearable"
            [notFoundText]="'aimo.select.noItemsFound' | cxTranslate"
            [closeOnSelect]="true"
            [items]="items"
            [multiple]="false"
            [ngModel]="selectedValue"
            [placeholder]="placeholder ? (placeholder | cxTranslate) : ''"
            [searchable]="false">
            <ng-template ng-option-tmp let-item="item" *ngIf="savedFilters">
                <div class="saved-facet-item">
                    {{ item.name }}
                    <i (click)="onClick($event, item)" class="aimo-icon icon-inline icon-sm icon-trash"></i>
                </div>
            </ng-template>

            <ng-template ng-option-tmp let-item="item" *ngIf="translateByCode">
                {{ 'aimo.select.' + nameForm + '.' + item[bindValue] | cxTranslate }}
            </ng-template>
            <ng-template ng-label-tmp let-item="item" *ngIf="translateByCode">
                {{ 'aimo.select.' + nameForm + '.' + item[bindValue] | cxTranslate }}
            </ng-template>
        </ng-select>
    </ng-container>
</div>

<div
    class="invalid-feedback d-block text-right"
    *ngIf="(isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)) && errorMessage">
    <span>{{ errorMessage | cxTranslate }}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
    {{ extraInfoBottom | cxTranslate }}
</div>
