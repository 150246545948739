import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';

import { AimoPasswordChecksComponent } from './aimo-password-checks.component';

@NgModule({
    declarations: [AimoPasswordChecksComponent],
    imports: [CommonModule, I18nModule, RouterModule],
    exports: [AimoPasswordChecksComponent],
})
export class AimoPasswordChecksModule {}
