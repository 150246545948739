import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { ListNavigationModule, MediaModule } from '@spartacus/storefront';

import { AimoCarouselModule } from '../../shared/carousel/aimo-carousel.module';
import { AimoProductListModule } from '../product-list/aimo-product-list.module';
import { AimoProductSummaryModule } from '../product-summary/aimo-product-summary.module';

import { AimoProductComparisonPreviewComponent } from './aimo-product-comparison-preview.component';
import { AimoProductComparisonComponent } from './aimo-product-comparison.component';
import { productComparisonLayout } from './comparison-layout.config';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        ReactiveFormsModule,
        MediaModule,
        AimoCarouselModule,
        AimoProductListModule,
        AimoProductSummaryModule,
    ],
    declarations: [AimoProductComparisonPreviewComponent, AimoProductComparisonComponent],
    exports: [AimoProductComparisonPreviewComponent, AimoProductComparisonComponent],
    providers: [provideDefaultConfig(productComparisonLayout)],
})
export class AimoProductComparisonModule {}
