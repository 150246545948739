import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { WindowRef } from '@spartacus/core';

import { AimoProduct } from '../../../../model/product.model';

@Component({
    selector: 'aimo-product-code',
    templateUrl: './aimo-product-code.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductCodeComponent {
    @Input() product: AimoProduct;
    @Input() klass: string = '';
    @ViewChild('productId') productId: ElementRef<HTMLElement>;

    constructor(protected winRef: WindowRef) {}

    selectProductId(code: string, $event: MouseEvent): void {
        $event.preventDefault();
        const selection = this.winRef.document.getSelection();
        const node = this.productId.nativeElement;
        const text = node.childNodes[0];
        const range = new Range();
        range.setStart(text, 0);
        range.setEnd(text, code.length);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}
