import { Component, OnInit } from '@angular/core';
import { AuthService, WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-cx-login',
    templateUrl: './aimo-login.component.html',
})
export class AimoLoginComponent implements OnInit {
    loggedIn$: Observable<boolean>;
    notLoggedIn$: Observable<boolean>;

    constructor(
        protected auth: AuthService,
        protected userService: AimoUserService,
        protected aimoRoutingService: AimoRoutingService,
        protected winRef: WindowRef,
    ) {}

    ngOnInit(): void {
        this.loggedIn$ = this.auth.isUserLoggedIn();
        this.notLoggedIn$ = this.loggedIn$.pipe(map((l) => l === false));
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    isOnUrlPage(url: string): void {
        if (typeof url === 'string' && this.winRef.nativeWindow?.location.href.includes(url)) {
            this.winRef.location.reload();
        }
    }
}
