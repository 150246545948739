<ng-container *ngIf="config$ | async as config">
    <div [attr.aria-label]="'searchBox.productSearch' | cxTranslate" role="search">
        <button class="btn-link btn-clear-results" (click)="onlyClear(); close($event)">
            <i class="aimo-icon icon-arrow-left"></i>
        </button>
        <label
            class="searchbox"
            [class.dirty]="!!searchInput.value"
            [attr.aria-label]="'searchBox.' + (isCartSearchBox ? 'cart.' : '') + 'placeholder' | cxTranslate">
            <div role="presentation" class="search-icon">
                <i class="aimo-icon icon-sm icon-magnifying-glass icon-petrol-blue"></i>
            </div>

            <input
                #searchInput
                [placeholder]="'searchBox.' + (isCartSearchBox ? 'cart.' : '') + 'placeholder' | cxTranslate"
                autocomplete="off"
                tabindex="0"
                aria-describedby="initialDescription"
                aria-controls="results"
                [attr.aria-label]="'searchBox.' + (isCartSearchBox ? 'cart.' : '') + 'placeholder' | cxTranslate"
                (input)="searchMinCharacters(searchInput.value)"
                (keydown.escape)="close($any($event))"
                (keydown.enter)="showAllResults($event)"
                (keydown.arrowup)="focusPreviousChild($any($event))"
                (keydown.tab)="focusNextChild($any($event))"
                (keydown.arrowdown)="focusNextChild($any($event))"
                value="{{ chosenWord }}" />

            <div #spinnerDiv class="d-none search-spinner">
                <aimo-spinner class="spinner-xsm" [isModal]="false"></aimo-spinner>
            </div>

            <button
                [attr.aria-label]="'common.reset' | cxTranslate"
                (mousedown)="clearAndClose($event)"
                (keydown.enter)="clearAndClose($event)"
                class="reset">
                <i class="aimo-icon icon-x"></i>
            </button>

            <button [attr.aria-label]="'common.search' | cxTranslate" class="search" (click)="open()">
                <i class="aimo-icon icon-md icon-magnifying-glass icon-petrol-blue"></i>
            </button>
        </label>
    </div>

    <ng-container *ngIf="searchInput.value?.length >= 2">
        <ng-container *ngIf="results$ | async as result">
            <ng-container *ngIf="result?.products">
                <aimo-cx-searchbox-result
                    [baseOrder]="baseOrder"
                    [cartId]="cartId"
                    [isCartSearchBox]="isCartSearchBox"
                    [isMobile]="isMobile"
                    [setResult]="result"
                    [config]="config"
                    [setCart]="cart"
                    [cartSearch]="cartSearch"
                    [orderTemplate]="orderTemplate"
                    [searchInputValue]="searchInput.value"
                    (onScroll)="onScrollEvent($event)"
                    (closeSearchResult)="onlyClear(); close($event)"
                    (onShowAll)="showAllResults($any($event))"></aimo-cx-searchbox-result>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
