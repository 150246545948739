import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductSearchService } from '@spartacus/core';
import { StateWithProduct } from '@spartacus/core/src/product/store/product-state';

import { AimoSearchConfig } from '../../../model/misc.model';

import {
    AimoCloseQuickSearchProducts,
    AimoQuickSearchProducts,
    AimoSearchProducts,
} from './aimo-product-search.action';

@Injectable({
    providedIn: 'root',
})
export class AimoProductSearchService extends ProductSearchService {
    constructor(private store2: Store<StateWithProduct>) {
        super(store2);
    }

    search(query: string, searchConfig?: AimoSearchConfig, auxiliary?: boolean): void {
        this.store.dispatch(
            searchConfig.suggestiveMode
                ? new AimoQuickSearchProducts(
                      {
                          queryText: query,
                          searchConfig,
                      },
                      searchConfig.origin,
                      auxiliary,
                  )
                : new AimoSearchProducts(
                      {
                          queryText: query,
                          searchConfig,
                      },
                      searchConfig.origin,
                      auxiliary,
                  ),
        );
    }

    closeQuickSearch(): void {
        this.store2.dispatch(new AimoCloseQuickSearchProducts());
    }
}
