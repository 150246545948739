import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AimoBaseOrder } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';

import { AimoBaseOrderDialogData } from './aimo-base-order-layout.config';

@Component({
    selector: 'aimo-base-order-block-remove-modal',
    templateUrl: './aimo-base-order-block-remove-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBaseOrderBlockRemoveModalComponent implements OnDestroy {
    subscription: Subscription = new Subscription();
    existingBaseOrder: AimoBaseOrder;
    submitClicked = false;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
    ) {
        this.launchDialogService.data$.pipe(take(1)).subscribe((dialogData: AimoBaseOrderDialogData) => {
            this.existingBaseOrder = dialogData.existingBaseOrder;
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.BLOCK_REMOVE_BASE_ORDER);
        this.launchDialogService.closeDialog('closed');
    }

    submit(): void {
        this.submitClicked = true;
        this.subscription.add(
            this.activeCartService
                .saveBaseOrder(
                    {
                        ...this.existingBaseOrder,
                        blocks: [],
                    },
                    true,
                )
                .pipe(take(1))
                .subscribe(() => this.closeDialog()),
        );
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event: Event): void {
        event?.preventDefault();
        this.closeButton.nativeElement.focus();
    }
}
