import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

import { AimoGenericLinkModule } from '../../../shared/generic-link/aimo-generic-link.module';

import { AimoNavigationUiComponent } from './aimo-navigation-ui.component';

@NgModule({
    declarations: [AimoNavigationUiComponent],
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                NavigationUIComponent: {
                    component: AimoNavigationUiComponent,
                },
            },
        }),
        IconModule,
        I18nModule,
        AimoGenericLinkModule,
    ],
    exports: [AimoNavigationUiComponent],
})
export class AimoNavigationUiModule {}
