<ng-container *ngIf="orders$ | async as orderHistory">
    <h1 class="page-title noPrint" tabindex="0" [attr.aria-label]="'aimo.orderhistory.title' | cxTranslate">
        {{ 'aimo.orderhistory.title' | cxTranslate }}
    </h1>
    <div class="noPrint">
        <ng-container
            *ngTemplateOutlet="
                notification;
                context: {
                    level: 'INFO',
                    textKey: 'aimo.orderhistory.' + (isPikatukku() ? 'pikatukku' : 'toimitustukku') + '.description'
                }
            "></ng-container>
        <ng-container *ngFor="let order of getUnPaidOrders(orderHistory)">
            <ng-container
                *ngTemplateOutlet="
                    notification;
                    context: {
                        level: 'ERROR',
                        order: order,
                        textKey: 'aimo.orderhistory.unPaidOrder',
                        textKeyLink: 'aimo.orderhistory.unPaidOrderLink'
                    }
                "></ng-container>
        </ng-container>
    </div>

    <div class="row cx-search-criteria noPrint" [formGroup]="searchForm">
        <div class="col-md-8 col-12 cx-search-criteria-find">
            <aimo-cx-input
                (afterChange)="fetchOrders()"
                [placeholder]="'aimo.orderhistory.search'"
                [parentForm]="searchForm"
                [nameForm]="'search'"
                [inputIcon]="'icon-magnifying-glass icon-petrol-blue'"></aimo-cx-input>
        </div>
        <div class="col-md-4 col-12 range-dates pt-2 pt-md-0">
            <aimo-datepicker-input
                class="date-from"
                [idName]="'delivery_date_from'"
                [selectedDate]="convertDate(orderHistory.searchFrom)"
                (afterChange)="fetchOrders()"
                [placeholder]="'aimo.orderhistory.dateFrom' | cxTranslate"
                [parentForm]="searchForm"
                [customDayTemplate]="customDay"
                [nameForm]="'from'"></aimo-datepicker-input>
            <span class="date-divider"> - </span>
            <aimo-datepicker-input
                class="date-to"
                [idName]="'delivery_date_to'"
                (afterChange)="fetchOrders()"
                [placeholder]="'aimo.orderhistory.dateTo' | cxTranslate"
                [parentForm]="searchForm"
                [customDayTemplate]="customDay"
                [selectedDate]="convertDate(orderHistory.searchTo)"
                [nameForm]="'to'"></aimo-datepicker-input>
        </div>
    </div>

    <ng-template
        #customDay
        let-date
        let-currentMonth="currentMonth"
        let-today="today"
        let-selected="selected"
        let-disabled="disabled"
        let-focused="focused">
        <button
            class="calendar-cell calendar-day link"
            [ngClass]="{ focused: focused, selected: selected, disabled: disabled }">
            {{ date.day }}
        </button>
    </ng-template>

    <div class="row cx-facets noPrint">
        <div class="col-md-10 col-12">
            <ng-container *ngFor="let facet of orderHistory.facets">
                <div class="aimo-facet" *ngIf="facet.values.length > 1">
                    <button
                        class="heading btn-facet"
                        [ngClass]="{ selected: facets.length === 0 }"
                        (click)="toggleFacet(null, null); pushGtmEvent('orderhistory-facets')">
                        {{ 'aimo.orderhistory.allStatuses' | cxTranslate }}
                        <span class="amount">{{ getTotalOrders(orderHistory.facets) }}</span>
                    </button>
                </div>
                <ng-container *ngFor="let facetValue of facet.values">
                    <div class="aimo-facet" *ngIf="facetValue.count > 0">
                        <button
                            class="heading btn-facet"
                            [ngClass]="{ selected: facetValue.selected }"
                            (click)="toggleFacet(facet, facetValue); pushGtmEvent('orderhistory-facets')">
                            {{ 'aimo.orderhistory.' + facet.code + '.' + facetValue.name | cxTranslate }}
                            <span class="amount">{{ facetValue.count }}</span>
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
        <div class="col-2 text-right d-none d-md-block">
            {{
                'aimo.orderhistory.totalResults' | cxTranslate : { totalResults: orderHistory.pagination.totalResults }
            }}
        </div>
    </div>

    <ng-container *ngFor="let day of orderHistory.dayGroupedOrders">
        <aimo-order-history-day
            [day]="day"
            [open]="orderHistory.dayGroupedOrders.length === 1"></aimo-order-history-day>
    </ng-container>
</ng-container>

<ng-template #notification let-level="level" let-order="order" let-textKey="textKey" let-textKeyLink="textKeyLink">
    <div
        class="notification"
        [ngClass]="level === 'INFO' ? 'notification-MESSAGE' : 'notification-' + level"
        *ngIf="!isMessageHidden('aimo.orderhistory.description', order?.code)">
        <div class="left-side">
            <i class="aimo-icon icon-inline icon-{{ level }} mr-2"></i>
        </div>
        <div class="d-inline text-sm middle">
            <span
                [innerHTML]="textKey | cxTranslate : { order: order?.code }"
                [ngClass]="{ 'mr-1': order?.code }"></span>
            <span
                *ngIf="order?.code"
                class="cursor-pointer text-underline link"
                [innerHTML]="textKeyLink | cxTranslate : { order: order?.code }"
                (click)="payNow(order?.code)"></span>
        </div>
        <div class="right-side">
            <button class="btn-link" (click)="hideMessage('aimo.orderhistory.description', order?.code)">
                <i class="aimo-icon icon-petrol-blue icon-inline icon-x float-right"></i>
            </button>
        </div>
    </div>
</ng-template>
