import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';

import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGTMProductAttributes, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';
import { AimoProductService } from '../../../service/product/aimo-product.service';

@Component({
    selector: 'aimo-order-history-order-entry',
    templateUrl: './aimo-order-history-order-entry.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistoryOrderEntryComponent {
    @Input() order: AimoCart;
    @Input() entry: AimoOrderEntry;
    @Input() isOrderHistory = false;
    @Input() index: number = 0;

    constructor(
        protected currentProductService: CurrentProductService,
        protected productService: AimoProductService,
        protected activeCartService: AimoActiveCartService,
        protected translation: TranslationService,
    ) {}

    openProductModal(sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            this.entry.product.code,
            this.createGtmAttributes(),
            null,
            null,
            null,
            sourceId,
        );
    }

    createGtmAttributes(): AimoGTMProductAttributes {
        return {
            index: this.index,
            item_list_id: GTMItemListId.order_history,
        } as AimoGTMProductAttributes;
    }

    downloadDeliveryNote(invoiceId: string, name: string): void {
        this.translation
            .translate(name, { id: invoiceId })
            .subscribe((t) => this.activeCartService.downloadOrderDocument(invoiceId, t, true))
            .unsubscribe();
    }
}
