import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoCMSBulletinHeaderComponent } from '../../model/cms.model';
import { AimoBulletin, AimoBulletinCategory } from '../../model/user.model';
import { AimoUserService } from '../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-bulletin-header-component',
    templateUrl: './aimo-bulletin-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBulletinHeaderComponent implements OnDestroy, OnInit {
    subscription: Subscription = new Subscription();

    data$: Observable<AimoCMSBulletinHeaderComponent>;
    categories$: Observable<AimoBulletinCategory[]>;
    allBulletins$: Observable<AimoBulletin[]>;
    selectedCategory: string = undefined;

    constructor(
        protected componentData: CmsComponentData<AimoCMSBulletinHeaderComponent>,
        protected activatedRoute: ActivatedRoute,
        protected cdr: ChangeDetectorRef,
        protected router: Router,
        protected userService: AimoUserService,
    ) {}

    ngOnInit(): void {
        const url = this.activatedRoute.snapshot.url;
        if (url.length > 1) {
            this.selectedCategory = url[1].path;
        }
        this.data$ = this.componentData.data$;
        this.allBulletins$ = this.userService.getActiveBulletinList(undefined).pipe(map((b) => b.bulletins));
        this.init();
    }

    init(): void {
        this.categories$ = this.allBulletins$.pipe(
            map((b) =>
                Array.from(
                    b.reduce(
                        (entryMap, e) => entryMap.set(e.category.code, [...(entryMap.get(e.category.code) || []), e]),
                        new Map(),
                    ),
                ).map((b) => ({
                    ...b[1][0].category,
                    amount: b[1].length,
                    selected: b[0] === this.selectedCategory,
                })),
            ),
        );
    }

    clickCategory(categoryCode?: string): void {
        this.selectedCategory = this.selectedCategory === categoryCode ? undefined : categoryCode;
        this.init();
        if (this.selectedCategory) {
            this.router.navigate(['/announcements/' + this.selectedCategory]);
        } else {
            this.router.navigate(['/announcements']);
        }
    }

    isAllCategoriesSelected(): boolean {
        return this.selectedCategory === undefined;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
