import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, PageMetaModule, provideConfig } from '@spartacus/core';

import { AimoBreadcrumbComponent } from './aimo-breadcrumb.component';

@NgModule({
    imports: [CommonModule, RouterModule, PageMetaModule, I18nModule],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                BreadcrumbComponent: {
                    component: AimoBreadcrumbComponent,
                },
            },
        }),
    ],
    declarations: [AimoBreadcrumbComponent],
    exports: [AimoBreadcrumbComponent],
})
export class AimoBreadcrumbModule {}
