import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LanguageService, OCC_USER_ID_ANONYMOUS } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { DateUtils } from '../../shared/util/date-utils';
import { AimoUserService } from '../user/aimo-user.service';

import { AimoLoadDeliveryDate, AimoStateWithDeliveryDate, getDeliveryDateState } from './aimo-deliverydate.action';

@Injectable({
    providedIn: 'root',
})
export class AimoDeliveryDateService {
    private deliveryDate$: Observable<string>;

    constructor(
        protected store: Store<AimoStateWithDeliveryDate>,
        protected userService: AimoUserService,
        protected language: LanguageService,
    ) {
        this.deliveryDate$ = this.userService.get().pipe(
            //   filter((user) => user?.uid !== OCC_USER_ID_ANONYMOUS),
            switchMap((user) => {
                if (!user || user.uid === OCC_USER_ID_ANONYMOUS) {
                    return of(DateUtils.convertDateToOccString(this.language, new Date()));
                }
                return this.store.pipe(
                    select(getDeliveryDateState),
                    tap((details) => {
                        if (!details.loading) {
                            this.loadDeliveryDate(user.uid);
                        }
                    }),
                    map((details) => details.date),
                );
            }),
            filter((d) => d !== undefined && d !== null),
        );
    }

    /**
     * can't user cart.requestedDeliveryDate, because this will result to a new catalog search after each add to cart
     */
    getDeliveryDate(): Observable<string> {
        return this.deliveryDate$;
    }

    loadDeliveryDate(userId: string): void {
        this.store.dispatch(new AimoLoadDeliveryDate(userId, 'current'));
    }
}
