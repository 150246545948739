import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';

import { AimoSpinnerComponent } from './aimo-spinner.component';
import { AimoSpinnerService } from './aimo-spinner.service';

@NgModule({
    imports: [CommonModule, I18nModule],
    declarations: [AimoSpinnerComponent],
    exports: [AimoSpinnerComponent],
    providers: [
        {
            provide: AimoSpinnerService,
            useClass: AimoSpinnerService,
        },
    ],
})
export class AimoSpinnerModule {}
