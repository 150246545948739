<ng-container *ngFor="let child of node?.children">
    <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"></ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth">
    <nav
        #menu
        class="{{ node.uid }}"
        [attr.aria-expanded]="isOpened"
        [attr.aria-controls]="'navigation-wrapper-' + node.uid"
        [ngClass]="isActivePage(node.url) ? 'active' : ''"
        (click)="toggleOpen($event)"
        (keydown.space)="toggleOpen($event)"
        (keydown.esc)="back()"
        *ngIf="
            !(node.uid === 'StripNarrowNavNode' && !stripNarrowAvailable) &&
            (node.uid !== 'CampaignNavNode' || node.url) &&
            (node.uid !== 'CampaignsNavNode' || node.url) &&
            (node.uid !== 'FavoritesNavNode' || node.url) &&
            (node.uid !== 'MostPurchasedNavNode' || node.url) &&
            (node.uid !== 'BaseOrdersNavNode' || node.url)
        ">
        <aimo-cx-generic-link
            *ngIf="node.url && (!node.children || node.children?.length === 0); else heading"
            [url]="node.url"
            [target]="node.target"
            [style]="node.styleAttributes"
            [class]="node.styleClasses?.includes('aimo-icon') ? '' : node.styleClasses"
            [title]="node.title"
            (click)="closeIfClickedTheSameLink(node)">
            <i class="{{ node.styleClasses }}" *ngIf="node.styleClasses?.includes('aimo-icon')"></i>
            <span class="d-inline" id="nav-{{ node.uid }}">{{ node.title }}</span>
        </aimo-cx-generic-link>

        <ng-template #heading>
            <ng-container *ngIf="node.uid && node.uid === 'MyAccountNavNode'; else normalHeading">
                <div
                    role="button"
                    [attr.aria-label]="node.title"
                    [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
                    <i class="aimo-icon icon-sm icon-white icon-user mr-2 d-lg-inline-block d-none"></i>
                    <i class="aimo-icon icon-petrol-blue icon-user mr-2 d-inline-block d-lg-none"></i>
                    <ng-container *ngIf="customerName$ | async as customerName">
                        <ng-container *ngIf="customerId$ | async as customerId">
                            <span id="account-{{ customerId }}">{{ customerName }}</span>
                            <ng-container *ngIf="pendingApprovals$ | async as pendingApprovals">
                                <span
                                    class="quantity circle circle-sm bg-red text-white text-xsm text-bold ml-1"
                                    *ngIf="pendingApprovals > 0">
                                    {{ pendingApprovals }}
                                </span>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                    <i
                        class="aimo-icon icon-inline icon-sm icon-white icon-caret-down ml-2 d-lg-inline-block d-none"></i>
                    <i class="aimo-icon icon-inline icon-petrol-blue icon-caret-down ml-2 d-inline-block d-lg-none"></i>
                </div>
            </ng-container>
            <ng-template #normalHeading>
                <div class="heading-wrapper">
                    <h4
                        [attr.aria-label]="node.title"
                        class="text-lg"
                        [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
                        {{ node.title }}
                        <cx-icon [type]="iconType.CARET_RIGHT"></cx-icon>
                    </h4>
                </div>
            </ng-template>
        </ng-template>

        <!-- we add a wrapper to allow for better layout handling in CSS -->
        <div class="wrapper" id="navigation-wrapper-{{ node.uid }}" *ngIf="node.children?.length > 0">
            <div
                class="childs"
                [attr.depth]="getTotalDepth(node)"
                [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
                [attr.columns]="getColumnCount(node.children?.length)">
                <ng-container *ngFor="let child of node.children">
                    <ng-container *ngIf="child.url">
                        <ng-container
                            *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }"></ng-container>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </nav>
</ng-template>
