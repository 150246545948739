import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCartAdapter } from '@spartacus/cart/base/occ';
import { ConverterService, GlobalMessageService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { AimoCart } from '../../model/cart.model';
import { AimoUserService } from '../user/aimo-user.service';

@Injectable()
export class AimoCartAdapter extends OccCartAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpointsService: OccEndpointsService,
        protected converterService: ConverterService,
        protected userService: AimoUserService,
        protected globalMessage: GlobalMessageService,
    ) {
        super(http, occEndpointsService, converterService);
    }

    load(userId: string, cartId: string): Observable<AimoCart | undefined> {
        return super.load(userId, cartId);
    }
}
