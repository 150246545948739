import { Component, Input, OnInit } from '@angular/core';

import { AimoDeposit, AimoOrderEntry, PaymentMode } from '../../../../model/cart.model';

@Component({
    selector: 'aimo-cart-item-deposits',
    templateUrl: './aimo-cart-item-deposits.component.html',
})
export class AimoCartItemDepositsComponent implements OnInit {
    @Input() paymentMode: PaymentMode;
    @Input() entry: AimoOrderEntry;
    @Input() alreadyOrdered = false;

    taxRates: string;

    constructor() {}

    ngOnInit(): void {
        this.taxRates = this.getDepositTaxRates(this.entry.deposits);
    }

    private getDepositTaxRates(deposits: AimoDeposit): string {
        return deposits?.taxes?.map((tax) => tax.rate).join(', ');
    }
}
