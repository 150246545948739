import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ProductLoadingService, ProductService, StateWithProduct, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AimoClassification, AimoFeature, AimoFeatureValue, AimoProduct } from '../../model/product.model';
import { AimoActiveCartService } from '../cart/aimo-active-cart.service';
import { AimoGtmProduct, AimoViewGtmProducts } from '../gtm/aimo-gtm.action';
import { AimoGTMProductAttributes, GTMEventCode, GTMItemListId } from '../gtm/aimo-gtm.model';

import { AimoProductConnector } from './aimo-product.connector';
import { AimoProductSearchConnector } from './search/aimo-product-search.connector';

export const CROSSSELING_PRODUCTS = 'CROSSELLING';
export const SIMILAR_PRODUCTS = 'SIMILAR';

@Injectable({
    providedIn: 'root',
})
export class AimoProductService extends ProductService {
    constructor(
        protected store: Store<StateWithProduct>,
        productLoading: ProductLoadingService,
        protected productSearchConnector: AimoProductSearchConnector,
        protected productConnector: AimoProductConnector,
        protected userIdService: UserIdService,
    ) {
        super(store, productLoading);
    }

    // eslint-disable-next-line
    getAimoProduct(
        productCode: string,
        gtmProductAttributes?: AimoGTMProductAttributes,
    ): Observable<AimoProduct | undefined> {
        return this.productConnector.getAimoProduct(
            productCode,
            gtmProductAttributes?.category_code,
            gtmProductAttributes?.search_term,
            gtmProductAttributes?.klevuParams,
        );
    }

    getProducts(products: string[]): Observable<AimoProduct[]> {
        return this.userIdService
            .getUserId()
            .pipe(switchMap((userId) => this.productSearchConnector.loadManyProducts(userId, products)));
    }

    getNutritionalContent(product: AimoProduct, code: string): AimoFeature {
        return this.getClassificationClass(product, 'nutritionalContent')?.features.find((nc) =>
            nc.code.endsWith('.' + code.toLowerCase()),
        );
    }

    getClassificationClass(product: AimoProduct, code: string): AimoClassification {
        return product?.classifications?.find((cl) => cl.code === code);
    }

    getClassification(product: AimoProduct, code: string): AimoFeature[] {
        return product?.classifications
            ?.map((c) => c.features)
            .reduce((x, y) => x.concat(y), [])
            .filter((f) => f.code.endsWith('.' + code.toLowerCase()));
    }

    getClassificationValueList(product: AimoProduct, code: string): string {
        return this.getClassification(product, code)
            ?.flatMap((value) => value.featureValues)
            .map((value) => value.value)
            .join(', ');
    }

    getClassificationNameList(product: AimoProduct, code: string): string {
        return this.getClassification(product, code)
            ?.flatMap((value) => value.featureValues)
            .map((value) => value.name)
            .join(', ');
    }

    getClassificationName(product: AimoProduct, code: string): string {
        return this.getClassification(product, code)
            ?.map((value) => value.name)
            .find((v) => v == v);
    }

    getClassificationValueName(product: AimoProduct, code: string): string {
        return this.getClassification(product, code)
            ?.map((value) => value.featureValues[0].name)
            .find((v) => v == v);
    }

    getClassificationValue(product: AimoProduct, code: string): AimoFeatureValue {
        return this.getClassification(product, code)
            ?.map((value) => value.featureValues[0])
            .find((v) => v == v);
    }

    getClassificationUnit(product: AimoProduct, code: string): string {
        return this.getClassification(product, code)
            ?.map((value) => value.featureUnit?.symbol)
            .find((v) => v == v);
    }

    excelExportProducts(query: string): void {
        const sub = this.userIdService
            .getUserId()
            .pipe(switchMap((userId) => this.productSearchConnector.excelExportProducts(query, { userId })))
            .subscribe((response) => {
                AimoActiveCartService.exportAsBinary(response);
                sub?.unsubscribe();
            });
    }

    fireProductListViewGtmEvent(products: AimoProduct[], itemListId: GTMItemListId): void {
        this.store.dispatch(new AimoViewGtmProducts(products, GTMEventCode.ViewItemList, itemListId));
    }

    fireSingleProductGtmEvent(
        product: AimoProduct,
        event: GTMEventCode,
        gtmProductAttributes: AimoGTMProductAttributes,
        fromAlternativeProducts?: boolean,
    ): void {
        this.store.dispatch(new AimoGtmProduct(product, event, gtmProductAttributes));
        if (fromAlternativeProducts) {
            this.store.dispatch(
                new AimoGtmProduct(
                    product,
                    GTMEventCode.ViewAlternativeProducts,
                    gtmProductAttributes,
                    this.getSimilarProducts(product),
                ),
            );
        }
    }

    getSimilarProducts(product: AimoProduct): AimoProduct[] {
        return product.productReferences[SIMILAR_PRODUCTS]?.map((r) => r.target);
    }

    getCrossSellingProducts(product: AimoProduct): AimoProduct[] {
        return product.productReferences[CROSSSELING_PRODUCTS]?.map((r) => r.target);
    }
}
