<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>

<div class="reset-content-sm">
    <h2>{{ 'resetPassword.title' | cxTranslate }}</h2>

    <form (ngSubmit)="onSubmit(token)" [formGroup]="form">
        <div class="field-content pt-2">
            <aimo-cx-input
                [label]="'loginForm.password.label'"
                [nameForm]="'password'"
                [parentForm]="form"
                [typeInput]="'password'"
                [labelClass]="'text-bold'"
                idName="password"></aimo-cx-input>
        </div>

        <aimo-password-checks [value]="form.get('password').value"></aimo-password-checks>

        <div class="field-content mb-4">
            <aimo-cx-input
                [label]="'resetPassword.confirmPassword'"
                [nameForm]="'passwordConfirm'"
                [parentForm]="form"
                [typeInput]="'password'"
                [labelClass]="'text-bold'"
                idName="passwordConfirm"></aimo-cx-input>
            <cx-form-errors
                [control]="form.get('passwordConfirm')"
                aria-atomic="true"
                aria-live="assertive"></cx-form-errors>
        </div>

        <div class="pt-2">
            <button type="submit" class="btn btn-primary" [disabled]="form.disabled || !token">
                {{ 'common.save' | cxTranslate }}
            </button>
        </div>

        <div class="re-captcha mt-4">
            <p innerHTML="{{ 'forgottenPassword.recaptcha' | cxTranslate }}"></p>
        </div>
    </form>
</div>
