import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';

import { AimoItemCounterModule } from '../../../shared/item-counter/aimo-item-counter.module';

import { AimoAddToCartComponent } from './aimo-add-to-cart.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, I18nModule, AimoItemCounterModule],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                ProductAddToCartComponent: {
                    component: AimoAddToCartComponent,
                    data: {
                        inventoryDisplay: false,
                    },
                },
            },
        }),
    ],
    declarations: [AimoAddToCartComponent],
    exports: [AimoAddToCartComponent],
})
export class AimoAddToCartModule {}
