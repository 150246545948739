import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import {
    CmsActivatedRouteSnapshot,
    CmsService,
    OCC_USER_ID_ANONYMOUS,
    Page,
    PageContext,
    RoutingService,
    SemanticPathService,
    UserIdService,
} from '@spartacus/core';
import {
    CmsComponentsService,
    CmsGuardsService,
    CmsI18nService,
    CmsPageGuardService,
    CmsRoutesService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AimoUser } from '../../model/user.model';
import { AimoUserAccountService } from '../user/aimo-user-account.service';

@Injectable({
    providedIn: 'root',
})
export class AimoCmsPageGuardService extends CmsPageGuardService {
    constructor(
        protected semanticPathService: SemanticPathService,
        protected cmsService: CmsService,
        protected cmsRoutes: CmsRoutesService,
        protected cmsI18n: CmsI18nService,
        protected cmsGuards: CmsGuardsService,
        protected cmsComponentsService: CmsComponentsService,
        protected routing: RoutingService,
        protected userAccountService: AimoUserAccountService,
        protected userIdService: UserIdService,
    ) {
        super(semanticPathService, cmsService, cmsRoutes, cmsI18n, cmsGuards, cmsComponentsService, routing);
    }

    canActivatePage(
        pageContext: PageContext,
        pageData: Page,
        route: CmsActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> {
        return super
            .canActivatePage(pageContext, pageData, route, state)
            .pipe(
                switchMap((result) =>
                    this.userAccountService.get().pipe(map((user) => result && this.checkConsents(pageData, user))),
                ),
            );
    }

    private checkConsents(pageData: Page, user: AimoUser): boolean {
        if (pageData.label !== '/consents') {
            return AimoCmsPageGuardService.openConsentPageIfNeeded(user, this.routing);
        }
        return true;
    }

    public static openConsentPageIfNeeded(user: AimoUser, routing: RoutingService): boolean {
        if (user && user.uid !== OCC_USER_ID_ANONYMOUS && !(user as AimoUser).consentApproved) {
            routing.go('/consents');
            return false;
        }
        return true;
    }
}
