<div class="messages" *ngIf="messages$ | async as messages">
    <div class="message message-ERROR" *ngFor="let errorMsg of messages[messageType.MSG_TYPE_ERROR]; let i = index">
        <div class="container">
            <i class="aimo-icon icon-inline icon-ERROR mr-2"></i>
            <div class="d-inline" [innerHTML]="errorMsg | cxTranslate | safeHtml"></div>
            <i
                class="aimo-icon icon-inline icon-x icon-sm ml-auto icon-petrol-blue icon-cursor"
                aria-label="{{ 'common.close' | cxTranslate }}"
                (click)="clear(messageType.MSG_TYPE_ERROR, i)"></i>
        </div>
    </div>

    <div class="alert alert-success" *ngFor="let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION]; let i = index">
        <div class="container">
            <div class="left-side">
                <i class="aimo-icon icon-inline icon-check-circle mr-2"></i>
                <span [innerHTML]="confMsg | cxTranslate | safeHtml"></span>
            </div>
            <i
                class="aimo-icon icon-inline icon-x icon-sm ml-auto icon-petrol-blue icon-cursor"
                aria-label="{{ 'common.close' | cxTranslate }}"
                (click)="clear(messageType.MSG_TYPE_CONFIRMATION, i)"></i>
        </div>
    </div>

    <div class="message message-INFO" *ngFor="let infoMsg of messages[messageType.MSG_TYPE_INFO]; let i = index">
        <div class="container">
            <i class="aimo-icon icon-inline icon-INFO mr-2"></i>
            <div class="d-inline" [innerHTML]="infoMsg | cxTranslate | safeHtml"></div>
            <i
                class="aimo-icon icon-inline icon-x icon-sm ml-auto icon-petrol-blue icon-cursor"
                aria-label="{{ 'common.close' | cxTranslate }}"
                (click)="clear(messageType.MSG_TYPE_INFO, i)"></i>
        </div>
    </div>

    <div class="message message-WARNING" *ngFor="let infoMsg of messages[messageType.MSG_TYPE_WARNING]; let i = index">
        <div class="container">
            <i class="aimo-icon icon-inline icon-WARNING mr-2"></i>
            <div class="d-inline" [innerHTML]="infoMsg | cxTranslate | safeHtml"></div>
            <i
                class="aimo-icon icon-inline icon-x icon-sm ml-auto icon-petrol-blue icon-cursor"
                aria-label="{{ 'common.close' | cxTranslate }}"
                (click)="clear(messageType.MSG_TYPE_WARNING, i)"></i>
        </div>
    </div>
</div>
