<ul class="list-unstyled mt-3 text-sm">
    <li class="mb-1">
        <i
            class="aimo-icon icon-inline mr-2"
            [ngClass]="
                checkRequirements(passwordRequirementType.MINIMUMCHARACTERS)
                    ? 'icon-check icon-summer-green'
                    : 'icon-minus icon-dark-grey'
            "></i>
        <p class="d-inline">{{ 'resetPassword.requirement.characters' | cxTranslate }}</p>
    </li>
    <li class="mb-1">
        <i
            class="aimo-icon icon-inline mr-2"
            [ngClass]="
                checkRequirements(passwordRequirementType.UPPERCASE)
                    ? 'icon-check icon-summer-green'
                    : 'icon-minus icon-dark-grey'
            "></i>
        <p class="d-inline">{{ 'resetPassword.requirement.capitalLetter' | cxTranslate }}</p>
    </li>
    <li class="mb-1">
        <i
            class="aimo-icon icon-inline mr-2"
            [ngClass]="
                checkRequirements(passwordRequirementType.LOWERCASE)
                    ? 'icon-check icon-summer-green'
                    : 'icon-minus icon-dark-grey'
            "></i>
        <p class="d-inline">{{ 'resetPassword.requirement.smallLetter' | cxTranslate }}</p>
    </li>
    <li>
        <i
            class="aimo-icon icon-inline mr-2"
            [ngClass]="
                checkRequirements(passwordRequirementType.NUMBER)
                    ? 'icon-check icon-summer-green'
                    : 'icon-minus icon-dark-grey'
            "></i>
        <p class="d-inline">{{ 'resetPassword.requirement.number' | cxTranslate }}</p>
    </li>
</ul>
