<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">{{ 'aimo.cart.itemMessages.title' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div [formGroup]="form" class="row">
                <div class="col-12 mb-3">
                    <aimo-cx-input
                        *ngIf="editable"
                        [autofocus]="true"
                        [isTextArea]="true"
                        [inputClass]="'input-sm'"
                        [labelClass]="'text-bold'"
                        [cols]="50"
                        [rows]="3"
                        [label]="'aimo.cart.itemMessages.messageToCustomer'"
                        [parentForm]="form"
                        [nameForm]="'messageToCustomer'"
                        idName="messageToCustomer">
                    </aimo-cx-input>

                    <div *ngIf="!editable">
                        {{ entry.messageToCustomer }}
                    </div>
                </div>

                <div class="col-12">
                    <aimo-cx-input
                        *ngIf="editable"
                        [isTextArea]="true"
                        [inputClass]="'input-sm'"
                        [labelClass]="'text-bold'"
                        [cols]="50"
                        [rows]="3"
                        [label]="'aimo.cart.itemMessages.messageToPickup'"
                        [parentForm]="form"
                        [nameForm]="'messageToPickup'"
                        idName="messageToPickup">
                    </aimo-cx-input>

                    <div *ngIf="!editable">
                        {{ entry.messageToPickup }}
                    </div>
                </div>
            </div>
            <div class="modal-actions flex-start">
                <button *ngIf="editable" class="btn btn-primary btn-sm btn-inline mr-2" (click)="updateEntry()">
                    {{ 'aimo.cart.itemMessages.submit' | cxTranslate }}
                </button>
                <button
                    [attr.aria-label]="'common.cancel' | cxTranslate"
                    class="btn btn-secondary btn-sm btn-inline"
                    (click)="closeDialog()">
                    {{ 'common.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
