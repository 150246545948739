import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CmsConfig, FeaturesConfigModule, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { CardModule, FormErrorsModule, PromotionsModule, PwaModule } from '@spartacus/storefront';

import { AimoCartDetailsModule } from '../cart-details/aimo-cart-details.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';

import { AimoOrderConfirmationComponent } from './aimo-order-confirmation.component';

@NgModule({
    imports: [
        CommonModule,
        CardModule,
        PwaModule,
        PromotionsModule,
        I18nModule,
        ReactiveFormsModule,
        FeaturesConfigModule,
        FormErrorsModule,
        AimoCartDetailsModule,
        AimoCartSharedModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                OrderConfirmationThankMessageComponent: {
                    component: AimoOrderConfirmationComponent,
                },
                AccountOrderDetailsItemsComponent: {
                    component: AimoOrderConfirmationComponent,
                },
            },
        }),
    ],
    declarations: [AimoOrderConfirmationComponent],
    exports: [AimoOrderConfirmationComponent],
})
export class AimoOrderConfirmationModule {}
