import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { AimoSpinnerModule } from '../../shared/spinner/aimo-spinner.module';

import { AimoDeliveryDateComponent } from './aimo-delivery-date.component';

@NgModule({
    imports: [
        I18nModule,
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                DeliveryDateComponent: {
                    component: AimoDeliveryDateComponent,
                },
            },
        }),
        NgbPopoverModule,
        AimoSpinnerModule,
    ],
    declarations: [AimoDeliveryDateComponent],
    entryComponents: [AimoDeliveryDateComponent],
    exports: [AimoDeliveryDateComponent],
})
export class AimoDeliveryDateModule {}
