<div id="main-navigation" class="main-navigation" *ngIf="mainCategories$ | async as mainCategories">
    <ng-container *ngIf="navigationNode$ | async as navigationNode">
        <ng-container *ngIf="navigationNode.children?.length > 0">
            <ng-container *ngFor="let child of navigationNode.children">
                <ng-container *ngFor="let childnode of child.children">
                    <nav #navChildren *ngIf="nodeVisible(childnode)">
                        <a
                            [attr.aria-expanded]="navigationOpened"
                            [attr.aria-controls]="'wrapper_' + childnode.uid"
                            [tabIndex]="0"
                            role="button"
                            [attr.aria-label]="childnode.title"
                            id="{{ childnode.uid }}"
                            aimoClickOutside
                            (clickOutsideEvent)="clickout(navChildren)"
                            class="main-title d-lg-block d-none"
                            (click)="openNavigationUI(navChildren)">
                            {{ childnode.title }}
                        </a>

                        <a
                            [attr.aria-expanded]="navigationOpened"
                            [attr.aria-controls]="'wrapper_' + childnode.uid"
                            id="mobile-{{ childnode.uid }}"
                            [tabIndex]="0"
                            role="button"
                            [attr.aria-label]="childnode.title"
                            #mainTitle
                            class="main-title main-title-mobile d-block d-lg-none"
                            (click)="hideShowMainTitle(mainTitle, navChildren)">
                            <i class="aimo-icon icon-petrol-blue icon-caret-left"></i>
                            {{ childnode.title }}
                            <i class="aimo-icon icon-petrol-blue icon-caret-right"></i>
                        </a>

                        <a
                            id="{{ lastSelectedCategoryName }}"
                            #categoryTitle
                            class="main-title d-none d-lg-none"
                            (click)="hideShowChildCategory(categoryTitle, mainTitle, navChildren)">
                            <i class="aimo-icon icon-petrol-blue icon-caret-left"></i>
                            {{ lastSelectedCategoryName }}
                            <i class="aimo-icon icon-petrol-blue icon-caret-right"></i>
                        </a>

                        <div
                            [id]="'wrapper_' + childnode.uid"
                            class="wrapper"
                            *ngIf="childnode.children?.length > 0"
                            [ngClass]="
                                childnode.uid && childnode.uid === 'ProductsNavNode'
                                    ? 'wrapper-products wrapper-md'
                                    : 'wrapper-sm'
                            ">
                            <ng-container *ngIf="childnode.uid && childnode.uid === 'ProductsNavNode'; else normalMenu">
                                <div class="main-categories">
                                    <aimo-cx-navigation-ui [node]="childnode"></aimo-cx-navigation-ui>

                                    <ul>
                                        <li
                                            [ngClass]="{ active: lastSelectedCategory === mainCat.id }"
                                            *ngFor="let mainCat of mainCategories.subcategories"
                                            (mouseover)="
                                                toggleChildCategory(
                                                    mainCat.id,
                                                    mainCat.name,
                                                    mainCat.totalNumber,
                                                    mainCat.url,
                                                    categoryTitle,
                                                    mainTitle,
                                                    navChildren,
                                                    $event
                                                )
                                            ">
                                            <a
                                                [attr.aria-expanded]="lastSelectedCategory === mainCat.id"
                                                [attr.aria-controls]="'child_categories'"
                                                [tabIndex]="0"
                                                role="button"
                                                [attr.aria-label]="mainCat.name + ' ' + mainCat.totalNumber"
                                                class="category"
                                                (click)="closeIfClickedTheSameLink(mainCat.url)"
                                                [routerLink]="mainCat.url">
                                                {{ mainCat.name }}
                                                <span class="amount">{{ mainCat.totalNumber }}</span>
                                            </a>
                                            <a
                                                [attr.aria-expanded]="lastSelectedCategory === mainCat.id"
                                                [attr.aria-controls]="'child_categories'"
                                                [tabIndex]="0"
                                                role="button"
                                                [attr.aria-label]=""
                                                class="btn-link pl-2"
                                                (click)="
                                                    toggleChildCategory(
                                                        mainCat.id,
                                                        mainCat.name,
                                                        mainCat.totalNumber,
                                                        mainCat.url,
                                                        categoryTitle,
                                                        mainTitle,
                                                        navChildren,
                                                        $event
                                                    )
                                                ">
                                                <i class="aimo-icon icon-inline icon-caret-right"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div
                                    class="child-categories"
                                    [id]="'child_categories'"
                                    *ngIf="childCategories$ && childCategories$ | async as childCategories">
                                    <ul>
                                        <li>
                                            <a
                                                [tabIndex]="0"
                                                role="button"
                                                [attr.aria-label]="
                                                    ('aimo.product.allCategories' | cxTranslate) +
                                                    ' ' +
                                                    lastSelectedCategoryName?.toLowerCase() +
                                                    ' ' +
                                                    lastSelectedCategoryTotal
                                                "
                                                class="category category-all"
                                                (click)="closeIfClickedTheSameLink(lastSelectedCategoryUrl)"
                                                [routerLink]="lastSelectedCategoryUrl">
                                                {{
                                                    ('aimo.product.allCategories' | cxTranslate) +
                                                        ' ' +
                                                        lastSelectedCategoryName?.toLowerCase()
                                                }}
                                                <span class="amount">{{ lastSelectedCategoryTotal }}</span>
                                            </a>
                                        </li>
                                        <ng-container>
                                            <li *ngFor="let childCat of childCategories.subcategories">
                                                <a
                                                    [tabIndex]="0"
                                                    role="button"
                                                    [attr.aria-label]="childCat.name + ' ' + childCat.totalNumber"
                                                    class="category"
                                                    (click)="closeIfClickedTheSameLink(childCat.url)"
                                                    [routerLink]="childCat.url">
                                                    {{ childCat.name }}
                                                    <span class="amount">{{ childCat.totalNumber }}</span>
                                                </a>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </ng-container>

                            <ng-template #normalMenu>
                                <aimo-cx-navigation-ui [node]="childnode"></aimo-cx-navigation-ui>
                            </ng-template>
                        </div>
                    </nav>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</div>
