import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoPipesModule } from '../utils/safe-html/aimo-pipes.module';

import { AimoContactItemComponent } from './aimo-contact-item.component';

@NgModule({
    imports: [CommonModule, RouterModule, IconModule, MediaModule, UrlModule, I18nModule, AimoPipesModule],
    declarations: [AimoContactItemComponent],
    exports: [AimoContactItemComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoContactItemComponent: {
                    component: AimoContactItemComponent,
                },
            },
        }),
    ],
})
export class AimoContactItemModule {}
