import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';

import { AimoNotFoundComponent } from './aimo-not-found.component';

@NgModule({
    imports: [
        CommonModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                NotFoundPageComponent: {
                    component: AimoNotFoundComponent,
                },
            },
        }),
        I18nModule,
        RouterModule,
        PageSlotModule,
    ],
    declarations: [AimoNotFoundComponent],
    exports: [AimoNotFoundComponent],
})
export class AimoNotFoundModule {}
