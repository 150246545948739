import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoProductListModule } from '../../product/product-list/aimo-product-list.module';
import { AimoSpinnerModule } from '../spinner/aimo-spinner.module';

import { AimoCategoryListComponent } from './aimo-category-list.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        MediaModule,
        UrlModule,
        I18nModule,
        AimoProductListModule,
        AimoSpinnerModule,
    ],
    declarations: [AimoCategoryListComponent],
    exports: [AimoCategoryListComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoProductCategoryComponent: {
                    component: AimoCategoryListComponent,
                },
            },
        }),
    ],
})
export class AimoCategoryListModule {}
