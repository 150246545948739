<div class="my-assortment-menu form-check form-check-inv" *ngIf="show$ | async">
    <label for="myAssortmentCheckbox">
        <input id="myAssortmentCheckbox" type="checkbox" [checked]="selected" (click)="toggleSelection()" />
        <i
            class="aimo-icon icon-inline icon-sm icon-white icon-house-line-bold mr-2 d-lg-inline-block d-none"
            placement="bottom"
            container="body"
            triggers="mouseenter:mouseleave"
            [ngbPopover]="'aimo.product.myAssortmentHeader' | cxTranslate"
            popoverClass="popover-md"></i>
        <i class="aimo-icon icon-inline icon-petrol-blue icon-house-line mr-2 d-lg-none d-inline-block"></i>
        <span>{{ 'aimo.user.myAssortment' | cxTranslate }}</span>
    </label>
</div>
