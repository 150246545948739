import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { ActiveFacetsModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';

import { AimoActiveFacetsComponent } from './aimo-active-facets.component';

@NgModule({
    imports: [CommonModule, RouterModule, I18nModule, IconModule, KeyboardFocusModule],
    declarations: [AimoActiveFacetsComponent],
    exports: [AimoActiveFacetsComponent],
})
export class AimoActiveFacetsModule extends ActiveFacetsModule {}
