import {Router} from '@angular/router';

import {AimoRoutingConfig} from '../../model/cms.model';

export const aimoRoutingConfig: AimoRoutingConfig = {
    routing: {
        protected: true,
        routes: {
            orderConfirmation: {
                paths: ['order-confirmation/:code'],
                paramsMapping: {code: 'code'},
            },
            welcome: {
                paths: ['welcome'],
                protected: false,
                authFlow: true,
            },
            resetPassword: {
                paths: ['login/reset-password'],
                protected: false,
                authFlow: true,
            },
            orders: {
                paths: ['orders'],
            },
            cookieDeclaration: {
                paths: ['cookie-declaration'],
                protected: false,
            },
            termsOfSale: {
                paths: ['terms-of-sale'],
                protected: false,
            },
            termsOfUse: {
                paths: ['terms-of-use'],
                protected: false,
            },
            cookies: {
                paths: ['cookies'],
                protected: false,
            },
            contact: {
                paths: ['contact'],
                protected: false,
            },
            faq: {
                paths: ['faq'],
                protected: false,
            },
            cart: {
                paths: ['cart'],
                protected: true,
                authFlow: true,
                allowPikatukku: true,
            },
            home: {
                paths: ['', '/'],
                protected: true,
                authFlow: true,
                allowPikatukku: true,
            },
            search: {
                paths: ['search/:query'],
                allowPikatukku: true,
            },
            announcements: {
                paths: ['announcements'],
                allowPikatukku: true,
            },
            announcement: {
                paths: ['announcement/:code'],
                allowPikatukku: true,
            },
            cmsAnnouncement: {
                paths: ['cms-announcement/:code'],
                allowPikatukku: true,
            },
            searchPage: {
                paths: ['search'],
                allowPikatukku: true,
            },
            category: {
                paths: ['category/:categoryCode'],
                paramsMapping: {categoryCode: 'code'},
                allowPikatukku: true,
            },
            product: {
                paths: ['product/:productCode/:name'],
                paramsMapping: {productCode: 'code'},
                allowPikatukku: true,
                protected: true,
            },
        },
    },
};

// eslint-disable-next-line
export function adjustRouterConfig(router: Router): any {
    // eslint-disable-next-line
    const result = () => {
        // Same route can be 'reloaded'
        router.routeReuseStrategy.shouldReuseRoute = (): boolean => {
            return false;
        };
    };
    return result;
}
