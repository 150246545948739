import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, of, Subscription } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { AimoBaseOrder } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGtmService } from '../../../service/gtm/aimo-gtm.service';
import { SELECT_UNIT_SUCCESS } from '../../../service/user/aimo-user.action';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoOrderTemplateDeleteDialogData } from '../../order-template/order-template-modal/aimo-order-template-layout.config';
import { openCloseSpinner, toggleFunctions } from '../../shared/utils/spinner/aimo-spinner-utils';
import { AimoBaseOrderDialogData } from '../base-order-modal/aimo-base-order-layout.config';

@Component({
    selector: 'aimo-base-order-details',
    templateUrl: './aimo-base-order-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBaseOrderDetailsComponent implements OnDestroy, OnInit {
    subscription = new Subscription();

    @Input()
    baseOrderId: string;

    baseOrder$: Observable<AimoBaseOrder>;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected windowRef: WindowRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected launchDialogService: LaunchDialogService,
        protected cdr: ChangeDetectorRef,
        protected gtmService: AimoGtmService,
    ) {}

    ngOnInit(): void {
        openCloseSpinner(true);
        this.initBaseOrder(this.activeCartService.getBaseOrder(this.baseOrderId));
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                    const cart = (action as CartActions.LoadCartSuccess).payload.cart as AimoBaseOrder;
                    if (cart.code === this.baseOrderId) {
                        this.initBaseOrder(of(cart));
                        this.cdr.detectChanges();
                    }
                }
                if (action.type === SELECT_UNIT_SUCCESS) {
                    this.initBaseOrder(this.activeCartService.getBaseOrder(this.baseOrderId));
                    this.cdr.detectChanges();
                }
                if (action.type === CartActions.DELETE_CART_SUCCESS) {
                    this.routingService.go('baseorders');
                }
            }),
        );
        this.subscription.add(
            this.launchDialogService.dialogClose.subscribe((next) => {
                if (next !== 'deleted') {
                    this.loadBaseOrder();
                }
            }),
        );
    }

    loadBaseOrder(): void {
        this.initBaseOrder(this.activeCartService.getBaseOrder(this.baseOrderId));
        this.cdr.detectChanges();
    }

    private initBaseOrder(baseOrder: Observable<AimoBaseOrder>): void {
        this.baseOrder$ = baseOrder.pipe(
            tap(() => openCloseSpinner(false)),
            filter((ot) => ot !== undefined && ot !== null),
            map((ot) => ({
                ...ot,
                categoryGroupedEntries: ot.categoryGroupedEntries.map((cge) => ({
                    ...cge,
                    entries: cge.entries.map((e) => ({ ...e })),
                })),
            })),
        );
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    edit(baseOrder: AimoBaseOrder): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CREATE_BASE_ORDER, undefined, {
            existingBaseOrder: baseOrder,
        } as AimoBaseOrderDialogData);
    }

    exportToExcel(): void {
        this.activeCartService.exportCartToExcel(undefined, this.baseOrderId);
    }

    print(): void {
        this.windowRef.nativeWindow.print();
    }

    delete(baseOrder: AimoBaseOrder): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE_DELETE, undefined, {
            template: baseOrder,
            baseOrder: true,
        } as AimoOrderTemplateDeleteDialogData);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showMoreFunctions(showHide: HTMLElement): void {
        toggleFunctions(showHide, '.cart-functions', '.btn-link');
    }

    // eslint-disable-next-line
    getBreadcrumbs(baseOrder: AimoBaseOrder, orderTemplatesPage?: string): any[] {
        const breadcrumbs = [];
        breadcrumbs.push({ label: orderTemplatesPage, link: '/baseorders' });
        breadcrumbs.push({ label: baseOrder.name, link: baseOrder.code });

        return breadcrumbs;
    }

    setBlocks(baseOrder: AimoBaseOrder): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.BLOCK_BASE_ORDER, undefined, {
            existingBaseOrder: baseOrder,
        });
    }

    approve(): void {
        this.subscription.add(
            this.activeCartService
                .approveBaseOrder(this.baseOrderId, true)
                .pipe(take(1))
                .subscribe(() => this.loadBaseOrder()),
        );
    }

    continue(baseOrder: AimoBaseOrder): void {
        this.subscription.add(
            this.activeCartService
                .saveBaseOrder({ ...baseOrder, blocks: [] })
                .pipe(take(1))
                .subscribe(() => this.loadBaseOrder()),
        );
    }

    cancel(): void {
        this.subscription.add(
            this.activeCartService
                .approveBaseOrder(this.baseOrderId, false)
                .pipe(take(1))
                .subscribe(() => this.loadBaseOrder()),
        );
    }

    scrollTo(id: string): void {
        const productElement = this.windowRef.document.querySelector('#product_' + id);
        if (productElement) {
            productElement.scrollIntoView({ block: 'nearest' });
        } else {
            this.windowRef.document.querySelector('aimo-order-template-item-list').scrollIntoView({ block: 'nearest' });
        }
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}
