import { Action } from '@ngrx/store';
import { PageContext } from '@spartacus/core';

import { AimoB2BUnitOption, AimoRegistration, AimoUserRegistrationData } from '../../model/user.model';

export const CREDIT_CHECK_ACTION = '[AIMO Custom] Credit Check';
export const CREDIT_CHECK_COMPLETE_ACTION = '[AIMO Custom] Credit Check Complete';
export const TOGGLE_FAVOURITE_PRODUCT = '[AIMO Custom] Toggle Favourite product';
export const TOGGLE_FAVOURITE_PRODUCT_SUCCESS = '[AIMO Custom] Toggle Favourite product successs';
export const TOGGLE_FAVOURITE_PRODUCT_FAIL = '[AIMO Custom] Toggle Favourite product fail';

export class CreditCheckPerformAction implements Action {
    readonly type = CREDIT_CHECK_ACTION;

    constructor(
        public payload: {
            stickyNotification: boolean;
            clearOldNotifications?: boolean;
            customMessageKey?: string;
            forceReloadCart?: boolean;
        },
    ) {
        this.payload = payload;
    }
}

export class CreditCheckCompleteAction implements Action {
    readonly type = CREDIT_CHECK_COMPLETE_ACTION;

    constructor() {}
}

export class ToggleFavouriteProduct implements Action {
    readonly type = TOGGLE_FAVOURITE_PRODUCT;

    constructor(public payload: { userId: string; productPk: number }) {}
}

export class ToggleFavouriteProductSuccess implements Action {
    readonly type = TOGGLE_FAVOURITE_PRODUCT_SUCCESS;

    constructor() {}
}

export class ToggleFavouriteProductFail implements Action {
    readonly type = TOGGLE_FAVOURITE_PRODUCT_FAIL;

    constructor() {}
}

export const SELECT_UNIT = '[User] Select Unit';

export class SelectUnit implements Action {
    readonly type = SELECT_UNIT;

    constructor(
        public userId: string,
        public unit: string,
        public fromSearch: boolean,
        public pageContext: PageContext,
    ) {}
}

export const SELECT_UNIT_SUCCESS = '[User] Select Unit Success';

export class SelectUnitSuccess implements Action {
    readonly type = SELECT_UNIT_SUCCESS;

    constructor(public userId: string, public unit: string) {}
}

export const REGISTER = '[User] Self register';

export class Register implements Action {
    readonly type = REGISTER;

    constructor(public userId: string, public data: AimoRegistration) {}
}

export const REGISTER_SUCCESS = '[User] Self register success';

export class RegisterSuccess implements Action {
    readonly type = REGISTER_SUCCESS;

    constructor() {}
}

export const REGISTER_FAILED = '[User] Self register failed';

export class RegisterFailed implements Action {
    readonly type = REGISTER_FAILED;

    constructor(public error: string) {}
}

export const ADD_USER = '[User] add or modify user';

export class AddOrModifyUser implements Action {
    readonly type = ADD_USER;

    constructor(public userId: string, public data: AimoUserRegistrationData) {}
}

export const ADD_USER_SUCCESS = '[User] add or modify user success';

export class AddOrModifyUserSuccess implements Action {
    readonly type = ADD_USER_SUCCESS;

    constructor() {}
}

export const ADD_USER_FAILED = '[User] add or modify user failed';

export class AddOrModifyUserFailed implements Action {
    readonly type = ADD_USER_FAILED;

    constructor(public error: string) {}
}

export const MODIFY_B2B_UNIT = '[B2BUnit] modify B2BUnit';

export class ModifyB2BUnit implements Action {
    readonly type = MODIFY_B2B_UNIT;

    constructor(public userId: string, public data: AimoB2BUnitOption) {}
}

export const MODIFY_B2B_UNIT_SUCCESS = '[B2BUnit] modify B2BUnit success';

export class ModifyB2BUnitSuccess implements Action {
    readonly type = MODIFY_B2B_UNIT_SUCCESS;

    constructor() {}
}

export const MODIFY_B2B_UNIT_FAILED = '[B2BUnit] modify B2BUnit failed';

export class ModifyB2BUnitFailed implements Action {
    readonly type = MODIFY_B2B_UNIT_FAILED;

    constructor(public error: string) {}
}

export const AIMO_GIVE_CONSENT = '[User] AIMO give consent';

export class AimoGiveConsent implements Action {
    readonly type = AIMO_GIVE_CONSENT;

    constructor(public userId: string) {}
}

export const AIMO_GIVE_CONSENT_SUCCESS = '[User] AIMO give consent success';

export class AimoGiveConsentSuccess implements Action {
    readonly type = AIMO_GIVE_CONSENT_SUCCESS;

    constructor(public userId: string) {}
}

export const AIMO_SET_HIDE_ORDER_TEMPLATES_FROM_BASIC_USERS = '[User] Set Hide Order Templates From Basic Users';

export class AimoSetHideNormalOrderTemplatesFromBasicUsers implements Action {
    readonly type = AIMO_SET_HIDE_ORDER_TEMPLATES_FROM_BASIC_USERS;

    constructor(public userId: string, public value: boolean) {}
}
