import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    BasePageMetaResolver,
    CategoryPageMetaResolver,
    CmsService,
    Occ,
    RoutingService,
    TranslationService,
} from '@spartacus/core';
import {Observable} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';

import {AimoProductListComponentService} from './aimo-product-list-component.service';
import {AimoProductSearchService} from './search/aimo-product-search.service';

import ProductSearchPage = Occ.ProductSearchPage;
import {AimoRoutingService} from "../../cms-structure/routing/aimo-routing.service";

@Injectable({
    providedIn: 'root',
})
export class AimoCategoryPageMetaResolver extends CategoryPageMetaResolver {
    constructor(
        protected productSearchService: AimoProductSearchService,
        protected cms: CmsService,
        protected translation: TranslationService,
        protected basePageMetaResolver: BasePageMetaResolver,
        protected routingService: RoutingService,
        protected route: ActivatedRoute,
        protected aimoRoutingService: AimoRoutingService,
    ) {
        super(productSearchService, cms, translation, basePageMetaResolver);
    }

    resolveTitle(): Observable<string> {
        return (<Observable<ProductSearchPage>>this.searchPage$).pipe(
            filter((page: ProductSearchPage) => !!page.pagination),
            switchMap((p: ProductSearchPage) =>
                this.routingService.getRouterState().pipe(
                    switchMap((routerState) => {
                        const split = routerState.state.url?.split('/');
                        let lastPathParam = split?.length > 0 ? split[split.length - 1] : undefined;
                        if (lastPathParam.indexOf('?') >= 0) {
                            lastPathParam = lastPathParam.substring(0, lastPathParam.indexOf('?'));
                        }
                        if (
                            AimoProductListComponentService.hasAnyCategoryParam(lastPathParam)
                        ) {
                            return this.translation.translate('pageMetaResolver.category.' + lastPathParam + '.title.' + (this.isPikatukku() ? 'pt' : 'tt'));
                        }
                        return this.translation.translate('pageMetaResolver.category.title.' + (this.isPikatukku() ? 'pt' : 'tt'), {
                            count: p.pagination?.totalResults,
                            query: p.breadcrumbs?.length
                                ? p.breadcrumbs[p.breadcrumbs.length - 1].facetValueName
                                : undefined,
                        });
                    }),
                ),
            ),
        );
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
