<div class="stores">
    <div class="store-selection" [ngClass]="{ active: isPikatukku() }">
        <i class="icon-inline aimo-icon icon-storefront icon-sm mr-2 mb-1"></i>
        <button class="link d-inline" (click)="gotoPikatukku()">
            {{ 'aimo.store.pikatukku' | cxTranslate }}
        </button>
        <div *ngIf="false" class="ml-2 d-inline-block circle circle-sm bg-red text-xsm text-bold"></div>
    </div>
    <div class="store-selection" [ngClass]="{ active: !isPikatukku() }">
        <i class="icon-inline aimo-icon icon-truck icon-sm mr-2"></i>
        <button class="link d-inline" (click)="gotoToimitustukku()">
            {{ 'aimo.store.toimitustukku' | cxTranslate }}
        </button>
    </div>
</div>
