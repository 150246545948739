/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { ActiveFacetsModule, IconModule, ProductFacetNavigationModule } from '@spartacus/storefront';

import { AimoProductFacetNavigationComponent } from './aimo-product-facet-navigation.component';
import { AimoFacetListModule } from './facet-list/aimo-facet-list.module';

@NgModule({
    imports: [
        CommonModule,
        AimoFacetListModule,
        ActiveFacetsModule,
        IconModule,
        I18nModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                ProductRefinementComponent: {
                    component: AimoProductFacetNavigationComponent,
                },
            },
        }),
    ],
    declarations: [AimoProductFacetNavigationComponent],
    exports: [AimoProductFacetNavigationComponent],
})
export class AimoProductFacetNavigationModule extends ProductFacetNavigationModule {}
