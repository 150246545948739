<ng-container *ngIf="data$ | async as data; else loading">
    <ng-container *ngIf="products$ | async as rawProducts$">
        <ng-container *ngIf="rawProducts$.length > 0">
            <div class="carousel-container">
                <aimo-cx-carousel
                    class="carousel-products"
                    role="region"
                    tabindex="-1"
                    itemWidth="10px"
                    [hideIndicators]="true"
                    [items]="rawProducts$"
                    [title]="data.title"
                    [showAllText]="data.showAllText"
                    [showAllUrl]="data.showAllUrl"
                    [template]="carouselItem"
                    [idSuffix]="data.uid"
                    [carouselType]="carouselType">
                </aimo-cx-carousel>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-template #carouselItem let-item="item" let-index="index" let-idSuffix="idSuffix">
    <aimo-product-grid-item
        [product]="item"
        [idSuffix]="idSuffix + '_' + index"
        [gtmProductAttributes]="createGtmAttributes(index, item)"
        [openProductPageFromLink]="false">
    </aimo-product-grid-item>
</ng-template>

<ng-template #loading>
    <aimo-spinner></aimo-spinner>
</ng-template>
