import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoFaqSectionHeaderComponent } from './aimo-faq-section-header.component';

@NgModule({
    imports: [CommonModule, RouterModule, IconModule, MediaModule, UrlModule, I18nModule],
    declarations: [AimoFaqSectionHeaderComponent],
    exports: [AimoFaqSectionHeaderComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoFaqSectionHeaderComponent: {
                    component: AimoFaqSectionHeaderComponent,
                },
            },
        }),
    ],
})
export class AimoFaqSectionHeaderModule {}
