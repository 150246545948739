import { Injectable, OnDestroy } from '@angular/core';
import { NgbDate, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService, OccConfig, TranslationService } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { DateUtils } from '../../../shared/util/date-utils';

@Injectable({
    providedIn: 'root',
})
export class AimoNgbDateParserFormatter extends NgbDateParserFormatter implements OnDestroy {
    language: string;
    dateFormat: string;
    private subscriptions: Subscription[] = [];

    constructor(
        protected languageService: LanguageService,
        protected config: OccConfig,
        protected translation: TranslationService,
    ) {
        super();
        this.subscriptions.push(
            languageService.getActive().subscribe(
                (lang) => (this.language = lang),
                // eslint-disable-next-line
                (err) => console.error(err),
            ),
        );
        this.subscriptions.push(
            this.translation.translate('aimo.dateFormat').subscribe(
                (format) => (this.dateFormat = format),
                // eslint-disable-next-line
                (err) => console.error(err),
            ),
        );
    }

    ngOnDestroy(): void {
        /* This method has to be called manually at component level on destroy method. ngOnDestroy is not automatically called on injectables */
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }

    // eslint-disable-next-line
    parse(value: any): NgbDate {
        if (value) {
            const date: Date = DateUtils.convertDate(value);
            return this.parseDate(date);
        }
        return null;
    }

    parseDate(date: Date): NgbDate {
        return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
    }

    format(date: NgbDateStruct): string {
        if (date !== undefined && date !== null) {
            const date1: Date = new Date(date.year, date.month - 1, date.day);
            return this.formatDate(date1);
        }
        return null;
    }

    /**
     * This method only returns date/month/year part of the date, hours/minute/seconds/TZ is appended in backend
     */
    formatToSolrString(date: NgbDate): string {
        if (date !== undefined && date !== null) {
            const date1: Date = this.toDate(date);
            return DateUtils.convertDateToSolrString(this.languageService, date1);
        }
        return null;
    }

    formatDate(date: Date): string {
        if (date !== undefined && date !== null) {
            return DateUtils.convertUIDate(this.languageService, this.dateFormat, date);
        }
        return null;
    }

    getDateFormat(): string {
        return this.dateFormat;
    }

    toDate(ngbDate: NgbDate): Date {
        return new Date(ngbDate.year + '/' + ngbDate.month + '/' + ngbDate.day);
    }

    toString(date: NgbDateStruct): string {
        return this.formatToSolrString(date as NgbDate);
    }
}
