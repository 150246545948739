import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
    I18nModule,
    RoutingService,
    UrlModule,
    AuthConfigService,
    GlobalMessageService,
    provideConfig,
    CmsConfig,
} from '@spartacus/core';
import { FormErrorsModule, PageSlotModule, SpinnerModule } from '@spartacus/storefront';
import { ForgotPasswordModule } from '@spartacus/user/profile/components';

import { AimoUserPasswordService } from '../../../service/user/aimo-user-password.service';
import { AimoInputModule } from '../../../shared/input/aimo-input.module';

import { AimoForgotPasswordComponentService } from './aimo-forgot-password-component.service';
import { AimoForgotPasswordComponent } from './aimo-forgot-password.component';

@NgModule({
    declarations: [AimoForgotPasswordComponent],
    imports: [
        CommonModule,
        FormErrorsModule,
        FormsModule,
        I18nModule,
        NgbTooltipModule,
        AimoInputModule,
        ReactiveFormsModule,
        RouterModule,
        SpinnerModule,
        UrlModule,
        PageSlotModule,
    ],
    providers: [
        {
            provide: AimoForgotPasswordComponentService,
            useClass: AimoForgotPasswordComponentService,
            deps: [AimoUserPasswordService, RoutingService, AuthConfigService, GlobalMessageService],
        },
        provideConfig(<CmsConfig>{
            cmsComponents: {
                ForgotPasswordComponent: {
                    component: AimoForgotPasswordComponent,
                },
            },
        }),
    ],
})
export class AimoForgotPasswordModule extends ForgotPasswordModule {}
