import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CustomFormValidators } from '@spartacus/storefront';
import { UpdatePasswordComponentService } from '@spartacus/user/profile/components';

import { AimoCustomFormValidators } from '../../../../shared/validators/aimo-custom-form-validators';

@Injectable()
export class AimoUpdatePasswordComponentService extends UpdatePasswordComponentService {
    form: FormGroup = new FormGroup(
        {
            oldPassword: new UntypedFormControl('', Validators.required),
            newPassword: new UntypedFormControl('', [Validators.required, AimoCustomFormValidators.passwordValidator]),
            newPasswordConfirm: new UntypedFormControl('', Validators.required),
        },
        {
            validators: CustomFormValidators.passwordsMustMatch('newPassword', 'newPasswordConfirm'),
        },
    );
}
