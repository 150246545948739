import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ConverterService,
    InterceptorUtil,
    normalizeHttpError,
    Occ,
    OccEndpointsService,
    USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { OccUserProfileAdapter } from '@spartacus/user/profile/occ';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
    AimoB2BUnitOption,
    AimoBulletinList,
    AimoContactUs,
    AimoRegistration,
    AimoUser,
    AimoUserRegistrationData,
    B2BUnitOption,
    KitchenAlcoholInfo,
    OpeningHour,
} from '../../model/user.model';

import User = Occ.User;

@Injectable({
    providedIn: 'root',
})
export class AimoOccUserProfileAdapter extends OccUserProfileAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpoints: OccEndpointsService,
        protected converter: ConverterService,
        protected recaptchaV3Service: ReCaptchaV3Service,
    ) {
        super(http, occEndpoints, converter);
    }

    searchUnit(userId: string, term: string): Observable<B2BUnitOption[]> {
        const url = this.occEndpoints.buildUrl('searchUnits', { urlParams: { userId }, queryParams: { term: term } });
        return this.http.get<B2BUnitOption[]>(url);
    }

    selectUnit(userId: string, uid: string, fromSearch: boolean): Observable<User> {
        const url = this.occEndpoints.buildUrl('selectUnit', {
            urlParams: { userId },
            queryParams: { uid, fromSearch },
        });
        return this.http.get<User>(url);
    }

    toggleFavorite(userId: string, productPk: number): Observable<void> {
        const url = this.occEndpoints.buildUrl('toggleFavorite', {
            urlParams: { userId: userId, productPk: productPk },
        });
        return this.http.put<void>(url, null);
    }

    setPassword(token: string, newPassword: string): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('userSetPassword');
        const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, this.getContentType());

        return this.http
            .post(url, { token, newPassword }, { headers })
            .pipe(catchError((error) => throwError(normalizeHttpError(error))));
    }

    contactUs(body: AimoContactUs): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('contactUs');
        const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, this.getContentType());

        return this.http
            .post(url, body, { headers })
            .pipe(catchError((error) => throwError(normalizeHttpError(error))));
    }

    registerCustomer(userId: string, data: AimoRegistration): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('registerCustomer');
        const headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, this.getContentType());

        return this.http
            .post(url, data, { headers })
            .pipe(catchError((error) => throwError(normalizeHttpError(error))));
    }

    addOrModifyUser(userId: string, data: AimoUserRegistrationData): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('addOrModifyUser', { urlParams: { userId } });
        const headers = this.getContentType();
        return this.http
            .post(url, data, { headers })
            .pipe(catchError((error) => throwError(normalizeHttpError(error))));
    }

    modifyB2BUnit(userId: string, data: AimoB2BUnitOption): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('modifyB2BUnit', { urlParams: { userId } });
        const headers = this.getContentType();
        return this.http
            .post(url, data, { headers })
            .pipe(catchError((error) => throwError(normalizeHttpError(error))));
    }

    getMyOrganizationDetails(userId: string): Observable<AimoUser> {
        const url = this.occEndpoints.buildUrl('user', {
            urlParams: { userId },
            queryParams: { fetchOrganizationDetails: true },
        });
        return this.http.get<AimoUser>(url);
    }

    getAlchoholQuotaInfo(userId: string): Observable<KitchenAlcoholInfo> {
        const url = this.occEndpoints.buildUrl('alchoholQuota', {
            urlParams: { userId },
        });
        return this.http.get<KitchenAlcoholInfo>(url);
    }

    getActiveBulletinList(userId: string, category: string, id: string): Observable<AimoBulletinList> {
        const url = this.occEndpoints.buildUrl('activeBulletins', {
            urlParams: { userId },
            queryParams: { categories: category, id: id },
        });
        return this.http.get<AimoBulletinList>(url);
    }

    getContentType(): HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
        });
    }

    giveConsent(userId: string): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('giveConsent', {
            urlParams: { userId },
        });
        return this.http.put(url, null);
    }

    getStoreOpeningHours(): Observable<OpeningHour[]> {
        const url = this.occEndpoints.buildUrl('storeOpeningHours');
        return this.http.get<OpeningHour[]>(url);
    }

    requestForgotPasswordEmail(userEmailAddress: string): Observable<unknown> {
        const url = this.occEndpoints.buildUrl('userForgotPassword');
        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        });
        headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
        return this.recaptchaV3Service.execute('importantAction').pipe(
            switchMap((token) => {
                const httpParams: HttpParams = new HttpParams()
                    .set('userId', userEmailAddress)
                    .set('captchaToken', token);
                return this.http
                    .post(url, httpParams, { headers })
                    .pipe(catchError((error) => throwError(normalizeHttpError(error))));
            }),
        );
    }

    setHideNormalOrderTemplatesFromBasicUsers(userId: string, value: boolean): Observable<User> {
        const url = this.occEndpoints.buildUrl('hideNormalOrderTemplatesFromBasicUsers', {
            urlParams: { userId, value },
        });
        return this.http.post<User>(url, undefined);
    }
}
