<ng-container *ngIf="pageAllowed() | async">
    <ng-container *ngIf="baseOrderId$ | async as baseOrderId; else baseOrders">
        <aimo-base-order-details [baseOrderId]="baseOrderId"></aimo-base-order-details>
    </ng-container>

    <ng-template #baseOrders>
        <aimo-breadcrumb
            *ngIf="breadcrumbs$ | async as breadcrumbs"
            [hideTitle]="true"
            [showBackLink]="false"
            [normalBreadCrumbs]="breadcrumbs">
        </aimo-breadcrumb>
        <ng-container *ngIf="baseOrderList$ | async as baseOrders">
            <h1 class="page-title" tabindex="0" [attr.aria-label]="'aimo.baseOrders.title' | cxTranslate">
                {{ 'aimo.baseOrders.title' | cxTranslate }}
            </h1>

            <p>
                {{ 'aimo.baseOrders.description1' | cxTranslate }}
            </p>
            <p>
                {{
                    'aimo.baseOrders.description2'
                        | cxTranslate
                            : {
                                  datesAhead: getDeliveryDatesAhead(baseOrders?.carts[0])
                              }
                }}
            </p>

            <div class="templates-search">
                <div class="row no-gutters">
                    <div class="col-lg-3 col-md-6 col-12 cx-search-criteria-find order-md-1 order-3">
                        <aimo-cx-input
                            [inputClass]="'input-md mt-0'"
                            (afterChange)="fetchBaseOrders()"
                            [placeholder]="'aimo.baseOrders.search'"
                            [parentForm]="searchForm"
                            [nameForm]="'search'"
                            [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                        </aimo-cx-input>
                    </div>
                    <div class="col-12 col-lg-5 pl-md-2 pl-0 order-lg-2 order-last mt-3 mt-lg-0"></div>
                    <div class="col-12 col-lg-4 col-md-6 order-lg-last order-md-2 order-2 pb-3 pb-md-0 text-right">
                        <button
                            class="btn btn-sm btn-primary"
                            (click)="openCreateBaseOrderModal(); pushGtmEvent('baseorders-create-new-modal-open')">
                            <i class="icon-plus-circle icon-white icon-inline aimo-icon mr-2 mb-1"></i>
                            {{ 'aimo.baseOrders.button.create' | cxTranslate }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="aimo-order-templates-table">
                <ng-container *ngFor="let baseOrder of baseOrders.carts">
                    <div
                        class="row no-gutters aimo-order-templates-row"
                        [ngClass]="{
                            'sales-blocked': baseOrder.salesBlocked,
                            unapproved: baseOrder.unApprovedChanges
                        }">
                        <div class="col-lg-12">
                            <a class="link routerlink d-block" [routerLink]="'/baseorders/' + baseOrder.code">
                                <div class="row no-gutters align-items-center">
                                    <div class="col-lg-3 col-12 pb-2 pb-lg-0 d-flex align-items-center">
                                        <i
                                            class="aimo-icon icon-arrow-clockwise icon-inline icon-petrol-blue mr-2"
                                            *ngIf="!baseOrder.salesBlocked">
                                        </i>

                                        <i
                                            class="aimo-icon icon-prohibit icon-inline icon-mid-grey mr-2"
                                            *ngIf="baseOrder.salesBlocked">
                                        </i>
                                        <h5 class="mb-0">
                                            {{ baseOrder.name }}
                                            <i
                                                class="left-side aimo-icon icon-inline icon-warning-circle icon-red"
                                                *ngIf="baseOrder.aboutToBeSalesBlocked"></i>
                                        </h5>
                                    </div>
                                    <div class="col-lg-5 col-6 d-none d-lg-flex aimo-order-templates-images">
                                        <ng-container
                                            *ngFor="
                                                let entry of baseOrder.entries
                                                    | slice
                                                        : 0
                                                        : (baseOrder.entries?.length > 6
                                                              ? 5
                                                              : baseOrder.entries?.length);
                                                let i = index
                                            ">
                                            <div
                                                class="cx-product-image-container mr-2"
                                                [ngClass]="{ 'd-xl-inline-block d-lg-none': i > 2 }">
                                                <cx-media
                                                    class="cx-product-image"
                                                    [alt]="entry.product.name"
                                                    [title]="entry.product.name"
                                                    triggers="mouseenter:mouseleave"
                                                    popoverClass="popover-default"
                                                    container="body"
                                                    [ngbPopover]="entry.product.name"
                                                    [container]="entry.product?.images?.PRIMARY"
                                                    format="cartIcon">
                                                </cx-media>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="baseOrder.entries.length > 4">
                                            <div class="aimo-order-template-additional-images-box d-lg-flex d-xl-none">
                                                <div class="text-dark-grey">+ {{ baseOrder.entries.length - 3 }}</div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="baseOrder.entries.length > 6">
                                            <div class="aimo-order-template-additional-images-box d-xl-flex d-lg-none">
                                                <div class="text-dark-grey">+ {{ baseOrder.entries.length - 5 }}</div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-lg-4 col-12 align-items-center text-default">
                                        <div class="row">
                                            <div class="col-lg-4 col-4 d-flex align-items-center">
                                                <div class="mr-lg-2 mr-0">
                                                    <ng-container
                                                        *ngFor="
                                                            let weekday of baseOrder.weekDays;
                                                            let last = last;
                                                            let first = first
                                                        ">
                                                        <i
                                                            *ngIf="first"
                                                            class="aimo-icon icon-calendar-blank icon-sm icon-petrol-blue icon-inline">
                                                        </i>
                                                        <span>{{
                                                            'aimo.calendar.weekday.short.' + weekday | cxTranslate
                                                        }}</span>
                                                        <span *ngIf="!last">, </span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-4 d-flex align-items-center">
                                                <div class="mr-lg-2 mr-0">
                                                    <i
                                                        class="aimo-icon icon-arrow-clockwise icon-sm icon-petrol-blue icon-inline">
                                                    </i>
                                                    <span *ngIf="baseOrder.oddWeek === true">{{
                                                        'aimo.select.evenOdd.odd' | cxTranslate
                                                    }}</span>
                                                    <span *ngIf="baseOrder.oddWeek === undefined">{{
                                                        'aimo.select.evenOdd.both' | cxTranslate
                                                    }}</span>
                                                    <span *ngIf="baseOrder.oddWeek === false">{{
                                                        'aimo.select.evenOdd.even' | cxTranslate
                                                    }}</span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-4 d-flex align-items-center justify-content-end">
                                                <span class="product-count">
                                                    {{
                                                        'aimo.ordertemplates.products.' +
                                                            (baseOrder.entries?.length === 1 ? 'one' : 'many')
                                                            | cxTranslate : { amount: baseOrder.entries?.length }
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </ng-template>
</ng-container>
