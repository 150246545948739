/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProductFacetNavigationComponent } from '@spartacus/storefront';

@Component({
    selector: 'aimo-product-facet-navigation',
    templateUrl: './aimo-product-facet-navigation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductFacetNavigationComponent extends ProductFacetNavigationComponent {
    @Input() mobileUI: boolean;
}
