<aimo-breadcrumb
    *ngIf="breadcrumbs$ | async as breadcrumbs"
    [hideTitle]="true"
    [showBackLink]="false"
    [normalBreadCrumbs]="breadcrumbs">
</aimo-breadcrumb>
<h1 class="page-title" tabindex="0" [attr.aria-label]="'aimo.claims.title' | cxTranslate">
    {{ 'aimo.claimslist.title' | cxTranslate }}
</h1>
<div class="noPrint">
    <ng-container
        *ngTemplateOutlet="
            notification;
            context: { level: 'INFO', textKey: 'aimo.claims.pageDescription' }
        "></ng-container>
</div>

<div class="row cx-search-criteria noPrint" [formGroup]="searchForm">
    <div class="col-12 col-md-8 order-md-2">
        <button
            id="create-claim"
            class="float-right btn btn-secondary btn-sm btn-inline btn-block-sm-down ml-2"
            (click)="createClaim(); pushGtmEvent('claims-create-new')">
            {{ 'aimo.claims.new.create' | cxTranslate }}
        </button>
    </div>
    <div class="col-12 col-md-4 order-md-1 range-dates pt-4 pt-md-0">
        <aimo-datepicker-input
            class="date-from"
            [idName]="'delivery_date_from'"
            (afterChange)="fetchClaims()"
            [placeholder]="'aimo.orderhistory.dateFrom' | cxTranslate"
            [parentForm]="searchForm"
            [customDayTemplate]="customDay"
            [nameForm]="'from'"></aimo-datepicker-input>
        <span class="date-divider"> - </span>
        <aimo-datepicker-input
            class="date-to"
            [idName]="'delivery_date_to'"
            (afterChange)="fetchClaims()"
            [placeholder]="'aimo.orderhistory.dateTo' | cxTranslate"
            [parentForm]="searchForm"
            [customDayTemplate]="customDay"
            [nameForm]="'to'"></aimo-datepicker-input>
    </div>
</div>

<ng-template
    #customDay
    let-date
    let-currentMonth="currentMonth"
    let-today="today"
    let-selected="selected"
    let-disabled="disabled"
    let-focused="focused">
    <button
        class="calendar-cell calendar-day link"
        [ngClass]="{ focused: focused, selected: selected, disabled: disabled }">
        {{ date.day }}
    </button>
</ng-template>

<div *ngIf="cartList$ | async as cartList" class="aimo-order-templates-table">
    <div
        class="aimo-order-templates-row row no-gutters cursor-pointer"
        *ngFor="let claim of cartList.carts"
        (click)="openClaim(claim.requestedDeliveryDate)">
        <div class="col-8 col-md-10 d-flex align-items-center">
            <i class="aimo-icon icon-petrol-blue icon-warning-octagon icon-inline mr-2"></i>
            <h5 class="mb-0">
                {{ convertDate(claim.requestedDeliveryDate) | cxDate : ('aimo.dateFormat' | cxTranslate) }}
            </h5>
        </div>
        <div class="col-4 col-md-2 product-count text-right">
            <span>
                {{
                    'aimo.ordertemplates.products.' + (claim.entries?.length === 1 ? 'one' : 'many')
                        | cxTranslate : { amount: claim.entries?.length }
                }}
            </span>
        </div>
    </div>
</div>

<ng-template #notification let-level="level" let-textKey="textKey">
    <div
        class="notification"
        [ngClass]="level === 'INFO' ? 'notification-MESSAGE' : 'notification-' + level"
        *ngIf="!isMessageHidden(textKey)">
        <div class="left-side">
            <i class="aimo-icon icon-inline icon-{{ level }} mr-2"></i>
        </div>
        <div class="d-inline text-sm middle">
            <span [innerHTML]="textKey | cxTranslate"></span>
        </div>
        <div class="right-side">
            <button class="btn-link" (click)="hideMessage(textKey)">
                <i class="aimo-icon icon-petrol-blue icon-inline icon-x float-right"></i>
            </button>
        </div>
    </div>
</ng-template>
