<div class="container">
    <ng-container *ngIf="data$ | async as data; else loading">
        <div class="bulletin-title row align-items-center" *ngIf="data.title">
            <div class="col-8 col-sm-6">
                <h3 *ngIf="data.title">{{ data.title }}</h3>
            </div>
            <div class="col-4 col-sm-6 text-right">
                <a class="show-all d-none d-sm-inline-block" [routerLink]="'/announcements'">{{
                    'aimo.bulletin.showAll' | cxTranslate
                }}</a>
                <a class="show-all d-inline-block d-sm-none" [routerLink]="'/announcements'">{{
                    'aimo.product.carousel.showAll' | cxTranslate
                }}</a>
            </div>
        </div>
    </ng-container>
    <div #bulletinList class="bulletin-list row" *ngIf="bulletins$ | async as bulletins; else loading">
        <div class="col-12 col-md-6 col-xl-4" *ngFor="let bulletin of bulletins.bulletins">
            <div class="bulletin">
                <div class="bulletin-image">
                    <cx-media [container]="bulletin.picture"></cx-media>
                </div>
                <div class="bulletin-description">
                    <div class="bulletin-tag-date">
                        <span class="bulletin-tag bulletin-tag-{{ bulletin.category.code }}">{{
                            bulletin.category.name
                        }}</span>
                        <span class="bulletin-date">{{
                            bulletin.publishingDate | cxDate : ('aimo.dateFormat' | cxTranslate)
                        }}</span>
                    </div>
                    <div class="d-flex flex-column h-100">
                        <div class="bulletin-description-wrapper flex-xl-grow-1">
                            <h5 class="bulletin-header">{{ bulletin.title }}</h5>
                            <div class="bulletin-short-description" [innerHTML]="bulletin.shortDescription"></div>
                        </div>
                        <a
                            class="stretched-link mb-2"
                            *ngIf="bulletin.url && !isExternal(bulletin)"
                            [routerLink]="bulletin.url"
                            >{{ 'aimo.bulletin.openBulletin' | cxTranslate }}</a
                        >
                        <a
                            class="stretched-link mb-2"
                            *ngIf="!bulletin.url && !isExternal(bulletin)"
                            [routerLink]="'/announcement/' + bulletin.id"
                            >{{ 'aimo.bulletin.openBulletin' | cxTranslate }}</a
                        >
                        <a
                            class="stretched-link mb-2"
                            *ngIf="bulletin.url && isExternal(bulletin)"
                            [href]="bulletin.url"
                            [target]="getTarget(bulletin)"
                            >{{ 'aimo.bulletin.openBulletin' | cxTranslate }}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <aimo-spinner></aimo-spinner>
</ng-template>
