/*
 * SPDX-FileCopyrightText: 2023 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */

import {Injectable} from '@angular/core';
import {ProductConnector} from '@spartacus/core';
import {Observable} from 'rxjs';

import {AimoProduct} from '../../model/product.model';
import {KlevuParams} from '../gtm/aimo-gtm.model';

import {AimoOccProductAdapter} from './aimo-occ-product.adapter';
import {AimoRecentProductsService} from "./aimo-recent-proucts.service";

@Injectable({
    providedIn: 'root',
})
export class AimoProductConnector extends ProductConnector {
    constructor(protected adapter: AimoOccProductAdapter,
                protected recentProductsService: AimoRecentProductsService) {
        super(adapter);
    }

    getAimoProduct(
        productCode: string,
        category: string,
        searchTerm: string,
        klevuParams: KlevuParams,
    ): Observable<AimoProduct> {
        this.recentProductsService.add([productCode]);
        return this.adapter.loadAimo(productCode, category, searchTerm, klevuParams);
    }
}
