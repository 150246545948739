import { Injectable } from '@angular/core';
import { EventService, RoutingService, SearchboxService, TranslationService, WindowRef } from '@spartacus/core';
import { SearchBoxComponentService } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AimoSearchConfig } from '../../../model/misc.model';
import { AimoSearchBoxConfig, AimoSearchResults } from '../../../model/product.model';

import { AimoProductSearchService } from './aimo-product-search.service';

const HAS_SEARCH_RESULT_CLASS = 'has-searchbox-results';

@Injectable({
    providedIn: 'root',
})
export class AimoSearchBoxComponentService extends SearchBoxComponentService {
    constructor(
        public searchService: SearchboxService,
        protected routingService: RoutingService,
        protected translationService: TranslationService,
        protected winRef: WindowRef,
        protected eventService: EventService,
        protected productSearchService: AimoProductSearchService,
    ) {
        super(searchService, routingService, translationService, winRef, eventService);
    }

    latestQuery: string;

    /**
     * Executes the search for products and suggestions,
     * unless the configuration is setup to not search for
     * products or suggestions.
     */
    search(query: string, config: AimoSearchBoxConfig): void {
        if (!query || query === '') {
            this.clearResults();
            return;
        }

        if (config.minCharactersBeforeRequest && query.length < config.minCharactersBeforeRequest) {
            return;
        }

        if (config.displayProducts) {
            this.latestQuery = query;
            setTimeout(() => {
                if (this.latestQuery === query) {
                    this.productSearchService.search(
                        query,
                        {
                            pageSize: config.maxProducts,
                            suggestiveMode: config.suggestiveMode,
                            currentPage: config.page ? config.page : 0,
                            origin: config.origin,
                        } as AimoSearchConfig,
                        true,
                    );
                }
            }, 700);
        }

        if (config.displaySuggestions) {
            this.searchService.searchSuggestions(query, {
                pageSize: config.maxSuggestions,
                suggestiveMode: config.suggestiveMode,
                currentPage: config.page ? config.page : 0,
            } as AimoSearchConfig);
        }
    }

    getResults(config: AimoSearchBoxConfig): Observable<AimoSearchResults> {
        return combineLatest([
            this.getProductResults(config),
            this.getProductSuggestions(config),
            this.getSearchMessage(config),
        ]).pipe(
            map(([productResults, suggestions, message]) => {
                return {
                    products: productResults ? productResults.products : undefined,
                    suggestions,
                    message,
                    pagination: productResults ? productResults.pagination : undefined,
                    keywordRedirectUrl: productResults.keywordRedirectUrl,
                } as AimoSearchResults;
            }),
            tap((results) => this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, this.hasResults(results))),
        );
    }

    /**
     * Clears the searchbox results, so that old values are
     * no longer emited upon next search.
     */
    clearResults(): void {
        this.searchService.clearResults();
        this.productSearchService.closeQuickSearch();
        this.toggleBodyClass(HAS_SEARCH_RESULT_CLASS, false);
    }

    launchSearchPage(query: string): void {
        this.routingService.go(
            {
                cxRoute: 'search',
                params: { query },
            },
            {
                queryParams: { followRedirects: true },
            },
        );
    }
}
