import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoCart } from '../../../model/cart.model';
import { AimoProduct } from '../../../model/product.model';

import { AimoOrderTemplateDeleteModalComponent } from './aimo-order-template-delete-modal.component';
import { AimoOrderTemplateModalComponent } from './aimo-order-template-modal.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        ORDER_TEMPLATE = 'ORDER_TEMPLATE',
        ORDER_TEMPLATE_DELETE = 'ORDER_TEMPLATE_DELETE',
    }
}

export const enum CART_VISIBILITY {
    PRIVATE = 'private',
    PUBLIC = 'public',
    CENTRAL = 'central',
}

export const orderTemplateLayout: LayoutConfig = {
    launch: {
        ORDER_TEMPLATE: {
            inlineRoot: true,
            component: AimoOrderTemplateModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
        ORDER_TEMPLATE_DELETE: {
            inlineRoot: true,
            component: AimoOrderTemplateDeleteModalComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoOrderTemplateDialogData {
    createNew?: boolean;
    edit?: AimoCart;
    importFrom?: boolean;
    oldCartId?: string;
    addToTemplateProduct?: AimoProduct;
    orderHistoryDay: string;
    openProductModalAfterClose?: boolean;
    focusIdAfterClose?: string;
}

export interface AimoOrderTemplateDeleteDialogData {
    template: AimoCart;
    baseOrder?: boolean;
}
