<div
    class="row no-gutters cart-item align-center bg-white"
    [ngClass]="{ deleted: entry.quantity === 0 }"
    id="orderhistory-{{ order.code }}-{{ entry.entryNumber }}">
    <div class="col-12 col-md-5 pb-2 pb-md-0">
        <div class="row no-gutters">
            <div class="col-12 align-center">
                <div class="cart-item-offer text-xsm text-uppercase" *ngIf="false">
                    {{ 'aimo.cart.item.offer' | cxTranslate }}
                </div>

                <a
                    [id]="'product_image_' + order.code + '_' + entry.entryNumber"
                    class="cx-product-image-container mr-1 cursor-pointer"
                    role="button"
                    (click)="openProductModal('product_image_' + order.code + '_' + entry.entryNumber)"
                    tabindex="0">
                    <aimo-product-tag [product]="entry.product" />
                    <cx-media
                        class="cx-product-image"
                        [container]="entry.product?.images?.PRIMARY"
                        format="cartIcon"></cx-media>
                </a>

                <div class="product-name-wrapper">
                    <button
                        [id]="'product_name_' + order.code + '_' + entry.entryNumber"
                        class="text-left btn-link text-bold cursor-pointer product-name"
                        [attr.aria-label]="
                            'aimo.product.openModal.arialLabel' | cxTranslate : { productName: entry.product.name }
                        "
                        (click)="openProductModal('product_name_' + order.code + '_' + entry.entryNumber)"
                        tabindex="0">
                        {{ entry.product.name }}
                    </button>

                    <div class="product-code">
                        <aimo-product-code [product]="entry.product"></aimo-product-code>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-md-5">
        <div class="row no-gutters">
            <div class="col-md-5 col-4 cart-item-data price">
                <div class="ml-1 mr-1">
                    <i
                        *ngIf="entry.product.myAssortment"
                        placement="top"
                        container="body"
                        triggers="mouseenter:mouseleave"
                        [ngbPopover]="'aimo.product.myAssortment' | cxTranslate"
                        class="aimo-icon icon-inline icon-sm icon-house-line-bold icon-petrol-blue"
                        [ngClass]="entry.product.strikeThroughPrice ? 'icon-red' : ''"></i>
                    <span
                        class="text-bold price d-inline-block mr-1"
                        [ngClass]="
                            entry.strikeThroughPrice
                                ? 'promo-price'
                                : entry.product.myAssortment
                                ? 'my-assortment-price'
                                : ''
                        ">
                        {{ entry.basePrice?.formattedValue }}
                    </span>
                    <span class="unit d-inline-block mr-1"> / {{ entry.unit.name }} </span>
                    <span class="vat d-inline-block">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                </div>
                <div class="text-strike ml-1 mr-1" *ngIf="entry.strikeThroughPrice">
                    {{ entry.strikeThroughPrice?.formattedValue }} / {{ entry.unit.name }}
                </div>
            </div>

            <div class="col-md-2 col-3 cart-item-data">
                <div class="quantity">
                    <span class="requestedQuantity">{{ entry.quantity }}</span> {{ entry.unit?.name }}
                </div>
            </div>

            <div class="col-md-5 col-5 cart-item-data">
                <div class="ml-1 mr-1">
                    <span class="text-bold totalPrice d-inline-block mr-1">{{ entry.totalPrice?.formattedValue }}</span>
                    <span class="d-inline-block mr-1">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                    <br />
                    <span class="totalPriceWithTax d-inline-block mr-1" *ngIf="!order.paymentMode.credit">{{
                        entry.totalPriceWithTax?.formattedValue
                    }}</span>
                    <span class="taxRate text-lowercase d-inline-block mr-1" *ngIf="!order.paymentMode.credit">
                        ({{ 'aimo.cart.totals.vat' | cxTranslate : { tax: entry.tax?.rate } }})
                    </span>
                    <aimo-cart-item-deposits
                        [alreadyOrdered]="true"
                        [paymentMode]="order.paymentMode"
                        [entry]="entry"></aimo-cart-item-deposits>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-2 col-12 cart-item-data align-center">
        <div class="cx-stock mt-0" *ngIf="entry.closingStatus">
            <span class="stock-status stock-status-{{ entry.closingStatus | lowercase }}"></span>
            <span
                class="closing-time"
                *ngIf="entry.closingTime"
                [innerHTML]="
                    'aimo.orderhistory.closingtime.' + entry.closingStatus
                        | cxTranslate
                            : {
                                  date: entry.closingTime | cxDate : 'dd.MM',
                                  time: entry.closingTime | cxDate : 'HH:mm'
                              }
                ">
            </span>
            <span *ngIf="!entry.closingTime && entry.closingStatus !== 'soon'">
                {{ 'aimo.orderhistory.closingtime.' + entry.closingStatus | cxTranslate }}
            </span>
        </div>
    </div>

    <div class="col-12 messages">
        <div class="row">
            <div class="message message-{{ message.severity }}" *ngFor="let message of entry.messages">
                <span>{{ message.message }}</span>
                <span *ngIf="message.action === 'deliveryNote'">
                    &nbsp;<span
                        class="btn-link"
                        (click)="downloadDeliveryNote(message.actionParams[0], 'aimo.orderhistory.deliverynoteName')">
                        {{
                            'aimo.orderhistory.lessConfirmationThanOrderedSuffix'
                                | cxTranslate : { id: message.actionParams[0] }
                        }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>
