import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import { CART_MODIFICATION_NORMALIZER, CART_NORMALIZER, CartModification } from '@spartacus/cart/base/root';
import { ConverterService, LanguageService, Occ, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AimoBaseOrder,
    AimoBaseOrderList,
    AimoCart,
    AimoCartList,
    AimoOrderEntry,
    AimoRouteCalendar,
} from '../../model/cart.model';
import { AimoOrderHistoryRequest } from '../../model/order.model';
import { DateUtils } from '../../shared/util/date-utils';
import { KlevuParams } from '../gtm/aimo-gtm.model';

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
});

@Injectable({
    providedIn: 'root',
})
export class AimoOccCartEntryAdapter extends OccCartEntryAdapter {
    constructor(
        protected http: HttpClient,
        protected occEndpointsService: OccEndpointsService,
        protected converterService: ConverterService,
        protected language: LanguageService,
    ) {
        super(http, occEndpointsService, converterService);
    }

    getRouteCalendar(userId: string, cartId: string, claimsMode?: boolean): Observable<AimoRouteCalendar> {
        return this.http.get<AimoRouteCalendar>(
            this.occEndpointsService.buildUrl('routeCalendar', {
                urlParams: { userId, cartId },
                queryParams: {
                    claimsMode,
                },
            }),
        );
    }

    public addAimoEntries(
        userId: string,
        cartId: string,
        entries: AimoOrderEntry[],
        categoryCode: string,
        searchTerm: string,
        klevuParams: KlevuParams,
    ): Observable<CartModification[]> {
        const data = entries.map((entry) => this.createOccEntry(entry));
        const url = this.occEndpointsService.buildUrl('addEntries', {
            urlParams: { userId, cartId },
            queryParams: {
                searchTerm,
                categoryCode,
                klevuBannerId: klevuParams?.klevuBannerId,
                klevuBannerTitle: klevuParams?.klevuBannerTitle,
                klevuBannerPageType: klevuParams?.klevuBannerPageType,
                klevuBannerType: klevuParams?.klevuBannerType,
            },
        });
        return this.http
            .post<CartModification[]>(url, JSON.stringify(data), { headers })
            .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER)) as Observable<CartModification[]>;
    }

    public updateAimo(
        userId: string,
        cartId: string,
        entry: AimoOrderEntry,
        searchTerm: string,
    ): Observable<CartModification> {
        const url = this.occEndpointsService.buildUrl('updateEntries', {
            urlParams: {
                userId,
                cartId,
                entryNumber: entry.entryNumber,
            },
            queryParams: { searchTerm },
        });

        return this.http
            .patch<CartModification>(url, this.createOccEntry(entry), { headers })
            .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
    }

    public updateHeader(
        userId: string,
        cartId: string,
        payload: AimoCart,
        resetExternalItems: boolean,
    ): Observable<AimoCart> {
        const url = this.occEndpointsService.buildUrl('updateHeader', {
            urlParams: { userId, cartId },
            queryParams: {
                resetExternalItems,
            },
        });
        return this.http
            .put<AimoCart>(url + (payload.visibility ? '&spinner=true' : ''), {
                ...payload,
                entries: payload.entries ? payload.entries : [],
                dayGroupedEntries: [],
            } as AimoCart)
            .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<AimoCart>;
    }

    public exportCartToExcel(userId: string, cartId: string, historyDay: string): Observable<ArrayBuffer> {
        const url = this.occEndpointsService.buildUrl('excelExportCart', {
            urlParams: { userId, cartId },
            queryParams: { historyDay },
        });
        // eslint-disable-next-line
        return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as any });
    }

    getDeliveryDate(userId: string, cartId: string): Observable<string> {
        return this.http
            .get<AimoCart>(
                this.occEndpointsService.buildUrl('deliveryDate', {
                    urlParams: { userId, cartId: cartId },
                }),
            )
            .pipe(map((cart) => cart.requestedDeliveryDate));
    }

    excelImport(userId: string, cartId: string, data: string): Observable<AimoCart> {
        const url = this.occEndpointsService.buildUrl('excelImport', {
            urlParams: { userId, cartId },
        });
        return this.http
            .post<AimoCart>(url, data)
            .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<AimoCart>;
    }

    addMany(userId: string, cartId: string, data: AimoCart): Observable<AimoCart> {
        const url = this.occEndpointsService.buildUrl('addMany', {
            urlParams: { userId, cartId },
        });
        return this.http
            .post<AimoCart>(url, {
                ...data,
                entries: data.entries?.map(this.createOccEntry),
            })
            .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<AimoCart>;
    }

    getOrderTemplates(
        userId: string,
        visibility: string,
        search: string,
        onlyEditable: boolean,
    ): Observable<AimoCartList> {
        return this.http
            .get<AimoCartList>(
                this.occEndpointsService.buildUrl('templates', {
                    urlParams: { userId },
                    queryParams: {
                        visibilities: visibility?.toUpperCase(),
                        templates: true,
                        search: search,
                        onlyEditable: onlyEditable,
                    },
                }),
            )
            .pipe(
                // pluck('carts'),
                map((cartList) => {
                    //cartList.carts ??[]
                    const carts = cartList.carts
                        ? this.converterService.convertMany(cartList.carts, CART_NORMALIZER)
                        : [];
                    return { ...cartList, carts };
                }),
            );
    }

    getOrderTemplate(userId: string, cartId: string, requestedDeliveryDate: string): Observable<AimoCart> {
        return this.http
            .get<Occ.CartList>(
                this.occEndpointsService.buildUrl('cart', {
                    urlParams: { userId, cartId },
                    queryParams: { requestedDeliveryDate },
                }),
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    createOrderTemplate(
        userId: string,
        visibility: string,
        name: string,
        oldCartId?: string,
        orderHistoryDay?: string,
    ): Observable<AimoCart> {
        return this.http
            .post<Occ.CartList>(
                this.occEndpointsService.buildUrl('template', {
                    urlParams: { userId },
                    queryParams: { visibility: visibility.toUpperCase(), name, oldCartId, orderHistoryDay },
                }),
                {},
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    templateImport(userId: string, cartId: string, fromId: string, selectedDate?: string): Observable<AimoCart> {
        return this.http
            .post<Occ.CartList>(
                this.occEndpointsService.buildUrl('templateImport', {
                    urlParams: { userId, cartId, fromId },
                    queryParams: { requestedDeliveryDate: selectedDate },
                }),
                {},
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    saveBaseOrder(userId: string, baseOrder: AimoBaseOrder, blockAdded: boolean): Observable<AimoBaseOrder> {
        return this.http.post<AimoBaseOrder>(
            this.occEndpointsService.buildUrl('baseOrders', {
                urlParams: { userId },
                queryParams: { blockAdded },
            }),
            {
                ...baseOrder,
                entries: baseOrder.entries.map((e) => ({ ...e, ...this.createOccEntry(e) })),
            },
        );
    }

    approveBaseOrder(userId: string, baseOrderId: string, approve: boolean): Observable<void> {
        return this.http.put<void>(
            this.occEndpointsService.buildUrl('baseOrderApprove', {
                urlParams: { userId, code: baseOrderId, action: approve ? 'approve' : 'reject' },
            }),
            undefined,
        );
    }

    getBaseOrders(userId: string, search: string): Observable<AimoBaseOrderList> {
        return this.http
            .get<AimoBaseOrderList>(
                this.occEndpointsService.buildUrl('baseOrders', {
                    urlParams: { userId },
                    queryParams: {
                        search: search,
                    },
                }),
            )
            .pipe(
                map((cartList) => {
                    const carts = cartList.carts
                        ? this.converterService.convertMany(cartList.carts, CART_NORMALIZER)
                        : [];
                    return { ...cartList, carts };
                }),
            ) as Observable<AimoBaseOrderList>;
    }

    getBaseOrder(userId: string, code: string): Observable<AimoBaseOrder> {
        return this.http
            .get<AimoBaseOrder>(
                this.occEndpointsService.buildUrl('baseOrder', {
                    urlParams: { userId, code },
                }),
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER)) as Observable<AimoBaseOrder>;
    }

    createClaim(
        userId: string,
        date: string,
        remark: string,
        contactType: string,
        entries: AimoOrderEntry[],
    ): Observable<AimoCart> {
        return this.http
            .post<Occ.CartList>(
                this.occEndpointsService.buildUrl('claims', {
                    urlParams: { userId },
                    queryParams: {
                        remark: remark,
                        contactType: contactType,
                        requestedDate: date,
                    },
                }),
                entries.map((e) => ({ ...e, ...this.createOccEntry(e) })),
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    getClaims(userId: string, startDate?: string, endDate?: string, detailsView?: boolean): Observable<AimoCartList> {
        return this.http
            .get<AimoCartList>(
                this.occEndpointsService.buildUrl('claims', {
                    urlParams: { userId },
                    queryParams: {
                        requestedDate: startDate,
                        requestedDateEnd: endDate,
                        detailsView,
                    },
                }),
            )
            .pipe(
                map((claimsList) => {
                    const claims = claimsList.carts
                        ? this.converterService.convertMany(claimsList.carts, CART_NORMALIZER)
                        : [];
                    return { ...claimsList, carts: claims };
                }),
            );
    }

    getPurchaseReport(userId: string, params: AimoOrderHistoryRequest): Observable<AimoCart> {
        return this.http
            .post<AimoCart>(
                this.occEndpointsService.buildUrl('purchaseReport', {
                    urlParams: { userId },
                }),
                this.convertSearchParams(params),
            )
            .pipe(this.converterService.pipeable(CART_NORMALIZER));
    }

    getPurchaseReportExcel(userId: string, params: AimoOrderHistoryRequest): Observable<ArrayBuffer> {
        return this.http.post<ArrayBuffer>(
            this.occEndpointsService.buildUrl('purchaseReportExcel', {
                urlParams: { userId },
            }),
            this.convertSearchParams(params),
            // eslint-disable-next-line
            { responseType: 'arraybuffer' as any },
        );
    }

    downloadOrderDocument(userId: string, invoiceId: string, deliveryNote: boolean): Observable<ArrayBuffer> {
        const url = this.occEndpointsService.buildUrl('downloadOrderDocument', {
            urlParams: { userId, invoiceId, deliveryNote },
        });
        // eslint-disable-next-line
        return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as any });
    }

    private convertSearchParams(params: AimoOrderHistoryRequest): unknown {
        return {
            ...params,
            deliveryDate: {
                from: DateUtils.convertDateToOccString(this.language, params.deliveryDate.from),
                to: DateUtils.convertDateToOccString(this.language, params.deliveryDate.to),
            },
        };
    }

    // eslint-disable-next-line
    private createOccEntry(entry: AimoOrderEntry): any {
        return {
            entryNumber: entry.entryNumber,
            quantity: 1,
            doubleQuantity: entry.quantity,
            product: { code: entry.product.code },
            messageToCustomer: entry.messageToCustomer,
            messageToPickup: entry.messageToPickup,
            requestedDeliveryDate: entry.requestedDeliveryDate,
            gtmItemListId: entry.gtmItemListId,
        };
    }
}
