import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, NavigationSkipped, Router } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { HamburgerMenuComponent } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoHamburgerMenuService } from './aimo-hamburger-menu.service';

@Component({
    selector: 'aimo-cx-hamburger-menu',
    templateUrl: './aimo-hamburger-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoHamburgerMenuComponent extends HamburgerMenuComponent implements OnInit, OnDestroy {
    constructor(
        protected aimoHamburgerMenuService: AimoHamburgerMenuService,
        protected winRef: WindowRef,
        protected renderer: Renderer2,
        private router: Router,
    ) {
        super(aimoHamburgerMenuService);
    }

    subscription: Subscription;

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd || event instanceof NavigationSkipped) {
                this.renderer.removeClass(document.body, 'menu-open');
            }
        });
    }

    toggle(): void {
        this.aimoHamburgerMenuService.toggle();

        if (this.aimoHamburgerMenuService.isExpanded) {
            // Clear navigation classes and show start page for hamburger menu
            const header = this.winRef.document.getElementsByTagName('header')[0];
            header.classList.remove('category-expanded');

            // Disable background scrolling by adding menu-open for body
            if (header.classList.contains('is-expanded')) {
                this.renderer.removeClass(document.body, 'menu-open');
            } else {
                this.renderer.addClass(document.body, 'menu-open');
            }

            const mainNavigation = document.getElementById('main-navigation') as HTMLElement;
            const navs = mainNavigation.querySelectorAll('nav');
            navs.forEach((nav) => {
                nav.classList.remove('child-category-open', 'mobile-menu', 'is-expanded');
                nav.querySelectorAll('.main-title').forEach((title) => {
                    title.classList.remove('d-block');
                    title.classList.add('d-none');
                });
                const mainTitle = nav.querySelector('.main-title-mobile');
                mainTitle?.classList.remove('d-none');
                mainTitle?.classList.add('d-block');
            });
        }
    }
}
