<div #spinnerDiv class="d-none">
    <aimo-spinner></aimo-spinner>
</div>

<div class="d-flex vertical-align-item" *ngIf="isSelectComponent; else barComponent">
    <span class="text-opacity pr-3">{{ 'productList.pageSize.title' | cxTranslate }}</span>
    <ng-select
        [searchable]="false"
        [clearable]="false"
        class="aimo-select"
        placeholder="{{ 'productList.pageSize.placeholder' | cxTranslate }}"
        [ngModel]="model.pagination.pageSize"
        (change)="changeListingItems()">
        <ng-option *ngFor="let pageSize of pageSizes" [value]="pageSize">
            {{ pageSize }}
        </ng-option>
    </ng-select>
</div>

<ng-template #barComponent>
    <div class="text-center page-size-progress">
        <p>
            {{
                'productList.showAmount'
                    | cxTranslate
                        : {
                              amount: +size,
                              totalAmount: model.pagination.totalResults
                          }
            }}
        </p>
        <div class="progress">
            <div
                class="progress-bar progress-bar-success"
                role="progressbar"
                style="width: {{ (+size * 100) / model.pagination.totalResults }}%"
                aria-valuenow="{{ model.pagination.pageSize }}"
                aria-valuemin="0"
                aria-valuemax="100"></div>
        </div>
        <button
            class="btn btn-primary"
            [attr.aria-label]="'productList.arialLabel.showMore' | cxTranslate"
            [disabled]="size >= model.pagination.totalResults"
            (click)="changeListingItems()">
            {{ 'productList.showMore' | cxTranslate }}
        </button>
    </div>
</ng-template>
