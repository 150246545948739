import { Action } from '@ngrx/store/src/models';
import { PaginationModel } from '@spartacus/core';

import { AimoCart } from '../../model/cart.model';
import { AimoProduct } from '../../model/product.model';

import {
    AimoGTMProduct,
    AimoGTMProductAttributes,
    GTMCalendarSource,
    GTMCartType,
    GTMEventCode,
    GTMItemListId,
} from './aimo-gtm.model';

export const AIMO_GTM_PRODUCT = 'AIMO_[GTM] SINGLE Product';

export class AimoGtmProduct implements Action {
    readonly type = AIMO_GTM_PRODUCT;

    constructor(
        public product: AimoProduct,
        public event: GTMEventCode,
        public gtmProductAttributes: AimoGTMProductAttributes,
        public linkedProducts?: AimoProduct[],
    ) {}
}

export const AIMO_GTM_PRODUCTS = 'AIMO_[GTM]  Products';

export class AimoGtmProducts implements Action {
    readonly type = AIMO_GTM_PRODUCTS;

    constructor(
        public products: AimoGTMProduct[],
        public event: GTMEventCode,
        public value?: number,
        public searchTerm?: string,
        public totalHits?: number,
        public linkedProducts?: AimoGTMProduct[],
        public searchOrigin?: GTMCartType,
    ) {}
}

export const AIMO_VIEW_GTM_PRODUCTS = 'AIMO_[GTM] View Products';

export class AimoViewGtmProducts implements Action {
    readonly type = AIMO_VIEW_GTM_PRODUCTS;

    constructor(
        public products: AimoProduct[],
        public event: GTMEventCode,
        public itemListId: GTMItemListId,
        public searchTerm?: string,
        public pagination?: PaginationModel,
        public search_origin?: GTMCartType,
    ) {}
}

export const AIMO_GTM_CALENDAR = 'AIMO_[GTM] calendar';

export class AimoGtmCalendar implements Action {
    readonly type = AIMO_GTM_CALENDAR;

    constructor(public cart: AimoCart, public event: GTMEventCode, public source: GTMCalendarSource) {}
}

export const AIMO_PLACE_ORDER_CLICKED = 'AIMO_[GTM] place order clicked';

export class AimoPlaceOrderClicked implements Action {
    readonly type = AIMO_PLACE_ORDER_CLICKED;

    constructor(public cart: AimoCart) {}
}
