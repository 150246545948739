import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoCartSharedModule } from '../aimo-cart-shared.module';

import { AimoMiniCartComponent } from './aimo-mini-cart.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        IconModule,
        I18nModule,
        NgbPopoverModule,
        MediaModule,
        AimoCartSharedModule,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                MiniCartComponent: {
                    component: AimoMiniCartComponent,
                },
            },
        }),
    ],
    declarations: [AimoMiniCartComponent],
    exports: [AimoMiniCartComponent],
})
export class AimoMiniCartModule {}
