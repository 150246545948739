import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AuthService, RoutingService, UserIdService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { AimoUser } from '../../../model/user.model';
import { AimoUserService } from '../../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-consent',
    templateUrl: './aimo-consent.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoConsentComponent implements OnDestroy {
    subscription: Subscription = new Subscription();
    user$: Observable<AimoUser>;

    constructor(
        protected userService: AimoUserService,
        protected userIdService: UserIdService,
        protected routingService: RoutingService,
        protected auth: AuthService,
        protected actions$: Actions,
    ) {
        this.user$ = this.userService.get().pipe(
            filter((user) => user !== undefined),
            tap((user) => {
                if (user.consentApproved) {
                    this.routingService.goByUrl('/');
                }
            }),
        );
    }

    deny(): void {
        this.auth.coreLogout().finally(() => {
            this.routingService.goByUrl('/login');
        });
    }

    approve(): void {
        this.userService.giveConsent();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
