import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService, TranslationService, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { AimoCart } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AIMO_CART_UPDATE_HEADER_SUCCESS } from '../../../service/cart/aimo-cart.action';
import { DateUtils } from '../../../shared/util/date-utils';

@Component({
    selector: 'aimo-order-history-order',
    templateUrl: './aimo-order-history-order.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistoryOrderComponent implements OnDestroy, OnInit {
    @Input()
    order: AimoCart;

    private subscriptions = new Subscription();
    invoices: string[];

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected windowRef: WindowRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected translation: TranslationService,
    ) {
        this.subscriptions.add(
            this.actions$
                .pipe(ofType(AIMO_CART_UPDATE_HEADER_SUCCESS, CartActions.CART_ADD_ENTRY_SUCCESS))
                .subscribe(() => this.routingService.go('/cart')),
        );
    }

    ngOnInit(): void {
        this.invoices = Array.from(
            new Set(this.order.entries.map((e) => e.invoiceDocumentNumber).filter((n) => n !== undefined)),
        );
    }

    downloadInvoice(invoiceId: string, name: string): void {
        this.translation
            .translate(name, { id: invoiceId })
            .subscribe((t) => this.activeCartService.downloadOrderDocument(invoiceId, t, false))
            .unsubscribe();
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    payNow(code: string): void {
        this.routingService.go('/cart', { queryParams: { payNow: code } });
    }
}
