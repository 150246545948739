import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, RoutingService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { UpdateProfileComponent } from '@spartacus/user/profile/components';
import { Subscription } from 'rxjs';

import { AimoRoutingService } from '../../../../cms-structure/routing/aimo-routing.service';
import { AimoUser } from '../../../../model/user.model';
import { AimoUserService } from '../../../../service/user/aimo-user.service';

import { AimoUpdateProfileComponentService } from './aimo-update-profile-component.service';

@Component({
    selector: 'aimo-update-profile',
    templateUrl: './aimo-update-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line
    host: { class: 'user-form' },
})
export class AimoUpdateProfileComponent extends UpdateProfileComponent implements OnInit, OnDestroy {
    constructor(
        protected service: AimoUpdateProfileComponentService,
        protected router: Router,
        protected routingService: RoutingService,
        protected authService: AuthService,
        protected userService: AimoUserService,
        protected cdr: ChangeDetectorRef,
        protected launchDialogService: LaunchDialogService,
        protected winRef: WindowRef,
        protected aimoRoutingService: AimoRoutingService,
    ) {
        super(service);
    }

    subscriptions: Subscription = new Subscription();
    form: UntypedFormGroup = null;
    user: AimoUser;
    // eslint-disable-next-line
    private originalFormValue: any;

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.userService.get().subscribe((user) => {
                if (user !== undefined) {
                    this.user = user;
                    this.form = this.service.initForm(user);
                    this.originalFormValue = JSON.stringify(this.form.value);
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    openUpdatePassword(): void {
        this.router.navigate(['/my-account/update-password']);
    }

    closeUserAccount(): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLOSE_ACCOUNT, undefined);
    }

    checkForChanges(): boolean {
        const currentFormValue = JSON.stringify(this.form.value);
        if (this.originalFormValue !== currentFormValue) {
            return true;
        }
        return false;
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
