<div class="customer-selection-wrapper" *ngIf="currentUser$ | async as currentUser">
    <button
        class="current-customer link"
        [tabIndex]="0"
        role="button"
        attr.aria-label="{{ 'aimo.b2bunit.dropdown.arialLabel' | cxTranslate }}"
        (click)="toggleVisible()"
        *ngIf="currentUser.availableUnits.length > 1 || currentUser.tukkumyyja; else singleResult">
        <i class="aimo-icon icon-sm icon-white icon-buildings mr-2 d-lg-inline-block d-none"></i>
        <i class="aimo-icon icon-petrol-blue icon-buildings mr-2 d-lg-none d-inline-block"></i>
        <span>{{ currentUser?.unit?.uid }} {{ currentUser?.unit?.name }}</span>
        <i
            class="aimo-icon icon-sm icon-white ml-2 d-lg-inline-block d-none"
            [class.icon-caret-down]="!visible"
            [class.icon-caret-up]="visible"></i>
        <i
            class="aimo-icon icon-petrol-blue ml-2 d-lg-none d-inline-block float-right"
            [class.icon-caret-down]="!visible"
            [class.icon-caret-up]="visible"></i>
    </button>

    <div class="customer-selection" *ngIf="visible">
        <cx-page-slot position="SearchUnits"></cx-page-slot>

        <div class="results" id="results" role="dialog"></div>
        <div
            class="my-results"
            id="my-results"
            role="dialog"
            *ngIf="!currentUser.tukkumyyja"
            (keydown.escape)="closeDropdown()">
            <div class="customer-header">{{ 'aimo.b2bunit.results.header' | cxTranslate }}</div>
            <ul class="b2bunits" role="listbox">
                <li
                    [ngClass]="{ 'credit-card-user': !b2bUnit.allowInvoices }"
                    *ngFor="let b2bUnit of currentUser.availableUnits; index as idx"
                    [attr.aria-label]="'aimo.arialLabel.customer.dropdown' | cxTranslate : { customer: b2bUnit.name }">
                    <button
                        [id]="'customer_select_' + idx"
                        (keydown.arrowDown)="nextFocus($event, idx, currentUser.availableUnits.length)"
                        (keydown.arrowUp)="previousFocus($event, idx)"
                        (mouseenter)="setFocus(idx)"
                        class="unit-button"
                        (click)="selectUnit(b2bUnit.uid, currentUser)">
                        <div class="unit-number">
                            <div>{{ b2bUnit.uid }}</div>
                            <i class="aimo-icon icon-credit-card icon-sm icon-red" *ngIf="!b2bUnit.allowInvoices"></i>
                        </div>
                        <div class="unit-name" (click)="selectUnit(b2bUnit.uid, currentUser)">
                            <div class="text-bold" [innerHTML]="b2bUnit.name"></div>
                            <ng-container *ngIf="b2bUnit.addresses?.length > 0">
                                <div [innerHTML]="b2bUnit.addresses[0].line1"></div>
                                <div>
                                    <div
                                        class="d-inline-block mr-1"
                                        [innerHTML]="b2bUnit.addresses[0].postalCode"></div>
                                    <div class="d-inline-block" [innerHTML]="b2bUnit.addresses[0].town"></div>
                                </div>
                            </ng-container>
                        </div>
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <ng-template #singleResult>
        <div class="current-customer">
            <span>{{ currentUser?.unit?.uid }} {{ currentUser?.unit?.name }}</span>
        </div>
    </ng-template>
</div>
