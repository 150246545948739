<div class="container" *ngIf="data$ | async as data">
    <div class="row no-gutters">
        <div class="col-12" [ngClass]="{ 'col-lg-6': data.image?.url }">
            <div class="category-content" [ngClass]="{ 'category-content-half-width': data.image?.url }">
                <aimo-breadcrumb [hideTitle]="true" [showBackLink]="false" [contentPageTitle]="data.title">
                </aimo-breadcrumb>
                <div class="category-description">
                    <h1>{{ data.title }}</h1>
                    <div [innerHTML]="data.text">{{ data.text }}</div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6" *ngIf="data.image?.url">
            <div class="category-image">
                <cx-media [container]="data.image"></cx-media>
            </div>
        </div>
    </div>
</div>
