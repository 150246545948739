import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DELIVERY_DATE_FEATURE } from './aimo-deliverydate.action';
import { AimoDeliveryDateEffects } from './aimo-deliverydate.effect';
import { aimoDeliveryDateReducerProvider, metaReducers, reducerToken } from './aimo-deliverydate.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature(DELIVERY_DATE_FEATURE, reducerToken, { metaReducers }),
        EffectsModule.forFeature([AimoDeliveryDateEffects]),
    ],
    providers: [aimoDeliveryDateReducerProvider],
})
export class AimoDeliverydateModule {}
