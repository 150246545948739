import { Injectable } from '@angular/core';
import { ProductSearchConnector } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoSearchConfig, FileExportWrapper } from '../../../model/misc.model';
import { AimoProduct } from '../../../model/product.model';
import { AimoOccProductAdapter } from '../aimo-occ-product.adapter';

import { AimoOccProductSearchAdapter } from './aimo-occ-product-search.adapter';

@Injectable({
    providedIn: 'root',
})
export class AimoProductSearchConnector extends ProductSearchConnector {
    constructor(protected searchAdapter: AimoOccProductSearchAdapter, protected productAdapter: AimoOccProductAdapter) {
        super(searchAdapter);
    }

    excelExportProducts(query: string, searchConfig: AimoSearchConfig): Observable<FileExportWrapper> {
        return this.searchAdapter
            .excelExportProducts(query, searchConfig)
            .pipe(map((buffer) => ({ name: 'products.xlsx', data: buffer } as FileExportWrapper)));
    }

    loadManyProducts(userId: string, products: string[]): Observable<AimoProduct[]> {
        return this.productAdapter.loadManyProducts(userId, products);
    }
}
