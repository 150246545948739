<div
    class="modal-dialog modal-dialog-centered"
    [ngClass]="{ 'modal-dialog-overflow-visible': form.controls.from || form.controls.to }">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0" id="template-modal-title">
                {{ 'aimo.baseOrder.blockTitle' | cxTranslate }}
            </h2>
            <div class="modal-action col-auto">
                <button #closeButton class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" [formGroup]="form" *ngIf="form">
            <div class="input-label-value required text-bold mb-3">
                {{ 'aimo.baseOrder.selection.label' | cxTranslate }}
            </div>
            <fieldset id="selection" class="mb-3">
                <ng-container *ngFor="let sel of selection; index as idx">
                    <label [for]="'selection_' + idx" class="field-content mb-2">
                        <input
                            [id]="'selection_' + idx"
                            (change)="switchSelection()"
                            type="radio"
                            name="selection"
                            [value]="sel"
                            [formControlName]="'selection'" />
                        <span>{{ 'aimo.baseOrder.selection.' + sel | cxTranslate }}</span>
                    </label>
                </ng-container>
            </fieldset>

            <ng-container *ngIf="form.controls.from || form.controls.to; else forNowMessage">
                <div class="mb-4">
                    <div class="input-label-value required text-bold">
                        {{ 'aimo.baseOrder.timeSpan.label' | cxTranslate }}
                    </div>
                    <div class="range-dates">
                        <aimo-datepicker-input
                            *ngIf="form.controls.from"
                            class="date-from"
                            [required]="true"
                            [errorMessage]="'formErrors.required'"
                            [idName]="'delivery_date_from'"
                            [placeholder]="'aimo.orderhistory.selectDate' | cxTranslate"
                            [parentForm]="form"
                            [submitClicked]="submitClicked"
                            [selectedDate]="getStartDate()"
                            [firstAllowedDate]="convertJsDate(existingBaseOrder.firstPossibleDeliveryDate)"
                            [customDayTemplate]="customDay"
                            [nameForm]="'from'"></aimo-datepicker-input>
                        <span class="date-divider"> - </span>
                        <aimo-datepicker-input
                            *ngIf="form.controls.to"
                            class="date-to"
                            [required]="true"
                            [errorMessage]="'formErrors.required'"
                            [idName]="'delivery_date_to'"
                            [placeholder]="'aimo.orderhistory.selectDate' | cxTranslate"
                            [parentForm]="form"
                            [submitClicked]="submitClicked"
                            [selectedDate]="getEndDate()"
                            [customDayTemplate]="customDay"
                            [firstAllowedDate]="convertJsDate(existingBaseOrder.firstPossibleDeliveryDate)"
                            [nameForm]="'to'"></aimo-datepicker-input>
                    </div>
                </div>
            </ng-container>

            <ng-template
                #customDay
                let-date
                let-currentMonth="currentMonth"
                let-today="today"
                let-selected="selected"
                let-disabled="disabled"
                let-focused="focused">
                <button
                    class="calendar-cell calendar-day link"
                    [ngClass]="{ focused: focused, selected: selected, disabled: disabled }">
                    {{ date.day }}
                </button>
            </ng-template>

            <ng-template #forNowMessage>
                <div class="messages mb-4" *ngIf="!isForNowBlock()">
                    <div class="message message-MESSAGE">
                        <i class="aimo-icon icon-MESSAGE icon-inline mr-2"></i>
                        <div class="d-inline">
                            <span>{{
                                'aimo.baseOrder.selection.removeMessage'
                                    | cxTranslate
                                        : {
                                              date:
                                                  (existingBaseOrder.firstPossibleDeliveryDate
                                                  | cxDate : ('aimo.dateFormat' | cxTranslate)),
                                              datesAhead: existingBaseOrder.deliveryDatesAhead
                                          }
                            }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="mb-4">
                <aimo-cx-input
                    *ngIf="form.controls.comment"
                    [idName]="'comment'"
                    [isTextArea]="true"
                    [inputClass]="'input-sm'"
                    [placeholder]="'aimo.baseOrder.comment'"
                    [labelClass]="'text-bold pt-0'"
                    [label]="'aimo.baseOrder.commentLabel'"
                    [nameForm]="'comment'"
                    [parentForm]="form">
                </aimo-cx-input>
            </div>

            <div class="form-check" *ngIf="form.controls.sendNotification">
                <label>
                    <input type="checkbox" [formControlName]="'sendNotification'" [checked]="isSendNotification()" />
                    <span>{{ 'aimo.baseOrder.sendNotification' | cxTranslate }}</span>
                </label>
            </div>

            <div class="modal-actions row no-gutters align-items-center">
                <div class="col-md-auto col-6 pr-1">
                    <button
                        class="btn btn-primary btn-sm btn-inline btn-block-sm-down"
                        [attr.aria-label]="'aimo.baseOrder.blockSave' | cxTranslate"
                        [title]="'aimo.baseOrder.blockSave' | cxTranslate"
                        (click)="submit()">
                        {{ 'aimo.baseOrder.blockSave' | cxTranslate }}
                    </button>
                </div>
                <div class="col-md-auto col-6 pl-1">
                    <button
                        class="btn btn-secondary btn-sm btn-inline btn-block-sm-down"
                        [attr.aria-label]="'common.cancel' | cxTranslate"
                        [title]="'common.cancel' | cxTranslate"
                        (click)="closeDialog()">
                        {{ 'common.cancel' | cxTranslate }}
                    </button>
                </div>
                <div class="col-md-auto ml-auto col-12 pt-md-0 pt-3 text-right">
                    <button
                        *ngIf="existingBaseOrder.salesBlocked || existingBaseOrder.aboutToBeSalesBlocked"
                        (click)="delete(existingBaseOrder)"
                        [attr.aria-label]="'aimo.baseOrder.selection.remove' | cxTranslate"
                        [title]="'aimo.baseOrder.selection.remove' | cxTranslate"
                        class="text-red btn-link">
                        <i class="aimo-icon icon-trash icon-red icon-inline"></i>
                        <span>{{ 'aimo.baseOrder.selection.remove' | cxTranslate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
