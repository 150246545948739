import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService, GlobalMessageService, WindowRef } from '@spartacus/core';
import { PageLayoutService, ProductListComponent, ViewConfig, ViewModes } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AimoRoutingService } from '../../../../cms-structure/routing/aimo-routing.service';
import { AimoProductFacetService } from '../../../../service/product/aimo-product-facet.service';
import { AimoProductListComponentService } from '../../../../service/product/aimo-product-list-component.service';
import { AimoProductService } from '../../../../service/product/aimo-product.service';
import { AimoProductFacetNavigationComponent } from '../../facet-navigation/aimo-product-facet-navigation.component';

@Component({
    selector: 'aimo-product-list',
    templateUrl: './aimo-product-list.component.html',
})
export class AimoProductListComponent extends ProductListComponent implements OnInit {
    private subscriptions = new Subscription();

    favoriteSelected = false;
    clickAndCollectSelected = false;

    @ViewChild('fixedFacets')
    fixedFacets: ElementRef;

    @ViewChild('productFacetNavigation')
    productFacetNavigation: AimoProductFacetNavigationComponent;

    loggedUser$ = this.auth.isUserLoggedIn();

    constructor(
        protected pageLayoutService2: PageLayoutService,
        protected productListComponentService2: AimoProductListComponentService,
        protected globalMessageService2: GlobalMessageService,
        protected scrollConfig2: ViewConfig,
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected productService: AimoProductService,
        private renderer: Renderer2,
        protected productFacetService: AimoProductFacetService,
        protected windowRef: WindowRef,
        protected aimoRoutingService: AimoRoutingService,
        protected auth: AuthService,
    ) {
        super(pageLayoutService2, productListComponentService2, globalMessageService2, scrollConfig2);
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.pageLayoutService2.templateName$.pipe(take(1)).subscribe((template) => {
                this.viewMode$.next(template.indexOf('Grid') >= 0 ? ViewModes.Grid : ViewModes.List);
            }),
        );
        this.subscriptions.add(
            this.activatedRoute.queryParams.subscribe((queryParams) => {
                this.favoriteSelected = queryParams?.query?.indexOf('favorites:true') > 0;
                this.clickAndCollectSelected = queryParams?.query?.indexOf('clickAndCollect:true') > 0;
            }),
        );
    }

    changeListingProducts(pageSize: number, pageNumber: number): void {
        this.productListComponentService2.getPageItemsForPageSize(pageSize, pageNumber);
    }

    search(queryParams: Params): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
        });
    }

    toggleFavorite(): void {
        this.favoriteSelected = !this.favoriteSelected;
        this.toggleFeature(this.favoriteSelected, 'favorites');
    }

    toggleClickAndCollect(): void {
        this.clickAndCollectSelected = !this.clickAndCollectSelected;
        this.toggleFeature(this.clickAndCollectSelected, 'clickAndCollect');
    }

    private toggleFeature(enabled: boolean, name: string): void {
        const currentQuery =
            this.productFacetService.getCurrentQuery(this.activatedRoute) +
            this.productFacetService.initQueryValue(this.activatedRoute);
        if (enabled) {
            this.search({ query: currentQuery.replace(':' + name + ':false', '') + ':' + name + ':true' });
        } else {
            this.search({ query: currentQuery.replace(':' + name + ':true', '') });
        }
    }

    excelExportProducts(): void {
        const queryValue = this.productFacetService.initQueryValue(this.activatedRoute);
        const currentQuery = this.productFacetService.getCurrentQuery(this.activatedRoute);
        this.productService.excelExportProducts(currentQuery + queryValue);
    }

    @HostListener('window:scroll', ['$event'])
    addFixedClass(): void {
        const header = <HTMLElement>document.querySelector('.sticky-header');
        if (header.offsetHeight > this.fixedFacets?.nativeElement.parentElement.getBoundingClientRect().top) {
            this.fixedFacets?.nativeElement.classList.add('enabled');
        } else {
            this.fixedFacets?.nativeElement.classList.remove('enabled');
        }
        const placeHolder = <HTMLElement>document.querySelector('.cx-sorting-fixed-placeholder');
        if (placeHolder) {
            this.renderer.setStyle(placeHolder, 'height', `${this.fixedFacets?.nativeElement.offsetHeight}px`);
        }
    }

    favoriteToggleVisible(): boolean {
        return (
            false && // disabled in klevu
            ((this.activatedRoute.snapshot.url.length > 0 && this.activatedRoute.snapshot.url[0].path === 'search') ||
                (this.activatedRoute.snapshot.url.length > 1 &&
                    this.activatedRoute.snapshot.url[1].path !== 'favorites'))
        );
    }

    print(): void {
        this.windowRef.nativeWindow.print();
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    showClickAndCollect(): boolean {
        return (
            this.isPikatukku() &&
            this.activatedRoute.snapshot.url.filter((url) => url.path === 'clickAndCollect').length === 0
        );
    }
}
