<ng-container *ngIf="model$ | async as model; else loading">
    <section
        class="product-list-results"
        tabindex="-1"
        [attr.aria-label]="'productList.productListResults' | cxTranslate">
        <ng-container *ngIf="viewMode$ | async as viewMode">
            <div class="cx-sorting top">
                <div
                    #fixedFacets
                    class="cx-sorting-fixed noPrint"
                    [ngClass]="(this.productFacetNavigation.isOpen$ | async) ? 'top' : ''">
                    <div class="cx-sorting-inner">
                        <div class="cx-sorting-padding">
                            <!-- Mobile facets sticky -->
                            <div class="d-block d-md-none">
                                <div class="row">
                                    <div class="col-4 facet-list">
                                        <aimo-product-facet-navigation
                                            [mobileUI]="true"
                                            #productFacetNavigation></aimo-product-facet-navigation>
                                    </div>
                                    <div class="col-8 cx-sort-dropdown text-right">
                                        <ng-container *ngTemplateOutlet="aimoSorting"></ng-container>
                                    </div>
                                </div>
                            </div>

                            <!-- Desktop facets sticky -->
                            <div class="facet-list-wrapper d-none d-md-flex">
                                <div class="facet-list">
                                    <aimo-product-facet-navigation [mobileUI]="false"></aimo-product-facet-navigation>
                                </div>
                                <div class="facet-switch text-right">
                                    <div
                                        class="d-md-inline product-list-action product-list-action-md"
                                        *ngIf="favoriteToggleVisible() && loggedUser$ | async">
                                        <label for="toggleFavorite">
                                            <div class="aimo-switch">
                                                <input
                                                    id="toggleFavorite"
                                                    name="checkbox_toggleFavorite"
                                                    type="checkbox"
                                                    [value]="favoriteSelected"
                                                    [checked]="favoriteSelected"
                                                    (change)="toggleFavorite()" />
                                                <span class="slider round"></span>
                                            </div>
                                            <span>{{ 'productList.favorites' | cxTranslate }}</span>
                                        </label>
                                    </div>
                                    <div
                                        class="d-md-inline product-list-action product-list-action-md"
                                        *ngIf="showClickAndCollect()">
                                        <label class="aimo-switch" for="clickAndCollect">
                                            <input
                                                id="clickAndCollect"
                                                type="checkbox"
                                                [checked]="clickAndCollectSelected"
                                                (change)="toggleClickAndCollect()" />
                                            <span class="slider round"></span>
                                        </label>
                                        <span>{{ 'productList.order.collect' | cxTranslate }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cx-sorting-fixed-placeholder"></div>

                <!-- Mobile facets below sticky -->
                <div class="d-block d-md-none">
                    <div class="row">
                        <div class="col-12">
                            <aimo-active-facets [productSearchPage]="model"></aimo-active-facets>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-3" *ngIf="favoriteToggleVisible() && loggedUser$ | async">
                            <label for="toggleFavorite_mobile">
                                <div class="aimo-switch">
                                    <input
                                        id="toggleFavorite_mobile"
                                        name="checkbox_toggleFavorite"
                                        type="checkbox"
                                        [value]="favoriteSelected"
                                        [checked]="favoriteSelected"
                                        (change)="toggleFavorite()" />
                                    <span class="slider round"></span>
                                </div>
                                <span>{{ 'productList.favorites' | cxTranslate }}</span>
                            </label>
                        </div>
                        <div class="col-6 mb-3" *ngIf="showClickAndCollect()">
                            <label class="aimo-switch" for="clickAndCollect_mobile">
                                <input
                                    id="clickAndCollect_mobile"
                                    type="checkbox"
                                    [checked]="clickAndCollectSelected"
                                    (change)="toggleClickAndCollect()" />
                                <span class="slider round"></span>
                            </label>
                            <span>{{ 'productList.order.collect' | cxTranslate }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <ng-container *ngTemplateOutlet="aimoTotalResults"></ng-container>
                        </div>
                        <div class="col-8 text-right">
                            <ng-container *ngTemplateOutlet="aimoListActions"></ng-container>
                        </div>
                    </div>
                </div>

                <!-- Desktop facets below sticky -->
                <div class="active-facet-list-wrapper d-none d-md-flex">
                    <div class="active-facet-list">
                        <aimo-active-facets [productSearchPage]="model"></aimo-active-facets>
                        <ng-container *ngTemplateOutlet="aimoTotalResults"></ng-container>
                    </div>
                    <div class="cx-sort-dropdown text-right">
                        <ng-container *ngTemplateOutlet="aimoListActions"></ng-container>
                        <ng-container *ngTemplateOutlet="aimoSorting"></ng-container>
                    </div>
                </div>
            </div>

            <div id="product-results-list" class="cx-product-container">
                <aimo-product-scroll [setModel]="model"></aimo-product-scroll>
            </div>

            <aimo-cx-page-size
                [model]="model"
                [isSelectComponent]="false"
                (pageSizeEvent)="changeListingProducts($event, model.pagination.currentPage)"></aimo-cx-page-size>

            <cx-page-slot position="NoResultsSlot" *ngIf="model.pagination.totalResults < 1"></cx-page-slot>
        </ng-container>
    </section>

    <ng-template #aimoSorting>
        <aimo-sorting
            [sortOptions]="model.sorts"
            (sortListEvent)="sortList($event)"
            [selectedOption]="model.pagination?.sort"
            [ariaLabel]="'productList.sortResults' | cxTranslate"
            ariaControls="product-results-list"
            placeholder="{{ 'productList.sortBy' | cxTranslate }}"></aimo-sorting>
    </ng-template>

    <ng-template #aimoListActions>
        <div class="product-list-action d-inline-block">
            <a class="btn-link" (click)="excelExportProducts()" *ngIf="loggedUser$ | async">
                <i class="aimo-icon icon-sm icon-inline icon-file-arrow-down"></i>
                <span>{{ 'productList.export.excel' | cxTranslate }}</span>
            </a>
        </div>
        <div class="product-list-action d-inline-block">
            <a class="btn-link" [id]="'print'" (click)="print()">
                <i class="aimo-icon icon-sm icon-inline icon-printer"></i>
                <span>{{ 'productList.print' | cxTranslate }}</span>
            </a>
        </div>
    </ng-template>

    <ng-template #aimoTotalResults>
        <span class="total-results">{{
            'productList.noProducts' | cxTranslate : { count: model?.pagination.totalResults }
        }}</span>
    </ng-template>
</ng-container>

<ng-template #loading>
    <aimo-spinner></aimo-spinner>
</ng-template>
