import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { ListNavigationModule, MediaModule, PageSlotModule } from '@spartacus/storefront';

import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoInputSelectModule } from '../../shared/input-select/aimo-input-select.module';
import { AimoItemCounterModule } from '../../shared/item-counter/aimo-item-counter.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from '../cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoBreadcrumbModule } from '../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoOrderTemplateModule } from '../order-template/aimo-order-template.module';
import { AimoProductListModule } from '../product/product-list/aimo-product-list.module';
import { AimoDatepickerModule } from '../shared/datepicker/aimo-datepicker.module';

import { AimoReportsComponent } from './aimo-reports.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        AimoInputModule,
        AimoDatepickerModule,
        ReactiveFormsModule,
        MediaModule,
        AimoDeliveryDateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        NgbDropdownModule,
        AimoCartSharedModule,
        PageSlotModule,
        AimoInputSelectModule,
        AimoItemCounterModule,
        AimoBreadcrumbModule,
        AimoOrderTemplateModule,
        AimoProductListModule,
    ],
    declarations: [AimoReportsComponent],
    exports: [AimoReportsComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AccountReportsComponent: {
                    component: AimoReportsComponent,
                    guards: [AuthGuard],
                },
            },
        }),
    ],
})
export class AimoReportsModule {}
