import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
    AuthService,
    CmsConfig,
    GlobalMessageService,
    I18nModule,
    NotAuthGuard,
    provideConfig,
    RoutingService,
    UrlModule,
    WindowRef
} from '@spartacus/core';
import {FormErrorsModule, PageSlotModule, SpinnerModule} from '@spartacus/storefront';
import {LoginFormComponentService, LoginFormModule} from '@spartacus/user/account/components';

import {AimoInputModule} from '../../../shared/input/aimo-input.module';

import {AimoLoginFormComponent} from './aimo-login-form.component';
import {AimoLoginFormComponentService} from "./aimo-login-form-component.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        SpinnerModule,
        AimoInputModule,
        PageSlotModule,
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                ReturningCustomerLoginComponent: {
                    component: AimoLoginFormComponent,
                    guards: [NotAuthGuard],
                    providers: [
                        {
                            provide: LoginFormComponentService,
                            useClass: AimoLoginFormComponentService,
                            deps: [AuthService, GlobalMessageService, WindowRef, RoutingService],
                        },
                    ],
                },
            },
        }),
    ],
    declarations: [AimoLoginFormComponent],
})
export class AimoLoginFormModule extends LoginFormModule {}
