import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FormErrorsModule, SpinnerModule } from '@spartacus/storefront';
import { AimoInputModule } from 'src/app/features/shared/input/aimo-input.module';

import { AimoConsentComponent } from './aimo-consent.component';

@NgModule({
    declarations: [AimoConsentComponent],
    exports: [AimoConsentComponent],
    imports: [
        CommonModule,
        FormErrorsModule,
        FormsModule,
        I18nModule,
        NgbTooltipModule,
        AimoInputModule,
        ReactiveFormsModule,
        SpinnerModule,
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                AimoRequireConsentComponent: {
                    component: AimoConsentComponent,
                },
            },
        }),
    ],
})
export class AimoConsentModule {}
