import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MultiCartService, StateWithMultiCart } from '@spartacus/cart/base/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { OCC_USER_ID_ANONYMOUS, UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, find, switchMap } from 'rxjs/operators';

import { AimoCart, AimoCartList, AimoOrderEntry } from '../../model/cart.model';
import { AimoGTMProductAttributes, GTMCalendarSource, GTMCartType } from '../gtm/aimo-gtm.model';

import {
    AimoAddManyProducts,
    AimoEmptyCart,
    AimoExcelImport,
    AimoOrderTemplateImport,
    AimoUpdateCartHeader,
} from './aimo-cart.action';
import { AimoCartConnector } from './aimo-cart.connector';
import { AimoCartAddEntries, AimoCartAddEntry, AimoCartUpdateEntry } from './aimo-entry.action';

@Injectable({
    providedIn: 'root',
})
export class AimoMultiCartService extends MultiCartService {
    constructor(
        protected store: Store<StateWithMultiCart>,
        protected userIdService: UserIdService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected cartConnector: AimoCartConnector,
    ) {
        super(store, userIdService);
    }

    getOrderTemplates(
        userId: string,
        visibility: string,
        search: string,
        onlyEditable: boolean,
    ): Observable<AimoCartList> {
        return this.cartConnector.getOrderTemplates(userId, visibility, search, onlyEditable);
    }

    getOrderTemplate(userId: string, code: string, requestedDeliveryDate: string): Observable<AimoCart> {
        return this.cartConnector.getOrderTemplate(userId, code, requestedDeliveryDate);
    }

    createOrderTemplate(
        userId: string,
        visibility: string,
        name: string,
        oldCartId?: string,
        orderHistoryDay?: string,
    ): Observable<AimoCart> {
        return this.cartConnector.createOrderTemplate(userId, visibility, name, oldCartId, orderHistoryDay);
    }

    editOrderTemplate(userId: string, templateId: string, cart: AimoCart): Observable<AimoCart> {
        return this.cartConnector.updateHeader(userId, templateId, cart, false);
    }

    createClaim(
        userId: string,
        date: string,
        remark: string,
        contactType: string,
        entries: AimoOrderEntry[],
    ): Observable<AimoCart> {
        return this.cartConnector.createClaim(userId, date, remark, contactType, entries);
    }

    getClaims(userId: string): Observable<AimoCartList> {
        return this.cartConnector.getClaims(userId);
    }

    getCart(cartId: string): Observable<AimoCart> {
        return super.getCart(cartId) as Observable<AimoCart>;
    }

    getEntries(cartId: string): Observable<AimoOrderEntry[]> {
        return super.getEntries(cartId) as Observable<AimoOrderEntry[]>;
    }

    // used to prevent http flooding when user taps the quantity + / - buttons
    latestEntry: AimoOrderEntry;

    addAimoEntry(
        userId: string,
        cartId: string,
        entry: AimoOrderEntry,
        gtmProductAttributes?: AimoGTMProductAttributes,
    ): void {
        this.latestEntry = entry;
        setTimeout(() => {
            if (this.latestEntry.product.code !== entry.product.code || this.latestEntry.quantity === entry.quantity) {
                this.store.dispatch(new AimoCartAddEntry(userId, cartId, entry, gtmProductAttributes));
            }
        }, 700);
    }

    addAimoEntries(
        userId: string,
        cartId: string,
        entries: AimoOrderEntry[],
        origin: GTMCartType,
        searchTerm: string,
    ): void {
        this.store.dispatch(new AimoCartAddEntries(userId, cartId, entries, origin, searchTerm));
    }

    updateAimoEntry(
        userId: string,
        cartId: string,
        entry: AimoOrderEntry,
        gtmProductAttributes?: AimoGTMProductAttributes,
    ): void {
        this.latestEntry = entry;
        setTimeout(() => {
            if (this.latestEntry.product.code !== entry.product.code || this.latestEntry.quantity === entry.quantity) {
                this.store.dispatch(new AimoCartUpdateEntry(userId, cartId, entry, gtmProductAttributes));
            }
        }, 700);
    }

    updateCartHeader(
        userId: string,
        cartId: string,
        cart: AimoCart,
        calendarSource?: GTMCalendarSource,
        resetExternalItems?: boolean,
    ): void {
        this.store.dispatch(new AimoUpdateCartHeader(userId, cartId, cart, calendarSource, resetExternalItems));
    }

    reloadCart(): void {
        this.router.navigated = false;
        this.router.navigate(['./'], {
            relativeTo: this.route,
        });
    }

    // eslint-disable-next-line
    loadCart({ cartId, userId, extraData }: { cartId: string; userId: string; extraData?: any }): void {
        if (userId === OCC_USER_ID_ANONYMOUS) {
            // we do not support anonymous carts, no need in unnecessary backend stacktraces or unnecessary anonymous carts
            return;
        }
        super.loadCart({ cartId, userId, extraData });
    }

    emptyCart(userId: string, cartId: string): void {
        this.store.dispatch(new AimoEmptyCart(userId, cartId));
    }

    getEntry(cartId: string, productCode: string): Observable<OrderEntry | undefined> {
        return this.getCart(cartId).pipe(
            filter(Boolean),
            switchMap((cart: AimoCart) => cart.dayGroupedEntries),
            switchMap((day) => day.entries),
            find((entry) => entry.product.code === productCode),
        );
    }

    excelImport(userId: string, cartId: string, data: string): void {
        this.store.dispatch(new AimoExcelImport(userId, cartId, data));
    }

    orderTemplateImport(userId: string, cartId: string, fromId: string, selectedDate?: string): void {
        this.store.dispatch(new AimoOrderTemplateImport(userId, cartId, fromId, selectedDate));
    }

    addManyProducts(userId: string, cartId: string, data: AimoCart): void {
        this.store.dispatch(new AimoAddManyProducts(userId, cartId, data));
    }
}
