<div class="container" *ngIf="data$ | async as data">
    <div class="row no-gutters">
        <div class="col-12" [ngClass]="{ 'col-lg-6': data.image?.url }">
            <div class="category-content" [ngClass]="{ 'category-content-half-width': data.image?.url }">
                <aimo-breadcrumb [hideTitle]="true" [showBackLink]="false" [contentPageTitle]="data.title">
                </aimo-breadcrumb>
                <div class="category-description">
                    <h1>{{ data.title }}</h1>
                    <div [innerHTML]="data.text"></div>
                </div>
                <div class="sub-categories-wrapper" *ngIf="categories$ | async as categories">
                    <div class="sub-categories">
                        <a
                            *ngIf="allBulletins$ | async as allBulletins"
                            (click)="clickCategory()"
                            class="btn-facet btn-sm category"
                            [ngClass]="{ selected: isAllCategoriesSelected() }">
                            {{ 'aimo.bulletin.allCategories' | cxTranslate }}
                            <span class="amount">{{ allBulletins.length }}</span>
                        </a>
                        <a
                            *ngFor="let category of categories"
                            (click)="clickCategory(category.code)"
                            class="btn-facet btn-sm category link"
                            [ngClass]="{ selected: category.selected }">
                            {{ category.name }}
                            <span class="amount">{{ category.amount }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6" *ngIf="data.image?.url">
            <div class="category-image">
                <cx-media [container]="data.image"></cx-media>
            </div>
        </div>
    </div>
</div>
