<div
    class="cx-order-history-day"
    [ngClass]="{ active: open, noPrint: !open }"
    id="day_{{ day.dateTitle.replace(' ', '_') }}">
    <div class="row no-gutters onlyPrint mb-3">
        <div class="col-10">
            <h1 class="print-keep-font-size">{{ 'aimo.orderhistory.title' | cxTranslate }} {{ day.dateTitle }}</h1>
        </div>
        <div class="col-2">
            <cx-page-slot position="SiteLogo"></cx-page-slot>
        </div>
    </div>
    <div class="row no-gutters header" (click)="toggleDay()">
        <div class="col-md-5 col-lg-5 col-7">
            <div class="row no-gutters align-items-center">
                <div class="col-md-8 col-lg-8 col-10 cursor-pointer d-flex align-items-center">
                    <i
                        class="aimo-icon icon-inline icon-sm icon-sm-min mr-2 noPrint"
                        [ngClass]="open ? 'icon-caret-up icon-white' : 'icon-caret-down icon-petrol-blue'"></i>
                    <i
                        class="aimo-icon icon-inline icon-min mr-2 d-none d-lg-block print"
                        [ngClass]="{
                            'icon-white': open,
                            'icon-petrol-blue': !open,
                            'icon-truck': !isPikatukku(),
                            'icon-storefront': isPikatukku()
                        }"></i>
                    <h4 class="cx-date-title d-inline mb-0 print-keep-font-size">{{ day.dateTitle }}</h4>
                </div>
                <div class="col-md-4 col-lg-4 col-auto ml-auto ml-md-0 mt-0">
                    <div class="cx-stock noPrint">
                        <span class="stock-status stock-status-{{ day.status.toLocaleLowerCase() }}"></span>
                        <span class="d-md-inline d-none">{{
                            'aimo.orderhistory.status.' + day.status | cxTranslate
                        }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7 col-5">
            <div class="row no-gutters align-items-center">
                <div class="col-4 d-none d-md-block">
                    <label class="d-inline">{{ 'aimo.orderhistory.orderNumbers' | cxTranslate }}:</label>
                    <ng-container *ngIf="day.orderNumbers.length > 1">
                        {{ 'aimo.orderhistory.several' | cxTranslate }}
                    </ng-container>
                    <span class="order-numbers" *ngIf="day.orderNumbers.length <= 1">
                        {{ day.orderNumbers.join('') }}
                    </span>
                </div>
                <div class="col-3 d-none d-md-block">
                    <label class="d-inline">{{ 'aimo.orderhistory.poNumbers' | cxTranslate }}:</label>
                    <span class="po-numbers">
                        <ng-container *ngIf="day.poNumbers.length > 1">
                            {{ 'aimo.orderhistory.several' | cxTranslate }}
                        </ng-container>
                        <ng-container *ngIf="day.poNumbers.length <= 1">
                            {{ day.poNumbers.join('') }}
                        </ng-container>
                        <ng-container *ngIf="day.poNumbers.length === 0"> -</ng-container>
                    </span>
                </div>
                <div class="col-md-2 col-12 d-none d-md-block product-count print">
                    {{ 'aimo.orderhistory.products' | cxTranslate : { amount: day.totalRows } }}
                </div>
                <div class="col-md-3 col-12 text-right product-total">
                    <span class="text-bold day-total d-block d-sm-inline-block">{{ day.total.formattedValue }}</span>
                    <span class="d-block d-sm-inline-block ml-sm-1">{{ 'aimo.cart.item.notax' | cxTranslate }}</span>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="open">
        <div class="bg-petrol-light row no-gutters d-md-flex d-none cx-order-subheader">
            <div class="col-2">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.subtotal' | cxTranslate }}</label>
                <div class="day-subtotal">{{ day.subTotal?.formattedValue }}</div>
            </div>
            <div class="col-2">
                <ng-container *ngIf="!isPikatukku()">
                    <label class="text-bold mr-2">{{ 'aimo.cart.totals.deliveryCost' | cxTranslate }}</label>
                    <div class="day-delivery-cost">
                        <span class="align-middle">{{
                            day.deliveryCost?.value !== 0.0 ? day.deliveryCost?.formattedValue : '...'
                        }}</span>
                        <span
                            class="btn btn-xs btn-icon btn-icon-transparent"
                            [ngbPopover]="'aimo.cart.totals.deliveryCostTooltip' | cxTranslate"
                            placement="left"
                            container="body"
                            triggers="mouseenter:mouseleave"
                            *ngIf="day.deliveryCost?.value === 0.0">
                            <i class="icon-inline aimo-icon icon-info icon-petrol-blue icon-sm"></i>
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="col-2">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.totalPrice' | cxTranslate }}</label>
                <div class="day-total">{{ day.total?.formattedValue }}</div>
            </div>
            <div class="col-4">
                <div class="row no-gutters">
                    <div class="col-6" *ngFor="let tax of day.taxes">
                        <label class="text-bold mr-2">{{
                            'aimo.cart.totals.vat' | cxTranslate : { tax: tax.rate }
                        }}</label>
                        <div class="day-tax-value">{{ tax.value.formattedValue }}</div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.totalPriceWithTax' | cxTranslate }}</label>
                <div class="day-total-with-tax">
                    <div
                        *ngIf="day.extraCharge?.value > 0"
                        triggers="mouseenter:mouseleave"
                        popoverClass="popover-default"
                        container="body"
                        [ngbPopover]="
                            'aimo.cart.totals.extraChargesDescription'
                                | cxTranslate : { total: day.originalTotalWithTax?.formattedValue }
                        ">
                        {{ day.totalWithTax?.formattedValue }}
                        <span class="icon-inline aimo-icon icon-info icon-petrol-blue icon-sm"></span>
                    </div>
                    <div *ngIf="day.extraCharge === undefined || day.extraCharge?.value === 0">
                        {{ day.totalWithTax?.formattedValue }}
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-petrol-light cx-order-history-actions noPrint">
            <button
                class="btn-link"
                [id]="'edit'"
                (click)="edit(); pushGtmEvent('orderhistory-edit')"
                *ngIf="isEditable && !isPikatukku()">
                <i class="aimo-icon icon-pencil-simple icon-inline icon-sm"></i>
                <span class="orderhistory-edit">{{ 'aimo.orderhistory.edit' | cxTranslate }}</span>
            </button>

            <button
                *ngIf="!isPikatukku()"
                class="btn-link"
                [id]="'re_order'"
                (click)="reOrder(); pushGtmEvent('orderhistory-reorder')">
                <i class="aimo-icon icon-repeat icon-inline icon-sm"></i>
                <span class="orderhistory-reorder">{{ 'aimo.orderhistory.reorder' | cxTranslate }}</span>
            </button>

            <button
                ngbDropdown
                ngbDropdownToggle
                role="button"
                [attr.aria-label]="'aimo.arialLabel.language.dropdown' | cxTranslate"
                class="btn-link"
                [id]="'delivery_notes'"
                *ngIf="deliveryNotes.length > 0">
                <i class="aimo-icon icon-folders icon-inline icon-sm"></i>
                <span class="orderhistory-deliveryNotes">{{ 'aimo.orderhistory.deliveryNotes' | cxTranslate }}</span>
                <div ngbDropdownMenu aria-labelledby="deliveryNotestoggle">
                    <div ngbDropdownItem *ngFor="let invoice of deliveryNotes">
                        <div (click)="downloadDeliveryNote(invoice, 'aimo.orderhistory.deliverynoteName')">
                            <i class="aimo-icon icon-file-text icon-inline icon-sm"></i>
                            {{ 'aimo.orderhistory.deliverynoteName' | cxTranslate : { id: invoice } }}
                        </div>
                    </div>
                </div>
            </button>

            <button class="btn-link" [id]="'claims'" (click)="claims()" *ngIf="day.claimAllowed">
                <i class="aimo-icon icon-x-circle icon-inline icon-sm"></i>
                <span class="orderhistory-claims">{{ 'aimo.orderhistory.claims' | cxTranslate }}</span>
            </button>

            <ng-container *ngIf="user$ | async as user">
                <button
                    *ngIf="!isPikatukku() && (user.customerAdmin || !user.isHideNormalOrderTemplatesFromBasicUsers)"
                    class="btn-link"
                    [id]="'new_template'"
                    (click)="addToOrderTemplate(day, 'new_template'); pushGtmEvent('orderhistory-new-template')">
                    <i class="aimo-icon icon-file-plus icon-inline icon-sm"></i>
                    <span class="orderhistory-newOrdertemplate">{{
                        'aimo.orderhistory.newOrdertemplate' | cxTranslate
                    }}</span>
                </button>
            </ng-container>

            <button
                class="btn-link"
                [id]="'to_excel'"
                (click)="exportOrderToExcel(day); pushGtmEvent('orderhistory-to-excel')">
                <i class="aimo-icon icon-file-arrow-down icon-inline icon-sm"></i>
                <span class="orderhistory-excel">{{ 'aimo.cart.export.excel' | cxTranslate }}</span>
            </button>

            <button class="btn-link" [id]="'print'" (click)="print(); pushGtmEvent('orderhistory-print')">
                <i class="aimo-icon icon-printer icon-inline icon-sm"></i>
                <span class="orderhistory-print">{{ 'aimo.cart.print' | cxTranslate }}</span>
            </button>
        </div>

        <ng-container *ngFor="let order of day.orders">
            <aimo-order-history-order [order]="order"></aimo-order-history-order>
        </ng-container>
    </ng-container>
</div>
