<div class="aimo-order-templates-row row no-gutters">
    <div class="col-lg-11 col-10">
        <a class="link routerlink d-block" [routerLink]="'/ordertemplates/' + orderTemplate.code">
            <div class="row no-gutters align-items-center">
                <div class="col-lg-3 col-12 pb-2 pb-lg-0 d-flex align-items-center">
                    <i class="aimo-icon icon-petrol-blue icon-file-text icon-inline mr-2"> </i>
                    <h5 class="mb-0">{{ orderTemplate.name }}</h5>
                </div>
                <div class="col-lg-6 col-6 d-none d-lg-flex aimo-order-templates-images">
                    <ng-container
                        *ngFor="
                            let entry of orderTemplate.entries
                                | slice : 0 : (orderTemplate.entries?.length > 6 ? 5 : orderTemplate.entries?.length);
                            let i = index
                        ">
                        <div
                            class="cx-product-image-container mr-2"
                            [ngClass]="{ 'd-xl-inline-block d-lg-none': i > 2 }">
                            <cx-media
                                class="cx-product-image"
                                [alt]="entry.product.name"
                                triggers="mouseenter:mouseleave"
                                popoverClass="popover-default"
                                container="body"
                                [ngbPopover]="entry.product.name"
                                [container]="entry.product?.images?.PRIMARY"
                                format="cartIcon">
                            </cx-media>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="orderTemplate.entries.length > 4">
                        <div class="aimo-order-template-additional-images-box d-lg-flex d-xl-none">
                            <div class="text-dark-grey">+ {{ orderTemplate.entries.length - 3 }}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="orderTemplate.entries.length > 6">
                        <div class="aimo-order-template-additional-images-box d-xl-flex d-lg-none">
                            <div class="text-dark-grey">+ {{ orderTemplate.entries.length - 5 }}</div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-lg-3 col-12 align-items-center text-default">
                    <div class="row">
                        <div class="col-lg-7 col-6 d-flex align-items-center">
                            <div class="mr-lg-2 mr-0">
                                <i
                                    class="aimo-icon icon-sm icon-petrol-blue icon-inline"
                                    [ngClass]="getCartVisibilityCss()">
                                </i>
                                <span class="template-visibility">{{
                                    'aimo.select.templateVisibility.' + orderTemplate.visibility | cxTranslate
                                }}</span>
                            </div>
                        </div>
                        <div class="col-lg-5 col-6 d-flex align-items-center justify-content-lg-end">
                            <span class="product-count">
                                {{
                                    'aimo.ordertemplates.products.' +
                                        (orderTemplate.entries?.length === 1 ? 'one' : 'many')
                                        | cxTranslate : { amount: orderTemplate.entries?.length }
                                }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div class="col-2 col-lg-1 text-right noPrint">
        <button
            class="btn btn-primary btn-icon btn-sm btn-add-to-cart ml-xl-4 ml-lg-2 ml-4"
            (click)="addAllToCart()"
            triggers="mouseenter:mouseleave"
            popoverClass="popover-default"
            container="body"
            [ngbPopover]="'aimo.ordertemplates.addAllToCart' | cxTranslate">
            <i class="aimo-icon icon-white icon-shopping-cart-simple icon-sm"> </i>
        </button>
    </div>
</div>
