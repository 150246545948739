<div>
    <div #fullSearchSpinnerDiv class="d-none">
        <aimo-spinner></aimo-spinner>
    </div>
    <div class="row">
        <ng-container *ngFor="let product of model?.products; index as idx">
            <aimo-product-grid-item
                [idSuffix]="'search_product_' + idx"
                [gtmProductAttributes]="createGtmAttributes(idx)"
                [product]="product"
                class="col-6 col-sm-6 col-md-4 col-xl-auto"></aimo-product-grid-item>
        </ng-container>
    </div>
</div>
