<a
    #popup="ngbPopover"
    [ngbPopover]="modification"
    [autoClose]="'outside'"
    (hidden)="onClosePopup()"
    [disablePopover]="!hasMessages()"
    placement="bottom"
    popoverClass="popover-md popover-white popover-minicart"
    class="minicart"
    [attr.aria-label]="'miniCart.item' | cxTranslate : { count: quantity$ | async }"
    (click)="goToCart()">
    <div class="d-inline-block position-relative">
        <button class="aimo-icon icon-shopping-cart-simple icon-petrol-blue icon-md icon-cursor link">&nbsp;</button>
        <ng-container *ngIf="quantity$ | async as quantity">
            <div class="quantity circle circle-sm bg-red text-white text-xsm text-bold">
                {{ quantity }}
            </div>
        </ng-container>
    </div>

    <div class="d-md-inline-block d-none pl-2">
        <div>{{ 'miniCart.total' | cxTranslate : { total: total$ | async } }}</div>
        <div class="text-bold">{{ total$ | async }}</div>
    </div>
</a>

<ng-template #modification>
    <div
        *ngIf="hasMessages()"
        class="minicart-popover row no-gutters align-items-center"
        (mouseenter)="setMouseOverPopup(true)"
        (mouseleave)="setMouseOverPopup(false)">
        <div class="col-12 bg-petrol-light text-bold p-2 mb-2">
            {{ 'aimo.cart.addedToCart' | cxTranslate }}
        </div>
        <div class="col-12 text-bold p-2 mb-2 bg-summer-light" *ngIf="addedProducts">
            <ng-container *ngIf="addedProducts > 1">
                {{ 'aimo.cart.addedToCartPlural' | cxTranslate : { amount: addedProducts } }}
            </ng-container>
            <ng-container *ngIf="addedProducts === 1">
                {{ 'aimo.cart.addedToCartSingle' | cxTranslate }}
            </ng-container>
        </div>
        <ng-container *ngFor="let popupEntry of popupEntries">
            <ng-container *ngIf="popupEntry.messages?.length">
                <div class="col-8 d-flex align-items-center">
                    <div class="cx-product-image-container d-inline-block">
                        <cx-media
                            class="cx-product-image"
                            [container]="popupEntry.product.images?.PRIMARY"
                            format="cartIcon"></cx-media>
                    </div>
                    <div class="product-name-wrapper">
                        <button
                            [id]="'minicart_modification_open_product'"
                            class="btn-link text-left text-bold"
                            [attr.aria-label]="
                                'aimo.product.openModal.arialLabel'
                                    | cxTranslate : { productName: popupEntry.product.name }
                            "
                            (click)="openProductModal(popupEntry.product.code, 'minicart_modification_open_product')"
                            tabindex="0">
                            {{ popupEntry.product.name }}
                        </button>
                    </div>
                </div>
                <div class="col-2">{{ popupEntry.quantity }} {{ popupEntry.unit.name }}</div>
                <div class="col-2 price text-bold">
                    {{ popupEntry.totalPrice.formattedValue }}
                </div>
                <aimo-cart-item-errors
                    [entry]="popupEntry"
                    [messageClass]="'m-0'"
                    class="col-12 mt-2"></aimo-cart-item-errors>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
