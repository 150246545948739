<div
    class="cart-item-deposit"
    *ngIf="entry.deposits"
    placement="bottom"
    [ngbPopover]="depositPopover"
    [innerHTML]="
        !paymentMode.credit || !alreadyOrdered
            ? ('aimo.cart.item.deposits'
              | cxTranslate
                  : {
                        amount: entry.deposits.totalPriceWithTax?.formattedValue,
                        tax: taxRates
                    })
            : ('aimo.cart.item.depositsNoTax' | cxTranslate)
    "></div>

<ng-template #depositPopover>
    <p
        class="mb-3"
        *ngIf="!paymentMode.credit || !alreadyOrdered"
        [innerHTML]="
            'aimo.cart.item.depositsText'
                | cxTranslate
                    : {
                          amount: entry.deposits.totalPriceWithTax.formattedValue,
                          tax: taxRates
                      }
        "></p>
    <ul class="mb-0 pl-3">
        <li *ngFor="let deposit of entry.deposits.entries">{{ deposit.product.name }} {{ deposit.quantity }}</li>
    </ul>
</ng-template>
