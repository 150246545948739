import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';
import '@angular/localize/init';

if (environment.production) {
    enableProdMode();
} else {
    // eslint-disable-next-line
    console.log('Enable Prod not working');
}

// eslint-disable-next-line
function bootstrap() {
    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        // eslint-disable-next-line
        .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}
