import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FacetListComponent, FacetService } from '@spartacus/storefront';
import { Subject, Subscription } from 'rxjs';

import { AimoFacet, AimoFacetValue } from '../../../../model/product.model';

@Component({
    selector: 'aimo-facet-list',
    templateUrl: './aimo-facet-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoFacetListComponent extends FacetListComponent implements OnInit {
    @Input() mobileUI: boolean;

    subscription: Subscription = new Subscription();

    openFacet: Subject<string> = new Subject<string>();
    mobileFacetValues: AimoFacetValue[];

    constructor(
        protected facetService: FacetService,
        protected elementRef: ElementRef,
        protected renderer: Renderer2,
        protected route: ActivatedRoute,
        protected cd: ChangeDetectorRef,
    ) {
        super(facetService, elementRef, renderer);
    }

    ngOnInit(): void {
        this.subscription.add(
            this.facetList$.subscribe((fl) => {
                return (this.mobileFacetValues = fl.facets.flatMap((facet) =>
                    facet.values
                        .map((value) => value as AimoFacetValue)
                        .filter((value) => value.selected)
                        .map((value) => ({
                            ...value,
                            facetCode: (facet as AimoFacet).code,
                        })),
                ));
                this.cd.detectChanges();
            }),
        );
    }

    triggerOpenFacet(name: string): void {
        this.openFacet.next(name);
    }

    setFacetTermsForMobile(facetValue: AimoFacetValue): void {
        if (facetValue.selected) {
            if (facetValue.rangeMin) {
                this.mobileFacetValues = this.mobileFacetValues.filter(
                    (f) => f.code !== facetValue.code && f.facetCode !== facetValue.facetCode,
                );
            }
            this.mobileFacetValues.push(facetValue);
        } else {
            this.removeMobileFacet(facetValue);
        }
    }

    removeMobileFacet(facetValue: AimoFacetValue): void {
        this.mobileFacetValues = this.mobileFacetValues.map((v) => ({
            ...v,
            selected: v.selected && v.code !== facetValue.code,
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
