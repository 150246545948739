import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';

import { AimoMyAssortmentComponent } from './aimo-my-assortment.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                MyAssortmentComponent: {
                    component: AimoMyAssortmentComponent,
                },
            },
        }),
        I18nModule,
        NgbPopoverModule,
    ],
    declarations: [AimoMyAssortmentComponent],
    entryComponents: [AimoMyAssortmentComponent],
    exports: [AimoMyAssortmentComponent],
})
export class AimoMyAssortmentModule {}
