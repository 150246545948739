import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SortingComponent } from '@spartacus/storefront';

@Component({
    selector: 'aimo-sorting',
    templateUrl: './aimo-sorting.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoSortingComponent extends SortingComponent {
    @Input()
    ariaLabelForOption: string | undefined;
}
