import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { LanguageService, RoutingService, TranslationService, WindowRef } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { AimoCart } from '../../model/cart.model';
import { AimoOrderHistoryRequest } from '../../model/order.model';
import { AimoActiveCartService } from '../../service/cart/aimo-active-cart.service';
import { GTMItemListId } from '../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../service/product/aimo-current-product.service';
import { SELECT_UNIT_SUCCESS } from '../../service/user/aimo-user.action';
import { AimoUserService } from '../../service/user/aimo-user.service';
import { AimoMessageService } from '../../service/util/aimo-message-service';
import { DateUtils } from '../../shared/util/date-utils';
import { AimoNgbDateParserFormatter } from '../shared/datepicker/aimo-datepicker-parser-formatter';

@Component({
    selector: 'aimo-reports',
    templateUrl: './aimo-reports.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AimoReportsComponent implements OnInit, OnDestroy {
    cart$: Observable<AimoCart>;
    searchForm: FormGroup;
    subscriptions: Subscription = new Subscription();

    // eslint-disable-next-line
    breadcrumbs$: Observable<any[]> = this.lTranslation.translate('aimo.reports.title').pipe(
        filter(Boolean),
        map((translation) => [{ label: translation, link: '/reports' }]),
    );

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected activeCartService: AimoActiveCartService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
        protected language: LanguageService,
        protected actions$: Actions,
        protected messageService: AimoMessageService,
        protected userService: AimoUserService,
        private lTranslation: TranslationService,
        protected currentProductService: AimoCurrentProductService,
        protected winRef: WindowRef,
    ) {}

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            searchTerm: new FormControl(),
            from: new FormControl(),
            to: new FormControl(),
        });
        this.subscriptions.add(
            this.actions$.subscribe((action) => {
                if (action.type === SELECT_UNIT_SUCCESS) {
                    this.fetchReports();
                }
            }),
        );
        this.fetchReports();
    }

    convertNgbDate(d: string): NgbDate {
        return d ? this.ngbDateParserFormatter.parse(new Date(d)) : null;
    }

    fetchReports(): void {
        this.cart$ = this.activeCartService.getPurchaseReport(this.getSearchParams()).pipe(
            tap((cart) => {
                if (this.searchForm.controls.from.value === undefined) {
                    this.searchForm.controls.from.setValue(
                        this.ngbDateParserFormatter.parse(cart.requestedDeliveryDate),
                    );
                }
            }),
        );
    }

    private getSearchParams(): AimoOrderHistoryRequest {
        return {
            searchTerm: this.searchForm.controls.searchTerm.value,
            deliveryDate: {
                from: this.searchForm.controls.from.value
                    ? this.ngbDateParserFormatter.toDate(this.searchForm.controls.from.value)
                    : null,
                to: this.searchForm.controls.to.value
                    ? this.ngbDateParserFormatter.toDate(this.searchForm.controls.to.value)
                    : null,
            },
        } as AimoOrderHistoryRequest;
    }

    hideMessage(id: string): void {
        this.messageService.hideMessage(id);
        this.cdr.detectChanges();
    }

    isMessageHidden(id: string): boolean {
        return this.messageService.isMessageHidden(id);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    openProductModal(code: string, sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            code,
            {
                item_list_id: GTMItemListId.reports,
            },
            null,
            null,
            null,
            sourceId,
        );
    }

    print(): void {
        this.winRef.nativeWindow.print();
    }

    exportCartToExcel(): void {
        this.activeCartService.getPurchaseReportExcel('aimo.reports.purchaseReport', this.getSearchParams());
    }
}
