import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { RoutingService, WindowRef } from '@spartacus/core';
import { CurrentProductService, LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoCart } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoSwitchDateHistoryDialogData } from '../../cart-details/switch-date-modal/aimo-switch-cart-date-layout.config';
import { CART_VISIBILITY } from '../order-template-modal/aimo-order-template-layout.config';

@Component({
    selector: 'aimo-order-template-row',
    templateUrl: './aimo-order-template-row.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderTemplateRowComponent {
    @Input()
    orderTemplate: AimoCart;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected windowRef: WindowRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected launchDialogService: LaunchDialogService,
        protected currentProductService: CurrentProductService,
    ) {}

    addAllToCart(): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.SWITCH_CART_DATE, undefined, {
            cart: this.orderTemplate,
            allowPastDates: false,
            orderTemplateCode: this.orderTemplate.code,
        } as AimoSwitchDateHistoryDialogData);
    }

    getCartVisibilityCss(): string {
        if (this.orderTemplate.visibility === CART_VISIBILITY.PRIVATE) {
            return 'icon-user';
        } else if (this.orderTemplate.visibility === CART_VISIBILITY.PUBLIC) {
            return 'icon-users';
        }
        // CENTRAL
        return 'icon-house-line';
    }
}
