import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { AimoStock } from '../../../../model/product.model';
import { DateUtils } from '../../../../shared/util/date-utils';

@Component({
    selector: 'aimo-product-availability',
    templateUrl: './aimo-product-availability.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductAvailabilityItemComponent implements OnInit {
    @Input() stock: AimoStock;
    @Input() suggestiveMode = false;
    @Input() printStockName = false;

    outOfStock: boolean;

    constructor() {}

    ngOnInit(): void {
        this.outOfStock = this.stock.stockLevelStatus === 'outOfStock';
    }

    convertDate(d: string): Date {
        return DateUtils.convertDate(d);
    }
}
