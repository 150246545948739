<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">{{ 'updateProfileForm.closeUser' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <p [innerHTML]="'aimo.closeaccount.description' | cxTranslate"></p>

            <div class="modal-actions flex-start">
                <button class="btn btn-primary btn-sm btn-inline mr-2" (click)="submit()">
                    {{ 'updateProfileForm.closeUser' | cxTranslate }}
                </button>
                <button class="btn btn-secondary btn-sm btn-inline" (click)="closeDialog()">
                    {{ 'common.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
