import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoBreadcrumbModule } from '../../navigation/breadcrumb/aimo-breadcrumb.module';

import { AimoContentPageHeaderComponent } from './aimo-content-page-header.component';

@NgModule({
    imports: [CommonModule, RouterModule, IconModule, MediaModule, UrlModule, I18nModule, AimoBreadcrumbModule],
    declarations: [AimoContentPageHeaderComponent],
    exports: [AimoContentPageHeaderComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoContentPageHeaderComponent: {
                    component: AimoContentPageHeaderComponent,
                },
            },
        }),
    ],
})
export class AimoContentPageHeaderModule {}
