<ng-container *ngIf="componentData$ | async as carousel; else loading">
    <ng-container *ngIf="banners$ | async as banners; else loading">
        <div
            class="aimo-banner-carousel"
            [ngClass]="{
                'aimo-banner-carousel-smaller': banners.length > 3
            }">
            <div
                class="aimo-background"
                [ngClass]="{
                    'bg-white': carousel.backgroundColor === 'WHITE',
                    'bg-light-grey': carousel.backgroundColor === 'GREY',
                    'bg-petrol-light': carousel.backgroundColor === 'PETROL_LIGHT'
                }">
                <div class="container">
                    <aimo-cx-carousel
                        class="carousel-banners"
                        role="region"
                        tabindex="-1"
                        itemWidth="10px"
                        [hideIndicators]="true"
                        [banners]="banners"
                        [title]="carousel.headText"
                        [template]="carouselItem"
                        [idSuffix]="carousel.uid">
                    </aimo-cx-carousel>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #carouselItem let-item="item" let-index="index" let-idSuffix="idSuffix">
    <aimo-banner [banner]="item"></aimo-banner>
</ng-template>

<ng-template #loading>
    <aimo-spinner></aimo-spinner>
</ng-template>
