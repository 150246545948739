import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoutingService, StateWithProcess, StateWithUser, UserIdService, UserService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { AimoCart, AimoOrder } from '../../model/cart.model';
import { AimoOrderHistory, AimoOrderHistoryRequest } from '../../model/order.model';
import { AimoProduct } from '../../model/product.model';
import {
    AimoB2BUnitOption,
    AimoBulletinList,
    AimoContactUs,
    AimoRegistration,
    AimoUser,
    AimoUserRegistrationData,
    B2BUnitOption,
    KitchenAlcoholInfo,
    OpeningHour,
} from '../../model/user.model';
import { AimoCmsPageGuardService } from '../cms/aimo-cms-page-guard.service';

import { AimoUserAccountService } from './aimo-user-account.service';
import { AimoUserOrderConnector } from './aimo-user-order.connector';
import { AimoUserProfileConnector } from './aimo-user-profile.connector';
import {
    AddOrModifyUser,
    AimoGiveConsent,
    AimoSetHideNormalOrderTemplatesFromBasicUsers,
    ModifyB2BUnit,
    Register,
    SelectUnit,
    ToggleFavouriteProduct,
} from './aimo-user.action';

@Injectable({
    providedIn: 'root',
})
export class AimoUserService extends UserService {
    constructor(
        protected store: Store<StateWithUser | StateWithProcess<void>>,
        protected userIdService: UserIdService,
        protected userOrderConnector: AimoUserOrderConnector,
        protected userProfileConnector: AimoUserProfileConnector,
        protected userAccountService: AimoUserAccountService,
        protected routingService: RoutingService,
    ) {
        super(store, userIdService);
    }

    get(): Observable<AimoUser> {
        //  let previousUser: AimoUser;
        return this.userAccountService.get().pipe(
            // filter((user) => user !== undefined),
            tap((user) => {
                AimoCmsPageGuardService.openConsentPageIfNeeded(user, this.routingService);
            }),
        );
    }

    getOrderHistoryDay(date: Date): Observable<AimoOrderHistory> {
        return this.getOrderHistory({
            from: 0,
            deliveryDate: {
                from: date,
                to: new Date(date.getTime() + 24 * 3600 * 1000 - 1), // 1ms before midnight
            },
            claimsMode: true,
        } as AimoOrderHistoryRequest);
    }

    getOrderHistory(params: AimoOrderHistoryRequest): Observable<AimoOrderHistory> {
        return this.userIdService
            .getUserId()
            .pipe(switchMap((userId) => this.userOrderConnector.getOrderHistory(userId, params)));
    }

    getOrder(code: string): Observable<AimoCart> {
        return this.userIdService
            .getUserId()
            .pipe(switchMap((userId) => this.userOrderConnector.getOrder(userId, code)));
    }

    searchUnits(term: string): Observable<B2BUnitOption[]> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.userProfileConnector.searchUnits(userId, term)));
    }

    selectUnit(uid: string, fromSearch: boolean): void {
        this.userIdService
            .takeUserId()
            .pipe(withLatestFrom(this.routingService.getPageContext().pipe(take(1))))
            .subscribe(([userId, pageContext]) =>
                this.store.dispatch(new SelectUnit(userId, uid, fromSearch, pageContext)),
            )
            .unsubscribe();
    }

    register(data: AimoRegistration): void {
        this.userIdService
            .takeUserId()
            .subscribe((userId) => this.store.dispatch(new Register(userId, data)))
            .unsubscribe();
    }

    getCurrentUnit(): Observable<B2BUnitOption> {
        return this.get().pipe(
            filter(Boolean),
            map((user: AimoUser) => user.unit),
        );
    }

    getAlchoholQuotaInfo(): Observable<KitchenAlcoholInfo> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((user) => this.userProfileConnector.getAlchoholQuotaInfo(user)));
    }

    getMyOrganizationDetails(): Observable<AimoUser> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((user) => this.userProfileConnector.getMyOrganizationDetails(user)));
    }

    addOrModifyUser(data: AimoUserRegistrationData): void {
        this.userIdService
            .takeUserId()
            .subscribe((userId) => this.store.dispatch(new AddOrModifyUser(userId, data)))
            .unsubscribe();
    }

    modifyB2BUnit(data: AimoB2BUnitOption): void {
        this.userIdService
            .takeUserId()
            .subscribe((userId) => this.store.dispatch(new ModifyB2BUnit(userId, data)))
            .unsubscribe();
    }

    toggleFavorite(product: AimoProduct): void {
        this.userIdService
            .takeUserId()
            .subscribe((userId) =>
                this.store.dispatch(
                    new ToggleFavouriteProduct({
                        userId,
                        productPk: product.pk,
                    }),
                ),
            )
            .unsubscribe();
    }

    closeUserAccount(): Observable<unknown> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.userProfileConnector.closeUserAccount(userId)));
    }

    contactUs(body: AimoContactUs): Observable<unknown> {
        return this.userProfileConnector.contactUs(body);
    }

    getOrderToBePaid(orderId: string): Observable<AimoOrder> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.userOrderConnector.getOrderToBePaid(userId, orderId)));
    }

    getActiveBulletinList(category: string, id?: string): Observable<AimoBulletinList> {
        return this.userIdService
            .takeUserId()
            .pipe(switchMap((userId) => this.userProfileConnector.getActiveBulletinList(userId, category, id)));
    }

    giveConsent(): void {
        this.userIdService
            .takeUserId()
            .subscribe((userId) => this.store.dispatch(new AimoGiveConsent(userId)))
            .unsubscribe();
    }

    getStoreOpeningHours(): Observable<OpeningHour[]> {
        return this.userProfileConnector.getStoreOpeningHours();
    }

    setHideNormalOrderTemplatesFromBasicUsers(value: boolean): void {
        this.userIdService
            .takeUserId()
            .pipe(take(1))
            .subscribe((userId) =>
                this.store.dispatch(new AimoSetHideNormalOrderTemplatesFromBasicUsers(userId, value)),
            );
    }
}
