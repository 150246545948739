import { Injectable } from '@angular/core';
import {
    ConverterService,
    Facet,
    Occ,
    OccProductSearchPageNormalizer,
    PRODUCT_NORMALIZER,
    ProductSearchPage,
} from '@spartacus/core';

@Injectable({ providedIn: 'root' })
export class AimoOccProductSearchPageNormalizer extends OccProductSearchPageNormalizer {
    constructor(private converterService2: ConverterService) {
        super(converterService2);
    }

    convert(source: Occ.ProductSearchPage, target: ProductSearchPage = {}): ProductSearchPage {
        target = {
            ...target,
            // eslint-disable-next-line
            ...(source as any),
        };

        this._normalizeFacetValues(target);
        if (source.products) {
            target.products = source.products.map((product) =>
                this.converterService2.convert(product, PRODUCT_NORMALIZER),
            );
        }
        return target;
    }

    private _normalizeFacetValues(target: ProductSearchPage): void {
        if (target.facets) {
            target.facets = target.facets.map((facetSource: Facet) => {
                const { topValues, ...facetTarget } = facetSource;
                facetTarget.topValueCount = topValues?.length > 0 ? topValues.length : this.DEFAULT_TOP_VALUES;
                return facetTarget;
            });
        }
    }
}
