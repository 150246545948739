<ng-container>
    <ng-container *ngIf="mainImage$ | async as main">
        <cx-media class="cx-primary-image" [container]="main" (click)="triggerZoom(true, main)"></cx-media>
        <aimo-product-image-zoom-trigger
            [expandImage]="expandImage.value"
            [galleryIndex]="selectedIndex"
            (dialogClose)="triggerZoom(false, main, product)"></aimo-product-image-zoom-trigger>
    </ng-container>

    <ng-container>
        <cx-carousel
            role="region"
            [attr.aria-label]="'carousel.carouselForProduct' | cxTranslate : { product: product.name }"
            tabindex="-1"
            class="thumbs"
            [items]="thumbs$"
            itemWidth="{{ isMobile ? '80px' : '60px' }}"
            [hideIndicators]="false"
            [template]="thumb"></cx-carousel>
    </ng-container>

    <ng-template #thumb let-item="item">
        <cx-media
            [container]="item.container"
            tabindex="0"
            (focus)="openImage(item.container)"
            [class.is-active]="isActive(item.container) | async"
            format="product">
        </cx-media>
    </ng-template>
</ng-container>
