import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

import { CMSImageComponent } from '../../../model/cms.model';

@Component({
    selector: 'aimo-image',
    templateUrl: './image.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
    constructor(public component: CmsComponentData<CMSImageComponent>) {}
}
