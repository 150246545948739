import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoB2BUnitOption, AimoUser } from '../../../../model/user.model';

import { AimoAddNewUserComponent } from './aimo-add-new-user.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        ADD_NEW_USER = 'ADD_NEW_USER',
    }
}

export const addNewUserLayout: LayoutConfig = {
    launch: {
        ADD_NEW_USER: {
            inlineRoot: true,
            component: AimoAddNewUserComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AddNewUserData {
    currentUser: AimoUser;
    user: AimoUser;
    b2bUnits: AimoB2BUnitOption[];
}
