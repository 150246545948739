import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
    selector: 'aimo-cx-background-image',
    templateUrl: './aimo-background-image.component.html',
})
export class AimoBackgroundImageComponent implements OnInit {
    data$: Observable<CmsBannerComponent>;

    constructor(public component: CmsComponentData<CmsBannerComponent>) {}

    ngOnInit(): void {
        this.data$ = this.component.data$;
    }
}
