<div
    [tabIndex]="0"
    class="d-inline"
    #divCategories
    [attr.aria-label]="
        categoryName && categoryCount
            ? ('productList.subCategory.arialLabel' | cxTranslate : { name: categoryName, count: categoryCount })
            : null
    ">
    <a
        [tabindex]="0"
        role="button"
        *ngFor="let subCategory of getCategories(values)"
        (click)="openCategoryFacet(subCategory.query.query.value, subCategory.name, subCategory.count, divCategories)"
        class="btn-facet btn-sm category cursor-pointer"
        [ngClass]="{ selected: subCategory.selected }"
        [attr.aria-label]="
            'productList.subCategory.arialLabel' | cxTranslate : { name: subCategory.name, count: subCategory.count }
        ">
        {{ subCategory.name }}
        <span class="amount">{{ subCategory.count }}</span>
    </a>

    <ng-container *ngIf="showToggle(values.length)">
        <a class="btn-facet btn-sm category show-all" (click)="toggleShowAll()">
            <i class="aimo-icon icon-sm icon-inline" [ngClass]="showAll ? 'icon-minus-bold' : 'icon-plus-bold'"></i>
            <span>{{ (showAll ? 'aimo.product.hideCategories' : 'aimo.product.showCategories') | cxTranslate }}</span>
        </a>
    </ng-container>
</div>
