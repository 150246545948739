<form *ngIf="product" [formGroup]="addToCartForm">
    <div class="quantity">
        <div class="cx-counter-stock">
            <aimo-item-counter
                [readonly]="!purchasable"
                [min]="0"
                [step]="quantityStep"
                [max]="maxQuantity"
                [addToCartCallBack]="addToCartCallBack"
                [showQuantity]="showQuantity"
                [productPage]="true"
                [control]="addToCartForm.get('quantity')"
                (afterChange)="updateCart($event)"
                [buttonsClass]="'btn btn-icon btn-primary'"
                [unitName]="product.unit.name"
                [product]="product"
                [id]="'qty_' + product.code"
                [autofocus]="autofocus"
                [gtmProductAttributes]="gtmProductAttributes"
                [isPLP]="isPLP"></aimo-item-counter>
        </div>
    </div>
</form>
