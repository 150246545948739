import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AimoGenericLinkComponent } from './aimo-generic-link.component';

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [AimoGenericLinkComponent],
    exports: [AimoGenericLinkComponent],
})
export class AimoGenericLinkModule {}
