import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

import { AimoServiceNotificationComponent } from './aimo-service-notification.component';
import { AimoTopHeaderComponent } from './aimo-top-header.component';

@NgModule({
    imports: [
        I18nModule,
        CommonModule,
        RouterModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                TopHeaderComponent: {
                    component: AimoTopHeaderComponent,
                },
                AimoServiceNotificationComponent: {
                    component: AimoServiceNotificationComponent,
                },
            },
        }),
    ],
    declarations: [AimoTopHeaderComponent, AimoServiceNotificationComponent],
    entryComponents: [AimoTopHeaderComponent, AimoServiceNotificationComponent],
    exports: [AimoTopHeaderComponent, AimoServiceNotificationComponent],
})
export class AimoTopHeaderModule {}
