import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CmsConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { GenericLinkModule, MediaModule } from '@spartacus/storefront';

import { AimoBannerModule } from '../banner/aimo-banner.module';
import { AimoCarouselModule } from '../carousel/aimo-carousel.module';
import { AimoSpinnerModule } from '../spinner/aimo-spinner.module';

import { AimoBannerCarouselComponent } from './aimo-banner-carousel.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        MediaModule,
        GenericLinkModule,
        YouTubePlayerModule,
        AimoBannerModule,
        AimoCarouselModule,
        AimoSpinnerModule,
    ],
    providers: [
        provideConfig(<CmsConfig>{
            cmsComponents: {
                AimoBannerCarouselComponent: {
                    component: AimoBannerCarouselComponent,
                },
            },
        }),
    ],
    declarations: [AimoBannerCarouselComponent],
    exports: [AimoBannerCarouselComponent],
})
export class AimoBannerCarouselModule {}
