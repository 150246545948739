<div class="container" *ngIf="data$ | async as data; else loading">
    <ng-container *ngIf="data.rootCategoryCode">
        <h3>{{ data.title }}</h3>
        <div
            class="sub-categories-wrapper"
            *ngIf="getCategoryHierarchy(data.rootCategoryCode) | async as hierarchy; else loading">
            <div class="sub-categories">
                <aimo-category-header-subcategory [showAll]="true" [values]="hierarchy.subcategories">
                </aimo-category-header-subcategory>
            </div>
        </div>
    </ng-container>
</div>

<ng-template #loading>
    <aimo-spinner></aimo-spinner>
</ng-template>
