<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">{{ 'aimo.myorganization.b2bunit.modify' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body" [formGroup]="form">
            <h3>{{ b2bUnit.name }} ({{ b2bUnit.uid }})</h3>
            <div class="field-content" *ngFor="let user of allUsers">
                <input
                    type="checkbox"
                    [formControlName]="user.uid"
                    [checked]="form.controls[user.uid].value === true" />
                <label innerHTML="{{ user.name }}  ({{ user.uid }})"></label>
            </div>

            <div class="modal-actions">
                <button class="btn btn-primary btn-sm" (click)="save()">
                    {{ 'aimo.myorganization.users.save' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
