import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { LanguageService, RoutingService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { AimoBaseOrder } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { DateUtils } from '../../../shared/util/date-utils';
import { AimoNgbDateParserFormatter } from '../../shared/datepicker/aimo-datepicker-parser-formatter';

import { AimoBaseOrderDialogData } from './aimo-base-order-layout.config';

@Component({
    selector: 'aimo-base-order-block-modal',
    templateUrl: './aimo-base-order-block-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBaseOrderBlockModalComponent implements OnDestroy {
    subscription: Subscription = new Subscription();
    form: FormGroup;
    existingBaseOrder: AimoBaseOrder;
    submitClicked = false;

    selection = ['timeSpan', 'forNow'];

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected eRef: ElementRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected winRef: WindowRef,
        protected language: LanguageService,
        protected ngbDateParserFormatter: AimoNgbDateParserFormatter,
    ) {
        this.launchDialogService.data$.pipe(take(1)).subscribe((dialogData: AimoBaseOrderDialogData) => {
            this.existingBaseOrder = dialogData.existingBaseOrder;
            this.initForm(this.isForNowBlock() ? 'forNow' : 'timeSpan');
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private initForm(defaultSelection: string, defaultComment?: string, defaultNotification?: boolean): void {
        const controls = {
            selection: new FormControl(defaultSelection, [Validators.required]),
        };
        if ('timeSpan' === defaultSelection) {
            controls['from'] = new FormControl(this.convertDate(this.existingBaseOrder.blocks.find(Boolean)?.start), [
                Validators.required,
            ]);
            controls['to'] = new FormControl(this.convertDate(this.existingBaseOrder.blocks.find(Boolean)?.end), [
                Validators.required,
            ]);
        }
        if ('remove' !== defaultSelection) {
            controls['sendNotification'] = new FormControl(defaultNotification ?? this.isSendNotification());
            controls['comment'] = new FormControl(defaultComment ?? this.getComment());
        }
        this.form = new FormGroup(controls);
    }

    getComment(): string {
        return this.existingBaseOrder.blocks.find(Boolean)?.comment;
    }

    isSendNotification(): boolean {
        return this.existingBaseOrder.blocks.find(Boolean)?.sendNotification === true;
    }

    isForNowBlock(): boolean {
        return (
            this.existingBaseOrder.blocks?.length > 0 && this.existingBaseOrder.blocks?.find(Boolean)?.end === undefined
        );
    }

    getStartDate(): NgbDate {
        return this.form?.controls.from.value;
    }

    getEndDate(): NgbDate {
        return this.form?.controls.to.value;
    }

    convertDate(date: string): NgbDate {
        return date ? this.ngbDateParserFormatter.parse(new Date(date)) : null;
    }

    convertJsDate(date: string): Date {
        return date ? new Date(date) : null;
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.BLOCK_BASE_ORDER);
        this.launchDialogService.closeDialog('closed');
    }

    delete(baseOrder: AimoBaseOrder): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.BLOCK_REMOVE_BASE_ORDER, undefined, {
            existingBaseOrder: baseOrder,
        });
    }

    submit(): void {
        this.submitClicked = true;
        if (this.form.valid) {
            this.subscription.add(
                this.activeCartService
                    .saveBaseOrder(
                        {
                            ...this.existingBaseOrder,
                            blocks: [
                                {
                                    comment: this.form.controls.comment.value,
                                    start:
                                        this.form.controls.selection.value === 'timeSpan'
                                            ? DateUtils.convertDateToOccString(
                                                  this.language,
                                                  this.ngbDateParserFormatter.toDate(this.form.controls.from.value),
                                              )
                                            : null,
                                    end:
                                        this.form.controls.selection.value === 'timeSpan'
                                            ? DateUtils.convertDateToOccString(
                                                  this.language,
                                                  this.ngbDateParserFormatter.toDate(this.form.controls.to.value),
                                              )
                                            : null,
                                    sendNotification: this.form.controls.sendNotification.value === true,
                                },
                            ],
                        },
                        true,
                    )
                    .pipe(take(1))
                    .subscribe(() => this.closeDialog()),
            );
        }
    }

    switchSelection(): void {
        this.initForm(
            this.form.controls.selection.value,
            this.form.controls.comment.value,
            this.form.controls.sendNotification.value,
        );
    }
}
