import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { ProductActions } from '@spartacus/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { AimoProductFacetService } from '../../../service/product/aimo-product-facet.service';
import { openCloseInnerSpinner, openCloseSpinner } from '../utils/spinner/aimo-spinner-utils';

@Injectable({
    providedIn: 'root',
})
export class AimoSpinnerService implements OnDestroy {
    constructor(protected actions$: Actions, protected facetService: AimoProductFacetService) {}

    subscription: Subscription = new Subscription();
    private title$ = new BehaviorSubject<string>(null);
    spinnerTitle$ = this.title$.asObservable();

    private isOpenedSpinner = new BehaviorSubject<boolean>(false);
    isOpenedSpinner$ = this.isOpenedSpinner.asObservable();

    setTitle(title: string): void {
        this.title$.next(title);
    }

    showInnerSpinner(divElementRef: ElementRef, hideElementRef?: ElementRef, forceDisplay?: boolean): void {
        if (forceDisplay) {
            if (divElementRef) {
                openCloseInnerSpinner(true, divElementRef);
            } else {
                openCloseSpinner(true);
            }
            if (hideElementRef != undefined) {
                hideElementRef.nativeElement?.classList?.add('d-none');
            }
            this.isOpenedSpinner.next(true);
        }
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === ProductActions.SEARCH_PRODUCTS) {
                    openCloseInnerSpinner(true, divElementRef);
                    if (hideElementRef != undefined) {
                        hideElementRef.nativeElement?.classList?.add('d-none');
                    }
                    this.isOpenedSpinner.next(true);
                }
                if (
                    action.type === ProductActions.SEARCH_PRODUCTS_SUCCESS ||
                    action.type === ProductActions.SEARCH_PRODUCTS_FAIL ||
                    action.type === CartActions.CART_ADD_ENTRY_SUCCESS ||
                    action.type === CartActions.CART_UPDATE_ENTRY_SUCCESS ||
                    action.type === CartActions.CART_ADD_ENTRY_FAIL ||
                    action.type === CartActions.CART_UPDATE_ENTRY_FAIL ||
                    action.type === CartActions.LOAD_CART_SUCCESS ||
                    action.type === CartActions.LOAD_CART_FAIL
                ) {
                    if (divElementRef) {
                        openCloseInnerSpinner(false, divElementRef);
                    } else {
                        openCloseSpinner(false);
                    }

                    this.facetService.setFacetClicked(false);
                    if (hideElementRef != undefined) {
                        hideElementRef.nativeElement?.classList?.remove('d-none');
                    }
                    setTimeout(() => {
                        this.isOpenedSpinner.next(false);
                    }, 250);
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
