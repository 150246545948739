<cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
<ng-container *ngIf="!isSuccess; else successNotification">
    <div class="forgot-content-sm">
        <h2>{{ 'loginForm.forgotPassword' | cxTranslate }}</h2>

        <cx-page-slot position="CenterLeftContentSlot" class="p-0"></cx-page-slot>
        <cx-page-slot position="CenterRightContentSlot" class="p-0"></cx-page-slot>

        <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="field-content mb-4">
                <aimo-cx-input
                    [label]="'loginForm.userName.label'"
                    [nameForm]="'userEmail'"
                    [parentForm]="form"
                    [placeholder]="'forgottenPassword.popup.email.placeholder'"
                    [typeInput]="'email'"
                    [labelClass]="'text-bold mb-0'"
                    idName="userEmail"
                    tabindex="0"></aimo-cx-input>
                <cx-form-errors
                    [control]="form.get('userEmail')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>
            <button type="submit" class="btn btn-primary mr-3" [disabled]="form.disabled">
                {{ 'common.send' | cxTranslate }}
            </button>
            <a [routerLink]="{ cxRoute: 'login' } | cxUrl" class="btn btn-secondary">
                {{ 'common.cancel' | cxTranslate }}
            </a>
            <div class="re-captcha mt-4">
                <p innerHTML="{{ 'forgottenPassword.recaptcha' | cxTranslate }}"></p>
            </div>
        </form>
    </div>
</ng-container>
<ng-template #successNotification>
    <div class="forgot-content-sm">
        <h2>{{ 'loginForm.forgotPassword' | cxTranslate }}</h2>

        <cx-page-slot position="MiddleContentSlot" class="p-0 mb-4"></cx-page-slot>
        <cx-page-slot position="CenterRightContentSlot" class="p-0"></cx-page-slot>
        <a (click)="finished()" [routerLink]="{ cxRoute: 'login' } | cxUrl" class="btn btn-primary">
            {{ 'loginForm.returnToLogin' | cxTranslate }}
        </a>
    </div>
</ng-template>
