import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AimoProduct } from '../../../../model/product.model';

@Component({
    selector: 'aimo-product-tag',
    templateUrl: './aimo-product-tag.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductTagComponent {
    @Input() product: AimoProduct;
}
