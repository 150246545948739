import { CmsConfig } from '@spartacus/core';

export const aimoDefaultCmsModuleConfig: CmsConfig = {
    backend: {
        occ: {
            endpoints: {
                components: 'cms/components?customerEmulationParameter=true',
                pages: 'cms/pages?customerEmulationParameter=true',
            },
        },
    },
    cmsComponents: {},
};
