import { Injectable } from '@angular/core';
import { OccCartNormalizer } from '@spartacus/cart/base/occ';
import { Cart } from '@spartacus/cart/base/root';
import { ConverterService, Occ, PRODUCT_NORMALIZER } from '@spartacus/core';

import { AimoCart } from '../../model/cart.model';
import { DateUtils } from '../../shared/util/date-utils';

@Injectable({ providedIn: 'root' })
export class AimoOccCartNormalizer extends OccCartNormalizer {
    constructor(protected converter2: ConverterService) {
        super(converter2);
    }

    convert(source: Occ.Cart, target?: Cart): AimoCart {
        // eslint-disable-next-line
        const cart: any = super.convert(source, target);
        cart.entries?.forEach((e) => {
            this.convertEntry(e);
        });
        cart.dayGroupedEntries
            ?.flatMap((g) => g.entries)
            .forEach((e) => {
                this.convertEntry(e);
            });
        cart.categoryGroupedEntries
            ?.flatMap((g) => g.entries)
            .forEach((e) => {
                this.convertEntry(e);
            });
        cart.dayGroupedEntries
            ?.flatMap((g) => g.alreadyOrdered)
            .filter((g) => g !== undefined)
            .flatMap((g) => g.entries)
            .forEach((e) => {
                this.convertEntry(e);
                e.deposits?.entries.forEach((d) => {
                    // eslint-disable-next-line
                    d.quantity = (d as any).doubleQuantity;
                });
            });

        return cart;
    }

    // eslint-disable-next-line
    private convertEntry(e: any): void {
        if (e.closingTimeWarningDate) {
            e.closingTimeWarningDate = DateUtils.convertDate(e.closingTimeWarningDate);
        }
        if (e.closingTime) {
            e.closingTime = DateUtils.convertDate(e.closingTime);
        }
        e.quantity = e?.doubleQuantity;
        if (e.product?.images?.length > 0) {
            e.product = this.converter2.convert(e.product, PRODUCT_NORMALIZER);
        }
    }
}
