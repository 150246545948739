import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    AuthService,
    GlobalMessageService,
    GlobalMessageType,
    HttpErrorModel,
    RouterState,
    RoutingService,
} from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AimoCustomFormValidators } from '../../../shared/validators/aimo-custom-form-validators';

@Injectable()
export class AimoResetPasswordComponentService implements OnDestroy {
    constructor(
        protected userPasswordService: UserPasswordFacade,
        protected routingService: RoutingService,
        protected globalMessage: GlobalMessageService,
        protected authService: AuthService,
    ) {}

    subscription: Subscription = new Subscription();

    protected busy$ = new BehaviorSubject(false);

    isUpdating$ = this.busy$.pipe(tap((state) => (state === true ? this.form.disable() : this.form.enable())));

    resetToken$: Observable<string> = this.routingService
        .getRouterState()
        .pipe(map((routerState: RouterState) => routerState.state.queryParams['token']));

    form: FormGroup = new FormGroup(
        {
            password: new FormControl(null, [Validators.required, AimoCustomFormValidators.passwordValidator]),
            passwordConfirm: new FormControl(null, Validators.required),
        },
        {
            validators: CustomFormValidators.passwordsMustMatch('password', 'passwordConfirm'),
        },
    );

    /**
     * Resets the password by the given token.
     *
     * The token has been provided during the request password flow.
     * The token is not validated on the client.
     */
    resetPassword(token: string): void {
        if (!this.form.valid) {
            this.form.markAllAsTouched();
            return;
        }

        this.busy$.next(true);

        const password = (this.form.get('password') as FormControl).value;
        this.subscription.add(
            this.userPasswordService.reset(token, password).subscribe({
                next: (user) => this.onSuccess(user),
                error: (error: unknown) => this.onError(error),
            }),
        );
    }

    protected onSuccess(user): void {
        this.globalMessage.add(
            { key: 'forgottenPassword.passwordResetSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION,
            5000,
        );
        this.busy$.next(false);
        const password = this.form.value.password;
        this.form.reset();
        this.authService.loginWithCredentials(user.uid, password);
    }

    protected onError(error: unknown): void {
        this.busy$.next(false);
        if (error instanceof HttpErrorModel) {
            (error.details ?? []).forEach((err) => {
                if (err.message) {
                    this.globalMessage.add({ raw: err.message }, GlobalMessageType.MSG_TYPE_ERROR, 5000);
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
