import { GlobalMessageType, SearchConfig } from '@spartacus/core';
import { SearchCriteria } from '@spartacus/storefront/cms-components/product/product-list/container/product-list.model';

import { GTMCartType } from '../service/gtm/aimo-gtm.model';

export interface DateRange {
    from: string;
    to: string;
}

export interface Sort {
    field: string;
    descending: boolean;
}

export interface HeadTable {
    field: string;
    label?: string;
    isNotSortable?: boolean;
    tooltipInfo?: string;
    tooltipLeft?: boolean;
    className?: string;
    labelClass?: string;
}

export interface AimoSearchConfig extends SearchConfig {
    previousPageSize?: number;
    userId?: string;
    suggestiveMode?: boolean;
    origin?: GTMCartType;
    followRedirects?: boolean;
    klevuBannerId?: string;
}

export interface AimoSearchCriteria extends SearchCriteria {
    followRedirects?: string;
}

export enum CalendarNavigationTypes {
    None = 'none',
    Select = 'select',
    Arrows = 'arrows',
}

export class Alert {
    id: string;
    type: GlobalMessageType;
    message: string;
    autoClose: boolean;
    keepAfterRouteChange: boolean;
    fade: boolean;
    hidePages: string[];

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export interface AimoListConfig {
    /* eslint-disable */
    action?: any;
    cssClass?: string | any;
    unit?: string | any;
    sortCode?: string | any;
    isExpandable?: boolean | any;
    isExpanded?: boolean | any;
    value?: string | any;
    tooltip?: string | any;
    name?: string;
    notSortable?: boolean;
}

export enum AimoNotificationType {
    ERROR = 'ERROR',
    INFO = 'INFO',
    WARNING = 'WARNING',
}

export enum PasswordRequirementType {
    UPPERCASE = 'UPPERCASE',
    LOWERCASE = 'LOWERCASE',
    MINIMUMCHARACTERS = 'MINIMUMCHARACTERS',
    NUMBER = 'NUMBER',
}

export interface FileExportWrapper {
    name?: string;
    data?: ArrayBuffer;
}
