import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { AimoAssortmentService } from '../../../service/product/aimo-assortment.service';

@Component({
    selector: 'aimo-my-assortment',
    templateUrl: './aimo-my-assortment.component.html',
})
export class AimoMyAssortmentComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    show$: Observable<boolean>;
    selected: boolean;

    constructor(protected assortmentService: AimoAssortmentService, protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.show$ = this.assortmentService.isAssortmentChangingEnabled();
        this.subscriptions.add(
            this.assortmentService.isOnlyMyAssortment().subscribe((s) => {
                this.selected = s;
                this.cdr.detectChanges();
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    toggleSelection(): void {
        this.assortmentService.setOnlyMyAssortment(!this.selected);
    }
}
