import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
    ActiveCartService,
    CartAdapter,
    CartEntryAdapter,
    CartEntryConnector,
    MultiCartService,
} from '@spartacus/cart/base/core';
import { CART_MODIFICATION_NORMALIZER, CART_NORMALIZER } from '@spartacus/cart/base/root';
import { AuthRedirectService, provideDefaultConfig } from '@spartacus/core';
import { ORDER_NORMALIZER } from '@spartacus/order/root';

import { AimoAuthRedirectService } from '../auth/aimo-auth-redirect.service';

import { AimoActiveCartService } from './aimo-active-cart.service';
import { AimoCartEntryEffects } from './aimo-cart-entry.effect';
import { AimoCartAdapter } from './aimo-cart.adapter';
import { AimoCartConnector } from './aimo-cart.connector';
import { AimoCartEffects } from './aimo-cart.effect';
import { AimoMultiCartService } from './aimo-multi-cart.service';
import { aimoOccCartConfig } from './aimo-occ-cart-config';
import { AimoOccCartEntryAdapter } from './aimo-occ-cart-entry.adapter';
import { AimoOccCartModificationNormalizer } from './aimo-occ-cart-modification-normalizer';
import { AimoOccCartNormalizer } from './aimo-occ-cart-normalizer';
import { AimoOccOrderNormalizer } from './aimo-order.normalizer';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([AimoCartEffects, AimoCartEntryEffects])],
    providers: [
        provideDefaultConfig(aimoOccCartConfig),
        {
            provide: AuthRedirectService,
            useClass: AimoAuthRedirectService,
        },
        {
            provide: MultiCartService,
            useClass: AimoMultiCartService,
        },
        {
            provide: CartEntryConnector,
            useClass: AimoCartConnector,
        },
        {
            provide: CART_NORMALIZER,
            useExisting: AimoOccCartNormalizer,
            multi: true,
        },
        {
            provide: CART_MODIFICATION_NORMALIZER,
            useExisting: AimoOccCartModificationNormalizer,
            multi: true,
        },
        {
            provide: CartEntryAdapter,
            useClass: AimoOccCartEntryAdapter,
        },
        {
            provide: CartAdapter,
            useClass: AimoCartAdapter,
        },
        {
            provide: ActiveCartService,
            useClass: AimoActiveCartService,
        },
        {
            provide: ORDER_NORMALIZER,
            useExisting: AimoOccOrderNormalizer,
            multi: true,
        },
    ],
})
export class AimoCartOccModule {}
