<a *ngFor="let subCategory of getCategories(values)" [routerLink]="subCategory.url" class="btn-facet btn-sm category">
    {{ subCategory.name }}
    <span class="amount">{{ subCategory.totalNumber }}</span>
</a>

<ng-container *ngIf="showToggle(values.length)">
    <a class="btn-facet btn-sm category show-all" (click)="toggleShowAll()">
        <i class="aimo-icon icon-sm icon-inline" [ngClass]="showAll ? 'icon-minus-bold' : 'icon-plus-bold'"></i>
        <span>{{ (showAll ? 'aimo.product.hideCategories' : 'aimo.product.showCategories') | cxTranslate }}</span>
    </a>
</ng-container>
