<ng-container *ngIf="loggedIn$ | async">
    <cx-page-slot position="HeaderLinks"></cx-page-slot>
</ng-container>

<ng-container *ngIf="isPikatukku() && notLoggedIn$ | async">
    <cx-page-slot position="AnonymousHeaderLinks"></cx-page-slot>
    <a class="link" [routerLink]="'/login'" (click)="isOnUrlPage('login')">
        <i class="aimo-icon icon-inline icon-sign-in d-lg-none d-inline-block mr-2"></i>
        <i class="aimo-icon icon-inline icon-sm icon-sign-in icon-white d-none d-lg-inline-block mr-1"></i>
        <span>{{ 'loginForm.signInHeader' | cxTranslate }}</span>
    </a>
</ng-container>
