import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpErrorHandler, TokenRevocationInterceptor } from '@spartacus/core';

import { ClickOutsideDirective } from './directives/aimo-clickout.directive';
import { CopyClipboardDirective } from './directives/aimo-clipboard.directive';
import { AimoForbiddenHandler } from './http-interceptors/aimo-forbidden.handler';
import { ErrorsHttpInterceptor } from './http-interceptors/errors-http-interceptor';

@NgModule({
    exports: [ClickOutsideDirective, CopyClipboardDirective],
    declarations: [ClickOutsideDirective, CopyClipboardDirective],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: TokenRevocationInterceptor,
            multi: true,
        },
        {
            provide: HttpErrorHandler,
            useExisting: AimoForbiddenHandler,
            multi: true,
        },
        /* {
            provide: HTTP_INTERCEPTORS,
            useExisting: AimoAsmAwareHttpInterceptor,
            multi: true,
        },*/
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: ErrorsHttpInterceptor,
            multi: true,
        },
    ],
})
export class SharedModule {}
