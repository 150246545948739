import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { ItemCounterModule } from '@spartacus/storefront';

import { AimoSpinnerModule } from '../../cms-components/shared/spinner/aimo-spinner.module';
import { SharedModule } from '../shared.module';

import { AimoItemCounterComponent } from './aimo-item-counter.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, I18nModule, SharedModule, AimoSpinnerModule, NgbPopoverModule],
    declarations: [AimoItemCounterComponent],
    exports: [AimoItemCounterComponent],
})
export class AimoItemCounterModule extends ItemCounterModule {}
