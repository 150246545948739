<div class="aimo-cx-delivery-date-component">
    <button
        #calendarLink
        class="delivery-date"
        [tabindex]="0"
        role="button"
        [attr.aria-label]="'aimo.arialLabel.openCalendar' | cxTranslate"
        #popup="ngbPopover"
        [ngbPopover]="calendar"
        (click)="initCalendar()"
        [autoClose]="'outside'"
        placement="bottom-right"
        container="body"
        popoverClass="popover-route-calendar"
        (keydown.tab)="focusNextChild($any($event))"
        *ngIf="!modal">
        <div class="d-inline-block position-relative">
            <i class="aimo-icon icon-calendar-blank icon-petrol-blue icon-inline icon-md link">&nbsp;</i>
            <div
                *ngIf="activeCartsAmount$ | async as activeCartsAmount"
                class="active-carts circle circle-sm bg-red text-white text-xsm text-bold"
                [ngClass]="activeCartsAmount > 0 ? '' : 'd-none'">
                {{ activeCartsAmount }}
            </div>
        </div>

        <div class="d-inline-block pl-md-2">
            <div class="d-md-block d-none">{{ 'calendar.title' | cxTranslate }}</div>
            <div class="d-md-block d-none text-bold">
                {{ selectedDate | async | cxDate : ('aimo.dateFormat' | cxTranslate) }}
            </div>
            <div class="d-block d-md-none text-bold text-xsm">{{ selectedDate | async | cxDate : 'd.M.' }}</div>
        </div>
    </button>
</div>

<div *ngIf="!modal; else calendar"></div>

<ng-template #calendar>
    <div class="route-calendar d-inline-block" #calendar [ngClass]="claimsMode ? 'claims-calendar' : ''">
        <div class="mt-5 pt-3" *ngIf="isUpdating">
            <aimo-spinner [isModal]="false"></aimo-spinner>
        </div>

        <ng-container *ngIf="routeCalendar$ | async as routeCalendar">
            <div class="calendar-row month">
                <span class="month-cell" (keydown)="restartFocusEvent(calendar, $event)">
                    <button
                        [tabIndex]="0"
                        id="previousMonth"
                        class="aimo-icon icon-inline icon-arrow-left icon-petrol-blue link"
                        (click)="setMonth(false)"
                        *ngIf="hasMonths(routeCalendar, false)">
                        &nbsp;
                    </button>
                </span>
                <div class="d-inline-block">
                    {{ 'aimo.calendar.month.' + getMonth(routeCalendar).month | cxTranslate }}
                    {{ getMonth(routeCalendar).year }}
                </div>
                <span class="month-cell" (keydown)="restartFocusEvent(calendar, $event)">
                    <button
                        [tabIndex]="0"
                        id="nextMonth"
                        class="aimo-icon icon-inline icon-arrow-right icon-petrol-blue link"
                        (click)="setMonth(true)"
                        *ngIf="hasMonths(routeCalendar, true)">
                        &nbsp;
                    </button>
                </span>
            </div>
            <div class="position-relative">
                <div class="vertical-line"></div>
                <div class="calendar-row">
                    <span
                        class="calendar-cell week-day"
                        [attr.aria-label]="'aimo.calendar.week.arialLabel' | cxTranslate">
                        {{ 'aimo.calendar.week.short' | cxTranslate }}
                    </span>
                    <span
                        class="calendar-cell week-day"
                        *ngFor="let weekDay of weekDays"
                        [attr.aria-label]="'aimo.calendar.weekday.' + weekDay | cxTranslate">
                        {{ 'aimo.calendar.weekday.short.' + weekDay | cxTranslate }}
                    </span>
                </div>

                <ng-container *ngIf="getMonth(routeCalendar) as month">
                    <div class="calendar-row" *ngFor="let week of month.weeks">
                        <div class="calendar-cell week-number">
                            <span
                                [attr.aria-label]="
                                    'aimo.calendar.week.arialLabel' | cxTranslate : { number: week.weekNumber }
                                "
                                >{{ week.weekNumber }}</span
                            >
                        </div>
                        <span
                            [ngClass]="{
                                'not-delivery-date': !weekDay.deliveryDate,
                                disabled: weekDay.disabled,
                                'incoming-order': weekDay.incomingOrder,
                                today: weekDay.today,
                                selected: weekDay.selected,
                                'open-cart': weekDay.openCart
                            }"
                            class="calendar-cell calendar-day"
                            (keydown)="restartFocusEvent(calendar, $event)"
                            *ngFor="let weekDay of week.days">
                            <button
                                [tabIndex]="0"
                                [id]="weekDay.today ? 'today_date' : weekDay.dayOfMonth + '_' + month.month + ':_date'"
                                role="button"
                                class="link"
                                (click)="selectDate(month, weekDay)"
                                [attr.aria-label]="
                                    weekDay.dayOfMonth +
                                    ' ' +
                                    ('aimo.calendar.month.' + getMonth(routeCalendar).month | cxTranslate)
                                ">
                                {{ weekDay.dayOfMonth }}
                            </button>
                        </span>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="claimsMode">
                <div class="legend row">
                    <div class="col-6 legend-option">
                        <span class="text-bold">{{ today | cxDate : 'd' }}</span>
                        {{ 'aimo.calendar.passingDay' | cxTranslate }}
                    </div>
                    <div class="col-6 legend-option">
                        <span class="incoming-order">&nbsp;</span>
                        {{ 'aimo.calendar.deliveredOrder' | cxTranslate }}
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!claimsMode">
                <div class="legend row">
                    <div class="col-6 legend-option">
                        <span class="passing-day">{{ today.getDate() }}</span>
                        {{ 'aimo.calendar.passingDay' | cxTranslate }}
                    </div>
                    <div class="col-6 legend-option">
                        <span class="incoming-order">&nbsp;</span>
                        {{ 'aimo.calendar.incomingOrders' | cxTranslate }}
                    </div>
                    <div class="col-6 legend-option">
                        <span class="selected-day">&nbsp;</span>
                        {{ 'aimo.calendar.selectedDay' | cxTranslate }}
                    </div>
                    <div class="col-6 legend-option">
                        <span class="open-cart">&nbsp;</span>
                        {{ 'aimo.calendar.openCarts' | cxTranslate }}
                    </div>
                </div>

                <div class="messages" *ngIf="exceptionDateSelected">
                    <div class="message message-WARNING">
                        <i class="left-side aimo-icon icon-inline icon-WARNING icon-red"></i>
                        <span class="ml-2" [innerHTML]="'aimo.calendar.exceptionDate' | cxTranslate"></span>
                    </div>
                </div>
                <div class="messages" *ngIf="expressDateSelected">
                    <div class="message message-WARNING">
                        <i class="left-side aimo-icon icon-inline icon-WARNING icon-red"></i>
                        <span class="ml-2" [innerHTML]="'aimo.calendar.expressDate' | cxTranslate"></span>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
