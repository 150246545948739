import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoCMSContentPageHeaderComponent } from '../../../model/cms.model';

@Component({
    selector: 'aimo-content-page-header',
    templateUrl: './aimo-content-page-header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoContentPageHeaderComponent implements OnInit {
    data$: Observable<AimoCMSContentPageHeaderComponent>;

    constructor(protected componentData: CmsComponentData<AimoCMSContentPageHeaderComponent>) {}

    ngOnInit(): void {
        this.data$ = this.componentData.data$;
    }
}
