import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule, PageSlotModule } from '@spartacus/storefront';

import { AimoTwoColumnContentComponent } from './aimo-two-column-content.component';

@NgModule({
    imports: [CommonModule, RouterModule, IconModule, MediaModule, UrlModule, I18nModule, PageSlotModule],
    declarations: [AimoTwoColumnContentComponent],
    exports: [AimoTwoColumnContentComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoTwoColumnContentComponent: {
                    component: AimoTwoColumnContentComponent,
                },
            },
        }),
    ],
})
export class AimoTwoColumnContentModule {}
