import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BaseSiteService, UserIdService, WindowRef } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { openCloseSpinner } from '../spinner/aimo-spinner-utils';

@Injectable({ providedIn: 'root' })
export class OccFunctionRequestInterceptor implements HttpInterceptor, OnDestroy {
    subscription: Subscription = new Subscription();

    constructor(
        protected lUserIdService: UserIdService,
        protected baseSiteService: BaseSiteService,
        protected winRef: WindowRef,
        private router: Router,
    ) {}

    // eslint-disable-next-line
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addEmulatedCustomerParameter(request);
        request = this.addSmarteditParameter(request);
        return this.handleSpinner(request, next);
    }

    // eslint-disable-next-line
    private handleSpinner(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isSpinnerFunction(request.url)) {
            openCloseSpinner(true);
            return next.handle(request).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse) {
                        openCloseSpinner(false);
                    }
                }),
            );
        }
        return next.handle(request);
    }

    isSpinnerFunction(url: string): boolean {
        const spinnerQueryParameter = 'spinner=true';
        return url.includes(spinnerQueryParameter);
    }

    /*
     * For ASM Purposes - in order to get components and search results for Emulated users
     * Adds customerEmulation={user.uid} to request as a parameter for
     *  (1) /products/search
     *  (2) /cms/pages
     *  (3) /cms/components
     *  (4) /products
     */
    // eslint-disable-next-line
    addEmulatedCustomerParameter(request: HttpRequest<any>): HttpRequest<any> {
        let userID;
        const customerEmulationParameter = 'customerEmulationParameter=true';

        if (request.url.includes(customerEmulationParameter)) {
            this.subscription.add(
                this.lUserIdService.getUserId().subscribe((id) => {
                    if (id != null) {
                        userID = id;
                    }
                }),
            );
            if (userID !== undefined) {
                return request.clone({
                    setParams: {
                        customerEmulation: userID,
                    },
                });
            }
        }
        return request;
    }

    // eslint-disable-next-line
    addSmarteditParameter(request: HttpRequest<any>): HttpRequest<any> {
        if (this.router.url.indexOf('cx-preview') >= 0) {
            return request.clone({
                setParams: {
                    smartEditMode: 'true',
                },
            });
        }
        return request;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
