import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    AuthGuard,
    CmsConfig,
    GlobalMessageService,
    I18nModule,
    provideConfig,
    provideDefaultConfig,
    RoutingService,
    UrlModule,
} from '@spartacus/core';
import { FormErrorsModule, NgSelectA11yModule, SpinnerModule } from '@spartacus/storefront';
import { UpdatePasswordComponentService, UpdateProfileComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade, UserProfileFacade } from '@spartacus/user/profile/root';

import { AimoInputModule } from '../../../shared/input/aimo-input.module';
import { AimoPasswordChecksModule } from '../../shared/password-checks/aimo-password-checks.module';

import { AimoAlcoholComponent } from './alcohol/aimo-alcohol.component';
import { AimoCloseAccountComponent } from './close-account-modal/aimo-close-account.component';
import { closeAccountHistoryLayout } from './close-account-modal/close-account-layout.config';
import { AimoUpdatePasswordComponentService } from './update-password/aimo-update-password-component.service';
import { AimoUpdatePasswordComponent } from './update-password/aimo-update-password.component';
import { AimoUpdateProfileComponentService } from './update-profile/aimo-update-profile-component.service';
import { AimoUpdateProfileComponent } from './update-profile/aimo-update-profile.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SpinnerModule,
        I18nModule,
        FormErrorsModule,
        RouterModule,
        UrlModule,
        NgSelectModule,
        NgSelectA11yModule,
        AimoInputModule,
        AimoPasswordChecksModule,
    ],
    providers: [
        {
            provide: UpdateProfileComponentService,
            useClass: AimoUpdateProfileComponentService,
        },
        provideDefaultConfig(closeAccountHistoryLayout),
        provideConfig(<CmsConfig>{
            cmsComponents: {
                UpdateProfileComponent: {
                    component: AimoUpdateProfileComponent,
                    guards: [AuthGuard],
                    providers: [
                        {
                            provide: UpdateProfileComponentService,
                            useClass: AimoUpdateProfileComponentService,
                            deps: [UserProfileFacade, GlobalMessageService],
                        },
                    ],
                },
                UpdatePasswordComponent: {
                    component: AimoUpdatePasswordComponent,
                    guards: [AuthGuard],
                    providers: [
                        {
                            provide: UpdatePasswordComponentService,
                            useClass: AimoUpdatePasswordComponentService,
                            deps: [UserPasswordFacade, RoutingService, GlobalMessageService],
                        },
                    ],
                },
                AlcoholComponent: {
                    component: AimoAlcoholComponent,
                },
            },
        }),
    ],
    declarations: [
        AimoUpdateProfileComponent,
        AimoUpdatePasswordComponent,
        AimoAlcoholComponent,
        AimoCloseAccountComponent,
    ],
    exports: [AimoUpdateProfileComponent, AimoUpdatePasswordComponent, AimoAlcoholComponent, AimoCloseAccountComponent],
})
export class AimoProfileModule {}
