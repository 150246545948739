import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoOrderEntry } from '../../../model/cart.model';
import { AimoDayGroupedOrderHistory } from '../../../model/order.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { GTMCalendarSource, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

import { AimoReorderHistoryDialogData } from './reorder-layout.config';

@Component({
    selector: 'aimo-order-history-reorder',
    templateUrl: './aimo-order-history-reorder.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistoryReorderComponent implements OnInit, OnDestroy {
    calendarSource = GTMCalendarSource.orderHistory;

    subscription: Subscription = new Subscription();
    day: AimoDayGroupedOrderHistory;
    selectedDate: string;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected eRef: ElementRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected spinnerService: AimoSpinnerService,
    ) {
        this.subscription.add(
            this.launchDialogService.data$.subscribe((dialogData: AimoReorderHistoryDialogData) => {
                this.day = dialogData.day;
            }),
        );
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.spinnerService.setTitle('aimo.loading.reOrder');
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.REORDER_HISTORY);
        this.launchDialogService.closeDialog('closed');
    }

    selectDate(date: string): void {
        this.selectedDate = date;
    }

    submit(): void {
        this.spinnerService.showInnerSpinner(null, null, true);
        this.activeCartService.addManyProducts({
            requestedDeliveryDate: this.selectedDate,
            entries: this.day.orders
                .flatMap((order) => order.entries)
                .map(
                    (entry) =>
                        ({
                            quantity: entry.quantity,
                            gtmItemListId: GTMItemListId.order_history,
                            product: { code: entry.product.code },
                        } as AimoOrderEntry),
                ),
        });

        const sub = this.actions$.subscribe((action) => {
            if (action.type === CartActions.LOAD_CART_SUCCESS) {
                this.closeDialog();
                this.routingService.go('/cart');
                sub.unsubscribe();
            }
        });
    }

    @ViewChild('closeButton')
    closeButton: ElementRef;

    getTheFocusBack(event: Event): void {
        event.preventDefault();
        this.closeButton.nativeElement.focus();
    }
}
