import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoCart, AimoOrderEntry } from '../../model/cart.model';
import { AimoItemMessageComponent } from '../cart-shared/cart-item/item-messages/aimo-item-message.component';

import { AimoExcelImportComponent } from './excel-import/aimo-excel-import.component';
import { AimoRouteSchedulesComponent } from './route-schedules/aimo-route-schedules.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        ROUTE_INFOS = 'ROUTE_INFOS',
        EXCEL_IMPORT = 'EXCEL_IMPORT',
        ITEM_MESSAGES = 'ITEM_MESSAGES',
    }
}

export const cartPageLayout: LayoutConfig = {
    launch: {
        ROUTE_INFOS: {
            inlineRoot: true,
            component: AimoRouteSchedulesComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
        EXCEL_IMPORT: {
            inlineRoot: true,
            component: AimoExcelImportComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
        ITEM_MESSAGES: {
            inlineRoot: true,
            component: AimoItemMessageComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoCartDialogData {
    cart: AimoCart;
}

export interface AimoCartEntryDialogData {
    entry: AimoOrderEntry;
}
