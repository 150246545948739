<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">
                {{ 'aimo.baseOrder.selection.remove' | cxTranslate }}
            </h2>
            <div class="modal-action col-auto">
                <button #closeButton class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <p class="mb-4">
                {{ 'aimo.baseOrder.selection.removeDescription' | cxTranslate }}
            </p>
            <p class="mb-4">
                {{
                    'aimo.baseOrder.selection.removeDescription2'
                        | cxTranslate
                            : {
                                  datesAhead: existingBaseOrder.deliveryDatesAhead
                              }
                }}
            </p>

            <div class="modal-actions flex-start">
                <button
                    [attr.aria-label]="'aimo.baseOrder.selection.remove' | cxTranslate"
                    [title]="'aimo.baseOrder.selection.remove' | cxTranslate"
                    class="btn btn-primary btn-sm btn-inline mr-2"
                    (click)="submit()">
                    {{ 'aimo.baseOrder.selection.remove' | cxTranslate }}
                </button>
                <button
                    [attr.aria-label]="'aimo.ordertemplate.cancel' | cxTranslate"
                    [title]="'aimo.ordertemplate.cancel' | cxTranslate"
                    class="btn btn-secondary btn-sm btn-inline"
                    (keydown.tab)="getTheFocusBack($event)"
                    (click)="closeDialog()">
                    {{ 'aimo.ordertemplate.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
