import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import {
    GlobalMessageService,
    GlobalMessageType,
    normalizeHttpError,
    SiteContextActions,
    withdrawOn,
} from '@spartacus/core';
import { from, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { AimoCartModification } from '../../model/cart.model';
import { AimoGtmProducts } from '../gtm/aimo-gtm.action';
import { AimoGTMProduct, GTMEventCode, GTMItemListId } from '../gtm/aimo-gtm.model';

import { AimoCartConnector } from './aimo-cart.connector';
import {
    AIMO_CART_ADD_ENTRIES,
    AIMO_CART_ADD_ENTRY,
    AIMO_CART_UPDATE_ENTRY,
    AimoCartAddEntries,
    AimoCartAddEntriesFail,
    AimoCartAddEntry,
    AimoCartAddEntrySuccess,
    AimoCartUpdateEntry,
    AimoCartUpdateEntrySuccess,
} from './aimo-entry.action';

@Injectable()
export class AimoCartEntryEffects {
    constructor(
        protected actions$: Actions,
        protected globalMessage: GlobalMessageService,
        protected cartEntryConnector: AimoCartConnector,
    ) {}

    private contextChange2$ = this.actions$.pipe(
        ofType(SiteContextActions.CURRENCY_CHANGE, SiteContextActions.LANGUAGE_CHANGE),
    );

    addEntry$: Observable<
        AimoCartAddEntrySuccess | CartActions.CartAddEntryFail | CartActions.LoadCart | AimoGtmProducts
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(AIMO_CART_ADD_ENTRY),
            map((action: AimoCartAddEntry) => action),
            concatMap((payload) => {
                return this.cartEntryConnector
                    .addAimoEntries(
                        payload.userId,
                        payload.cartId,
                        [payload.entry],
                        payload.gtmProductAttributes?.category_code,
                        payload.gtmProductAttributes?.search_term,
                        payload.gtmProductAttributes?.klevuParams,
                    )
                    .pipe(
                        concatMap((modifications: AimoCartModification[]) =>
                            modifications.flatMap((modification) => {
                                if (modification.statusMessage) {
                                    this.globalMessage.add(
                                        modification.statusMessage,
                                        GlobalMessageType.MSG_TYPE_INFO,
                                        5000,
                                    );
                                }
                                return [
                                    new AimoCartAddEntrySuccess({
                                        cartId: payload.cartId,
                                        productCode: payload.entry?.product?.code,
                                        userId: payload.userId,
                                        ...(modification as Required<AimoCartModification>),
                                        quantity: modification.entry.quantity,
                                    }),
                                    modification.quantityAdded !== 0
                                        ? new AimoGtmProducts(
                                              [
                                                  {
                                                      ...modification.entry.product,
                                                      quantity: Math.abs(modification.quantityAdded),
                                                      discount: modification.addedQuantityDiscount,
                                                      price: modification.adjustedBasePrice,
                                                      gtmProductAttributes: payload.gtmProductAttributes,
                                                  } as AimoGTMProduct,
                                              ],
                                              modification.quantityAdded > 0
                                                  ? GTMEventCode.AddToCart
                                                  : GTMEventCode.RemoveFromCart,
                                              modification.addedQuantityPrice
                                                  ? modification.addedQuantityPrice.value
                                                  : 0,
                                              null,
                                              null,
                                              null,
                                              payload.gtmProductAttributes.cart_type,
                                          )
                                        : null,
                                ].filter((m) => m !== null);
                            }),
                        ),
                        catchError((error) =>
                            from([
                                new CartActions.CartAddEntryFail({
                                    ...payload,
                                    productCode: payload.entry?.product?.code,
                                    quantity: payload.entry?.quantity,
                                    error: normalizeHttpError(error),
                                }),
                                new CartActions.LoadCart({
                                    cartId: payload.cartId,
                                    userId: payload.userId,
                                }),
                            ]),
                        ),
                    );
            }),
            withdrawOn(this.contextChange2$),
        ),
    );

    addEntries$: Observable<AimoCartAddEntrySuccess | AimoCartAddEntriesFail | CartActions.LoadCart | AimoGtmProducts> =
        createEffect(() =>
            this.actions$.pipe(
                ofType(AIMO_CART_ADD_ENTRIES),
                concatMap((action: AimoCartAddEntries) => {
                    const payload = {
                        userId: action.userId,
                        cartId: action.cartId,
                        productCodes: action.entries?.map((entry) => entry.product.code),
                    };
                    return this.cartEntryConnector
                        .addAimoEntries(action.userId, action.cartId, action.entries, null, action.searchTerm, null)
                        .pipe(
                            concatMap((modifications: AimoCartModification[]) => {
                                return [
                                    new AimoCartAddEntrySuccess({
                                        cartId: payload.cartId,
                                        productCode: modifications[0]?.entry?.product?.code,
                                        userId: payload.userId,
                                        modifications: modifications,
                                        ...(modifications[0] as Required<AimoCartModification>),
                                    }),
                                    new AimoGtmProducts(
                                        modifications.map(
                                            (modification) =>
                                                ({
                                                    ...modification.entry.product,
                                                    quantity: modification.quantityAdded,
                                                    discount: modification.addedQuantityDiscount,
                                                    price: modification.adjustedBasePrice,
                                                    gtmProductAttributes: {
                                                        item_list_id: GTMItemListId.cart_suggestive_search,
                                                        index: action.entries?.findIndex(
                                                            (e) => e.product.code === modification.entry.product.code,
                                                        ),
                                                    },
                                                } as AimoGTMProduct),
                                        ),
                                        GTMEventCode.AddToCart,
                                        //   modifications.reduce((sum, mod) => sum + mod.entry?.totalPrice?.value ?? 0, 0),
                                        modifications.reduce((sum, mod) => sum + mod.addedQuantityPrice?.value ?? 0, 0),
                                        null,
                                        null,
                                        null,
                                        action.origin,
                                    ),
                                ];
                            }),
                            catchError((error) => {
                                return from([
                                    new AimoCartAddEntriesFail({
                                        ...payload,
                                        error: normalizeHttpError(error),
                                    }),
                                    new CartActions.LoadCart({
                                        cartId: payload.cartId,
                                        userId: payload.userId,
                                    }),
                                ]);
                            }),
                        );
                }),
                withdrawOn(this.contextChange2$),
            ),
        );

    updateEntry$: Observable<
        AimoCartUpdateEntrySuccess | CartActions.CartUpdateEntryFail | CartActions.LoadCart | AimoGtmProducts
    > = createEffect(() =>
        this.actions$.pipe(
            ofType(AIMO_CART_UPDATE_ENTRY),
            map((action: AimoCartUpdateEntry) => action),
            concatMap((payload) =>
                this.cartEntryConnector
                    .updateAimo(
                        payload.userId,
                        payload.cartId,
                        payload.entry,
                        payload.gtmProductAttributes?.search_term,
                    )
                    .pipe(
                        concatMap((modification: AimoCartModification) => {
                            return [
                                new AimoCartUpdateEntrySuccess({
                                    cartId: payload.cartId,
                                    productCode: modification.entry?.product.code,
                                    entryNumber: modification.entry?.entryNumber + '',
                                    entry: modification.entry,
                                    userId: payload.userId,
                                    ...(modification as Required<AimoCartModification>),
                                    quantity: modification.quantityAdded,
                                }),
                                modification.entry && modification.quantityAdded !== 0
                                    ? new AimoGtmProducts(
                                          [
                                              {
                                                  ...modification.entry.product,
                                                  quantity: Math.abs(modification.quantityAdded), // Quantities are always positive numbers
                                                  discount: modification.addedQuantityDiscount,
                                                  price: modification.adjustedBasePrice,
                                                  gtmProductAttributes: payload.gtmProductAttributes,
                                              } as AimoGTMProduct,
                                          ],
                                          modification.quantityAdded > 0
                                              ? GTMEventCode.AddToCart
                                              : GTMEventCode.RemoveFromCart,
                                          modification.addedQuantityPrice ? modification.addedQuantityPrice.value : 0,
                                          null,
                                          null,
                                          null,
                                          payload.gtmProductAttributes.cart_type,
                                      )
                                    : null,
                            ].filter((m) => m !== null);
                        }),
                        catchError((error) =>
                            from([
                                new CartActions.CartUpdateEntryFail({
                                    ...payload,
                                    entryNumber: payload.entry?.entryNumber + '',
                                    quantity: payload.entry?.quantity,
                                    error: normalizeHttpError(error),
                                }),
                                new CartActions.LoadCart({
                                    cartId: payload.cartId,
                                    userId: payload.userId,
                                }),
                            ]),
                        ),
                    ),
            ),
            withdrawOn(this.contextChange2$),
        ),
    );
}
