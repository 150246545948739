import { OccConfig, OccEndpoints } from '@spartacus/core';

const organizationUsers = 'organizationUsers(active,approved,lastLogin,uid,name,customerAdmin,availableUnits(FULL))';

const userFields =
    'isHideNormalOrderTemplatesFromBasicUsers,active,customerAdmin,approved,consentTitle,assortmentChangeEnabled,consentText,consentApproved,title,titleCode,displayUid,defaultAddress(FULL),language(FULL),' +
    'currency(FULL),uid,name,email,deliveryCost(FULL),unitSystem,phone,unit(active,FULL),tukkumyyja,pendingApprovals,allAvailableUnits(FULL),availableUnits(FULL,' +
    organizationUsers +
    '),lastLogin,orderConfirmationsInEmail,' +
    organizationUsers;

export const aimoOccUserConfig: OccConfig = {
    backend: {
        occ: {
            endpoints: {
                category: 'users/${userId}/catalogs/categories/${categoryId}',
                categoryHierarchy: 'users/${userId}/catalogs/categories/${categoryId}/hierarchy',
                contactUs: 'contactUs',
                requestAccess: 'requestAccess',
                searchUnits:
                    'users/${userId}/units/searchUnits?customerEmulationParameter=true&fields=uid,name,addresses(FULL),allowInvoices',
                selectUnit:
                    'users/${userId}/units/selectUnit?uid=${uid}&cartId=${cartId}&customerEmulationParameter=true',
                toggleFavorite: 'users/${userId}/toggleFavorite/${productPk}?customerEmulationParameter=true',
                userSetPassword: 'setPassword?customerEmulationParameter=true',
                userUpdatePassword: 'users/${userId}/password',
                excelExportOrder: 'users/${userId}/excelExport/${orderNumber}',
                registerCustomer: '/registerCustomer',
                addOrModifyUser: 'users/${userId}/createOrModifyUser',
                modifyB2BUnit: 'users/${userId}/modifyB2BUnit',
                user: 'users/${userId}?fields=' + userFields,
                alchoholQuota: 'users/${userId}/alchoholQuota',
                activeBulletins: 'users/${userId}/bulletins/list',
                giveConsent: 'users/${userId}/consent',
                storeOpeningHours: '/basestores/pikatukku/openingHours',
                hideNormalOrderTemplatesFromBasicUsers:
                    'users/${userId}/hideNormalOrderTemplatesFromBasicUsers/${value}?fields=' + userFields,
            } as OccEndpoints,
        },
    },
};
