import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { WindowRef } from '@spartacus/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoRouteCalendar, AimoRouteCalendarDay, AimoRouteCalendarMonth } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { GTMCalendarSource } from '../../../service/gtm/aimo-gtm.model';
import { AimoDeliveryDateService } from '../../../service/routecalendar/aimo-delivery-date.service';
import { DateUtils } from '../../../shared/util/date-utils';

@Component({
    selector: 'aimo-cx-delivery-date-component',
    templateUrl: './aimo-delivery-date.component.html',
})
export class AimoDeliveryDateComponent implements AfterViewInit, OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    routeCalendar$: Observable<AimoRouteCalendar>;
    isUpdating = true;
    weekDays = [2, 3, 4, 5, 6, 7, 1]; // use java.util.Calendar -week days
    selectedDate: Observable<Date>;

    changedDate: string;

    today = new Date();

    activeCartsAmount$: Observable<number>;

    exceptionDateSelected = false;

    expressDateSelected = false;

    @Input()
    allowPastDates = true;

    @Input()
    modal = false;

    @Input()
    autofocus: boolean = true;

    @Input()
    claimsMode: boolean = false;

    @Input()
    calendarSource: GTMCalendarSource;

    @Output()
    dayClicked = new EventEmitter<string>();

    @ViewChild('popup')
    popup: NgbPopover;

    @ViewChild('calendarLink')
    calendarLink: ElementRef;

    //used for caching the month data, so it doesn't need to be build everytime
    private selectedMonth: AimoRouteCalendarMonth;

    // used for keeping track the month change functionality
    private activeMonth: AimoRouteCalendarMonth = {};

    private deliveryDate$ = this.deliveryDateService.getDeliveryDate();

    constructor(
        protected deliveryDateService: AimoDeliveryDateService,
        protected activeCartService: AimoActiveCartService,
        protected cdr: ChangeDetectorRef,
        protected winRef: WindowRef,
        protected store: Store,
        protected actions: Actions,
    ) {}

    initCalendar(): void {
        this.routeCalendar$ = combineLatest([
            this.deliveryDate$,
            this.activeCartService.getRouteCalendar(this.claimsMode),
        ]).pipe(
            map(([deliveryDate, routeCal]) => {
                this.isUpdating = false;
                this.selectedMonth = undefined;
                this.changedDate = undefined;
                this.expressDateSelected = false;
                this.exceptionDateSelected = false;
                this.activeMonth = {
                    month: routeCal.activeMonth,
                    year: this.getDeliveryDay(deliveryDate).getFullYear(),
                };
                this.activeCartService.triggerGTMOpenCalendar(this.calendarSource);
                return routeCal;
            }),
        );
    }

    ngOnInit(): void {
        this.initCalendar();
        this.selectedDate = this.deliveryDate$.pipe(map((d) => this.getDeliveryDay(d)));
        this.activeCartsAmount$ = this.activeCartService.getActive().pipe(
            map((cart) => {
                const amount = cart.dayGroupedEntries?.filter((g) => g.modifiedEntries > 0).length;
                return amount == 1 && cart.requestedDeliveryDate === cart.dayGroupedEntries[0].date ? 0 : amount;
            }),
        );
    }

    getDeliveryDay(d: string): Date {
        const date = DateUtils.convertDate(d);
        if (date.getTime() > new Date().getTime() && this.claimsMode) {
            return new Date();
        }
        return date;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // eslint-disable-next-line
    focusNextChild(event: UIEvent): void {
        if (this.calendarLink.nativeElement.getAttribute('aria-describedby')) {
            this.winRef.document.getElementById('previousMonth')?.focus();
        }
    }

    getMonth(routeCal: AimoRouteCalendar): AimoRouteCalendarMonth {
        if (!this.selectedMonth) {
            const month = routeCal.months.find((m) => m.month === this.activeMonth.month);
            this.selectedMonth = {
                ...month,
                weeks: month?.weeks?.map((w) => ({
                    ...w,
                    days: w.days.map((d) => {
                        return {
                            ...d,
                            selected: this.changedDate === undefined ? d.selected : d.date === this.changedDate, // set selected date indicator if the date is changed in ui
                        };
                    }),
                })),
            };
            this.checkExceptionDates();
            this.focusCalendar();
        }
        return this.selectedMonth;
    }

    checkExceptionDates(): void {
        this.expressDateSelected = false;
        this.exceptionDateSelected = false;
        this.selectedMonth?.weeks?.forEach((w) =>
            w.days.forEach((d) => {
                if (d.selected) {
                    if (d.today) {
                        this.expressDateSelected = !d.deliveryDate;
                    } else {
                        this.exceptionDateSelected = !d.deliveryDate;
                    }
                }
            }),
        );
    }

    hasMonths(routeCal: AimoRouteCalendar, forward: boolean): boolean {
        const next = this.getNextMonth(forward);
        if (forward) {
            return routeCal.months.find((m) => m.month === next.month && m.year == next.year) !== undefined;
        }
        const now = new Date();
        return (
            routeCal.months.find(
                (m) =>
                    m.month === next.month &&
                    m.year == next.year &&
                    (this.allowPastDates || now.getTime() <= this.createDate(m.year, m.month).getTime()),
            ) !== undefined
        );
    }

    createDate(year: number, month: number): Date {
        const d = new Date();
        d.setFullYear(year);
        d.setMonth(month);
        return d;
    }

    setMonth(forward: boolean): void {
        this.activeMonth = this.getNextMonth(forward);
        this.selectedMonth = undefined;
        this.cdr.detectChanges();
    }

    getNextMonth(forward: boolean): AimoRouteCalendarMonth {
        if (forward) {
            return {
                month: this.activeMonth.month === 11 ? 0 : this.activeMonth.month + 1,
                year: this.activeMonth.month === 11 ? this.activeMonth.year + 1 : this.activeMonth.year,
            };
        }
        return {
            month: this.activeMonth.month === 0 ? 11 : this.activeMonth.month - 1,
            year: this.activeMonth.month === 0 ? this.activeMonth.year - 1 : this.activeMonth.year,
        };
    }

    selectDate(month: AimoRouteCalendarMonth, weekDay: AimoRouteCalendarDay): void {
        if (!weekDay.disabled) {
            this.changedDate = weekDay.date;
            this.dayClicked.emit(weekDay.date);
            month?.weeks?.forEach((week) => week.days.forEach((day) => (day.selected = false)));
            weekDay.selected = true;
            this.checkExceptionDates();
            this.cdr.detectChanges();
            if (!this.modal) {
                this.activeCartService.updateRequestedDate(weekDay.date, GTMCalendarSource.calendar);
                this.popup.close();
            }
        }
    }

    ngAfterViewInit(): void {
        this.focusCalendar();
    }

    focusCalendar(): void {
        if (this.autofocus) {
            setTimeout(() => {
                this.winRef.document.getElementById('today_date')?.focus();
            }, 200);
        }
    }

    restartFocusEvent(calendar: HTMLDivElement, event): void {
        if (event.keyCode == 9 || (event.shiftKey && event.keyCode == 9)) {
            const elements = calendar.querySelectorAll('.month-cell, .calendar-cell.calendar-day');
            const firstElement = <HTMLElement>elements[0];
            const lastElement = <HTMLElement>elements[elements.length - 1];

            // tab
            if (event.keyCode == 9) {
                if (lastElement.contains(event.target as HTMLElement)) {
                    event.preventDefault();
                    firstElement.querySelector('button').focus();
                }
            }

            // shift + tab
            if (event.shiftKey && event.keyCode == 9) {
                if (firstElement.contains(event.target as HTMLElement)) {
                    event.preventDefault();
                    // focus back to delivery date button after month arrows
                    const deliveryDate = document.querySelectorAll('.aimo-cx-delivery-date-component');
                    const deliveryDateBtn = <HTMLElement>deliveryDate[0];
                    deliveryDateBtn.querySelector('button').focus();
                }
            }
        }
    }
}
