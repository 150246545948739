import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import {
    CmsPageGuard,
    FormErrorsModule,
    ListNavigationModule,
    MediaModule,
    PageLayoutComponent,
} from '@spartacus/storefront';

import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoInputSelectModule } from '../../shared/input-select/aimo-input-select.module';
import { AimoItemCounterModule } from '../../shared/item-counter/aimo-item-counter.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from '../cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoBreadcrumbModule } from '../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoSearchBoxModule } from '../navigation/search-box/aimo-search-box.module';
import { AimoOrderHistoryModule } from '../order-history/aimo-order-history.module';
import { AimoOrderTemplateModule } from '../order-template/aimo-order-template.module';
import { AimoProductListModule } from '../product/product-list/aimo-product-list.module';
import { AimoDatepickerModule } from '../shared/datepicker/aimo-datepicker.module';
import { AimoPipesModule } from '../shared/utils/safe-html/aimo-pipes.module';

import { AimoBaseOrdersComponent } from './aimo-base-orders.component';
import { AimoBaseOrderDetailsComponent } from './base-order-details/aimo-base-order-details.component';
import { AimoBaseOrderBlockModalComponent } from './base-order-modal/aimo-base-order-block-modal.component';
import { AimoBaseOrderBlockRemoveModalComponent } from './base-order-modal/aimo-base-order-block-remove-modal.component';
import { baseOrderLayout } from './base-order-modal/aimo-base-order-layout.config';
import { AimoBaseOrderModalComponent } from './base-order-modal/aimo-base-order-modal.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        AimoInputModule,
        AimoDatepickerModule,
        ReactiveFormsModule,
        MediaModule,
        AimoDeliveryDateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        FormErrorsModule,
        AimoInputSelectModule,
        AimoItemCounterModule,
        AimoOrderHistoryModule,

        RouterModule.forChild([
            {
                // @ts-ignore
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: {
                    cxRoute: 'baseOrders',
                },
            },
            {
                // @ts-ignore
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: {
                    cxRoute: 'baseOrderDetails',
                },
            },
        ]),
        AimoSearchBoxModule,
        AimoCartSharedModule,
        AimoProductListModule,
        AimoBreadcrumbModule,
        AimoPipesModule,
        AimoOrderTemplateModule,
    ],
    declarations: [
        AimoBaseOrdersComponent,
        AimoBaseOrderModalComponent,
        AimoBaseOrderDetailsComponent,
        AimoBaseOrderBlockModalComponent,
        AimoBaseOrderBlockRemoveModalComponent,
    ],
    exports: [
        AimoBaseOrdersComponent,
        AimoBaseOrderModalComponent,
        AimoBaseOrderDetailsComponent,
        AimoBaseOrderBlockModalComponent,
        AimoBaseOrderBlockRemoveModalComponent,
    ],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                BaseOrdersTemplatesComponent: {
                    component: AimoBaseOrdersComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {},
        }),
        provideDefaultConfig(baseOrderLayout),
        provideDefaultConfig(<RoutingConfig>{
            routing: {
                routes: {
                    baseOrders: {
                        paths: ['baseorders'],
                    },
                    baseOrderDetails: {
                        paths: ['baseorders/:orderId'],
                        paramsMapping: { orderId: 'orderId' },
                    },
                },
            },
        }),
    ],
})
export class AimoBaseOrderModule {}
