import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoRouteInfo } from '../../../model/user.model';
import { AimoCartDialogData } from '../aimo-cart-layout.config';

@Component({
    selector: 'aimo-route-schedules',
    templateUrl: './aimo-route-schedules.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoRouteSchedulesComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    routeInfos: AimoRouteInfo[];

    constructor(protected launchDialogService: LaunchDialogService) {}

    ngOnInit(): void {
        this.subscription.add(
            this.launchDialogService.data$.subscribe((dialogData: AimoCartDialogData) => {
                if (dialogData) {
                    this.routeInfos = dialogData.cart.unit.routeInfos;
                }
            }),
        );
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.ROUTE_INFOS);
        this.launchDialogService.closeDialog('closed');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
