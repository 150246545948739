import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoCloseAccountComponent } from './aimo-close-account.component';

export const closeAccountHistoryLayout: LayoutConfig = {
    launch: {
        CLOSE_ACCOUNT: {
            inlineRoot: true,
            component: AimoCloseAccountComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
