import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';

import { AimoProductListModule } from '../../product/product-list/aimo-product-list.module';
import { AimoSpinnerModule } from '../spinner/aimo-spinner.module';

import { AimoCarouselComponent } from './aimo-carousel.component';
import { AimoCMSCarouselComponent } from './aimo-cms-carousel.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        MediaModule,
        UrlModule,
        I18nModule,
        AimoProductListModule,
        AimoSpinnerModule,
    ],
    declarations: [AimoCarouselComponent, AimoCMSCarouselComponent],
    exports: [AimoCarouselComponent, AimoCMSCarouselComponent],
    providers: [
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AimoProductCarouselComponent: {
                    component: AimoCMSCarouselComponent,
                },
            },
        }),
    ],
})
export class AimoCarouselModule {}
