import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { UpdatePasswordComponent, UpdatePasswordComponentService } from '@spartacus/user/profile/components';

/* eslint-disable */
@Component({
    selector: 'aimo-update-password',
    templateUrl: './aimo-update-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line
    host: { class: 'user-form' },
})
export class AimoUpdatePasswordComponent extends UpdatePasswordComponent implements OnInit {
    constructor(protected service: UpdatePasswordComponentService, protected winRef: WindowRef) {
        super(service);
    }

    ngOnInit(): void {}
}
/* eslint-enable */
