import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AimoFacetValue } from '../../../../model/product.model';

export const MAX_CATEGORY_FACETS = 10;

@Component({
    selector: 'aimo-product-category-facets',
    templateUrl: './aimo-product-category-facets.component.html',
})
export class AimoProductCategoryFacetsComponent {
    @Input() values: AimoFacetValue[];
    @Input() link: string;
    @Input() showAll = false;

    @Output() showAllAction = new EventEmitter<boolean>();

    categoryName: string;
    categoryCount: number;

    constructor(protected router: Router, protected cdr: ChangeDetectorRef) {}

    toggleShowAll(): void {
        this.showAll = !this.showAll;
        this.showAllAction.emit(this.showAll);
    }

    getCategories(values: AimoFacetValue[]): AimoFacetValue[] {
        return this.showAll ? values : values.slice(0, MAX_CATEGORY_FACETS);
    }

    showToggle(length: number): boolean {
        return length > MAX_CATEGORY_FACETS;
    }

    openCategoryFacet(queryParameters, name: string, count: number, divCategories: HTMLDivElement): void {
        this.categoryName = name;
        this.categoryCount = count;
        this.cdr.detectChanges();
        this.router.navigate([this.link], { queryParams: { query: queryParameters } });
        divCategories.focus();
    }
}
