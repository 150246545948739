<div class="product-code text-dark-grey">
    <ng-container *ngIf="product.frozenProduct">
        <i
            class="aimo-icon icon-inline icon-dark-grey icon-sm icon-snow-flake"
            triggers="mouseenter:mouseleave"
            popoverClass="popover-default"
            container="body"
            role="img"
            [attr.aria-label]="'aimo.product.frozenProduct' | cxTranslate"
            [ngbPopover]="'aimo.product.frozenProduct' | cxTranslate"></i>
    </ng-container>
    <span class="{{ klass }}" #productId (dblclick)="selectProductId(product.code, $event)">{{ product.code }}</span>
    <span class="{{ klass }} text-left-space">{{ product.lotSizeInfo }}</span>
</div>
