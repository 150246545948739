import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { EventService, UserIdService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { UserAccountChangedEvent } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoB2BUnitOption, AimoUser } from '../../../model/user.model';
import {
    ADD_USER_FAILED,
    ADD_USER_SUCCESS,
    MODIFY_B2B_UNIT_FAILED,
    MODIFY_B2B_UNIT_SUCCESS,
    RegisterFailed,
} from '../../../service/user/aimo-user.action';
import { AimoUserService } from '../../../service/user/aimo-user.service';

import { AimoEditB2BUnitData } from './edit-b2bunit/aimo-edit-b2bunit.config';
import { AddNewUserData } from './new-user/aimo-new-user.config';

@Component({
    selector: 'aimo-my-organization',
    templateUrl: './aimo-my-organization.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoMyOrganizationComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    error: string;
    currentUser$: Observable<AimoUser>;
    allUsers$: Observable<AimoUser[]>;
    pendingApprovals$: Observable<number>;
    availableUnitsSearch: string;
    usersSearch: string;

    constructor(
        private actions$: Actions,
        protected cdr: ChangeDetectorRef,
        protected userService: AimoUserService,
        protected launchDialogService: LaunchDialogService,
        protected winRef: WindowRef,
        protected userIdService: UserIdService,
        protected eventService: EventService,
    ) {}

    ngOnInit(): void {
        this.setCurrentUser();
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (action.type === ADD_USER_SUCCESS || action.type === MODIFY_B2B_UNIT_SUCCESS) {
                    this.error = undefined;
                    this.setCurrentUser();
                    this.cdr.detectChanges();
                } else if (action.type === ADD_USER_FAILED || action.type === MODIFY_B2B_UNIT_FAILED) {
                    this.error = (action as RegisterFailed).error;
                    this.cdr.detectChanges();
                }
            }),
        );
    }

    setCurrentUser(): void {
        this.currentUser$ = this.userService.getMyOrganizationDetails();
        this.allUsers$ = this.currentUser$.pipe(map((currentUser) => this.getAllOrganizationUsers(currentUser)));
        this.pendingApprovals$ = this.allUsers$.pipe(map((l) => l.filter((u) => !u.approved).length));
    }

    addNewUser(b2bUnits: AimoB2BUnitOption[], user?: AimoUser): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ADD_NEW_USER, undefined, {
            user,
            b2bUnits,
        } as AddNewUserData);
    }

    editB2BUnit(currentUser: AimoUser, b2bUnit: AimoB2BUnitOption): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.EDIT_B2BUNIT, undefined, {
            allUsers: this.getAllOrganizationUsers(currentUser),
            b2bUnit: b2bUnit,
        } as AimoEditB2BUnitData);
    }

    editB2BUser(currentUser: AimoUser, b2bUnits: AimoB2BUnitOption[], user?: AimoUser): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ADD_NEW_USER, undefined, {
            currentUser: currentUser,
            user: user,
            b2bUnits: b2bUnits,
        } as AddNewUserData);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getUserList(unit: AimoB2BUnitOption): string {
        return unit.organizationUsers
            ?.filter((user) => user.approved && user.active)
            ?.map((user) => user.uid)
            .join(', ');
    }

    getAllOrganizationUsers(currentUser: AimoUser): AimoUser[] {
        return currentUser.organizationUsers;
    }

    approveUser(user: AimoUser, currentUser: AimoUser, deny: boolean): void {
        this.userService.addOrModifyUser({
            uid: user.uid,
            activate: !deny,
            denyApproval: deny,
            b2bUnits: [],
        });
        const sub = this.actions$.subscribe((action) => {
            if (action.type === ADD_USER_SUCCESS) {
                // Reset the approval indicators from header
                const evt = new UserAccountChangedEvent();
                evt.user = user;
                this.eventService.dispatch(evt);
                this.userIdService.setUserId(currentUser.uid);
                sub.unsubscribe();
            }
        });
    }

    changeAvailableUnitsSearch(search: string): void {
        this.availableUnitsSearch = search;
        this.cdr.detectChanges();
    }

    changeUsersSearch(search: string): void {
        this.usersSearch = search;
        this.cdr.detectChanges();
    }
}
