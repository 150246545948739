import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoB2BUnitOption, AimoUser } from '../../../../model/user.model';

import { AimoEditB2bunitComponent } from './aimo-edit-b2bunit.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        EDIT_B2BUNIT = 'EDIT_B2BUNIT',
    }
}

export const editB2BUnitLayout: LayoutConfig = {
    launch: {
        EDIT_B2BUNIT: {
            inlineRoot: true,
            component: AimoEditB2bunitComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoEditB2BUnitData {
    allUsers: AimoUser[];
    b2bUnit: AimoB2BUnitOption;
}
