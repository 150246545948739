import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';

import { AimoInputSelectComponent } from './aimo-input-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        I18nModule,
        NgSelectModule,
        NgbTooltipModule,
    ],
    declarations: [AimoInputSelectComponent],
    exports: [AimoInputSelectComponent],
    entryComponents: [AimoInputSelectComponent],
})
export class AimoInputSelectModule {}
