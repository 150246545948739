import { DIALOG_TYPE, LAUNCH_CALLER, LayoutConfig } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';

import { AimoProduct } from '../../../model/product.model';
import { KlevuParams } from '../../../service/gtm/aimo-gtm.model';

import { AimoProductSummaryComponent } from './aimo-product-summary.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        PRODUCT_SUMMARY = 'PRODUCT_SUMMARY',
    }
}

export const productSummaryLayout: LayoutConfig = {
    launch: {
        PRODUCT_SUMMARY: {
            inlineRoot: true,
            component: AimoProductSummaryComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};

export interface AimoProductSummaryDialogData {
    product: Observable<AimoProduct>;
    scrollToId?: string;
    addToCartCallBack: BehaviorSubject<number>;
    openModalAfterClose?: LAUNCH_CALLER;
    focusIdAfterClose?: string;
    klevuParams?: KlevuParams;
}
