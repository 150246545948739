<!-- https://github.com/angular/angular/issues/24567 -->
<ng-container *ngIf="isExternalUrl(); else isLocalUrl">
    <a
        [href]="url"
        [attr.target]="target"
        [attr.rel]="rel"
        [attr.id]="id"
        [attr.class]="class"
        [attr.style]="style"
        [attr.title]="title"
        [tabIndex]="0"
        role="button"
        [attr.aria-label]="title">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-container>

<ng-template #isLocalUrl>
    <a
        [routerLink]="routerUrl"
        [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="matchOptions"
        [queryParams]="queryParams"
        [fragment]="fragment"
        [target]="target"
        [attr.id]="id"
        [attr.class]="class"
        [attr.style]="style"
        [attr.title]="title"
        [tabIndex]="0"
        role="button"
        [attr.aria-label]="title">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
