import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { RoutingService, TranslationService, WindowRef } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoRoutingService } from '../../../cms-structure/routing/aimo-routing.service';
import { AimoDayGroupedOrderHistory } from '../../../model/order.model';
import { AimoUser } from '../../../model/user.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AIMO_CART_UPDATE_HEADER_SUCCESS } from '../../../service/cart/aimo-cart.action';
import { GTMCalendarSource } from '../../../service/gtm/aimo-gtm.model';
import { AimoGtmService } from '../../../service/gtm/aimo-gtm.service';
import { AimoUserService } from '../../../service/user/aimo-user.service';
import { AimoClaimsDialogData } from '../../claims/claims-modal/aimo-claims-layout.config';
import { AimoOrderTemplateDialogData } from '../../order-template/order-template-modal/aimo-order-template-layout.config';
import { AimoReorderHistoryDialogData } from '../reorder-modal/reorder-layout.config';

@Component({
    selector: 'aimo-order-history-day',
    templateUrl: './aimo-order-history-day.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderHistoryDayComponent implements OnInit {
    @Input()
    day: AimoDayGroupedOrderHistory;

    @Input() open = false;

    deliveryNotes: string[];
    isEditable: boolean;
    user$: Observable<AimoUser>;

    constructor(
        protected activeCartService: AimoActiveCartService,
        protected windowRef: WindowRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected launchDialogService: LaunchDialogService,
        protected translation: TranslationService,
        protected aimoRoutingService: AimoRoutingService,
        protected gtmService: AimoGtmService,
        protected userService: AimoUserService,
    ) {}

    ngOnInit(): void {
        this.user$ = this.userService.get();
        this.day = { ...this.day };
        this.deliveryNotes = Array.from(
            new Set(
                this.day.orders.flatMap((o) =>
                    o.entries.map((e) => e.invoiceDocumentNumber).filter((n) => n !== undefined),
                ),
            ),
        );

        this.isEditable = this.day.orders.flatMap((o) => o.entries.filter((e) => e.editable)).length > 0;
    }

    toggleDay(): void {
        this.open = !this.open;
        if (this.open) {
            this.gtmService.expandOrderHistory(this.day);
        }
    }

    print(): void {
        this.windowRef.nativeWindow.print();
    }

    exportOrderToExcel(day: AimoDayGroupedOrderHistory): void {
        this.activeCartService.exportCartToExcel(day.date);
    }

    edit(): void {
        this.activeCartService.updateRequestedDate(this.day.date, GTMCalendarSource.orderHistory);

        const sub = this.actions$.subscribe((action) => {
            if (action.type === AIMO_CART_UPDATE_HEADER_SUCCESS) {
                this.routingService.go('/cart', { queryParams: { openOrders: this.day.orderNumbers.join(',') } });
                sub.unsubscribe();
            }
        });
    }

    reOrder(): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.REORDER_HISTORY, undefined, {
            day: this.day,
        } as AimoReorderHistoryDialogData);
    }

    downloadDeliveryNote(invoiceId: string, name: string): void {
        this.translation
            .translate(name, { id: invoiceId })
            .subscribe((t) => this.activeCartService.downloadOrderDocument(invoiceId, t, true))
            .unsubscribe();
    }

    claims(): void {
        this.launchDialogService.closeDialog('closed');
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CLAIMS, undefined, {
            day: this.day,
            editable: !this.day.claimCreated,
        } as AimoClaimsDialogData);
    }

    addToOrderTemplate(day: AimoDayGroupedOrderHistory, sourceId: string): void {
        this.launchDialogService.closeDialog(null);
        this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.ORDER_TEMPLATE, undefined, {
            orderHistoryDay: day.date,
            createNew: true,
            focusIdAfterClose: sourceId,
        } as AimoOrderTemplateDialogData);
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}
