<div class="container">
    <div class="row no-gutters">
        <div class="col-12 col-lg-6">
            <cx-page-slot position="Section2A"></cx-page-slot>
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0">
            <cx-page-slot position="Section2B"></cx-page-slot>
        </div>
    </div>
</div>
