<div class="collapsed" #facetDiv *ngIf="aimoFacet.values.length > 0" id="facet_{{ aimoFacet.code }}">
    <ng-container *ngIf="state$ | async as state">
        <button
            class="heading btn-facet"
            [attr.aria-expanded]="facetOpened"
            [attr.aria-controls]="'facet_' + aimoFacet.name"
            (click)="toggleFacet()"
            [attr.aria-expanded]="isExpanded"
            [attr.aria-label]="'productFacetNavigation.filterBy.name' | cxTranslate : { name: facet.name }">
            <span class="facet-text pr-2">{{ facet.name }}</span>
            <span class="facet-active-value" *ngIf="getSelectedFacet(aimoFacet.values) > 0">
                {{ getSelectedFacet(aimoFacet.values) }}
            </span>
            <i class="aimo-icon icon-caret-up collapse-icon"></i>
            <i class="aimo-icon icon-caret-down expand-icon"></i>
        </button>

        <div class="facet-content" [id]="'facet_' + aimoFacet.name" *ngIf="!aimoFacet.range; else customRangeFacet">
            <label
                [for]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code"
                class="searchbox"
                attr.aria-label="{{
                    'productFacetNavigation.ariaLabel.search' | cxTranslate : { name: aimoFacet.name }
                }}">
                <i class="aimo-icon icon-sm mr-2 icon-magnifying-glass icon-petrol-blue icon-inline"></i>
                <input
                    type="text"
                    [(ngModel)]="searchText"
                    name="facet_{{ aimoFacet.name }}"
                    [id]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code" />
            </label>
            <div class="facet-options">
                <ng-container
                    *ngFor="
                        let value of aimoFacet.values
                            | aimoFilter : searchText : ['name']
                            | slice : 0 : state.maxVisible
                    ">
                    <div
                        class="form-check"
                        attr.aria-label="{{
                            'productFacetNavigation.ariaLabelItemsAvailable'
                                | cxTranslate : { name: value.name, count: value.count }
                        }}">
                        <input
                            [id]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code + '_' + value.code"
                            type="checkbox"
                            [checked]="value.selected"
                            (change)="toggleSelected(value)" />
                        <label
                            [for]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code + '_' + value.code">
                            <span class="text-break">{{ value.name }}</span>
                            <span aria-hidden="true" class="count float-right text-dark-grey">{{ value.count }}</span>
                        </label>
                    </div>
                </ng-container>
            </div>
            <div class="pt-3 d-flex row">
                <div class="col-6">
                    <button
                        class="btn btn-block btn-primary p-0"
                        [attr.aria-label]="'productList.filter.limit' | cxTranslate"
                        (click)="getLinkParamsForFacet($event)">
                        {{ 'productList.filter.limit' | cxTranslate }}
                    </button>
                </div>
                <div class="col-6">
                    <button
                        class="btn btn-block btn-secondary p-0"
                        [attr.aria-label]="'productList.filter.empty' | cxTranslate"
                        (click)="getLinkParamsForFacet($event, true)">
                        {{ 'productList.filter.empty' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #customRangeFacet>
        <div
            #facetContainer
            [id]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.name"
            class="value facet-content"
            *ngIf="!aimoFacet.search && aimoFacet.type !== 'date'">
            <div *ngFor="let facetValue of aimoFacet.values" class="form-check">
                <input
                    [id]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code + '_' + facetValue.code"
                    type="checkbox"
                    [checked]="facetValue.selected"
                    (change)="toggleSelected(facetValue)" />
                <label
                    class="facet-label"
                    [for]="(mobileUI ? 'mobile_' : 'desktop_') + 'facet_' + aimoFacet.code + '_' + facetValue.code"
                    [innerHTML]="facetValue.name"></label>
                <div *ngIf="facetValue.selected">
                    <div class="facet-range">
                        <aimo-cx-input
                            [idName]="'minQty_' + facetValue.code"
                            [inputValue]="facetValue.rangeMin"
                            [placeholder]="'common.min'"
                            [typeInput]="'number'"
                            [showControls]="false"
                            [inputClass]="'input-sm'"
                            [isNotValidFunction]="rangeIsNotValid(facetValue, facetContainer)"
                            (afterChange)="emitFacetChanges(facetValue, facetContainer)"
                            class="pr-0"></aimo-cx-input>

                        <i class="separator aimo-icon icon-inline icon-sm icon-minus"></i>

                        <aimo-cx-input
                            [idName]="'maxQty_' + facetValue.code"
                            [inputValue]="facetValue.rangeMax"
                            [placeholder]="'common.max'"
                            [typeInput]="'number'"
                            [showControls]="false"
                            [inputClass]="'input-sm'"
                            [isNotValidFunction]="rangeIsNotValid(facetValue, facetContainer)"
                            (afterChange)="emitFacetChanges(facetValue, facetContainer)"
                            class="pl-0"></aimo-cx-input>
                    </div>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-6 pr-1">
                    <button
                        class="btn-primary btn btn-block p-0"
                        [attr.aria-label]="'productList.filter.limit' | cxTranslate"
                        (click)="getLinkParamsForFacetRange($event, facetContainer)">
                        {{ 'productList.filter.limit' | cxTranslate }}
                    </button>
                </div>
                <div class="col-6 pl-1">
                    <button
                        class="btn-secondary btn btn-block p-0"
                        [attr.aria-label]="'productList.filter.empty' | cxTranslate"
                        (click)="getLinkParamsForFacetRange($event, facetContainer, true)">
                        {{ 'productList.filter.empty' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>
    </ng-template>
</div>
