import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { StateUtils } from '@spartacus/core';

export const DELIVERY_DATE_FEATURE = 'aimoDeliveryDate';

const getDeliveryDateContentSelector = (state: AimoDeliveryDateState) => {
    return state.date;
};

export const getDeliveryDatesState: MemoizedSelector<AimoStateWithDeliveryDate, AimoDeliveryDatesState> =
    createFeatureSelector<AimoDeliveryDatesState>(DELIVERY_DATE_FEATURE);

export const getActiveDeliveryDateState: MemoizedSelector<
    AimoStateWithDeliveryDate,
    StateUtils.LoaderState<AimoDeliveryDateState>
> = createSelector(getDeliveryDatesState, (cartsState: AimoDeliveryDatesState) => cartsState.active);

export const getDeliveryDateState: MemoizedSelector<AimoStateWithDeliveryDate, AimoDeliveryDateState> = createSelector(
    getActiveDeliveryDateState,
    (state) => StateUtils.loaderValueSelector(state),
);

export const getDeliveryDateContent: MemoizedSelector<AimoStateWithDeliveryDate, string> = createSelector(
    getDeliveryDateState,
    getDeliveryDateContentSelector,
);

export interface AimoStateWithDeliveryDate {
    [DELIVERY_DATE_FEATURE]: AimoDeliveryDateState;
}

export interface AimoDeliveryDatesState {
    active: StateUtils.LoaderState<AimoDeliveryDateState>;
}

export interface AimoDeliveryDateState {
    date: string;
    loading: boolean;
}

export const DELIVERY_DATE_DATA = '[DeliveryDate] Data';
export const LOAD_DELIVERY_DATE = '[DeliveryDate] Load calendar';

export class AimoLoadDeliveryDate extends StateUtils.LoaderLoadAction {
    readonly type = LOAD_DELIVERY_DATE;

    constructor(public userId: string, public cartId: string) {
        super(DELIVERY_DATE_DATA);
    }
}

export const LOAD_DELIVERY_DATE_SUCCESS = '[DeliveryDate] Load Success';

export class AimoLoadDeliveryDateSuccess extends StateUtils.LoaderSuccessAction {
    readonly type = LOAD_DELIVERY_DATE_SUCCESS;

    constructor(public date: string) {
        super(DELIVERY_DATE_DATA);
    }
}

export const LOAD_DELIVERY_DATE_FAIL = '[DeliveryDate] Load Failed';

export class AimoLoadDeliveryDateFail extends StateUtils.LoaderFailAction {
    readonly type = LOAD_DELIVERY_DATE_FAIL;

    constructor() {
        super(DELIVERY_DATE_DATA);
    }
}
