import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';

import { AimoBackgroundImageComponent } from './aimo-background-image.component';

@NgModule({
    declarations: [AimoBackgroundImageComponent],
    imports: [
        CommonModule,
        MediaModule,
        ConfigModule.withConfig({
            cmsComponents: {
                AimoBackgroundImageComponent: {
                    component: AimoBackgroundImageComponent,
                },
            },
        } as CmsConfig),
    ],
})
export class AimoBackgroundImageModule {}
