import { DIALOG_TYPE, LayoutConfig } from '@spartacus/storefront';

import { AimoProductComparisonComponent } from './aimo-product-comparison.component';

declare module '@spartacus/storefront' {
    const enum LAUNCH_CALLER {
        PRODUCT_COMPARISON = 'PRODUCT_COMPARISON',
    }
}

export const productComparisonLayout: LayoutConfig = {
    launch: {
        PRODUCT_COMPARISON: {
            inlineRoot: true,
            component: AimoProductComparisonComponent,
            dialogType: DIALOG_TYPE.DIALOG,
        },
    },
};
