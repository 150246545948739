import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class AimoRoutingService {
    constructor(protected winRef: WindowRef) {}

    isPikatukku(): boolean {
        return this.winRef.location.host?.indexOf('pikatukku') >= 0;
    }

    gotoPikatukku(): void {
        this.winRef.location.href = this.winRef.location.origin.replace('toimitustukku', 'pikatukku');
    }

    gotoToimitustukku(): void {
        this.winRef.location.href = this.winRef.location.origin.replace('pikatukku', 'toimitustukku');
    }
}
