<ng-container *ngIf="data$ | async as data">
    <div class="footer-notification" *ngIf="isPikatukku()">
        <div class="container" *ngIf="openingHours$ | async as openingHours">
            <div class="row align-items-center">
                <div class="col-12 col-md-8">
                    <div class="d-inline-block mr-2">
                        <div *ngIf="data.openingHoursDescription" [innerHTML]="data.openingHoursDescription">
                            {{ data.openingHoursDescription }}
                        </div>
                    </div>
                    <div class="d-inline-block mr-2" *ngFor="let op of openingHours">
                        <div class="d-inline-block text-bold" *ngIf="op.activeDay">
                            {{ op.location }}:
                            <span *ngIf="!op.activeDay.closed"
                                >{{ op.activeDay.opening_hour }}-{{ op.activeDay.closing_hour }}</span
                            >
                            <span *ngIf="op.activeDay.closed">{{ 'aimo.pikatukku.closed' | cxTranslate }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4 text-md-right">
                    <a
                        *ngIf="data.openingHoursException && data.openingHoursExceptionUrl"
                        [href]="data.openingHoursExceptionUrl"
                        target="_blank"
                        class="text-underline"
                        >{{ data.openingHoursException }}</a
                    >
                </div>
            </div>
        </div>
    </div>
    <div class="footer {{ data.styleClasses }}">
        <div class="container">
            <div class="row footer-top-navigation">
                <div class="col-12 col-md-2">
                    <cx-page-slot position="SiteLogo"></cx-page-slot>
                </div>
                <div class="col-6 col-md-2">
                    <ng-container *ngIf="storeGroupNavNode$ | async as storeNavNode">
                        <ng-container *ngTemplateOutlet="footerNavGroup; context: { node: storeNavNode }">
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-6 col-md-2">
                    <ng-container *ngIf="customerServiceGroupNavNode$ | async as customerServiceNavNode">
                        <ng-container *ngTemplateOutlet="footerNavGroup; context: { node: customerServiceNavNode }">
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-6 col-md-2">
                    <div class="footer-navigation-column">
                        <div class="footer-navigation-group">
                            <div class="footer-navigation-group-list" [innerHTML]="data.companyInfo">
                                {{ data.companyInfo }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-md-2">
                    <ng-container *ngIf="valioAimoGroupNavNode$ | async as valioAimoNavNode">
                        <ng-container *ngTemplateOutlet="footerNavGroup; context: { node: valioAimoNavNode }">
                        </ng-container>
                    </ng-container>
                </div>
                <div class="col-12 col-md-2">
                    <ng-container *ngIf="someNavNode$ | async as someNavigationNode">
                        <ul class="footer-top-content-social-media" *ngIf="someNavigationNode.children?.length > 0">
                            <ng-container *ngFor="let child of someNavigationNode.children">
                                <ng-container *ngFor="let childnode of child.children">
                                    <li>
                                        <cx-generic-link
                                            *ngIf="
                                                childnode.url &&
                                                (!childnode.children || childnode.children?.length === 0)
                                            "
                                            [url]="childnode.url"
                                            [target]="childnode.target"
                                            [class]="childnode.styleClasses">
                                            {{ childnode.title }}
                                        </cx-generic-link>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </ng-container>
                </div>
            </div>
            <div class="row footer-bottom-copyright">
                <div class="col-12 col-md-6">
                    <p>{{ data.notice }}</p>
                </div>
                <div class="col-12 col-md-6 text-md-right">
                    <ng-container *ngIf="legalNavNode$ | async as bottomNavigationNode">
                        <ng-container *ngIf="bottomNavigationNode.children?.length > 0">
                            <ng-container *ngFor="let child of bottomNavigationNode.children">
                                <ng-container *ngFor="let childnode of child.children">
                                    <cx-generic-link
                                        *ngIf="
                                            childnode.url && (!childnode.children || childnode.children?.length === 0)
                                        "
                                        [url]="childnode.url"
                                        [target]="childnode.target"
                                        [class]="childnode.styleClasses">
                                        {{ childnode.title }}
                                    </cx-generic-link>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #footerNavGroup let-node="node">
    <ng-container *ngIf="node.children?.length > 0">
        <div class="footer-navigation-column">
            <div class="footer-navigation-group">
                <ng-container *ngFor="let child of node.children">
                    <div class="footer-navigation-group-list">
                        <ul>
                            <ng-container *ngFor="let childnode of child.children">
                                <ng-container *ngIf="!childnode.url && childnode.styleClasses; else footerLink">
                                    <li class="{{ childnode.styleClasses }}">
                                        <span *ngIf="!childnode.url">{{ childnode.title }}</span>
                                    </li>
                                </ng-container>
                                <ng-template #footerLink>
                                    <li class="{{ childnode.styleClasses }}">
                                        <cx-generic-link
                                            *ngIf="
                                                childnode.url &&
                                                (!childnode.children || childnode.children?.length === 0)
                                            "
                                            [url]="childnode.url"
                                            [target]="childnode.target">
                                            {{ childnode.title }}
                                        </cx-generic-link>
                                    </li>
                                </ng-template>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</ng-template>

<aimo-product-comparison-preview></aimo-product-comparison-preview>
