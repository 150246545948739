import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Actions } from '@ngrx/effects';
import { MiniCartComponent, MiniCartComponentService } from '@spartacus/cart/base/components/mini-cart';
import { CartActions } from '@spartacus/cart/base/core';
import { RoutingService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoCart, AimoOrderEntry } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoCartAddEntrySuccess } from '../../../service/cart/aimo-entry.action';
import { AimoGTMProductAttributes, GTMItemListId } from '../../../service/gtm/aimo-gtm.model';
import { AimoCurrentProductService } from '../../../service/product/aimo-current-product.service';

@Component({
    selector: 'aimo-cx-mini-cart',
    templateUrl: './aimo-mini-cart.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoMiniCartComponent extends MiniCartComponent implements OnInit, OnDestroy {
    constructor(
        protected miniCartComponentService: MiniCartComponentService,
        protected routingService: RoutingService,
        protected actions$: Actions,
        protected currentProductService: CurrentProductService,
        protected cdr: ChangeDetectorRef,
        protected activeCartService: AimoActiveCartService,
    ) {
        super(miniCartComponentService);
        this.quantity$ = this.activeCartService.getActive().pipe(map((cart) => cart.deliveryItemsQuantity));
    }

    @ViewChild('popup')
    popup: NgbPopover;

    subscription = new Subscription();
    popupEntries: AimoOrderEntry[];
    isMouseOverPopup: boolean = false;
    addedProducts: number;
    // eslint-disable-next-line
    private timer: any;

    ngOnInit(): void {
        this.subscription.add(
            this.actions$.subscribe((action) => {
                if (
                    action.type === CartActions.CART_ADD_ENTRY_SUCCESS ||
                    action.type === CartActions.CART_UPDATE_ENTRY_SUCCESS
                ) {
                    const payload = (action as AimoCartAddEntrySuccess).payload;
                    if (payload.modifications) {
                        this.popupEntries = payload.modifications.map((p) => p.entry).filter((e) => e !== undefined);
                    } else {
                        this.popupEntries = [(action as AimoCartAddEntrySuccess).payload.entry].filter(
                            (e) => e !== undefined,
                        );
                    }
                    this.addedProducts = this.popupEntries.length > 1 ? this.popupEntries.length : undefined;
                }
                if (action.type === CartActions.LOAD_CART_SUCCESS) {
                    const cart = (action as CartActions.LoadCartSuccess).payload.cart as AimoCart;
                    if (
                        this.popupEntries?.length > 0 &&
                        cart.dayGroupedEntries &&
                        Array.isArray(cart.dayGroupedEntries)
                    ) {
                        this.popupEntries = this.popupEntries
                            .map((popupEntry) =>
                                popupEntry.messages?.length > 0
                                    ? popupEntry
                                    : cart.dayGroupedEntries
                                          .find((d) => d.active)
                                          .entries?.find((e) => e.entryNumber === popupEntry.entryNumber),
                            )
                            .filter((e) => e !== undefined);
                        this.cdr.detectChanges();
                        if (this.hasMessages()) {
                            this.popup.open();
                            this.timer = setTimeout(() => {
                                if (this.isMouseOverPopup) {
                                    clearTimeout(this.timer);
                                } else {
                                    this.popup.close();
                                }
                            }, 5000);
                        }
                    }
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    goToCart(): void {
        this.routingService.go('/cart');
    }

    onClosePopup(): void {
        this.popupEntries = null;
    }

    openProductModal(productCode: string, sourceId: string): void {
        (this.currentProductService as AimoCurrentProductService).openProductModal(
            productCode,
            this.createGtmAttributes(0),
            null,
            null,
            null,
            sourceId,
        );
    }

    setMouseOverPopup(mouseOver: boolean): void {
        this.isMouseOverPopup = mouseOver;
        if (!this.isMouseOverPopup) {
            this.timer = setTimeout(() => {
                clearTimeout(this.timer);
                this.popup.close();
            }, 5000);
        }
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: GTMItemListId.mini_cart,
        } as AimoGTMProductAttributes;
    }

    hasMessages(): boolean {
        return (
            //   this.addedProducts !== undefined ||
            this.popupEntries?.flatMap((e) => e?.messages).filter((m) => m !== undefined).length > 0
        );
    }
}
