<div class="row">
    <div class="col-12 messages">
        <div class="message message-ERROR" *ngIf="paymentErrorStatus">
            {{ 'order.error.payment.' + paymentErrorStatus | cxTranslate }}
        </div>
        <div class="message message-{{ message.severity }}" *ngFor="let message of cart.messages" #cartMessage>
            <i class="aimo-icon icon-inline icon-{{ message.severity }} mr-2"></i>
            <div class="d-inline">
                <span [innerHTML]="message.message"></span>
                <ng-container *ngIf="message.action === 'internalLink'">
                    <span class="text-left-space">
                        <button [routerLink]="message.actionParams[0]" class="btn-link text-underline text-default">
                            {{ message.actionParams[1] }}
                        </button>
                    </span>
                </ng-container>

                <ng-container *ngIf="message.action === 'productLinks'">
                    <span *ngFor="let param of message?.actionParams; index as idx">
                        &nbsp;<button
                            class="btn-link text-underline text-default"
                            (click)="toggleMobileListAndScrollTo('product_' + param)">
                            {{ param }}
                        </button>
                        <span *ngIf="idx < message?.actionParams.length - 1">, </span>
                    </span>
                </ng-container>

                <ng-container *ngIf="message.action === 'refreshPage'">
                    <button (click)="refreshPage()" class="link text-underline">
                        {{ 'aimo.order.error.errorInSapAction' | cxTranslate }}
                    </button>
                </ng-container>
            </div>
            <i
                class="aimo-icon icon-inline icon-x icon-sm ml-auto icon-petrol-blue icon-cursor"
                (click)="hideMessage(cartMessage)"></i>
        </div>
    </div>
    <ng-container
        *ngIf="
            dayGroupedEntries.entries &&
                (dayGroupedEntries.entries.length > 0 || dayGroupedEntries.alreadyOrdered.length > 0);
            else noEntries
        ">
        <div class="day-group col-lg-9 col-12">
            <div
                class="d-block d-md-none bg-petrol-light sub-header mb-3 cursor-pointer noPrint"
                (click)="toggleMobileList()">
                <h4 class="text-default d-inline-block mb-0">{{ 'aimo.cart.item.productTitle' | cxTranslate }}</h4>
                <div class="ml-2 d-inline-block circle circle-sm bg-petrol text-xsm text-bold text-white">
                    {{ dayGroupedEntries.entries.length }}
                </div>
                <i
                    [ngClass]="showHideList ? 'icon-rotate-180' : ''"
                    class="aimo-icon icon-inline float-right icon-caret-down icon-petrol-blue icon-cursor"></i>
            </div>

            <div
                class="sub-header row bg-petrol-light no-gutters p-2 sub-header-line"
                *ngIf="dayGroupedEntries.entries.length > 0">
                <div class="col-4 text-bold">
                    {{ 'aimo.cart.item.product' | cxTranslate }}
                </div>
                <div class="col-3 row no-gutters">
                    <div class="col-6">
                        <span class="text-bold">{{ 'aimo.cart.item.basePrice' | cxTranslate }}</span>
                        <br />
                        {{ 'aimo.cart.item.notax' | cxTranslate }}
                    </div>
                    <div class="col-6">
                        <span class="text-bold">{{ 'aimo.cart.item.comparisonPrice' | cxTranslate }}</span>
                        <br />
                        {{ 'aimo.cart.item.notax' | cxTranslate }}
                    </div>
                </div>
                <div class="col-2 text-bold">
                    {{ 'aimo.cart.item.quantity' | cxTranslate }}
                </div>
                <div class="col-1 text-bold">
                    {{ 'aimo.cart.item.lotSize' | cxTranslate }}
                </div>
                <div class="col-2">
                    <span class="text-bold">{{ 'aimo.cart.item.total' | cxTranslate }}</span>
                    <br />
                    {{ 'aimo.cart.item.vat.included' | cxTranslate }}
                </div>
            </div>

            <div class="day-group-list d-md-block" [ngClass]="showHideList ? '' : 'd-none'">
                <ng-container *ngFor="let entry of dayGroupedEntries.entries; index as idx">
                    <aimo-cart-item
                        [entry]="entry"
                        [cart]="cart"
                        [gtmProductAttributes]="createGtmAttributes(idx)"></aimo-cart-item>
                </ng-container>
            </div>

            <div
                *ngIf="!isPikatukku()"
                #mobileAlreadyOrdered
                class="d-block d-md-none bg-petrol-light sub-header mb-3 cursor-pointer noPrint"
                (click)="toggleMobileAlreadyOrderedList()">
                <h4 class="text-default d-inline-block mb-0">
                    {{ 'aimo.cart.item.alreadyOrdered' | cxTranslate }}
                </h4>
                <div class="ml-2 d-inline-block circle circle-sm bg-petrol text-xsm text-bold text-white">
                    {{ dayGroupedEntries.alreadyOrdered.length }}
                </div>
                <i
                    [ngClass]="showHideAlreadyOrderedList ? 'icon-rotate-180' : ''"
                    class="aimo-icon icon-inline float-right icon-caret-down icon-petrol-blue icon-cursor"></i>
            </div>

            <div
                class="d-none d-md-block row no-gutters cart-item pt-5 pb-4 print"
                *ngIf="alreadyOrderedEntriesOpen !== null && dayGroupedEntries.alreadyOrdered?.length > 0">
                <div class="col-10">
                    <h4>
                        {{ 'aimo.cart.item.alreadyOrdered' | cxTranslate }}
                    </h4>
                </div>
            </div>

            <div class="day-group-ordered" *ngFor="let order of dayGroupedEntries.alreadyOrdered; index as idx1">
                <ng-container
                    *ngTemplateOutlet="
                        alreadyOrderedEntry;
                        context: { order: order, idx1: idx1, open: isOpen(order.orderNumber) }
                    ">
                </ng-container>
            </div>
        </div>

        <div class="col-lg-3 col-12 mt-3 mt-lg-0">
            <aimo-cart-totals [cart]="cart"></aimo-cart-totals>
        </div>
    </ng-container>

    <ng-template #toggleAlreadyOrdered let-order="order" let-open="open">
        <i
            [ngClass]="open ? 'icon-caret-up' : 'icon-caret-down'"
            class="aimo-icon icon-inline icon-petrol-blue icon-sm mr-2 icon-cursor noPrint"></i>
        <h5 class="text-bold d-inline mb-0">
            {{ 'aimo.orderhistory.orderTitle' | cxTranslate }} {{ order.orderNumber }}
        </h5>
        &nbsp;
        <div class="order-purchase-order-number">{{ order.purchaseOrderNumber }}</div>
    </ng-template>

    <ng-template #alreadyOrderedEntry let-order="order" let-open="open" let-idx1="idx">
        <div
            (click)="toggleAlreadyOrderedEntries(order.orderNumber)"
            class="sub-header row bg-petrol-light no-gutters p-2 d-md-none noPrint"
            [ngClass]="showHideAlreadyOrderedList ? 'd-block' : 'd-none'"
            *ngIf="dayGroupedEntries.alreadyOrdered?.length > 0">
            <div class="col-12 d-flex align-items-center">
                <ng-container
                    *ngTemplateOutlet="toggleAlreadyOrdered; context: { order: order, open: open }"></ng-container>
            </div>
        </div>
        <div
            (click)="toggleAlreadyOrderedEntries(order.orderNumber)"
            class="sub-header row bg-petrol-light no-gutters p-2 sub-header-line"
            *ngIf="dayGroupedEntries.alreadyOrdered?.length > 0">
            <div class="col-4 d-flex align-items-center">
                <ng-container
                    *ngTemplateOutlet="toggleAlreadyOrdered; context: { order: order, open: open }"></ng-container>
            </div>
            <div [ngClass]="open ? 'col-3' : 'd-none'">
                <div class="row no-gutters">
                    <div class="col-6">
                        <span class="text-bold">{{ 'aimo.cart.item.basePrice' | cxTranslate }}</span>
                        <br />
                        {{ 'aimo.cart.item.notax' | cxTranslate }}
                    </div>
                    <div class="col-6">
                        <span class="text-bold">{{ 'aimo.cart.item.comparisonPrice' | cxTranslate }}</span>
                        <br />
                        {{ 'aimo.cart.item.notax' | cxTranslate }}
                    </div>
                </div>
            </div>
            <div class="text-bold" [ngClass]="open ? 'col-2' : 'd-none'">
                {{ 'aimo.cart.item.quantity' | cxTranslate }}
            </div>
            <div class="text-bold" [ngClass]="open ? 'col-1' : 'd-none'">
                {{ 'aimo.cart.item.lotSize' | cxTranslate }}
            </div>
            <div [ngClass]="open ? 'col-2' : 'd-none'">
                <span class="text-bold">{{ 'aimo.cart.item.total' | cxTranslate }}</span>
                <br />
                {{ 'aimo.cart.item.vat.included' | cxTranslate }}
            </div>
            <div class="product-count" [ngClass]="!open ? 'col-2 offset-6' : 'd-none'">
                {{ 'aimo.orderhistory.entries' | cxTranslate : { amount: order.entries.length } }}
            </div>
        </div>
        <div
            *ngIf="!cart?.user.tukkumyyja && open"
            class="day-group-ordered-list d-md-none"
            [ngClass]="showHideAlreadyOrderedList && open ? 'mt-3 mt-md-0' : 'd-none'">
            <ng-container *ngFor="let entry of order.entries; index as idx2">
                <aimo-cart-item
                    [cart]="cart"
                    [entry]="entry"
                    [gtmProductAttributes]="createGtmAttributes(idx1 * idx2)"></aimo-cart-item>
            </ng-container>
        </div>
        <div class="day-group-ordered-list" *ngIf="open" [ngClass]="open ? 'mt-3 mt-md-0 d-block' : 'd-none'">
            <ng-container *ngFor="let entry of order.entries; index as idx2">
                <aimo-cart-item
                    [alreadyOrdered]="true"
                    [cart]="cart"
                    [entry]="entry"
                    [gtmProductAttributes]="createGtmAttributes(idx1 * idx2)"></aimo-cart-item>
            </ng-container>
        </div>
    </ng-template>

    <ng-template #noEntries>
        <div class="col-12 pb-md-4 pb-2 pt-md-3 pt-2">
            <h3 class="text-default mb-3">{{ 'aimo.cart.noProducts.title' | cxTranslate }}</h3>
        </div>
        <div class="col-12 col-md-6">
            <div class="bg-petrol-light p-4 excel-import-action">
                <h4>{{ 'aimo.cart.orderFromFile.title' | cxTranslate }}</h4>
                <p>{{ 'aimo.cart.orderFromFile.content' | cxTranslate }}</p>
                <button class="btn btn-block btn-secondary" (click)="openExcelImportModal()">
                    <i class="aimo-icon icon-inline icon-clipboard-text icon-petrol-blue mr-2"></i>
                    {{ 'aimo.cart.orderFromFile.button' | cxTranslate }}
                </button>
            </div>
        </div>
        <div class="col-12 col-md-6 pt-3 pt-md-0" *ngIf="!isPikatukku()">
            <div class="bg-petrol-light p-4 empty-cart-action">
                <h4>{{ 'aimo.cart.importOrder.title' | cxTranslate }}</h4>
                <p>{{ 'aimo.cart.importOrder.content' | cxTranslate }}</p>
                <button class="btn btn-block btn-secondary" (click)="importFromOrderTemplate('import_from_template')">
                    <i class="aimo-icon icon-inline icon-file-text icon-petrol-blue mr-2"></i>
                    {{ 'aimo.cart.importOrder.button' | cxTranslate }}
                </button>
            </div>
        </div>
    </ng-template>
</div>
