import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, NavigationModule } from '@spartacus/storefront';

import { AimoNavigationUiModule } from '../navigation-ui/aimo-navigation-ui.module';

import { AimoNavigationComponent } from './aimo-navigation.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        GenericLinkModule,
        I18nModule,
        AimoNavigationUiModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                NavigationComponent: {
                    component: AimoNavigationComponent,
                },
            },
        }),
    ],
    declarations: [AimoNavigationComponent],
    entryComponents: [AimoNavigationComponent],
    exports: [AimoNavigationComponent],
})
export class AimoNavigationModule extends NavigationModule {}
