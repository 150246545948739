import { Action } from '@ngrx/store';
import { MULTI_CART_DATA } from '@spartacus/cart/base/core';
import { StateUtils } from '@spartacus/core';

import { AimoCart } from '../../model/cart.model';
import { GTMCalendarSource } from '../gtm/aimo-gtm.model';

export const AIMO_EMPTY_CART = 'AIMO_[Cart] Cart Empty';
export const AIMO_EMPTY_CART_SUCCESS = 'AIMO_[Cart] Cart Empty Success';
export const AIMO_EMPTY_CART_FAIL = 'AIMO_[Cart] Cart Empty Fail';

export class AimoEmptyCart implements Action {
    readonly type = AIMO_EMPTY_CART;

    constructor(public userId: string, public cartId: string) {}
}

export class AimoEmptyCartSuccess implements Action {
    readonly type = AIMO_EMPTY_CART_SUCCESS;

    constructor(public userId: string, public cartId: string, public cart: AimoCart) {}
}

export class AimoEmptyCartFail implements Action {
    readonly type = AIMO_EMPTY_CART_FAIL;

    // eslint-disable-next-line
    constructor(public userId: string, public cartId: string, public error: any) {}
}

export const AIMO_CART_UPDATE_HEADER = 'AIMO_[Cart] Update header';

export class AimoUpdateCartHeader extends StateUtils.EntityProcessesIncrementAction {
    readonly type = AIMO_CART_UPDATE_HEADER;

    constructor(
        public userId: string,
        public cartId: string,
        public cart: AimoCart,
        public calendarSource?: GTMCalendarSource,
        public resetExternalItems?: boolean,
    ) {
        super(MULTI_CART_DATA, cartId);
    }
}

export const AIMO_CART_UPDATE_HEADER_SUCCESS = 'AIMO_[Cart] Update header Success';

export class AimoUpdateCartHeaderSuccess extends StateUtils.EntityProcessesDecrementAction {
    readonly type = AIMO_CART_UPDATE_HEADER_SUCCESS;

    constructor(
        public payload: {
            cartId: string;
            userId: string;
            cart: AimoCart;
        },
    ) {
        super(MULTI_CART_DATA, payload.cartId);
    }
}

export const AIMO_EXCEL_IMPORT = 'AIMO_[Cart] Excel import';

export class AimoExcelImport implements Action {
    readonly type = AIMO_EXCEL_IMPORT;

    constructor(public userId: string, public cartId: string, public data: string) {}
}

export const AIMO_ADD_MANY_PRODUCTS = 'AIMO_[Cart] Add Many Products';

export class AimoAddManyProducts implements Action {
    readonly type = AIMO_ADD_MANY_PRODUCTS;

    constructor(public userId: string, public cartId: string, public data: AimoCart) {}
}

export const AIMO_ORDER_TEMPLATE_IMPORT = 'AIMO_[Cart] Order Template import';

export class AimoOrderTemplateImport implements Action {
    readonly type = AIMO_ORDER_TEMPLATE_IMPORT;

    constructor(public userId: string, public cartId: string, public fromId: string, public selectedDate?: string) {}
}
