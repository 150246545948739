import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';

import { AimoDatepickerDayComponent } from './aimo-datepicker-day.component';
import { AimoNgbDatepickerI18n } from './aimo-datepicker-i18n';
import { AimoDatepickerInputComponent } from './aimo-datepicker-input.component';
import { AimoNgbDateParserFormatter } from './aimo-datepicker-parser-formatter';

@NgModule({
    exports: [AimoDatepickerInputComponent, AimoDatepickerDayComponent],
    providers: [
        { provide: NgbDatepickerI18n, useClass: AimoNgbDatepickerI18n },
        { provide: NgbDateParserFormatter, useClass: AimoNgbDateParserFormatter },
    ],
    entryComponents: [AimoDatepickerInputComponent],
    imports: [ReactiveFormsModule, I18nModule, NgbDatepickerModule, CommonModule],
    declarations: [AimoDatepickerInputComponent, AimoDatepickerDayComponent],
})
export class AimoDatepickerModule {}
