import { NgModule } from '@angular/core';
import { CmsConfig, provideConfig } from '@spartacus/core';
import { SmartEditService } from '@spartacus/smartedit/core';
import { SmartEditConfig, SmartEditRootModule } from '@spartacus/smartedit/root';

import { smartEditDecorators } from './decorators';

@NgModule({
    declarations: [],
    imports: [SmartEditRootModule],
    providers: [
        ...smartEditDecorators,
        provideConfig({
            featureModules: {
                smartEdit: {
                    module: () => import('@spartacus/smartedit').then((m) => m.SmartEditModule),
                },
            },
        } as CmsConfig),
        provideConfig(<SmartEditConfig>{
            smartEdit: {
                storefrontPreviewRoute: 'cx-preview',
                allowOrigin:
                    'localhost:9002, toimitustukku-local.valioaimo.fi:9002, tukkumyynti-local.valioaimo.fi:9002, pikatukku-local.valioaimo.fi:9002,' +
                    ' api-dev.valioaimo.fi:443, backoffice-dev.valioaimo.fi:443, api-test.valioaimo.fi:443, backoffice-test.valioaimo.fi:443, api-qa.valioaimo.fi:443, backoffice-qa.valioaimo.fi:443, ' +
                    'api.valioaimo.fi:443, backoffice.valioaimo.fi:443, www.google.com:443, siteintercept.qualtrics.com:443, login-eu-c1.cc.sinch.com:443',
            },
        }),
    ],
})
export class SmartEditFeatureModule {
    constructor(private smartEditService: SmartEditService) {
        this.smartEditService.processCmsPage();
    }
}
