import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import {
    CmsPageGuard,
    FormErrorsModule,
    ListNavigationModule,
    MediaModule,
    PageLayoutComponent,
} from '@spartacus/storefront';

import { AimoInputSelectModule } from '../../shared/input-select/aimo-input-select.module';
import { AimoInputModule } from '../../shared/input/aimo-input.module';
import { AimoItemCounterModule } from '../../shared/item-counter/aimo-item-counter.module';
import { AimoCartSharedModule } from '../cart-shared/aimo-cart-shared.module';
import { AimoDeliveryDateModule } from '../cart-shared/delivery-date/aimo-delivery-date.module';
import { AimoBreadcrumbModule } from '../navigation/breadcrumb/aimo-breadcrumb.module';
import { AimoSearchBoxModule } from '../navigation/search-box/aimo-search-box.module';
import { AimoOrderHistoryModule } from '../order-history/aimo-order-history.module';
import { AimoProductListModule } from '../product/product-list/aimo-product-list.module';
import { AimoDatepickerModule } from '../shared/datepicker/aimo-datepicker.module';
import { AimoPipesModule } from '../shared/utils/safe-html/aimo-pipes.module';

import { AimoOrderTemplatesComponent } from './aimo-order-templates.component';
import { AimoOrderTemplateDetailsComponent } from './order-template-details/aimo-order-template-details.component';
import { AimoOrderTemplateItemListComponent } from './order-template-item-list/aimo-order-template-item-list.component';
import { AimoOrderTemplateItemComponent } from './order-template-item/aimo-order-template-item.component';
import { AimoOrderTemplateDeleteModalComponent } from './order-template-modal/aimo-order-template-delete-modal.component';
import { orderTemplateLayout } from './order-template-modal/aimo-order-template-layout.config';
import { AimoOrderTemplateModalComponent } from './order-template-modal/aimo-order-template-modal.component';
import { AimoOrderTemplateRowComponent } from './order-template-row/aimo-order-template-row.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        AimoInputModule,
        AimoDatepickerModule,
        ReactiveFormsModule,
        MediaModule,
        AimoDeliveryDateModule,
        NgbTooltipModule,
        NgbPopoverModule,
        FormErrorsModule,
        AimoInputSelectModule,
        AimoItemCounterModule,
        AimoOrderHistoryModule,

        RouterModule.forChild([
            {
                // @ts-ignore
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: {
                    cxRoute: 'orderTemplates',
                },
            },
            {
                // @ts-ignore
                path: null,
                canActivate: [AuthGuard, CmsPageGuard],
                component: PageLayoutComponent,
                data: {
                    cxRoute: 'orderTemplateDetails',
                },
            },
        ]),
        AimoSearchBoxModule,
        AimoCartSharedModule,
        AimoProductListModule,
        AimoBreadcrumbModule,
        AimoPipesModule,
    ],
    declarations: [
        AimoOrderTemplateModalComponent,
        AimoOrderTemplateDeleteModalComponent,
        AimoOrderTemplatesComponent,
        AimoOrderTemplateRowComponent,
        AimoOrderTemplateDetailsComponent,
        AimoOrderTemplateItemListComponent,
        AimoOrderTemplateItemComponent,
    ],
    exports: [
        AimoOrderTemplateModalComponent,
        AimoOrderTemplateDeleteModalComponent,
        AimoOrderTemplateRowComponent,
        AimoOrderTemplateDetailsComponent,
        AimoOrderTemplateItemListComponent,
        AimoOrderTemplateItemComponent,
    ],
    providers: [
        provideDefaultConfig(orderTemplateLayout),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {
                AccountOrderTemplatesComponent: {
                    component: AimoOrderTemplatesComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        provideDefaultConfig(<CmsConfig>{
            cmsComponents: {},
        }),

        provideDefaultConfig(<RoutingConfig>{
            routing: {
                routes: {
                    orderTemplates: {
                        paths: ['ordertemplates'],
                    },
                    orderTemplateDetails: {
                        paths: ['ordertemplates/:templateId'],
                        paramsMapping: { templateId: 'templateId' },
                    },
                },
            },
        }),
    ],
})
export class AimoOrderTemplateModule {}
