import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductSearchPage } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { GTMItemListId, AimoGTMProductAttributes, GTMCartType } from '../../../../service/gtm/aimo-gtm.model';
import { AimoProductFacetService } from '../../../../service/product/aimo-product-facet.service';
import { AimoSpinnerService } from '../../../shared/spinner/aimo-spinner.service';

@Component({
    selector: 'aimo-product-scroll',
    templateUrl: './aimo-product-scroll.component.html',
})
export class AimoProductScrollComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();

    model: ProductSearchPage;

    @ViewChild('fullSearchSpinnerDiv') spinnerDiv: ElementRef;

    constructor(
        private ref: ChangeDetectorRef,
        protected activatedRoute: ActivatedRoute,
        protected facetService: AimoProductFacetService,
        protected spinnerService: AimoSpinnerService,
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.facetService.facetClicked$.subscribe((clicked) => {
                if (clicked) {
                    this.spinnerService.showInnerSpinner(this.spinnerDiv);
                }
            }),
        );
    }

    @Input()
    set setModel(inputModel: ProductSearchPage) {
        if (inputModel.pagination.currentPage > 0) {
            this.model = {
                ...inputModel,
                products: this.model?.products?.concat(inputModel.products ?? []),
            };
        } else {
            this.model = inputModel;
        }
        this.ref.markForCheck();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isCategoryPage(): boolean {
        return (
            this.activatedRoute.snapshot.url.length > 1 &&
            (this.activatedRoute.snapshot.url[0].path === 'category' ||
                this.activatedRoute.snapshot.url[0].path === 'c')
        );
    }

    createGtmAttributes(idx: number): AimoGTMProductAttributes {
        return {
            index: idx,
            item_list_id: this.isCategoryPage()
                ? GTMItemListId.product_category_page
                : GTMItemListId.product_search_page,
            cart_type: GTMCartType.cart,
            category_code: this.getCategoryCode(),
            search_term: this.getSearchTerm(),
        } as AimoGTMProductAttributes;
    }

    private getCategoryCode(): string {
        if (!this.isCategoryPage()) {
            return null;
        }
        if (this.activatedRoute.snapshot.queryParams?.query) {
            const split = this.activatedRoute.snapshot.queryParams?.query.split(':');
            for (let i = 0; i < split.length; ++i) {
                if (split[i] === 'category') {
                    return split[i + 1];
                }
            }
        }
        return this.activatedRoute.snapshot.url[1].path;
    }

    private getSearchTerm(): string {
        if (this.isCategoryPage()) {
            return null;
        }
        if (this.activatedRoute.snapshot.params?.query) {
            return this.activatedRoute.snapshot.params?.query;
        }
        if (this.activatedRoute.snapshot.queryParams?.query) {
            return this.activatedRoute.snapshot.queryParams?.query.split(':')[0];
        }
        return null;
    }
}
