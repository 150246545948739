import { Component } from '@angular/core';
import { ProductSearchPage } from '@spartacus/core';
import { ProductFacetService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AimoFacet } from '../../../../model/product.model';
import { AimoProductListComponentService } from '../../../../service/product/aimo-product-list-component.service';

@Component({
    selector: 'aimo-product-categories',
    templateUrl: './aimo-product-categories.component.html',
})
export class AimoProductCategoriesComponent {
    model$: Observable<ProductSearchPage> = this.productListComponentService.model$;

    facets$: Observable<AimoFacet> = this.productFacetService.facetList$.pipe(
        switchMap((list) =>
            list.facets.filter((facet) => (facet as AimoFacet).code === 'category').length == 0
                ? [{ code: 'category', values: [] } as AimoFacet] //clear category facets for  searches, which do not result values
                : list.facets.filter((facet) => (facet as AimoFacet).code === 'category'),
        ),
    );

    constructor(
        protected productFacetService: ProductFacetService,
        protected productListComponentService: AimoProductListComponentService,
    ) {}

    showAllCategories = false;

    toggleShowAllCategories(showAllCategories: boolean): void {
        this.showAllCategories = showAllCategories;
    }
}
