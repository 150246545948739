<div class="show-password text-right" *ngIf="isShowPasswordVisible && typeInput && typeInput === 'password'">
    <a (click)="toggleVisibility(idName)">{{ 'common.showPassword' | cxTranslate }}</a>
</div>

<ng-container *ngIf="parentForm; else formlessInput">
    <div
        [formGroup]="parentForm"
        class="input-group {{ componentClass }}"
        [class.is-invalid]="isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)"
        [ngClass]="{
            'has-value': hasValue(),
            range: showControls && typeInput === 'number'
        }">
        <label
            *ngIf="label"
            for="{{ idName }}"
            class="input-label-value {{ labelClass }}"
            [ngClass]="{ required: isRequired() }">
            {{ label | cxTranslate }}
            <span *ngIf="label && subLabel" class="input-sublabel-value">{{ subLabel | cxTranslate }}</span>
            <span
                *ngIf="labelTooltip ? (labelTooltip | cxTranslate) : false"
                class="aimo-icon icon-inline icon-info icon-sm"
                placement="top"
                ngbTooltip="{{ labelTooltip | cxTranslate }}"
                role="button"></span>
        </label>

        <div
            class="input-container"
            [ngClass]="{
                'input-container-icon': inputIcon,
                'icon-left': isLeftIcon && inputIcon,
                'icon-right': !isLeftIcon && inputIcon
            }">
            <i *ngIf="inputIcon && isLeftIcon" class="aimo-icon icon-inline icon-sm input-icon {{ inputIcon }}"></i>
            <i
                *ngIf="showControls && typeInput === 'number'"
                (click)="decrement()"
                [ngClass]="{ 'icon-disable': inputValue <= min }"
                class="control aimo-icon icon-inline icon-sm icon-minus"></i>

            <input
                #inputElement
                *ngIf="!isTextArea && !disabled"
                (blur)="onBlur($event)"
                (focus)="onFocus($event)"
                (mouseout)="onMouseOut($event)"
                (change)="onChange($event)"
                (input)="onInputChange($event.target.value)"
                (keydown.enter)="onEnter($event.target.value)"
                (keyup)="onKeypress($event)"
                [ngClass]="{ 'with-controls': showControls }"
                [attr.aria-label]="
                    arialLabel ? (arialLabel | cxTranslate) : placeholder ? (placeholder | cxTranslate) : ''
                "
                [attr.required]="isRequired() ? 'required' : null"
                aria-required="{{ isRequired() }}"
                class="form-control aimo-input mb-0 {{ inputClass }}"
                formControlName="{{ nameForm }}"
                id="{{ idName }}"
                [readOnly]="readOnly"
                [maxLength]="maxlength"
                name="{{ nameForm }}"
                placeholder="{{ placeholder ? (placeholder | cxTranslate) : '' }}"
                type="{{ typeInput ? typeInput : 'text' }}" />

            <i
                *ngIf="showControls && typeInput === 'number'"
                (click)="increment()"
                [ngClass]="{ 'icon-disable': inputValue >= max }"
                class="control aimo-icon icon-inline icon-sm icon-plus"></i>

            <textarea
                #inputElement
                *ngIf="isTextArea && !disabled"
                (blur)="onBlur($event)"
                (focus)="onFocus($event)"
                (change)="onChange($event)"
                (mouseout)="onMouseOut($event)"
                (input)="onInputChange($event.target.value)"
                (keydown.enter)="onEnter($event.target.value)"
                (keypress)="onKeypress($event)"
                [attr.aria-label]="
                    arialLabel ? (arialLabel | cxTranslate) : placeholder ? (placeholder | cxTranslate) : ''
                "
                [attr.required]="isRequired() ? 'required' : null"
                aria-required="{{ isRequired() }}"
                class="form-control aimo-textarea {{ inputClass }}"
                formControlName="{{ nameForm }}"
                id="{{ idName }}"
                [readOnly]="readOnly"
                [maxLength]="maxlength"
                name="{{ nameForm }}"
                placeholder="{{ placeholder ? (placeholder | cxTranslate) : '' }}"></textarea>

            <i
                *ngIf="inputIcon && !isLeftIcon"
                class="aimo-icon icon-inline icon-sm input-icon {{ inputIcon }}"
                (click)="onIconClick($event)"></i>
        </div>

        <p
            *ngIf="disabled && !nonEditable"
            class="disabled-input-value aimo-input {{ inputClass }}"
            [ngClass]="{
                'input-container-icon': disabledIcon,
                'icon-left': isLeftIcon && disabledIcon,
                'icon-right': !isLeftIcon && disabledIcon
            }">
            <span>
                {{
                    typeInput === 'password'
                        ? '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                        : nameForm.value || '---'
                }}
            </span>
            <i
                *ngIf="!isTextArea && disabledIcon"
                (click)="onDisabledActionIcon()"
                class="aimo-icon icon-inline icon-sm input-icon {{ disabledIcon }}"></i>
        </p>
        <p
            *ngIf="nonEditable"
            class="non-editable-input-value aimo-input {{ inputClass }}"
            [ngClass]="{
                'input-container-icon': disabledIcon,
                'icon-left': isLeftIcon && disabledIcon,
                'icon-right': !isLeftIcon && disabledIcon
            }">
            <span>
                {{
                    typeInput === 'password'
                        ? '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                        : nameForm.value || '---'
                }}
            </span>
            <i
                *ngIf="!isTextArea && disabledIcon"
                (click)="onDisabledActionIcon()"
                class="aimo-icon icon-inline icon-sm input-icon {{ disabledIcon }}"></i>
        </p>

        <p *ngIf="hint" class="input-hint">{{ hint }}</p>
    </div>
</ng-container>

<ng-template #formlessInput>
    <div
        [class.is-invalid]="isNotValidFunction !== undefined ? isNotValidFunction : false"
        class="input-group input-label {{ hasValue() ? 'has-value' : '' }} {{ componentClass }}"
        [ngClass]="{ range: showControls && typeInput === 'number' }">
        <label
            *ngIf="label"
            for="{{ idName }}"
            class="input-label-value {{ labelClass }}"
            [ngClass]="{ required: isRequired() }">
            {{ label | cxTranslate }}
            <span *ngIf="label && subLabel" class="input-sublabel-value">{{ subLabel | cxTranslate }}</span>
            <span
                *ngIf="labelTooltip ? (labelTooltip | cxTranslate) : false"
                class="aimo-icon icon-inline icon-info icon-sm"
                placement="top"
                ngbTooltip="{{ labelTooltip | cxTranslate }}"
                role="button"></span>
        </label>

        <div
            class="input-container"
            [ngClass]="{
                'input-container-icon': inputIcon,
                'icon-left': isLeftIcon && inputIcon,
                'icon-right': !isLeftIcon && inputIcon
            }">
            <i *ngIf="inputIcon && isLeftIcon" class="aimo-icon icon-inline icon-sm input-icon {{ inputIcon }}"></i>

            <i
                *ngIf="showControls && typeInput === 'number'"
                (click)="decrement()"
                [ngClass]="{ 'icon-disable': inputValue <= min }"
                class="control aimo-icon icon-inline icon-sm icon-minus"></i>

            <input
                #inputElement
                *ngIf="!isTextArea && !disabled"
                (blur)="onBlur($event)"
                (focus)="onFocus($event)"
                (change)="onChange($event)"
                (mouseout)="onMouseOut($event)"
                (input)="onInputChange($event.target.value)"
                (keydown.enter)="onEnter($event.target.value)"
                (keypress)="onKeypress($event)"
                [ngClass]="{ 'with-controls': showControls }"
                [attr.aria-label]="
                    arialLabel ? (arialLabel | cxTranslate) : placeholder ? (placeholder | cxTranslate) : ''
                "
                [attr.required]="isRequired() ? 'required' : null"
                aria-required="{{ isRequired() }}"
                class="form-control aimo-input {{ inputClass }}"
                id="{{ idName }}"
                [maxLength]="maxlength"
                name="{{ nameForm }}"
                placeholder="{{ placeholder ? (placeholder | cxTranslate) : '' }}"
                type="{{ typeInput ? typeInput : 'text' }}"
                value="{{ inputValue }}" />

            <i
                *ngIf="showControls && typeInput === 'number'"
                (click)="increment()"
                [ngClass]="{ 'icon-disable': inputValue >= max }"
                class="control aimo-icon icon-inline icon-sm icon-plus"></i>

            <textarea
                #inputElement
                rows="{{ rows }}"
                cols="{{ cols }}"
                *ngIf="isTextArea && !disabled"
                (blur)="onBlur($event)"
                (focus)="onFocus($event)"
                (change)="onChange($event)"
                (mouseout)="onMouseOut($event)"
                (input)="onInputChange($event.target.value)"
                (keydown.enter)="onEnter($event.target.value)"
                (keypress)="onKeypress($event)"
                [attr.aria-label]="
                    arialLabel ? (arialLabel | cxTranslate) : placeholder ? (placeholder | cxTranslate) : ''
                "
                [attr.required]="isRequired() ? 'required' : null"
                aria-required="{{ isRequired() }}"
                class="form-control aimo-textarea {{ inputClass }}"
                id="{{ idName }}"
                [readOnly]="readOnly"
                [maxLength]="maxlength"
                name="{{ nameForm }}"
                placeholder="{{ placeholder ? (placeholder | cxTranslate) : '' }}"
                value="{{ inputValue }}"></textarea>

            <i
                *ngIf="inputIcon && !isLeftIcon"
                class="aimo-icon icon-inline icon-sm input-icon {{ inputIcon }}"
                (click)="onIconClick($event)"></i>
        </div>

        <p
            *ngIf="disabled && !nonEditable"
            class="disabled-input-value aimo-input {{ inputClass }}"
            [ngClass]="{
                'input-container-icon': disabledIcon,
                'icon-left': isLeftIcon && disabledIcon,
                'icon-right': !isLeftIcon && disabledIcon
            }">
            <span>
                {{
                    typeInput === 'password'
                        ? '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                        : inputValue || '---'
                }} </span
            ><i
                *ngIf="!isTextArea && disabledIcon"
                (click)="onDisabledActionIcon()"
                class="aimo-icon icon-inline icon-sm input-icon {{ disabledIcon }}"></i>
        </p>

        <p
            *ngIf="nonEditable"
            class="non-editable-input-value aimo-input {{ inputClass }}"
            [ngClass]="{
                'input-container-icon': disabledIcon,
                'icon-left': isLeftIcon && disabledIcon,
                'icon-right': !isLeftIcon && disabledIcon
            }">
            <span>
                {{
                    typeInput === 'password'
                        ? '&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;'
                        : inputValue || '---'
                }} </span
            ><i
                *ngIf="!isTextArea && disabledIcon"
                (click)="onDisabledActionIcon()"
                class="aimo-icon icon-inline icon-sm input-icon {{ disabledIcon }}"></i>
        </p>

        <p *ngIf="hint" class="input-hint">{{ hint }}</p>
    </div>
</ng-template>

<div
    class="invalid-feedback d-block text-right"
    *ngIf="(isNotValidFunction ? isNotValidFunction : isNotValid(nameForm)) && errorMessage">
    <span>{{ errorMessage | cxTranslate }}</span>
</div>
<div class="extra-info text-right" *ngIf="extraInfoBottom">
    {{ extraInfoBottom | cxTranslate }}
</div>
