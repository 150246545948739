<div class="register login-content-md" id="register-top-element">
    <form (ngSubmit)="onSubmit()" [formGroup]="form" *ngIf="!registerDone; else registered">
        <h1 class="pb-4 mb-3">{{ 'aimo.register.title' | cxTranslate }}</h1>

        <h4>
            {{ 'aimo.register.myInfo' | cxTranslate }}
        </h4>

        <div class="messages" *ngIf="error">
            <div class="message message-ERROR" [innerHTML]="error"></div>
        </div>

        <div class="row">
            <div class="col-12 pb-4">
                <aimo-cx-input
                    [required]="true"
                    [parentForm]="form"
                    [label]="'aimo.register.name.label'"
                    [nameForm]="'name'"
                    [labelClass]="'text-bold'"
                    idName="name"></aimo-cx-input>
                <cx-form-errors [control]="form.get('name')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
            </div>

            <div class="col-md-6 col-12 pb-4">
                <aimo-cx-input
                    [required]="true"
                    [parentForm]="form"
                    [label]="'aimo.register.email.label'"
                    [nameForm]="'email'"
                    [labelClass]="'text-bold'"
                    idName="email"></aimo-cx-input>
                <cx-form-errors [control]="form.get('email')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
            </div>
            <div class="col-md-6 col-12 pb-4">
                <aimo-cx-input
                    [required]="true"
                    [parentForm]="form"
                    [label]="'aimo.register.phone.label'"
                    [nameForm]="'phone'"
                    [placeholder]="'aimo.register.phone.placeholder'"
                    [labelClass]="'text-bold'"
                    idName="phone"></aimo-cx-input>
                <cx-form-errors [control]="form.get('phone')" aria-atomic="true" aria-live="assertive"></cx-form-errors>
            </div>

            <div class="col-md-6 col-12 pb-4">
                <aimo-cx-input
                    [typeInput]="'password'"
                    [required]="true"
                    [parentForm]="form"
                    [label]="'aimo.register.password.label'"
                    [nameForm]="'password'"
                    [labelClass]="'text-bold'"
                    idName="password"></aimo-cx-input>
                <aimo-password-checks [value]="form.get('password').value"></aimo-password-checks>
            </div>
            <div class="col-md-6 col-12 pb-4">
                <aimo-cx-input
                    [typeInput]="'password'"
                    [required]="true"
                    [parentForm]="form"
                    [label]="'aimo.register.passwordConfirm.label'"
                    [nameForm]="'passwordConfirm'"
                    [labelClass]="'text-bold'"
                    idName="passwordConfirm"></aimo-cx-input>
                <cx-form-errors
                    [control]="form.get('passwordConfirm')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>
        </div>

        <h4 class="mt-2 mb-4">
            {{ 'aimo.register.companyInfo.title' | cxTranslate }}
        </h4>
        <p
            class="mb-4"
            [innerHTML]="
                ('aimo.register.companyInfo.description' | cxTranslate) + ' ' + ('aimo.register.contact' | cxTranslate)
            "></p>
        <div class="row customers">
            <hr class="w-100 ml-2 d-md-none mt-0 mr-2 mb-4" />
            <div class="col-md-6 col-12 pb-4">
                <aimo-cx-input
                    class="customer-id"
                    [labelClass]="'text-bold'"
                    [parentForm]="form"
                    [required]="true"
                    [label]="'aimo.register.customerId.label'"
                    [nameForm]="'customer'"
                    [idName]="'customer'"></aimo-cx-input>
                <cx-form-errors
                    [control]="form.get('customer')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>
        </div>

        <div class="form-check mb-3 pb-4">
            <label for="acceptTerms">
                <input required="true" type="checkbox" formControlName="acceptTerms" id="acceptTerms" />
                <span innerHTML="{{ 'aimo.register.acceptTerms.label' | cxTranslate }}"></span>
                <cx-form-errors
                    [control]="form.get('acceptTerms')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </label>
        </div>

        <div class="row align-items-center">
            <div class="col-md-3 col-12">
                <button type="submit" class="btn btn-primary mr-3">
                    {{ 'aimo.register.title' | cxTranslate }}
                </button>
            </div>
            <div class="col-md-9 col-12 pt-3 pt-md-0">
                {{ 'aimo.register.alreadyRegistered' | cxTranslate }}
                <a class="d-inline-block" [routerLink]="{ cxRoute: 'login' } | cxUrl">{{
                    'loginForm.title' | cxTranslate
                }}</a>
            </div>
        </div>

        <div class="re-captcha mt-4">
            <p innerHTML="{{ 'forgottenPassword.recaptcha' | cxTranslate }}"></p>
        </div>
    </form>
</div>

<ng-template #registered>
    <h1 class="mb-3 pb-4">{{ 'aimo.register.thankyou.title' | cxTranslate }}</h1>
    <p [innerHTML]="'aimo.register.thankyou.description' | cxTranslate"></p>
    <p [innerHTML]="'aimo.register.contact' | cxTranslate"></p>
    <button type="submit" class="btn btn-primary mt-3" [routerLink]="{ cxRoute: 'login' } | cxUrl">
        {{ 'aimo.register.thankyou.ok' | cxTranslate }}
    </button>
</ng-template>
