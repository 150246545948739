import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';

import { AimoCartTotalsComponent } from './aimo-cart-totals.component';

@NgModule({
    imports: [CommonModule, I18nModule, NgbPopoverModule],
    declarations: [AimoCartTotalsComponent],
    exports: [AimoCartTotalsComponent],
})
export class AimoCartTotalsModule {}
