import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { OrderService } from '@spartacus/order/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoCart } from '../../model/cart.model';
import { AimoUserService } from '../../service/user/aimo-user.service';

@Component({
    selector: 'aimo-order-confirmation-items',
    templateUrl: './aimo-order-confirmation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoOrderConfirmationComponent {
    order$: Observable<AimoCart>;

    constructor(
        protected routingService: RoutingService,
        protected router: Router,
        protected orderFacade: OrderService,
        protected activatedRoute: ActivatedRoute,
        protected userService: AimoUserService,
    ) {
        if (activatedRoute.snapshot.url.length === 3) {
            this.order$ = this.userService.getOrder(activatedRoute.snapshot.url[2].path);
        } else {
            this.order$ = this.orderFacade.getOrderDetails().pipe(
                // eslint-disable-next-line
                map((order) => order as any),
            );
        }
    }

    gotoOrder(code: string): void {
        this.router.navigate(['/my-account/order/' + code, { returnUrl: 'orders' }]);
    }
}
