import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';

import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoSpinnerService } from '../../shared/spinner/aimo-spinner.service';

@Component({
    selector: 'aimo-excel-import',
    templateUrl: './aimo-excel-import.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoExcelImportComponent implements OnInit, OnDestroy {
    form: UntypedFormGroup;

    @ViewChild('lineNumbers')
    lineNumbers: ElementRef;

    submitEnabled = false;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected spinnerService: AimoSpinnerService,
    ) {}

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            data: new UntypedFormControl('', Validators.required),
        });
        this.spinnerService.setTitle('aimo.loading.cart.excelImport');
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
    }

    closeDialog(): void {
        this.launchDialogService.clear(LAUNCH_CALLER.EXCEL_IMPORT);
        this.launchDialogService.closeDialog('closed');
    }

    excelImport(): void {
        this.closeDialog();
        this.activeCartService.excelImport(this.form.controls['data'].value);
    }

    addLineNumbers(event: KeyboardEvent): void {
        const value = (event.target as HTMLInputElement).value;
        this.submitEnabled = value.length > 1;
        const numberOfLines = value.split('\n').length;
        this.lineNumbers.nativeElement.innerHTML = Array(numberOfLines).fill('<span></span>').join('');
    }
}
