import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { I18nModule, UserAuthModule } from '@spartacus/core';
import { AppRoutingModule, BaseStorefrontModule, StorefrontComponentModule } from '@spartacus/storefront';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';

// Here register all the locales that will be in use for Dates in the project
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeSv, 'sv');

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [AppComponent],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.recaptcha.siteKey,
        },
    ],
    imports: [
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        UserAuthModule,
        StorefrontComponentModule,
        BaseStorefrontModule,
        BrowserModule.withServerTransition({ appId: 'spartacus-app' }),
        BrowserTransferStateModule,
        HttpClientModule,
        AppRoutingModule,
        FeaturesModule,
        I18nModule,
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  maxAge: 25,
              })
            : [],
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
