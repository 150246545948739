import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

import { AimoPipesModule } from '../utils/safe-html/aimo-pipes.module';

import { AimoGlobalMessageComponent } from './aimo-global-message.component';

@NgModule({
    imports: [CommonModule, IconModule, I18nModule, AimoPipesModule],
    declarations: [AimoGlobalMessageComponent],
    exports: [AimoGlobalMessageComponent],
})
export class AimoGlobalMessageModule {}
