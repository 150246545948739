import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoNavigationNode } from '../../../service/navigation/aimo-navigation-node.model';
import { AimoNavigationService } from '../../../service/navigation/aimo-navigation.service';

@Component({
    selector: 'aimo-cx-navigation',
    templateUrl: './aimo-navigation.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoNavigationComponent {
    node$: Observable<AimoNavigationNode> = this.service.createNavigation(this.componentData.data$);

    styleClass$: Observable<string> = this.componentData.data$.pipe(map((d) => d?.styleClass));

    constructor(
        protected componentData: CmsComponentData<CmsNavigationComponent>,
        protected service: AimoNavigationService,
    ) {}
}
