<div class="container" *ngIf="bulletin$ | async as bulletin">
    <div class="row no-gutters">
        <div class="col-12 order-2 order-lg-1" [ngClass]="{ 'col-lg-6': bulletin.picture?.url }">
            <div class="category-content" [ngClass]="{ 'category-content-half-width': bulletin.picture?.url }">
                <aimo-breadcrumb
                    *ngIf="breadcrumbs$ | async as breadcrumbs"
                    [hideTitle]="true"
                    [showBackLink]="false"
                    [normalBreadCrumbs]="breadcrumbs">
                </aimo-breadcrumb>
                <div class="category-description bulletin-description">
                    <div class="bulletin-tag-date">
                        <span class="bulletin-tag bulletin-tag-{{ bulletin.category.code }}">{{
                            bulletin.category.name
                        }}</span>
                        <span class="bulletin-date">{{
                            bulletin.publishingDate | cxDate : ('aimo.dateFormat' | cxTranslate)
                        }}</span>
                    </div>
                    <h1>{{ bulletin.title }}</h1>
                    <div [innerHTML]="bulletin.description"></div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 order-1 order-lg-2" *ngIf="bulletin.picture?.url">
            <div class="category-image bulletin-image">
                <cx-media [container]="bulletin.picture"></cx-media>
            </div>
        </div>
    </div>
</div>
