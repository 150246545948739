import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Actions } from '@ngrx/effects';
import {
    GlobalMessageService,
    GlobalMessageType,
    RoutingService,
    TranslatableParams,
    WindowRef,
} from '@spartacus/core';
import { Translatable } from '@spartacus/core/src/i18n/translatable';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { AimoBaseOrder } from '../../../model/cart.model';
import { AimoActiveCartService } from '../../../service/cart/aimo-active-cart.service';
import { AimoGtmService } from '../../../service/gtm/aimo-gtm.service';

import { AimoBaseOrderDialogData } from './aimo-base-order-layout.config';

@Component({
    selector: 'aimo-base-order-modal',
    templateUrl: './aimo-base-order-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBaseOrderModalComponent implements OnDestroy {
    subscription: Subscription = new Subscription();
    form: FormGroup;
    weekDays: number[];
    evenOdd = [{ id: 'even' }, { id: 'odd' }, { id: 'both' }];

    existingBaseOrder: AimoBaseOrder;

    constructor(
        protected launchDialogService: LaunchDialogService,
        protected activeCartService: AimoActiveCartService,
        protected eRef: ElementRef,
        protected actions$: Actions,
        protected routingService: RoutingService,
        protected cdr: ChangeDetectorRef,
        protected globalMessage: GlobalMessageService,
        protected winRef: WindowRef,
        protected gtmService: AimoGtmService,
    ) {
        this.launchDialogService.data$.pipe(take(1)).subscribe((dialogData: AimoBaseOrderDialogData) => {
            this.existingBaseOrder = dialogData.existingBaseOrder;
            this.activeCartService
                .getRouteCalendar()
                .pipe(take(1))
                .subscribe((routeCal) => {
                    this.weekDays = routeCal.routeWeekDays;
                    this.initForm();
                });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private initForm(): void {
        const controls = {
            name: new FormControl(this.existingBaseOrder ? this.existingBaseOrder.name : null, [Validators.required]),
            purchaseOrderNumber: new FormControl(
                this.existingBaseOrder ? this.existingBaseOrder.purchaseOrderNumber : null,
            ),
            evenOdd: new FormControl(
                this.existingBaseOrder
                    ? this.existingBaseOrder.oddWeek === undefined
                        ? 'both'
                        : this.existingBaseOrder.oddWeek === true
                        ? 'odd'
                        : 'even'
                    : null,
                [Validators.required],
            ),
        };
        this.weekDays?.forEach(
            (weekday) =>
                (controls['weekday_' + weekday] = new FormControl(
                    this.existingBaseOrder
                        ? this.existingBaseOrder.weekDays.find((w) => w === weekday) !== undefined
                        : null,
                )),
        );
        this.form = new FormGroup(controls);
        this.cdr.detectChanges();
    }

    closeDialog(reason = 'closed'): void {
        this.launchDialogService.clear(LAUNCH_CALLER.CREATE_BASE_ORDER);
        this.launchDialogService.closeDialog(reason);
    }

    submit(): void {
        if (this.form.valid) {
            this.subscription.add(
                this.activeCartService
                    .saveBaseOrder({
                        code: this.existingBaseOrder ? this.existingBaseOrder.code : undefined,
                        blocks: this.existingBaseOrder ? this.existingBaseOrder.blocks : [],
                        entries: [],
                        weekDays: Object.keys(this.form.controls)
                            .filter((key) => key.startsWith('weekday'))
                            .filter((key) => this.form.controls[key].value === true)
                            .map((key) => parseInt(key.replace('weekday_', ''))),
                        name: this.form.controls.name.value,
                        purchaseOrderNumber: this.form.controls.purchaseOrderNumber.value,
                        oddWeek:
                            this.form.controls.evenOdd.value === 'both'
                                ? null
                                : this.form.controls.evenOdd.value === 'odd'
                                ? true
                                : false,
                    } as AimoBaseOrder)
                    .pipe(
                        take(1),
                        tap((bo) => {
                            if (!this.existingBaseOrder) {
                                this.routingService.go('/baseorders/' + bo.code);
                                this.globalMessage.add(
                                    {
                                        key: 'aimo.baseOrders.createdSuccessfully',
                                        params: {
                                            name: this.form.controls.name.value,
                                        } as TranslatableParams,
                                    } as Translatable,
                                    GlobalMessageType.MSG_TYPE_INFO,
                                    6000,
                                );
                            }
                        }),
                    )
                    .subscribe(() => {
                        this.closeDialog('created');
                    }),
            );
        }
    }

    isValid(): boolean {
        return this.form.valid;
    }

    pushGtmEvent(event: string): void {
        this.gtmService.pushEvent(event);
    }
}
