<div class="row" *ngIf="alcoholInfo$ | async as alcoholInfo">
    <h1 class="text-primary col-md-auto col-12 page-title">{{ 'aimo.alcoholCooking.title' | cxTranslate }}</h1>
    <div class="col-md-auto col-12 ml-md-auto mb-5" *ngIf="false">
        <span class="text-bold">{{ 'aimo.alcoholCooking.license.label' | cxTranslate }}</span>
        : {{ 'aimo.alcoholCooking.license.' + (alcoholInfo.validLicence ? 'valid' : 'invalid') | cxTranslate }}
        <br />
        <span class="text-bold">{{ 'aimo.alcoholCooking.license.level' | cxTranslate }}</span>
        : {{ alcoholInfo.licenceLevel }}
    </div>

    <div class="w-100"></div>

    <ng-container *ngFor="let year of alcoholInfo.quotas">
        <div class="col-lg-4 col-12 mb-md-5 mb-3">
            <h3 class="text-primary">{{ year.year }} {{ 'aimo.alcoholCooking.perYear.quota' | cxTranslate }}</h3>
            <span class="text-bold">{{ 'aimo.alcoholCooking.perYear.total' | cxTranslate }}</span>
            : {{ year.total.toString().replace('.', ',') }} {{ 'aimo.alcoholCooking.perYear.liters' | cxTranslate }}
        </div>

        <div class="col-lg-8 col-12 mb-5">
            <div class="progress progress-xxl">
                <div
                    class="progress-bar bg-summer"
                    role="progressbar"
                    style="width: {{ (+year.used * 100) / year.total }}%"
                    aria-valuenow="{{ year.used }}"
                    aria-valuemin="{{ 0 }}"
                    aria-valuemax="{{ year.total }}"></div>
            </div>
            <div class="d-inline">
                <span class="text-bold">{{ 'aimo.alcoholCooking.perYear.used' | cxTranslate }}</span>
                : {{ year.used.toString().replace('.', ',') }} {{ 'aimo.alcoholCooking.perYear.liters' | cxTranslate }}
            </div>
            <div class="d-inline float-right text-right">
                <span class="text-bold">{{ 'aimo.alcoholCooking.perYear.remaining' | cxTranslate }}</span>
                : {{ year.remaining.toString().replace('.', ',') }}
                {{ 'aimo.alcoholCooking.perYear.liters' | cxTranslate }}
            </div>
        </div>
    </ng-container>
</div>
