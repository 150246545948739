import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WindowRef } from '@spartacus/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { AimoCart, AimoOrder, AimoOrderEntry } from '../../model/cart.model';
import { AimoDayGroupedOrderHistory } from '../../model/order.model';
import { AimoPrice } from '../../model/product.model';
import { DateUtils } from '../../shared/util/date-utils';

import { AimoGtmCalendar, AimoGtmProducts } from './aimo-gtm.action';
import {
    AimoGTMProduct,
    GTMCalendarSource,
    GTMCartType,
    GTMContentType,
    GTMCreativeSlot,
    GTMEvent,
    GTMEventCode,
    GTMItem,
    GTMItemListId,
    GTMItemType,
    GTMOrderStatus,
    GTMPurchaseType,
} from './aimo-gtm.model';

const CURRENCY_CODE = 'EUR';

@Injectable({
    providedIn: 'root',
})
export class AimoGtmService {
    constructor(
        protected gtmService: GoogleTagManagerService,
        protected activatedRoute: ActivatedRoute,
        protected winRef: WindowRef,
    ) {
        const gtmUrlParam = this.activatedRoute.snapshot.queryParamMap.get('_gl');
        this.addToDom(gtmUrlParam);
    }

    public addToDom(gtmUrlParam: string): void {
        if (this.winRef.document) {
            this.gtmService.addGtmToDom();
        }
        if (gtmUrlParam) {
            this.gtmService.pushTag({ crossDomain: gtmUrlParam });
        }
    }

    pushProducts(action: AimoGtmProducts): void {
        let entryData;
        if (action.linkedProducts) {
            entryData = this.createProductEventData(
                action.linkedProducts,
                action.event,
                action.value,
                action.searchTerm,
                action.totalHits,
                action.products[0],
                action.searchOrigin,
            );
        } else {
            entryData = this.createProductEventData(
                action.products,
                action.event,
                action.value,
                action.searchTerm,
                action.totalHits,
                null,
                action.searchOrigin,
            );
        }
        this.pushData(entryData);
    }

    pushCalendar(action: AimoGtmCalendar): void {
        if (action.cart.requestedDeliveryDate) {
            const entryData = this.createCalendarEventData(
                this.getDaysUntilSelectedDate(action.cart.requestedDeliveryDate),
                action.cart.dayGroupedEntries.filter((d) => d.active).map((d) => d.entries).length,
                action.cart.dayGroupedEntries.length,
                action.source,
                action.event,
            );
            this.pushData(entryData);
        }
    }

    private getDaysUntilSelectedDate(date: string): number {
        return Math.ceil((DateUtils.convertDate(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
    }

    createCalendarEventData(
        days_until_selected_date: number,
        items_in_cart: number,
        amount_of_open_carts: number,
        source: GTMCalendarSource,
        event: GTMEventCode,
    ): GTMEvent {
        return {
            event,
            source: source,
            days_until_selected_date: days_until_selected_date,
            items_in_cart: items_in_cart,
            amount_of_open_carts: amount_of_open_carts,
        } as GTMEvent;
    }

    pushContentEvent(
        creative_slot: GTMCreativeSlot,
        promotion_name: string,
        content_type: GTMContentType,
        vendor_id: string,
        event: GTMEventCode,
    ): void {
        this.pushData(
            this.createContentEventData(
                this.winRef.location.href,
                creative_slot,
                promotion_name,
                content_type,
                vendor_id,
                event,
            ),
        );
    }

    private createContentEventData(
        content_id: string,
        creative_slot: GTMCreativeSlot,
        promotion_name: string,
        content_type: GTMContentType,
        vendor_id: string,
        event: GTMEventCode,
    ): GTMEvent {
        return {
            event,
            ecommerce: {
                creative_slot: creative_slot,
                items: [
                    {
                        item_type: GTMItemType.content,
                        item_id: content_id,
                        item_name: promotion_name,
                        content_type: content_type,
                        vendor_id: vendor_id,
                    },
                ],
            },
        } as GTMEvent;
    }

    public expandOrderHistory(day: AimoDayGroupedOrderHistory): void {
        try {
            this.pushData({
                event: GTMEventCode.ExpandOrderHistory,
                days_until_selected_date: this.getDaysUntilSelectedDate(day.date),
                order_status: day.status === 'open' ? GTMOrderStatus.open : GTMOrderStatus.closed,
                ecommerce: {
                    items: this.convertEntriesToGtmProducts(
                        day.orders.flatMap((o) => o.entries),
                        GTMItemListId.order_history,
                    )
                        ?.map((p) => this.createGTMItem(p))
                        .filter((i) => i !== undefined),
                },
            } as GTMEvent);
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }

    public pushOrderPlaced(order: AimoOrder): void {
        // check change orders
        if (order.entries) {
            order.entries
                .filter((e) => e.externalOrderNumber !== undefined)
                .reduce(
                    (entryMap, e) =>
                        entryMap.set(e.externalOrderNumber, [...(entryMap.get(e.externalOrderNumber) || []), e]),
                    new Map(),
                )
                .forEach((entries) =>
                    this.sendOrder(
                        entries,
                        entries[0].externalOrderNumber,
                        GTMEventCode.Purchase,
                        parseFloat(
                            entries
                                .map((e) => e.totalPrice.value)
                                .reduce((sum, value) => sum + value, 0)
                                .toFixed(2),
                        ),
                        parseFloat(
                            entries
                                .map((e) => e.tax.value.value)
                                .reduce((sum, value) => sum + value, 0)
                                .toFixed(2),
                        ),
                        true,
                        GTMPurchaseType.modification,
                    ),
                );

            // then send new orders
            this.sendOrder(
                order.entries.filter((e) => e.externalOrderNumber === undefined),
                order.code,
                GTMEventCode.Purchase,
                order.totalPrice.value,
                order.totalTax.value,
                false,
                order.entries.filter((e) => e.externalOrderNumber !== undefined).length == 0
                    ? GTMPurchaseType.new
                    : GTMPurchaseType.modification,
            );
        }
    }

    pushOrderClicked(order: AimoCart, event: GTMEventCode): void {
        const products = this.convertEntriesToGtmProducts(
            order.dayGroupedEntries.filter((g) => g.active).flatMap((d) => d.entries),
            GTMItemListId.cart,
        );
        const entryData = this.createProductEventData(products, event, order.totalPrice.value);
        this.pushData(entryData);
    }

    public pushEvent(event: string): void {
        if (event !== undefined) {
            this.gtmService.pushTag({ ecommerce: null });
            this.gtmService.pushTag({ event: event });
        }
    }

    private createProductEventData(
        products: AimoGTMProduct[],
        event: GTMEventCode,
        value: number,
        searchTerm?: string,
        totalHits?: number,
        originalProduct?: AimoGTMProduct,
        searchOrigin?: GTMCartType,
    ): GTMEvent {
        const ret = {
            event,
            search_term: searchTerm,
            total_hits: totalHits,
            original_product: originalProduct ? this.createGTMItem(originalProduct) : undefined,
            items: originalProduct
                ? products.map((p) => this.createGTMItem(p)).filter((i) => i !== undefined)
                : undefined,
            search_origin: searchOrigin,
            ecommerce:
                originalProduct || searchTerm
                    ? undefined
                    : {
                          currency: CURRENCY_CODE,
                          value: value,
                          items: products?.map((p) => this.createGTMItem(p)).filter((i) => i !== undefined),
                          cart_type: searchOrigin,
                      },
        };

        return ret.ecommerce === undefined || ret.ecommerce?.items?.length > 0 ? ret : undefined;
    }

    private getItemListName(id: GTMItemListId): string {
        switch (id) {
            case GTMItemListId.suggestive_search:
            case GTMItemListId.cart_suggestive_search:
                return 'Search box';
            case GTMItemListId.product_search_page:
                return 'Search results';
            case GTMItemListId.product_category_page:
                return 'Category page';
            case GTMItemListId.mostPurchased:
                return 'Front page last purchased';
            case GTMItemListId.novelty:
                return 'Front page novelty';
            case GTMItemListId.campaign:
                return 'Front page campaigns';
            case GTMItemListId.cart:
                return 'Cart';
            case GTMItemListId.order_template:
                return 'Order template';
            default:
                return id;
        }
    }

    private sendOrder(
        entries: AimoOrderEntry[],
        transactionId: string,
        event: GTMEventCode,
        value: number,
        tax: number,
        change: boolean,
        modificationType: GTMPurchaseType,
    ): void {
        if (entries.length > 0) {
            const transaction_id = transactionId?.split(';').find((id) => id !== '');
            this.pushData({
                event,
                purchaseType: modificationType,
                originalTransactionId: transaction_id,
                ecommerce: {
                    currency: CURRENCY_CODE,
                    transaction_id:
                        transaction_id + (change ? DateUtils.formatDate('-yyyyMMdd-HHmmss', new Date()) : ''),
                    value: value,
                    tax: tax,
                    items: this.convertEntriesToGtmProducts(entries, change ? GTMItemListId.cart : undefined)
                        ?.map((p) => this.createGTMItem(p))
                        .filter((i) => i !== undefined),
                },
            } as GTMEvent);
        }
    }

    private convertEntriesToGtmProducts(entries: AimoOrderEntry[], itemListId?: GTMItemListId): AimoGTMProduct[] {
        let index = 0;
        return entries
            ?.flatMap((e) => [e, ...(e.deposits?.entries ?? [])])
            .map(
                (e) =>
                    ({
                        ...e.product,
                        price: this.convertPrice(e.comparisonPrice ?? e.basePrice, e.quantity),
                        quantity: this.roundQuantity(e.quantity),
                        discount: (e as AimoOrderEntry).discount,
                        gtmProductAttributes: {
                            index: index++,
                            item_list_id: itemListId ?? e.gtmItemListId,
                        },
                    } as AimoGTMProduct),
            );
    }

    private createGTMItem(p: AimoGTMProduct): GTMItem {
        if (p.gtmProductAttributes?.item_list_id === undefined) {
            // debugger
        }

        const listName = this.getItemListName(p.gtmProductAttributes?.item_list_id);
        const categories = p.categories ? p.categories.slice(0, p.categories.length - 1) : [];
        const item: Partial<GTMItem> = {
            item_type: GTMItemType.product,
            item_id: p.code,
            item_name: p.name,
            price: Number(((p.price?.value * p.quantity) / this.roundQuantity(p.quantity)).toFixed(2)),
            discount: p.discount?.value,
            index: p.gtmProductAttributes?.index,
            item_brand: p.vendor?.name,
            item_category: categories?.length > 0 ? categories[categories.length - 1].name : undefined,
            item_category2: categories?.length > 1 ? categories[categories.length - 2].name : undefined,
            item_category3: categories?.length > 2 ? categories[categories.length - 3].name : undefined,
            item_category4: categories?.length > 3 ? categories[categories.length - 4].name : undefined,
            item_category5: categories?.length > 4 ? categories[categories.length - 5].name : undefined,
            item_list_id:
                p.gtmProductAttributes?.item_list_id != undefined ? p.gtmProductAttributes?.item_list_id : undefined,
            item_list_name: listName,
        };

        if (p.quantity !== undefined && p.quantity !== null) {
            // Add quantity only if defined. list_views cannot have quantity with NaN
            item.quantity = this.roundQuantity(p.quantity);
        }
        return item as GTMItem;
    }

    private convertPrice(basePrice: AimoPrice, qty: number): AimoPrice {
        return {
            ...basePrice,
            value: Number(((basePrice?.value * qty) / this.roundQuantity(qty)).toFixed(2)),
        };
    }

    private roundQuantity(qty: number): number {
        if (qty !== undefined && qty !== null) {
            // If qty is null, this would return NaN, which is not supported value in GTM
            const rounded = Math.round(qty);
            return rounded === 0 ? 1 : rounded;
        }
        return qty;
    }

    private pushData(eventData: GTMEvent): void {
        if (eventData !== undefined) {
            this.gtmService.pushTag({ ecommerce: null });
            this.gtmService.pushTag(eventData);
            //console.log('GTM: ' + eventData.event + ': ' + JSON.stringify(eventData));
            //console.log('GTM: ' , eventData);
        }
    }
}
