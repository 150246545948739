import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, NotAuthGuard, UrlModule } from '@spartacus/core';
import { FormErrorsModule, PageSlotModule } from '@spartacus/storefront';
import { LoginRegisterModule } from '@spartacus/user/account/components';

import { AimoInputModule } from '../../../shared/input/aimo-input.module';
import { AimoPasswordChecksModule } from '../../shared/password-checks/aimo-password-checks.module';

import { AimoLoginRegisterComponent } from './aimo-login-register.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        I18nModule,
        AimoInputModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                RegistrationComponent: {
                    component: AimoLoginRegisterComponent,
                    guards: [NotAuthGuard],
                },
            },
        }),
        FormErrorsModule,
        ReactiveFormsModule,
        AimoPasswordChecksModule,
    ],
    declarations: [AimoLoginRegisterComponent],
    exports: [AimoLoginRegisterComponent],
})
export class AimoLoginRegisterModule extends LoginRegisterModule {}
