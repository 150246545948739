import { NgModule } from '@angular/core';
import { CmsPageGuardService } from '@spartacus/storefront';

import { AimoCmsPageGuardService } from './aimo-cms-page-guard.service';

@NgModule({
    providers: [
        {
            provide: CmsPageGuardService,
            useExisting: AimoCmsPageGuardService,
        },
    ],
})
export class AimoCMSServiceModule {}
