import { Injectable } from '@angular/core';
import { EventService, LoginEvent, WindowRef } from '@spartacus/core';

const MESSAGE_KEY = 'hiddenMessages';

@Injectable({ providedIn: 'root' })
export class AimoMessageService {
    constructor(protected winRef: WindowRef, protected eventService: EventService) {
        this.eventService.get(LoginEvent).subscribe(() => this.winRef.sessionStorage.removeItem(MESSAGE_KEY));
    }

    hideMessage(id: string): void {
        if (!this.isMessageHidden(id)) {
            let value = this.winRef.sessionStorage.getItem(MESSAGE_KEY);
            if (value === undefined) {
                value = id;
            } else {
                value += ',' + id;
            }
            this.winRef.sessionStorage.setItem(MESSAGE_KEY, value);
        }
    }

    isMessageHidden(id: string): boolean {
        return this.getMessages()?.find((m) => m === id) !== undefined;
    }

    private getMessages(): string[] {
        return this.winRef.sessionStorage.getItem(MESSAGE_KEY)?.split(',');
    }
}
