import { ViewConfig } from '@spartacus/storefront';

export const aimoProductViewConfig: ViewConfig = {
    view: {
        defaultPageSize: 20,
        infiniteScroll: {
            active: false,
            productLimit: 0,
            showMoreButton: false,
        },
    },
};
