import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { AimoCMSBulletinListComponent } from '../../model/cms.model';
import { AimoBulletin, AimoBulletinList } from '../../model/user.model';
import { AimoUserService } from '../../service/user/aimo-user.service';

const BULLETIN_LIMIT_ON_FRONTPAGE = 3;

@Component({
    selector: 'aimo-bulletin-list-component',
    templateUrl: './aimo-bulletin-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoBulletinListComponent implements OnDestroy, OnInit, AfterViewChecked {
    bulletins$: Observable<AimoBulletinList>;
    data$: Observable<AimoCMSBulletinListComponent>;
    subscription: Subscription = new Subscription();
    bulletinHeightsChecked: boolean = false;
    @ViewChild('bulletinList') bulletinList: ElementRef<HTMLElement>;

    constructor(
        protected componentData: CmsComponentData<AimoCMSBulletinListComponent>,
        protected userService: AimoUserService,
        protected activatedRoute: ActivatedRoute,
        protected cdr: ChangeDetectorRef,
        protected elRef: ElementRef,
    ) {
        this.data$ = componentData.data$;
    }

    ngOnInit(): void {
        if (this.componentData.uid === 'BulletinListComponent') {
            //bulletin page
            this.subscription.add(
                this.activatedRoute.url.subscribe((url) => {
                    this.init(url.length > 1 ? url[1].path : undefined);
                    this.cdr.detectChanges();
                }),
            );
        } else {
            //front page
            this.subscription.add(
                this.activatedRoute.url.subscribe((url) => {
                    this.init(url.length > 1 ? url[1].path : undefined, BULLETIN_LIMIT_ON_FRONTPAGE);
                    this.cdr.detectChanges();
                }),
            );
        }
    }

    ngAfterViewChecked(): void {
        if (!this.bulletinHeightsChecked && this.bulletinList?.nativeElement != null) {
            this.elRef.nativeElement.querySelectorAll('.bulletin-list .bulletin-header').forEach((bulletinTitle) => {
                if (bulletinTitle.nextSibling.offsetHeight) {
                    const parentHeight = bulletinTitle.closest('.bulletin-description-wrapper').offsetHeight;
                    const titleHeight = bulletinTitle.offsetHeight;
                    const margin = 8;
                    const lineHeight = 21;
                    const shortDescriptionHeight = parentHeight - (titleHeight + margin);
                    const newLineCount = Math.floor(shortDescriptionHeight / lineHeight);
                    const newHeight = newLineCount * lineHeight;
                    bulletinTitle.nextSibling.style.height = newHeight + 'px';
                    bulletinTitle.nextSibling.style.setProperty('-webkit-line-clamp', newLineCount);
                    const childParagraph = bulletinTitle.nextSibling.childNodes[0];
                    if (childParagraph.nodeName === 'P') {
                        childParagraph.style.height = newHeight + 'px';
                        childParagraph.style.setProperty('-webkit-line-clamp', newLineCount);
                    }
                }
            });
            this.bulletinHeightsChecked = true;
        }
    }

    init(categoryCode: string, limit?: number): void {
        this.bulletins$ = this.userService.getActiveBulletinList(categoryCode).pipe(
            map((list) => ({
                ...list,
                bulletins: list.bulletins.slice(0, limit ? limit : list.bulletins.length),
            })),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    isExternal(data: AimoBulletin): boolean | null {
        return data.external === 'true' || data.external === true;
    }

    getTarget(data: AimoBulletin): string | null {
        return this.isExternal(data) ? '_blank' : null;
    }
}
