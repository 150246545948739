<div class="row no-gutters login-content-md" *ngIf="(errorOnLogin$ | async) === false">
    <form [formGroup]="form" class="col-md-6 col-sm-12 login-content-left" id="loginForm">
        <h2>{{ 'loginForm.title' | cxTranslate }}</h2>
        <aimo-cx-input
            [arialLabel]="'loginForm.userName.arialLabel'"
            [errorMessage]="'loginForm.userName.errorMessage'"
            [idName]="'userId'"
            [label]="'loginForm.userName.label'"
            [nameForm]="'userId'"
            [typeInput]="'email'"
            [parentForm]="form"
            [componentClass]="'pb-4'"
            [labelClass]="'text-bold'"
            [placeholder]="'forgottenPassword.popup.email.placeholder'"
            (afterEnter)="onSubmit()">
        </aimo-cx-input>

        <aimo-cx-input
            [arialLabel]="'loginForm.password.label'"
            [errorMessage]="'loginForm.password.errorMessage'"
            [idName]="'password'"
            [label]="'loginForm.password.label'"
            [nameForm]="'password'"
            [typeInput]="'password'"
            [parentForm]="form"
            [componentClass]="'pb-4'"
            [labelClass]="'text-bold'"
            (afterEnter)="onSubmit()">
        </aimo-cx-input>

        <button
            [id]="'loginButton'"
            type="button"
            (click)="onSubmit()"
            class="btn btn-primary mb-4 d-flex flex-wrap"
            [disabled]="form.disabled">
            {{ 'loginForm.signIn' | cxTranslate }}
        </button>

        <a [id]="'forgotPasswordLink'" [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" class="btn-link text-bold">
            {{ 'loginForm.forgotPassword' | cxTranslate }}
        </a>
    </form>
    <div class="col-md-6 col-sm-12 bg-petrol-light login-content-right">
        <ng-container *ngIf="!isPikatukku()">
            <cx-page-slot position="CenterRightContentSlot" class="p-0"></cx-page-slot>
            <div class="mb-4">
                <button class="btn btn-secondary btn-block" [routerLink]="{ cxRoute: 'register' } | cxUrl">
                    {{ 'loginForm.selfRegisterCustomer.apply.button' | cxTranslate }}
                </button>
            </div>
        </ng-container>

        <cx-page-slot position="RightContentSlot" class="p-0"></cx-page-slot>
        <div class="mb-2">
            <a
                class="btn btn-secondary btn-block"
                [href]="'loginForm.becomeCustomer.apply.buttonUrl' | cxTranslate"
                target="_blank">
                {{ 'loginForm.becomeCustomer.apply.button' | cxTranslate }}
            </a>
        </div>
    </div>
</div>

<div class="login-content-md text-md" *ngIf="errorOnLogin$ | async">
    <h2>{{ 'loginForm.becomeCustomer.title' + getTextSuffix() | cxTranslate }}</h2>
    <p>{{ 'loginForm.becomeCustomer.content' + getTextSuffix() | cxTranslate }}</p>
    <h4>{{ 'loginForm.becomeCustomer.apply.title' + getTextSuffix() | cxTranslate }}</h4>
    <p>{{ 'loginForm.becomeCustomer.apply.content' + getTextSuffix() | cxTranslate }}</p>

    <a [href]="'loginForm.becomeCustomer.apply.buttonUrl' | cxTranslate">
        <button class="btn btn-primary mr-2 mb-2">
            {{ 'loginForm.becomeCustomer.apply.button' | cxTranslate }}
        </button>
    </a>

    <ng-container *ngIf="'loginForm.becomeCustomer.readMoreButtonUrl' + getTextSuffix() | cxTranslate as url">
        <a [href]="url" *ngIf="url?.trim().length > 1">
            <button class="btn btn-secondary mr-2 mb-2">
                {{ 'loginForm.becomeCustomer.readMore' | cxTranslate }}
            </button>
        </a>
    </ng-container>

    <a [href]="'/login'">
        <button class="btn btn-secondary mb-2" (click)="gotoLogin()">
            {{ 'loginForm.title' | cxTranslate }}
        </button>
    </a>
</div>

<div class="login-content-sm d-none" #terms>
    <cx-page-slot position="CenterLeftContentSlot" class="p-0"></cx-page-slot>
    <button class="btn btn-primary mr-2">
        {{ 'loginForm.terms.accept' | cxTranslate }}
    </button>
    <button class="btn btn-secondary">
        {{ 'loginForm.terms.refuse' | cxTranslate }}
    </button>
</div>
