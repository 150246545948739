import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'aimoFilter' })
export class AimoFilterPipe implements PipeTransform {
    /**
     * Pipe filters the list of elements based on the search text provided
     *
     * @param items list of elements to search in
     * @param searchText search string
     * @param filterAttribute name of object attribute in which we will like to filter
     * if it is not defined then it will filter based on all the object attributes which are type string
     * @returns list of elements filtered by search text or []
     */
    transform(items: any[], searchText: string, filterAttributes?: string[]): any[] {
        if (!items || !searchText) {
            return items;
        }

        searchText = searchText.toLowerCase();

        return items.filter((item) => {
            if (filterAttributes && filterAttributes.length > 0) {
                return filterAttributes.some((filterAttribute) => {
                    const attributeValue = this.getAttributeValue(item, filterAttribute);
                    return attributeValue && attributeValue.toLowerCase().includes(searchText);
                });
            } else {
                return this.checkAnyAttributeContainsText(item, searchText);
            }
        });
    }

    private getAttributeValue(item: any, attributePath: string): string {
        if (attributePath === 'addresses[0].town') {
            return item.addresses && item.addresses.length > 0 ? item.addresses[0].town : null;
        }
        const attributes = attributePath.split('.');
        let value = item;
        for (let i = 0; i < attributes.length; i++) {
            if (value && value.hasOwnProperty(attributes[i])) {
                value = value[attributes[i]];
            } else {
                value = null;
                break;
            }
        }
        return value;
    }

    private checkAnyAttributeContainsText(item: any, searchText: string): boolean {
        return Object.keys(item).some((key) => {
            if (typeof item[key] === 'string' && item[key].toLowerCase().includes(searchText)) {
                return true;
            }
            return false;
        });
    }
}
