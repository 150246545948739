<div
    class="order-template"
    *ngIf="baseOrder$ | async as baseOrder"
    [ngClass]="{ 'sales-blocked': baseOrder.salesBlocked }">
    <div class="order-template" *ngIf="baseOrder" [ngClass]="{ 'sales-blocked': baseOrder.salesBlocked }">
        <aimo-breadcrumb
            [hideTitle]="true"
            [showBackLink]="false"
            [normalBreadCrumbs]="getBreadcrumbs(baseOrder, 'aimo.baseOrders.title' | cxTranslate)">
        </aimo-breadcrumb>
        <h1 class="page-title">
            {{ baseOrder.name }}
        </h1>

        <div class="messages mb-4">
            <div class="message message-{{ message.severity }}" *ngFor="let message of baseOrder.messages">
                <i class="aimo-icon icon-inline icon-{{ message.severity }} mr-2"></i>
                <div class="d-inline">
                    <span [innerHTML]="message.message"></span>
                    <ng-container *ngIf="message.action === 'blockedProducts'">
                        <span class="text-left-space" *ngFor="let code of message.actionParams; index as idx">
                            <button class="btn-link text-underline text-default" (click)="scrollTo(code)">
                                {{ code }}
                            </button>
                            <span *ngIf="idx < message?.actionParams.length - 1">, </span>
                        </span>
                    </ng-container>
                    &nbsp;
                    <button
                        (click)="setBlocks(baseOrder)"
                        class="link text-underline"
                        *ngIf="message.action === 'editBlock'">
                        {{ 'aimo.baseOrder.modifyBlock' | cxTranslate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="row no-gutters mb-3 mb-md-3">
            <div class="col-md-3 col-6">
                <label class="text-bold">{{ 'aimo.ordertemplates.createdAt' | cxTranslate }}</label>
                <div>
                    {{
                        'aimo.orderhistory.datetime'
                            | cxTranslate
                                : {
                                      date:
                                          (convertDate(baseOrder.created) | cxDate : ('aimo.dateFormat' | cxTranslate)),
                                      time:
                                          (convertDate(baseOrder.created) | cxDate : ('aimo.timeFormat' | cxTranslate))
                                  }
                    }}
                </div>
                <div *ngIf="baseOrder.modified">
                    ({{ 'aimo.ordertemplates.modifiedAt' | cxTranslate }}
                    {{
                        'aimo.orderhistory.datetime'
                            | cxTranslate
                                : {
                                      date:
                                          (convertDate(baseOrder.modified)
                                          | cxDate : ('aimo.dateFormat' | cxTranslate)),
                                      time:
                                          (convertDate(baseOrder.modified) | cxDate : ('aimo.timeFormat' | cxTranslate))
                                  }
                    }})
                </div>
            </div>

            <div class="col-md-3 col-6">
                <label class="text-bold">{{ 'aimo.ordertemplates.createdBy' | cxTranslate }}</label>
                <div>{{ baseOrder.user.name }}</div>
                <div>{{ baseOrder.user.email }}</div>
            </div>

            <div class="col-md-2 col-6 pt-3 pt-md-0">
                <label class="text-bold">{{ 'aimo.baseOrders.weekdays' | cxTranslate }}</label>
                <div [ngClass]="{ 'text-mid-grey': baseOrder.salesBlocked }">
                    <ng-container *ngFor="let weekday of baseOrder.weekDays; let last = last">
                        <span>{{ 'aimo.calendar.weekday.short.' + weekday | cxTranslate }}</span>
                        <span *ngIf="!last">, </span>
                    </ng-container>
                </div>
            </div>

            <div class="col-md-2 col-6 pt-3 pt-md-0">
                <label class="text-bold">{{ 'aimo.baseOrders.evenOdd.label' | cxTranslate }}</label>
                <div [ngClass]="{ 'text-mid-grey': baseOrder.salesBlocked }">
                    <span *ngIf="baseOrder.oddWeek === true">{{ 'aimo.select.evenOdd.odd' | cxTranslate }}</span>
                    <span *ngIf="baseOrder.oddWeek === undefined">{{ 'aimo.select.evenOdd.both' | cxTranslate }}</span>
                    <span *ngIf="baseOrder.oddWeek === false">{{ 'aimo.select.evenOdd.even' | cxTranslate }}</span>
                </div>
                <div *ngIf="baseOrder.salesBlocked && false" class="text-red">
                    <ng-container *ngIf="baseOrder.blocks[0].start === undefined">
                        {{ 'aimo.baseOrder.salesBlockedForNow' | cxTranslate }}
                    </ng-container>
                    <ng-container *ngIf="baseOrder.blocks[0].start !== undefined">
                        {{
                            'aimo.baseOrder.salesBlocked'
                                | cxTranslate
                                    : {
                                          from:
                                              (convertDate(baseOrder.blocks[0].start)
                                              | cxDate : ('aimo.dateFormat' | cxTranslate)),
                                          to:
                                              (convertDate(baseOrder.blocks[0].end)
                                              | cxDate : ('aimo.dateFormat' | cxTranslate))
                                      }
                        }}
                    </ng-container>
                </div>
            </div>

            <div class="col-md-2 col-6">
                <label class="text-bold">{{ 'aimo.baseOrders.purchaseOrderNumber.label' | cxTranslate }}</label>
                <div>{{ baseOrder.purchaseOrderNumber }}</div>
            </div>
        </div>

        <h2 class="mb-3 d-none d-md-block">{{ 'aimo.cart.item.productTitle' | cxTranslate }}</h2>
        <div class="bg-petrol-light row no-gutters p-2 noPrint mb-2">
            <div class="col-lg-2 col-xl-3 col-12">
                <aimo-cx-searchbox
                    [cart]="baseOrder"
                    [baseOrder]="true"
                    [cartId]="baseOrderId"
                    id="cartSearchBox"
                    [isCartSearchBox]="true"></aimo-cx-searchbox>
            </div>
            <div class="cart-functions float-right col-lg-10 col-xl-9 col-12 pt-3 pb-2 pt-lg-0 pb-lg-0 row no-gutters">
                <button
                    *ngIf="baseOrder.editable"
                    [id]="'edit'"
                    class="btn-link order-1"
                    [attr.aria-label]="'aimo.ordertemplates.edit' | cxTranslate"
                    [title]="'aimo.ordertemplates.edit' | cxTranslate"
                    (click)="edit(baseOrder)">
                    <i class="aimo-icon icon-pencil-simple icon-inline"></i>
                    <span>{{ 'aimo.ordertemplates.edit' | cxTranslate }}</span>
                </button>

                <button
                    [id]="'to_excel'"
                    class="btn-link d-none d-md-inline-block order-3"
                    [attr.aria-label]="'aimo.cart.export.excel' | cxTranslate"
                    [title]="'aimo.cart.export.excel' | cxTranslate"
                    [disabled]="baseOrder.categoryGroupedEntries.length <= 0"
                    (click)="exportToExcel()">
                    <i class="aimo-icon icon-file-arrow-down icon-inline"></i>
                    <span>{{ 'aimo.cart.export.excel' | cxTranslate }}</span>
                </button>

                <button
                    [id]="'print'"
                    class="btn-link d-none d-md-inline-block order-4"
                    [attr.aria-label]="'aimo.cart.print' | cxTranslate"
                    [title]="'aimo.cart.print' | cxTranslate"
                    [disabled]="baseOrder.categoryGroupedEntries.length <= 0"
                    (click)="print()">
                    <i class="aimo-icon icon-printer icon-inline"></i>
                    <span>{{ 'aimo.cart.print' | cxTranslate }}</span>
                </button>

                <button
                    *ngIf="baseOrder.editable"
                    [id]="'block'"
                    class="btn-link d-none order-5"
                    [ngClass]="
                        baseOrder.categoryGroupedEntries.length <= 0 || baseOrder.unApprovedChanges
                            ? 'd-md-none'
                            : 'd-md-inline-block'
                    "
                    [attr.aria-label]="'aimo.baseOrder.block' | cxTranslate"
                    [title]="'aimo.baseOrder.block' | cxTranslate"
                    [disabled]="baseOrder.categoryGroupedEntries.length <= 0"
                    (click)="setBlocks(baseOrder)">
                    <i class="aimo-icon icon-prohibit icon-inline"></i>
                    <span *ngIf="!(baseOrder.salesBlocked || baseOrder.aboutToBeSalesBlocked)">{{
                        'aimo.baseOrder.block' | cxTranslate
                    }}</span>
                    <span *ngIf="baseOrder.salesBlocked || baseOrder.aboutToBeSalesBlocked">{{
                        'aimo.baseOrder.modifyBlock' | cxTranslate
                    }}</span>
                </button>

                <button
                    [id]="'delete'"
                    class="btn-link order-6"
                    [attr.aria-label]="'aimo.ordertemplates.delete' | cxTranslate"
                    [title]="'aimo.ordertemplates.delete' | cxTranslate"
                    (click)="delete(baseOrder)">
                    <i class="aimo-icon icon-trash icon-inline"></i>
                    <span>{{ 'aimo.ordertemplates.delete' | cxTranslate }}</span>
                </button>

                <button
                    #moreFunctionsOption
                    [id]="'more_functions'"
                    class="btn-link d-md-none order-7"
                    [attr.aria-label]="'aimo.cart.more.functions' | cxTranslate"
                    [title]="'aimo.cart.more.functions' | cxTranslate"
                    (click)="showMoreFunctions(moreFunctionsOption)">
                    <i class="aimo-icon icon-dots-three-vertical icon-inline"></i>
                    <span>{{ 'aimo.cart.more.functions' | cxTranslate }}</span>
                </button>

                <button
                    *ngIf="baseOrder.unApprovedChanges"
                    [id]="'cancel'"
                    class="btn-link order-8"
                    [attr.aria-label]="'aimo.baseOrder.cancel' | cxTranslate"
                    [title]="'aimo.baseOrder.cancel' | cxTranslate"
                    (click)="cancel(); pushGtmEvent('baseorders-cancel-change')">
                    <i class="aimo-icon icon-x icon-inline"></i>
                    <span>{{ 'aimo.baseOrder.cancel' | cxTranslate }}</span>
                </button>

                <button
                    *ngIf="baseOrder.unApprovedChanges"
                    [id]="'save'"
                    class="btn btn-sm btn-highlighted order-9 order-md-last ml-md-3 mt-3 mt-md-0 float-right"
                    [attr.aria-label]="'aimo.baseOrder.save' | cxTranslate"
                    [title]="'aimo.baseOrder.save' | cxTranslate"
                    (click)="approve(); pushGtmEvent('baseorders-confirm-change')">
                    <i class="aimo-icon icon-check icon-inline mr-2 mb-1"></i>
                    {{ 'aimo.baseOrder.save' | cxTranslate }}
                </button>

                <button
                    *ngIf="baseOrder.salesBlocked && false"
                    [id]="'continue'"
                    class="btn btn-sm btn-highlighted order-9 order-md-last ml-md-3 mt-3 mt-md-0 float-right"
                    [attr.aria-label]="'aimo.baseOrder.continue' | cxTranslate"
                    [title]="'aimo.baseOrder.continue' | cxTranslate"
                    (click)="continue(baseOrder)">
                    <i class="aimo-icon icon-arrow-counter-clockwise icon-inline mr-2 mb-1"></i>
                    {{ 'aimo.baseOrder.continue' | cxTranslate }}
                </button>
            </div>
        </div>

        <div
            class="bg-petrol-light row no-gutters d-md-flex d-none p-2 mb-4"
            *ngIf="baseOrder.categoryGroupedEntries.length > 0">
            <div class="col-3">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.subtotal' | cxTranslate }}</label>
                <div class="day-subtotal">{{ baseOrder.subTotal?.formattedValue }}</div>
            </div>

            <div class="col-3">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.totalPrice' | cxTranslate }}</label>
                <div class="day-total">{{ baseOrder.totalPrice?.formattedValue }}</div>
            </div>

            <div class="col-2" *ngFor="let tax of baseOrder.taxes">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.vat' | cxTranslate : { tax: tax.rate } }}</label>
                <div class="day-tax-value">{{ tax.value.formattedValue }}</div>
            </div>

            <div class="col-2">
                <label class="text-bold mr-2">{{ 'aimo.cart.totals.totalPriceWithTax' | cxTranslate }}</label>
                <div class="day-total-with-tax">
                    {{ baseOrder.totalPriceWithTax?.formattedValue }}
                </div>
            </div>
        </div>

        <aimo-order-template-item-list [baseOrder]="true" [orderTemplate]="baseOrder"></aimo-order-template-item-list>
    </div>
</div>
