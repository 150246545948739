import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
    AuthHttpHeaderService,
    AuthRedirectService,
    AuthService,
    AuthStorageService,
    GlobalMessageService,
    GlobalMessageType,
    OAuthLibWrapperService,
    OccEndpointsService,
    RoutingService,
} from '@spartacus/core';

@Injectable({
    providedIn: 'root',
})
export class AimoAuthHttpHeaderService extends AuthHttpHeaderService {
    constructor(
        protected authService: AuthService,
        protected authStorageService: AuthStorageService,
        protected oAuthLibWrapperService: OAuthLibWrapperService,
        protected routingService: RoutingService,
        protected occEndpoints: OccEndpointsService,
        protected globalMessageService: GlobalMessageService,
        protected authRedirectService: AuthRedirectService,
        protected router: Router,
    ) {
        super(
            authService,
            authStorageService,
            oAuthLibWrapperService,
            routingService,
            occEndpoints,
            globalMessageService,
            authRedirectService,
        );
    }
}
