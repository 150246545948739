<div>
    <h1 class="page-title">{{ 'aimo.myorganization.title' | cxTranslate }}</h1>

    <div class="messages" *ngIf="error">
        <div class="message message-ERROR" [innerHTML]="error"></div>
    </div>

    <ng-container *ngIf="currentUser$ | async as currentUser">
        <ng-container *ngIf="allUsers$ | async as allUsers">
            <ng-container *ngIf="pendingApprovals$ | async as pendingApprovals">
                <ng-container *ngIf="pendingApprovals > 0">
                    <div class="messages">
                        <div class="message message-WARNING">
                            <i class="left-side aimo-icon icon-inline icon-WARNING"></i>
                            <span
                                *ngIf="pendingApprovals === 1"
                                [innerHTML]="
                                    'aimo.myorganization.approval' | cxTranslate : { approvals: pendingApprovals }
                                "></span>
                            <span
                                *ngIf="pendingApprovals > 1"
                                [innerHTML]="
                                    'aimo.myorganization.approvals' | cxTranslate : { approvals: pendingApprovals }
                                "></span>
                        </div>
                    </div>

                    <div class="table-responsive data-table pb-5 d-md-block d-none">
                        <table class="table table-sm">
                            <thead>
                                <tr class="no-border">
                                    <th class="text-bold col-3 table-subTitle">
                                        {{ 'aimo.myorganization.users.user' | cxTranslate }}
                                    </th>

                                    <th class="text-bold col-2 table-subTitle">
                                        {{ 'aimo.myorganization.users.role' | cxTranslate }}
                                    </th>
                                    <th class="text-bold col-4 table-subTitle">
                                        {{ 'aimo.myorganization.users.customers' | cxTranslate }}
                                    </th>
                                    <th class="text-bold col-3 table-subTitle">
                                        {{ 'aimo.myorganization.users.state' | cxTranslate }}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <ng-container *ngFor="let user of allUsers">
                                    <tr *ngIf="!user.approved">
                                        <td class="table-content">
                                            <b>{{ user.name }}</b>
                                            <div>{{ user.uid }}</div>
                                        </td>
                                        <td class="table-content">
                                            <ng-container *ngIf="user.customerAdmin">
                                                {{ 'aimo.myorganization.userRole.customerAdmin' | cxTranslate }}
                                            </ng-container>
                                            <ng-container *ngIf="!user.customerAdmin">
                                                {{ 'aimo.myorganization.userRole.user' | cxTranslate }}
                                            </ng-container>
                                        </td>
                                        <td class="table-content">
                                            <div class="row" *ngFor="let unit of user.availableUnits">
                                                <div class="text-bold col-3">{{ unit.uid }}</div>
                                                <div class="col-9">{{ unit.name }}</div>
                                            </div>
                                        </td>
                                        <td class="table-content">
                                            <div class="pb-2">
                                                <i class="aimo-icon icon-inline icon-info icon-petrol-blue icon-sm"></i>
                                                {{ 'aimo.myorganization.users.waitingForApproval' | cxTranslate }}
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-12">
                                                    <button
                                                        class="btn-link text-primary text-md"
                                                        [attr.aria-label]="
                                                            'aimo.myorganization.users.approve' | cxTranslate
                                                        "
                                                        [title]="'aimo.myorganization.users.approve' | cxTranslate"
                                                        (click)="approveUser(user, currentUser, false)">
                                                        <i class="aimo-icon icon-inline icon-check icon-sm"></i>
                                                        <span>{{
                                                            'aimo.myorganization.users.approve' | cxTranslate
                                                        }}</span>
                                                    </button>
                                                </div>
                                                <div class="col-lg-6 col-12">
                                                    <button
                                                        class="btn-link text-red text-md"
                                                        [attr.aria-label]="
                                                            'aimo.myorganization.users.denyApproval' | cxTranslate
                                                        "
                                                        [title]="'aimo.myorganization.users.denyApproval' | cxTranslate"
                                                        (click)="approveUser(user, currentUser, true)">
                                                        <i class="aimo-icon icon-inline icon-x icon-sm icon-red"></i>
                                                        <span>{{
                                                            'aimo.myorganization.users.denyApproval' | cxTranslate
                                                        }}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-flex-block d-md-none row no-gutters">
                        <ng-container *ngFor="let user of allUsers">
                            <ng-container *ngIf="!user.approved">
                                <div class="col-12 pb-3">
                                    <b>{{ user.name }}</b>
                                    <div>{{ user.uid }}</div>
                                </div>
                                <div class="col-8 text-bold pb-2">
                                    {{ 'aimo.myorganization.users.role' | cxTranslate }}
                                </div>
                                <div class="col-4 text-right pb-2">
                                    <ng-container *ngIf="user.customerAdmin">
                                        {{ 'aimo.myorganization.userRole.customerAdmin' | cxTranslate }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.customerAdmin">
                                        {{ 'aimo.myorganization.userRole.user' | cxTranslate }}
                                    </ng-container>
                                </div>
                                <div class="col-12 text-bold">
                                    {{ 'aimo.myorganization.users.customers' | cxTranslate }}
                                </div>
                                <div class="col-12 pb-2">
                                    <div *ngFor="let unit of user.availableUnits">
                                        <span class="text-bold">{{ unit.uid }}</span> {{ unit.name }}
                                    </div>
                                </div>
                                <div class="col-5 text-bold">
                                    {{ 'aimo.myorganization.users.state' | cxTranslate }}
                                </div>
                                <div class="col-7">
                                    <div class="pb-2 text-right">
                                        <i class="aimo-icon icon-inline icon-info icon-petrol-blue icon-sm"></i>
                                        {{ 'aimo.myorganization.users.waitingForApproval' | cxTranslate }}
                                    </div>

                                    <div class="row">
                                        <div class="col-6 text-right">
                                            <button
                                                class="btn-link text-primary text-md"
                                                [attr.aria-label]="'aimo.myorganization.users.approve' | cxTranslate"
                                                [title]="'aimo.myorganization.users.approve' | cxTranslate"
                                                (click)="approveUser(user, currentUser, false)">
                                                <i class="aimo-icon icon-inline icon-check icon-sm"></i>
                                                <span>{{ 'aimo.myorganization.users.approve' | cxTranslate }}</span>
                                            </button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button
                                                class="btn-link text-red text-md"
                                                [attr.aria-label]="
                                                    'aimo.myorganization.users.denyApproval' | cxTranslate
                                                "
                                                [title]="'aimo.myorganization.users.denyApproval' | cxTranslate"
                                                (click)="approveUser(user, currentUser, true)">
                                                <i class="aimo-icon icon-inline icon-x icon-sm icon-red"></i>
                                                <span>{{
                                                    'aimo.myorganization.users.denyApproval' | cxTranslate
                                                }}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr class="col-12 mb-2 pb-1" />
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-container>

            <div class="table-responsive data-table pb-5 d-md-block d-none">
                <div class="pb-4 d-flex">
                    <h2 class="mb-0">{{ 'aimo.myorganization.users.title' | cxTranslate }}</h2>
                    <div class="position-relative ml-auto">
                        <aimo-cx-input
                            (afterInput)="changeUsersSearch($event)"
                            [placeholder]="'aimo.myorganization.users.search'"
                            [nameForm]="'search'"
                            [inputValue]="usersSearch"
                            [label]="'aimo.myorganization.users.search'"
                            [labelClass]="'d-none'"
                            [idName]="'usersSearch'"
                            [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                        </aimo-cx-input>
                        <button class="btn-link btn-close" *ngIf="usersSearch" (click)="changeUsersSearch('')">
                            <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                        </button>
                    </div>
                </div>

                <table class="table table-sm">
                    <thead>
                        <tr class="no-border">
                            <th class="text-bold table-subTitle col-3">
                                {{ 'aimo.myorganization.users.user' | cxTranslate }}
                            </th>
                            <th class="text-bold table-subTitle col-2">
                                {{ 'aimo.myorganization.users.role' | cxTranslate }}
                            </th>
                            <th class="text-bold table-subTitle col-4">
                                {{ 'aimo.myorganization.users.customers' | cxTranslate }}
                            </th>
                            <th class="text-bold table-subTitle col-3">
                                {{ 'aimo.myorganization.users.state' | cxTranslate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let user of allUsers | aimoFilter : usersSearch : ['name', 'uid']">
                            <tr *ngIf="user.approved">
                                <td class="table-content">
                                    <b>{{ user.name }}</b>
                                    <div>{{ user.uid }}</div>
                                </td>
                                <td class="table-content">
                                    <ng-container *ngIf="user.customerAdmin">
                                        {{ 'aimo.myorganization.userRole.customerAdmin' | cxTranslate }}
                                    </ng-container>
                                    <ng-container *ngIf="!user.customerAdmin">
                                        {{ 'aimo.myorganization.userRole.user' | cxTranslate }}
                                    </ng-container>
                                </td>
                                <td class="table-content">
                                    <div class="row" *ngFor="let unit of user.availableUnits">
                                        <div class="col-3 text-bold">{{ unit.uid }}</div>
                                        <div class="col-9">{{ unit.name }}</div>
                                    </div>
                                </td>
                                <td class="table-content d-flex">
                                    <span *ngIf="user.active && user.lastLogin">
                                        {{
                                            'aimo.myorganization.users.lastLogin'
                                                | cxTranslate
                                                    : {
                                                          date:
                                                              user.lastLogin
                                                              | cxDate : ('aimo.dateFormat' | cxTranslate)
                                                      }
                                        }}
                                    </span>
                                    <span *ngIf="!user.active">
                                        <i class="aimo-icon icon-inline icon-info mb-1 icon-red"></i>
                                        {{ 'aimo.myorganization.users.locked' | cxTranslate }}
                                    </span>

                                    <button
                                        class="btn-link circle-md circle bg-petrol-light icon-cursor ml-auto"
                                        [attr.aria-label]="'aimo.myorganization.users.arialLabel.edit' | cxTranslate"
                                        [title]="'aimo.myorganization.users.arialLabel.edit' | cxTranslate"
                                        (click)="editB2BUser(currentUser, currentUser.availableUnits, user)">
                                        <i
                                            class="mb-1 aimo-icon icon-pencil-simple icon-inline icon-sm icon-petrol-blue"></i>
                                    </button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>

            <div class="d-flex-block d-md-none row no-gutters">
                <h2 class="mt-4 col-12 mb-2">{{ 'aimo.myorganization.users.title' | cxTranslate }}</h2>
                <div class="position-relative col-12 mb-3">
                    <aimo-cx-input
                        (afterInput)="changeUsersSearch($event)"
                        [placeholder]="'aimo.myorganization.users.search'"
                        [nameForm]="'search'"
                        [inputValue]="usersSearch"
                        [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                    </aimo-cx-input>
                    <button class="btn-link btn-close" *ngIf="usersSearch" (click)="changeUsersSearch('')">
                        <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                    </button>
                </div>
                <ng-container *ngFor="let user of allUsers | aimoFilter : usersSearch : ['name', 'uid']">
                    <ng-container *ngIf="user.approved">
                        <div class="col-12 d-flex align-items-center pb-2">
                            <div>
                                <b>{{ user.name }}</b>
                                <div>{{ user.uid }}</div>
                            </div>
                            <button
                                class="btn-link circle-md circle bg-petrol-light icon-cursor ml-auto"
                                [attr.aria-label]="'aimo.myorganization.users.arialLabel.edit' | cxTranslate"
                                [title]="'aimo.myorganization.users.arialLabel.edit' | cxTranslate"
                                (click)="editB2BUser(currentUser, currentUser.availableUnits, user)">
                                <i class="mb-1 aimo-icon icon-pencil-simple icon-inline icon-sm icon-petrol-blue"></i>
                            </button>
                        </div>
                        <div class="col-8 text-bold pb-2">
                            {{ 'aimo.myorganization.users.role' | cxTranslate }}
                        </div>
                        <div class="col-4 text-right pb-2">
                            <ng-container *ngIf="user.customerAdmin">
                                {{ 'aimo.myorganization.userRole.customerAdmin' | cxTranslate }}
                            </ng-container>
                            <ng-container *ngIf="!user.customerAdmin">
                                {{ 'aimo.myorganization.userRole.user' | cxTranslate }}
                            </ng-container>
                        </div>
                        <div class="col-12 text-bold">
                            {{ 'aimo.myorganization.users.customers' | cxTranslate }}
                        </div>
                        <div class="col-12 pb-2">
                            <div *ngFor="let unit of user.availableUnits">
                                <span class="text-bold">{{ unit.uid }}</span> {{ unit.name }}
                            </div>
                        </div>
                        <div class="col-5 text-bold">
                            {{ 'aimo.myorganization.users.state' | cxTranslate }}
                        </div>
                        <div class="col-7 text-right">
                            <span *ngIf="user.active && user.lastLogin">
                                {{
                                    'aimo.myorganization.users.lastLogin'
                                        | cxTranslate
                                            : { date: user.lastLogin | cxDate : ('aimo.dateFormat' | cxTranslate) }
                                }}
                            </span>
                            <span *ngIf="!user.active">
                                <i class="aimo-icon icon-inline icon-info mb-1 icon-red"></i>
                                {{ 'aimo.myorganization.users.locked' | cxTranslate }}
                            </span>
                        </div>
                        <hr class="col-12" />
                    </ng-container>
                </ng-container>
            </div>

            <div class="table-responsive data-table pb-5 d-md-block d-none">
                <div class="pb-3 d-flex">
                    <h2 class="mb-0">{{ 'aimo.myorganization.customers.title' | cxTranslate }}</h2>
                    <div class="position-relative ml-auto">
                        <aimo-cx-input
                            (afterInput)="changeAvailableUnitsSearch($event)"
                            [placeholder]="'aimo.myorganization.customers.search'"
                            [nameForm]="'search'"
                            [inputValue]="availableUnitsSearch"
                            [label]="'aimo.myorganization.customers.search'"
                            [labelClass]="'d-none'"
                            [idName]="'availableUnitsSearch'"
                            [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                        </aimo-cx-input>
                        <button
                            class="btn-link btn-close"
                            *ngIf="availableUnitsSearch"
                            (click)="changeAvailableUnitsSearch('')">
                            <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                        </button>
                    </div>
                </div>

                <table class="table table-sm">
                    <thead>
                        <tr class="no-border">
                            <th class="text-bold col-2 table-subTitle">
                                {{ 'aimo.myorganization.customers.id' | cxTranslate }}
                            </th>
                            <th class="text-bold col-4 table-subTitle">
                                {{ 'aimo.myorganization.customers.name' | cxTranslate }}
                            </th>
                            <th class="text-bold col-2 table-subTitle">
                                {{ 'aimo.myorganization.customers.city' | cxTranslate }}
                            </th>
                            <th class="text-bold col-4 table-subTitle">
                                {{ 'aimo.myorganization.customers.users' | cxTranslate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            *ngFor="
                                let unit of currentUser.availableUnits
                                    | aimoFilter : availableUnitsSearch : ['name', 'uid', 'addresses[0].town']
                            ">
                            <td class="table-content text-bold">
                                {{ unit.uid }}
                            </td>
                            <td class="table-content">
                                {{ unit.name }}
                            </td>
                            <td class="table-content">
                                {{ unit.addresses[0].town }}
                            </td>
                            <td class="table-content d-flex">
                                <span>{{ getUserList(unit) }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="d-flex-block d-md-none row no-gutters">
                <h2 class="mt-4 col-12 mb-2">{{ 'aimo.myorganization.customers.title' | cxTranslate }}</h2>
                <div class="position-relative col-12 mb-3">
                    <aimo-cx-input
                        (afterInput)="changeAvailableUnitsSearch($event)"
                        [placeholder]="'aimo.myorganization.customers.search'"
                        [nameForm]="'search'"
                        [inputValue]="availableUnitsSearch"
                        [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                    </aimo-cx-input>
                    <button
                        class="btn-link btn-close"
                        *ngIf="availableUnitsSearch"
                        (click)="changeAvailableUnitsSearch('')">
                        <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                    </button>
                </div>
                <ng-container
                    *ngFor="
                        let unit of currentUser.availableUnits
                            | aimoFilter : availableUnitsSearch : ['name', 'uid', 'addresses[0].town']
                    ">
                    <div class="col-12 pb-2">
                        <b>{{ unit.uid }}</b> <br />
                        <span>{{ unit.name }}</span>
                    </div>
                    <div class="col-6 pb-2 text-bold">
                        {{ 'aimo.myorganization.customers.city' | cxTranslate }}
                    </div>
                    <div class="col-6 pb-2 text-right">
                        {{ unit.addresses[0].town }}
                    </div>
                    <div class="col-6 text-bold">
                        {{ 'aimo.myorganization.customers.users' | cxTranslate }}
                    </div>
                    <div class="col-6 text-right">
                        <span class="float-right">{{ getUserList(unit) }}</span>
                    </div>
                    <hr class="col-12 mb-2 pb-1" />
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</div>
