import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, PageSlotModule } from '@spartacus/storefront';
import { LoginRegisterModule } from '@spartacus/user/account/components';

import { AimoInputModule } from '../../../shared/input/aimo-input.module';
import { AimoPasswordChecksModule } from '../../shared/password-checks/aimo-password-checks.module';
import { AimoPipesModule } from '../../shared/utils/safe-html/aimo-pipes.module';

import { AimoMyOrganizationComponent } from './aimo-my-organization.component';
import { AimoEditB2bunitComponent } from './edit-b2bunit/aimo-edit-b2bunit.component';
import { editB2BUnitLayout } from './edit-b2bunit/aimo-edit-b2bunit.config';
import { AimoAddNewUserComponent } from './new-user/aimo-add-new-user.component';
import { addNewUserLayout } from './new-user/aimo-new-user.config';

@NgModule({
    providers: [provideDefaultConfig(addNewUserLayout), provideDefaultConfig(editB2BUnitLayout)],
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        PageSlotModule,
        I18nModule,
        AimoInputModule,
        ConfigModule.withConfig(<CmsConfig>{
            cmsComponents: {
                MyOrganizationComponent: {
                    component: AimoMyOrganizationComponent,
                    guards: [AuthGuard],
                },
            },
        }),
        FormErrorsModule,
        ReactiveFormsModule,
        AimoPasswordChecksModule,
        NgbPopoverModule,
        NgbTooltipModule,
        AimoPipesModule,
    ],
    declarations: [AimoMyOrganizationComponent, AimoAddNewUserComponent, AimoEditB2bunitComponent],
    exports: [AimoMyOrganizationComponent, AimoAddNewUserComponent, AimoEditB2bunitComponent],
})
export class AimoMyOrganizationModule extends LoginRegisterModule {}
