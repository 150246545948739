import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { I18nextTranslationService, TranslationService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoI18nextTranslationService } from './aimo-i18next-translation.service';

@Injectable({ providedIn: 'root' })
export class AimoI18nextTranslationServiceStrategy implements TranslationService, OnDestroy {
    private showTranslations: boolean = false;
    subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private spartacusImpl: I18nextTranslationService,
        private aimoImpl: AimoI18nextTranslationService,
    ) {
        this.subscription.add(
            this.router.events
                .pipe(
                    filter((e) => e instanceof ActivationEnd),
                    map((e: ActivationEnd) => e.snapshot.queryParams),
                )
                .subscribe((params) => {
                    this.showTranslations = params['showtranslationskeys'] == 'true';
                }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    translate(
        key: string,
        // eslint-disable-next-line
        options: any = {},
        whitespaceUntilLoaded: boolean = false,
    ): Observable<string> {
        return this.getCurrentStrategy().translate(key, options, whitespaceUntilLoaded);
    }

    // eslint-disable-next-line
    loadChunks(chunkNames: string | string[]): Promise<any> {
        return this.getCurrentStrategy().loadChunks(chunkNames);
    }

    getCurrentStrategy(): TranslationService {
        if (this.showTranslations) {
            return this.aimoImpl;
        } else {
            return this.spartacusImpl;
        }
    }
}
