/*
 * SPDX-FileCopyrightText: 2022 SAP Spartacus team <spartacus-team@sap.com>
 *
 * SPDX-License-Identifier: Apache-2.0
 */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AimoSpinnerService } from './aimo-spinner.service';

// TODO: Improve a11y with better text appropriate to usage (example: loading cart spinner)

@Component({
    selector: 'aimo-spinner',
    templateUrl: './aimo-spinner.component.html',
})
export class AimoSpinnerComponent implements OnInit, OnDestroy {
    constructor(protected spinnerService: AimoSpinnerService, protected cdr: ChangeDetectorRef) {}

    title: string;
    private subscription = new Subscription();

    @Input() isModal = true;

    ngOnInit(): void {
        this.subscription.add(
            this.spinnerService.spinnerTitle$.subscribe((value) => {
                this.title = value;
                this.cdr.detectChanges();
            }),
        );
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }
}
