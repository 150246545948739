import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { NgSelectA11yModule } from '@spartacus/storefront';

import { AimoSortingComponent } from './aimo-sorting.component';

@NgModule({
    imports: [CommonModule, NgSelectModule, FormsModule, NgSelectA11yModule, I18nModule],
    declarations: [AimoSortingComponent],
    exports: [AimoSortingComponent],
})
export class AimoSortingModule {}
