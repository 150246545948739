<div class="modal-dialog modal-dialog-centered">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0">{{ 'aimo.cart.excelImport.title' | cxTranslate }}</h2>
            <div class="modal-action col-auto">
                <button class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <p>{{ 'aimo.cart.excelImport.description' | cxTranslate }}</p>
            <div [formGroup]="form" class="excel-import">
                <div class="editor-scrollable">
                    <div class="editor">
                        <div class="line-numbers" #lineNumbers>
                            <span></span>
                        </div>
                        <aimo-cx-input
                            [autofocus]="true"
                            (keyup)="addLineNumbers($event)"
                            [cols]="50"
                            [rows]="20"
                            [isTextArea]="true"
                            [parentForm]="form"
                            [nameForm]="'data'"
                            [placeholder]="'aimo.cart.excelImport.textArea.placeholder'"
                            idName="excelImport">
                        </aimo-cx-input>
                    </div>
                </div>
            </div>

            <div class="modal-actions flex-start">
                <button class="btn btn-primary btn-sm" (click)="excelImport()" [disabled]="!submitEnabled">
                    {{ 'aimo.cart.excelImport.submit' | cxTranslate }}
                </button>
                <button class="btn btn-secondary btn-sm ml-2" (click)="closeDialog()">
                    {{ 'aimo.cart.excelImport.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
