/**
 * The layout configuration is used to define the overall layout of the storefront.
 * The configuration includes the following aspects:
 * - breakpoint layout (AKA screen layout)
 * - Page sections slot configuration (i.e. header vs footer)
 * - page template slot configuration (i.e. landing page template vs PDP page template)
 * - deferred loading configuration
 *
 * The page slot configurations is directly related to the data in the backend. If you use the
 * Spartacus sample-data, you will have an aligned setup. However, if you introduce custom page
 * templates and/or slots, you most likely need to further adjust or replace this configuration.
 */
import { LayoutConfig } from '@spartacus/storefront';

export const aimoLayoutConfig: LayoutConfig = {
    layoutSlots: {
        topHeader: {
            lg: {
                slots: ['TopHeaderSlot', 'SelectUnits', 'MyAssortment', 'SiteLogin', 'SiteContext'],
            },
            slots: ['TopHeaderSlot'],
        },
        header: {
            lg: {
                slots: ['SiteLogo', 'NavigationBar', 'SearchBox', 'MiniCart'],
            },
            slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart'],
        },
        navigation: {
            lg: { slots: [] },
            slots: ['NavigationBar', 'MyAssortment', 'SelectUnits', 'SiteLogin', 'SiteContext'],
        },
        footer: {
            slots: ['Footer'],
        },
        LandingPage2Template: {
            pageFold: 'Section1',
            slots: ['Section1'],
        },
        ContentPage1Template: {
            slots: ['Section1', 'Section2', 'Section2A', 'Section2B', 'Section3', 'Section4', 'Section5'],
        },
        CategoryPageTemplate: {
            pageFold: 'Section2',
            slots: ['Section1', 'Section2', 'Section3'],
        },
        ProductListPageTemplate: {
            slots: ['Section1', 'ProductListSlot'],
        },
        ProductGridPageTemplate: {
            slots: ['Section1', 'ProductGridSlot', 'Section2'],
        },
        SearchResultsListPageTemplate: {
            slots: ['Section1', 'SearchResultsListSlot'],
        },
        SearchResultsGridPageTemplate: {
            slots: ['Section1', 'SearchResultsGridSlot', 'Section2'],
        },
        ProductDetailsPageTemplate: {
            lg: {
                pageFold: 'UpSelling',
            },
            pageFold: 'Summary',
            slots: ['Summary'],
        },
        CartPageTemplate: {
            slots: ['TopContent', 'EmptyCartMiddleContent'],
        },
        AccountPageTemplate: {
            slots: ['SideContent', 'BodyContent'],
        },
        LoginPageTemplate: {
            slots: ['LeftContentSlot', 'BackgroundSlot', 'CenterContentSlot'],
        },
        ErrorPageTemplate: {
            slots: ['BodyContent', 'BottomContent', 'BackgroundSlot'],
        },
        OrderConfirmationPageTemplate: {
            slots: ['BodyContent', 'SideContent'],
        },
        MultiStepCheckoutSummaryPageTemplate: {
            slots: ['TopContent', 'BodyContent', 'SideContent', 'BottomContent'],
        },
        CheckoutLoginPageTemplate: {
            slots: ['RightContentSlot'],
        },
    },
};
