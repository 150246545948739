<ng-container *ngIf="showQuantity">
    <button
        *ngIf="focused"
        [attr.aria-label]="'aimo.product.decreaseQuantity' | cxTranslate"
        [ngbPopover]="'aimo.product.decreaseQuantity' | cxTranslate"
        container="body"
        triggers="mouseenter:mouseleave"
        popoverClass="popover-default popover-hide-mobile"
        (keydown.enter)="enterPressed($event)"
        [ngClass]="buttonsClass ? buttonsClass : 'button-decrease'"
        type="button"
        (click)="cancelEvent()">
        <i class="aimo-icon icon-sm icon-x"></i>
    </button>
    <button
        *ngIf="!focused"
        [attr.aria-label]="'aimo.product.decreaseQuantity' | cxTranslate"
        [ngbPopover]="'aimo.product.decreaseQuantity' | cxTranslate"
        container="body"
        triggers="mouseenter:mouseleave"
        popoverClass="popover-default popover-hide-mobile"
        (keydown.enter)="enterPressed($event)"
        [ngClass]="buttonsClass ? buttonsClass : 'button-decrease'"
        class="btn-minus"
        type="button"
        (click)="decrementEvent($event)"
        [disabled]="qtyFormField.disabled || (min && qtyFormField.value <= min)"
        [tabindex]="qtyFormField.disabled || qtyFormField.value <= min ? -1 : 0">
        <i
            class="aimo-icon icon-sm icon-minus-bold"
            [ngClass]="[
                showSpinner && (isOpenedSpinner$ | async) && isOpenedLocalSpinner() && !isPLP ? 'icon-white' : '',
                showSpinner && (isOpenedSpinner$ | async) && isPLP ? 'd-none' : ''
            ]"></i>
    </button>
    <ng-container [formGroup]="parentForm" *ngIf="parentForm">
        <ng-container [formArrayName]="parentFormArrayName">
            <ng-container [formArrayName]="parentFormGroupName">
                <ng-container *ngTemplateOutlet="inputField"></ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!parentForm">
        <ng-container *ngTemplateOutlet="inputField"></ng-container>
    </ng-container>

    <button
        #increaseButton
        *ngIf="focused"
        [attr.aria-label]="'aimo.product.increaseQuantity' | cxTranslate"
        [ngbPopover]="'aimo.product.increaseQuantity' | cxTranslate"
        container="body"
        triggers="mouseenter:mouseleave"
        popoverClass="popover-default popover-hide-mobile"
        placement="top"
        (keydown.enter)="enterPressed($event)"
        [ngClass]="buttonsClass ? buttonsClass : 'button-increase'"
        type="button"
        (click)="enterPressed($event)"
        [disabled]="qtyFormField.disabled"
        tabindex="0">
        <i class="aimo-icon icon-sm icon-check"></i>
    </button>

    <button
        #increaseButton
        *ngIf="!focused"
        [attr.aria-label]="'aimo.product.increaseQuantity' | cxTranslate"
        [ngbPopover]="'aimo.product.increaseQuantity' | cxTranslate"
        container="body"
        (keydown.enter)="enterPressed($event)"
        triggers="mouseenter:mouseleave"
        popoverClass="popover-default popover-hide-mobile"
        placement="top"
        [ngClass]="buttonsClass ? buttonsClass : 'button-increase'"
        type="button"
        (click)="incrementEvent($event, true)"
        [disabled]="qtyFormField.disabled || (max && qtyFormField.value >= max)"
        tabindex="0">
        <i
            class="aimo-icon icon-sm icon-plus-bold"
            [ngClass]="[
                showSpinner && (isOpenedSpinner$ | async) && isOpenedLocalSpinner() && !isPLP ? 'icon-white' : '',
                showSpinner && (isOpenedSpinner$ | async) && isPLP ? 'd-none' : ''
            ]"></i>
        <div #spinnerDiv class="d-none spinner" *ngIf="showSpinner">
            <aimo-spinner
                class="spinner-xsm"
                [ngClass]="buttonsClass ? 'spinner-white' : ''"
                [isModal]="false"></aimo-spinner>
        </div>
    </button>
</ng-container>

<button
    #increaseButton
    *ngIf="!showQuantity"
    (click)="incrementEvent($event, true, true, true)"
    class="btn btn-primary btn-icon btn-add-to-cart noPrint"
    type="submit"
    [attr.aria-label]="'addToCart.addToCart' | cxTranslate : { name: product?.name }"
    [ngbPopover]="
        (readonly ? 'addToCart.addToCartDisabled' : 'addToCart.addToCart') | cxTranslate : { name: product?.name }
    "
    container="body"
    triggers="mouseenter:mouseleave"
    popoverClass="popover-default"
    placement="top"
    (keydown.enter)="enterPressed($event)"
    [disabled]="readonly">
    <i class="aimo-icon icon-white icon-shopping-cart-simple icon-sm noPrint" #iconCart></i>
    <i class="available-qty noPrint" *ngIf="qtyShown > 0">{{ qtyShown }}</i>
</button>

<ng-template #inputField>
    <div class="cx-amount" (click)="onClick()">
        <input
            [attr.aria-label]="'aimo.product.inputQuantity' | cxTranslate : { name: product?.name }"
            #qtyInput
            type="number"
            [id]="id"
            [min]="min"
            [max]="max"
            [readonly]="readonly"
            [tabindex]="readonly ? -1 : 0"
            [formControl]="qtyFormField"
            (ngModelChange)="inputChanged($event, qtyInput, false)"
            (change)="inputChanged($event, qtyInput, true)"
            (keydown.enter)="enterPressed($event)"
            (keydown)="changeCommaToDot($event)"
            (blur)="onBlur($event)"
            class="aimo-input" />
        <span *ngIf="unitName">{{ unitName | lowercase }}</span>
    </div>
</ng-template>
