import { LanguageService } from '@spartacus/core';

export class DateUtils {
    static stdTimezoneOffset(d: Date): number {
        const jan = new Date(d.getFullYear(), 0, 1);
        const jul = new Date(d.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }

    static isDstObserved(d: Date): boolean {
        return d.getTimezoneOffset() < DateUtils.stdTimezoneOffset(d);
    }

    static convertDate(date: string | Date): Date {
        if (date !== undefined) {
            if (typeof date === 'string') {
                return new Date(date);
            }

            if (date instanceof Date) {
                return date;
            }

            return new Date(date);
        }
        return undefined;
    }

    static convertUIDate(language: LanguageService, format: string, d: Date): string {
        if (d === null) {
            return null;
        }
        return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
    }

    static convertDateToOccString(language: LanguageService, date: Date): string {
        if (date === null || date === undefined) {
            return null;
        }
        return this.convertDateToString(date, '-') + 'T00:00:00+0000'; //UTC time
    }

    private static convertDateToString(d: Date, delimeter: string): string {
        return (
            d.getFullYear() +
            delimeter +
            ('0' + (d.getMonth() + 1)).slice(-2) +
            delimeter +
            ('0' + d.getDate()).slice(-2)
        );
    }

    static convertDateToSolrString(language: LanguageService, date: Date): string {
        if (date === null) {
            return null;
        }
        return this.convertDateToString(date, '-');
    }

    // https://dev.to/riversun/introducing-a-handy-javascript-date-formatting-function-5cd7
    public static formatDate(format: string, date: Date, opts?): string {
        if (!date) {
            date = new Date();
        }
        opts = opts || {};

        let _days = opts.days;

        if (!_days) {
            _days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        }

        let _months = opts.months;

        if (!_months) {
            _months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        }

        const pad = (number: number, strDigits: string, isUnpad?: boolean): string => {
            const strNum = Math.abs(number).toString();
            if (!isUnpad && strNum.length > strDigits.length) {
                return strNum;
            } else {
                return ('0000' + strNum).slice(-strDigits.length);
            }
        };

        const timezone = (date: Date, letter: string): string => {
            const chunk = [];
            const offset = -date.getTimezoneOffset();
            chunk.push(offset === 0 ? 'Z' : offset > 0 ? '+' : '-'); //add Z or +,-
            if (offset === 0) return chunk.join('');
            chunk.push(pad(Math.floor(offset / 60), '00')); //hour
            if (letter === 'X') return chunk.join('');
            if (letter === 'XXX') chunk.push(':');
            chunk.push(pad(offset % 60, '00')); //min
            return chunk.join('');
        };

        const DELIM = '\0\0';
        const escapeStack = [];

        const escapedFmtStr = format.replace(/'.*?'/g, (m) => {
            escapeStack.push(m.replace(/'/g, ''));
            return `${DELIM}${escapeStack.length - 1}${DELIM}`;
        });

        const formattedStr = escapedFmtStr
            .replace(/y{4}|y{2}/g, (m) => pad(date.getFullYear(), m, true))
            .replace(/M{3}/g, () => _months[date.getMonth()])
            .replace(/M{1,2}/g, (m) => pad(date.getMonth() + 1, m))
            .replace(/M{1,2}/g, (m) => pad(date.getMonth() + 1, m))
            .replace(/d{1,2}/g, (m) => pad(date.getDate(), m))
            .replace(/H{1,2}/g, (m) => pad(date.getHours(), m))
            .replace(/h{1,2}/g, (m) => {
                const hours = date.getHours();
                return pad(hours === 0 ? 12 : hours > 12 ? hours - 12 : hours, m);
            })
            .replace(/a{1,2}/g, () => (date.getHours() >= 12 ? 'PM' : 'AM'))
            .replace(/m{1,2}/g, (m) => pad(date.getMinutes(), m))
            .replace(/s{1,2}/g, (m) => pad(date.getSeconds(), m))
            .replace(/S{3}/g, (m) => pad(date.getMilliseconds(), m))
            .replace(/[E]+/g, () => _days[date.getDay()])
            .replace(/[Z]+/g, (m) => timezone(date, m))
            .replace(/X{1,3}/g, (m) => timezone(date, m));

        const unescapedStr = formattedStr.replace(new RegExp(`${DELIM}\\d+${DELIM}`, 'g'), () => {
            // eslint-disable-next-line
            const unescaped = escapeStack.shift();
            // eslint-disable-next-line
            return unescaped.length > 0 ? unescaped : "'";
        });

        return unescapedStr;
    }
}
