import { Injectable } from '@angular/core';
import { facadeFactory } from '@spartacus/core';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
    useFactory: () =>
        facadeFactory({
            facade: AimoUserPasswordFacade,
            feature: 'AimoUserProfileCore',
            methods: ['update', 'reset', 'requestForgotPasswordEmail', 'setPassword'],
        }),
})
export abstract class AimoUserPasswordFacade extends UserPasswordFacade {
    /**
     * Set new password. Part of the invite b2b customer flow.
     *
     * @param token
     * @param password
     */
    abstract setPassword(token: string, password: string): Observable<unknown>;

    abstract requestForgotPasswordEmail(email: string): Observable<unknown>;
}
