import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { AuthActions, OCC_USER_ID_ANONYMOUS, WindowRef } from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AimoUserService } from '../user/aimo-user.service';

@Injectable({
    providedIn: 'root',
})
export class AimoAssortmentService implements OnDestroy {
    private subscriptions = new Subscription();

    constructor(protected userService: AimoUserService, protected winRef: WindowRef, private actions$: Actions) {
        this.subscriptions.add(
            this.actions$
                .pipe(ofType(AuthActions.LOGIN))
                .subscribe(() => (this.assortmentSubject = new BehaviorSubject<boolean>(false))),
        );
    }

    private assortmentSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    isAssortmentChangingEnabled(): Observable<boolean> {
        return this.userService.get().pipe(
            filter((user) => user?.uid !== OCC_USER_ID_ANONYMOUS),
            map((user) => user?.assortmentChangeEnabled),
        );
    }

    isOnlyMyAssortment(): Observable<boolean> {
        return this.assortmentSubject.asObservable();
    }

    setOnlyMyAssortment(value: boolean): void {
        this.assortmentSubject.next(value);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
