import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Actions} from '@ngrx/effects';
import {AuthRedirectService, RoutingService, WindowRef} from '@spartacus/core';
import {LoginFormComponent, LoginFormComponentService} from '@spartacus/user/account/components';
import {BehaviorSubject, Subscription} from 'rxjs';

import {AimoRoutingService} from '../../../cms-structure/routing/aimo-routing.service';
import {LOGINFAIL} from '../../../service/auth/login-logout-fail.action';
import {AimoSpinnerService} from '../../shared/spinner/aimo-spinner.service';
import {openCloseSpinner} from '../../shared/utils/spinner/aimo-spinner-utils';

@Component({
    selector: 'aimo-cx-login-form',
    templateUrl: './aimo-login-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoLoginFormComponent extends LoginFormComponent implements OnInit, OnDestroy {
    errorOnLogin$ = new BehaviorSubject(false);
    subscription = new Subscription();

    constructor(
        protected service: LoginFormComponentService,
        protected actions: Actions,
        protected spinnerService: AimoSpinnerService,
        protected activatedRoute: ActivatedRoute,
        protected authRedirectService: AuthRedirectService,
        protected aimoRoutingService: AimoRoutingService,
        protected routingService: RoutingService,
        protected winRef: WindowRef,
    ) {
        super(service);
    }

    ngOnInit(): void {
        this.spinnerService.setTitle('aimo.loading.loginForm');
        openCloseSpinner(false);
        this.subscription.add(
            this.actions.subscribe((action) => {
                if (action.type == LOGINFAIL) {
                    this.routingService.go('/login')
                    this.errorOnLogin$.next(true);
                }
            }),
        );
        const gtmUrlParam = this.activatedRoute.snapshot.queryParamMap.get('_gl');
        if (gtmUrlParam) {
            this.authRedirectService.setRedirectUrl('/?_gl=' + gtmUrlParam);
        }
    }

    ngOnDestroy(): void {
        this.spinnerService.setTitle(null);
        this.subscription.unsubscribe();
    }

    onSubmit(): void {
        this.subscription.add(
            this.isUpdating$.subscribe((isUpdating) => {
                openCloseSpinner(isUpdating);
            }),
        );
        super.onSubmit();
    }

    getTextSuffix(): string {
        return this.aimoRoutingService.isPikatukku() ? '_pt' : '_tt';
    }

    gotoLogin(): void {
        this.winRef.location.reload();
    }

    isPikatukku(): boolean {
        return this.aimoRoutingService.isPikatukku();
    }
}
