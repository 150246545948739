import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsComponent } from '@spartacus/core/src/model/cms.model';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

import { AimoCategoryHierarchy } from '../../../model/product.model';
import { AimoCategoryService } from '../../../service/category/aimo-category.service';

@Component({
    selector: 'aimo-category-list',
    templateUrl: './aimo-category-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoCategoryListComponent {
    data$: Observable<AimoProductCategoryComponentData>;
    hierarchy$: Observable<AimoCategoryHierarchy>;

    constructor(
        protected componentData: CmsComponentData<AimoProductCategoryComponentData>,
        protected categoryService: AimoCategoryService,
    ) {
        this.data$ = componentData.data$;
    }

    getCategoryHierarchy(rootCategoryCode: string): Observable<AimoCategoryHierarchy> {
        if (!this.hierarchy$) {
            this.hierarchy$ = this.categoryService.getCategoryHierarchyById(rootCategoryCode);
        }
        return this.hierarchy$;
    }
}

interface AimoProductCategoryComponentData extends CmsComponent {
    title?: string;
    rootCategoryCode?: string;
}
