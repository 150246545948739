import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store } from '@ngrx/store';
import { AuthActions, SiteContextActions, StateWithSiteContext, WindowRef } from '@spartacus/core';
import { SiteContextComponentService, SiteContextSelectorComponent } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
    selector: 'aimo-site-context-selector',
    templateUrl: './aimo-site-context-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoSiteContextSelectorComponent extends SiteContextSelectorComponent implements OnDestroy {
    subscriptions: Subscription = new Subscription();

    constructor(
        protected winRef: WindowRef,
        protected actionsSubject: ActionsSubject,
        protected store: Store<StateWithSiteContext>,
        componentService2: SiteContextComponentService,
    ) {
        super(componentService2);
        this.subscriptions.add(
            this.actionsSubject.pipe(ofType(AuthActions.LOGOUT, AuthActions.LOGIN)).subscribe(() => {
                this.store.dispatch(new SiteContextActions.LoadLanguages());
            }),
        );
        this.subscriptions.add(
            this.actionsSubject.pipe(ofType(SiteContextActions.LANGUAGE_CHANGE)).subscribe(() => {
                this.store.dispatch(new SiteContextActions.LoadLanguages());
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    changeLanguage(value: string): void {
        this.active = value;
        this.winRef.location.reload();
    }
}
