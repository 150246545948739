import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductSearchPage } from '@spartacus/core';
import { ViewConfig } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

import { AimoProductFacetService } from '../../../service/product/aimo-product-facet.service';
import { AimoProductListComponentService } from '../../../service/product/aimo-product-list-component.service';
import { AimoSpinnerService } from '../spinner/aimo-spinner.service';

@Component({
    selector: 'aimo-cx-page-size',
    templateUrl: './aimo-page-size.component.html',
})
export class AimoPageSizeComponent implements OnDestroy {
    constructor(
        protected activatedRoute: ActivatedRoute,
        protected router: Router,
        protected viewConfig: ViewConfig,
        protected productListComponentService: AimoProductListComponentService,
        protected spinnerService: AimoSpinnerService,
        protected productFacetService: AimoProductFacetService,
    ) {}

    pageSizes = [20, 50, 100];
    @Input() model: ProductSearchPage;
    @Input() isSelectComponent = true;
    @Input() skipLocationChange = false;
    @Output() pageSizeEvent: EventEmitter<number> = new EventEmitter<number>();

    subscriptions: Subscription = new Subscription();
    @ViewChild('spinnerDiv') divElementRef: ElementRef;

    changeListingItems(): void {
        this.spinnerService.showInnerSpinner(this.divElementRef);
        this.productListComponentService.getPageItems(this.model?.pagination.currentPage + 1);
        this.productFacetService.setScrollPosition(window.pageYOffset);
    }

    get size(): number {
        return this.model.pagination.currentPage * this.model.pagination.pageSize + this.model.products.length;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
