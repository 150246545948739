<div class="modal-dialog modal-dialog-centered modal-dialog-overflow-visible">
    <div class="modal-content modal-sm">
        <div class="modal-header">
            <h2 class="mb-0" id="template-modal-title">
                {{
                    (edit
                        ? 'aimo.ordertemplates.editTitle'
                        : importFrom
                        ? 'aimo.ordertemplate.importFrom'
                        : createNew
                        ? 'aimo.ordertemplate.createNew'
                        : 'aimo.ordertemplate.addTo'
                    ) | cxTranslate
                }}
            </h2>
            <div class="modal-action col-auto">
                <button #closeButton class="btn-link" [attr.aria-label]="'aimo.arialLabel.close.modal' | cxTranslate">
                    <i class="aimo-icon icon-inline icon-x" (click)="closeDialog()"></i>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div *ngIf="product" class="row no-gutters mb-4">
                <div class="cx-product-image-container col-4">
                    <cx-media [container]="product.images?.PRIMARY" format="cartIcon"></cx-media>
                </div>
                <div class="product-name-wrapper col-8 ml-3">
                    <button
                        class="btn-link text-left text-bold"
                        [attr.aria-label]="
                            'aimo.product.openModal.arialLabel' | cxTranslate : { productName: product.name }
                        "
                        (click)="openProductModal()"
                        tabindex="0">
                        {{ product.name }}
                    </button>

                    <div class="product-code">
                        <aimo-product-code [product]="product"></aimo-product-code>
                    </div>
                </div>
            </div>

            <p
                class="mb-4"
                [innerHTML]="
                    (edit
                        ? 'aimo.ordertemplate.editDescription'
                        : importFrom
                        ? 'aimo.ordertemplate.importFromDescription'
                        : createNew
                        ? 'aimo.ordertemplate.createNewDescription'
                        : 'aimo.ordertemplate.addToDescription'
                    ) | cxTranslate
                "></p>

            <div *ngIf="!createNew && !edit" class="mb-4">
                <aimo-cx-input-select
                    [autofocus]="true"
                    [labelClass]="'text-bold pt-0 mb-2'"
                    [bindLabel]="'name'"
                    [bindValue]="'code'"
                    [required]="true"
                    [clearable]="true"
                    [items]="templates$ | async"
                    [showTemplateIcon]="true"
                    [label]="'aimo.ordertemplate.templateCode.label'"
                    [nameForm]="'templateCode'"
                    [parentForm]="form"
                    [placeholder]="'aimo.ordertemplate.templateCode.placeholder'"
                    idName="templateCode"></aimo-cx-input-select>
                <cx-form-errors
                    [control]="form.get('templateCode')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>

            <div *ngIf="showCreateNewTemplate()" class="mb-4">
                <aimo-cx-input
                    class="customer-id"
                    [autofocus]="true"
                    [labelClass]="'text-bold pt-0'"
                    [parentForm]="form"
                    [required]="true"
                    [label]="'aimo.ordertemplate.templateName.label'"
                    [nameForm]="'templateName'"
                    [idName]="'templateName'"></aimo-cx-input>
                <cx-form-errors
                    [control]="form.get('templateName')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>

            <div *ngIf="showCreateNewTemplate()" class="mb-4">
                <aimo-cx-input-select
                    [bindValue]="'id'"
                    [items]="visibilities"
                    [labelClass]="'text-bold pt-0 mb-2'"
                    [label]="'aimo.ordertemplate.templateVisibility.label'"
                    [nameForm]="'templateVisibility'"
                    [parentForm]="form"
                    [translateByCode]="true"
                    [required]="true"
                    [placeholder]="'aimo.ordertemplate.templateVisibility.placeholder'"
                    idName="templateVisibility"></aimo-cx-input-select>
                <cx-form-errors
                    [control]="form.get('templateVisibility')"
                    aria-atomic="true"
                    aria-live="assertive"></cx-form-errors>
            </div>

            <div class="cx-counter-stock mb-4" *ngIf="form.controls.quantity">
                <label [for]="'quantity'" class="input-label-value text-bold pt-0">
                    {{ 'aimo.ordertemplate.templateVisibility.quantity' | cxTranslate }}
                </label>
                <aimo-item-counter
                    class="cart-item-counter noPrint"
                    [min]="0"
                    [showSpinner]="false"
                    [id]="'add_to_template_quantity'"
                    [step]="product.allowedLotSize"
                    [control]="form.controls.quantity"
                    [unitName]="product.unit.name"
                    [cartCounter]="true"
                    [doNotUpdateCart]="true"
                    [product]="product"
                    [gtmProductAttributes]="createGtmAttributes()"></aimo-item-counter>
            </div>

            <div *ngIf="isCentralSelected()">
                <div class="mb-3 position-relative" *ngIf="availableUnits.length > 10">
                    <aimo-cx-input
                        [autofocus]="true"
                        (afterInput)="changeSearch($event)"
                        [placeholder]="'aimo.myorganization.users.editUser.search'"
                        [nameForm]="'search'"
                        [inputValue]="searchUnits"
                        [label]="'aimo.myorganization.users.editUser.search'"
                        [labelClass]="'d-none'"
                        [idName]="'b2bUnitsSearch'"
                        [inputIcon]="'icon-magnifying-glass icon-petrol-blue'">
                    </aimo-cx-input>
                    <button class="btn-link btn-close" *ngIf="searchUnits" (click)="changeSearch('')">
                        <i class="aimo-icon icon-x icon-petrol-blue icon-sm"></i>
                    </button>
                </div>

                <div [ngClass]="availableUnits.length < 10 ? '' : 'div-units'">
                    <fieldset class="div-units-scroll">
                        <legend class="d-none">{{ 'aimo.arialLabel.organization.customers' | cxTranslate }}</legend>

                        <div class="form-check">
                            <label (click)="toggleSelectAllUnits()">
                                <input type="checkbox" [(ngModel)]="isAllUnitsSelected" />
                                <span class="text-bold">{{ 'aimo.myorganization.units.selectAll' | cxTranslate }}</span>
                            </label>
                        </div>
                        <div
                            [formGroup]="form"
                            class="form-check"
                            *ngFor="let b2bUnit of availableUnits | aimoFilter : searchUnits : ['name', 'uid']">
                            <label>
                                <input type="checkbox" [formControlName]="'unit_' + b2bUnit.uid" />
                                <span class="text-bold">{{ b2bUnit.uid }}</span
                                >&nbsp;&nbsp;<span>{{ b2bUnit.name }}</span>
                            </label>
                        </div>
                    </fieldset>
                </div>
            </div>

            <div class="modal-actions flex-start">
                <button
                    [attr.aria-label]="
                        (importFrom
                            ? 'aimo.ordertemplate.importFrom'
                            : createNew
                            ? 'aimo.ordertemplate.createNewSubmit'
                            : edit
                            ? 'aimo.ordertemplate.editOrder'
                            : 'aimo.ordertemplate.addToSubmit'
                        ) | cxTranslate
                    "
                    [title]="
                        (importFrom
                            ? 'aimo.ordertemplate.importFrom'
                            : createNew
                            ? 'aimo.ordertemplate.createNewSubmit'
                            : edit
                            ? 'aimo.ordertemplate.editOrder'
                            : 'aimo.ordertemplate.addToSubmit'
                        ) | cxTranslate
                    "
                    class="btn btn-primary btn-sm btn-inline mr-2"
                    (click)="submit()"
                    [disabled]="!isValid()">
                    {{
                        (importFrom
                            ? 'aimo.ordertemplate.importFrom'
                            : createNew
                            ? 'aimo.ordertemplate.createNewSubmit'
                            : edit
                            ? 'aimo.ordertemplate.editOrder'
                            : 'aimo.ordertemplate.addToSubmit'
                        ) | cxTranslate
                    }}
                </button>
                <button
                    class="btn btn-secondary btn-sm btn-inline"
                    [attr.aria-label]="'aimo.ordertemplate.cancel' | cxTranslate"
                    [title]="'aimo.ordertemplate.cancel' | cxTranslate"
                    (keydown.tab)="getTheFocusBack($event)"
                    (click)="closeDialog()">
                    {{ 'aimo.ordertemplate.cancel' | cxTranslate }}
                </button>
            </div>
        </div>
    </div>
</div>
