import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
    PageMetaResolver,
    PRODUCT_NORMALIZER,
    PRODUCT_SEARCH_PAGE_NORMALIZER,
    ProductAdapter,
    ProductConnector,
    ProductOccModule,
    ProductSearchAdapter,
    ProductSearchConnector,
    ProductSearchService,
    ProductService,
    provideDefaultConfig,
} from '@spartacus/core';
import {
    CurrentProductService,
    ProductFacetService,
    ProductListComponentService,
    SearchBoxComponentService,
    ViewConfig,
} from '@spartacus/storefront';

import { AimoCategoryPageMetaResolver } from './aimo-category-page-meta.resolver';
import { AimoCurrentProductService } from './aimo-current-product.service';
import { aimoOccProductConfig } from './aimo-occ-product-config';
import { AimoOccProductAdapter } from './aimo-occ-product.adapter';
import { AimoProductFacetService } from './aimo-product-facet.service';
import { AimoProductListComponentService } from './aimo-product-list-component.service';
import { AimoProductNormalizer } from './aimo-product-normalizer.service';
import { AimoProductPageMetaResolver } from './aimo-product-page-meta.resolver';
import { aimoProductViewConfig } from './aimo-product-view-config';
import { AimoProductConnector } from './aimo-product.connector';
import { AimoProductSearchEffects } from './aimo-product.effect';
import { AimoProductService } from './aimo-product.service';
import { AimoOccProductSearchPageNormalizer } from './search/aimo-occ-product-search-page-normalizer';
import { AimoOccProductSearchAdapter } from './search/aimo-occ-product-search.adapter';
import { AimoProductSearchConnector } from './search/aimo-product-search.connector';
import { AimoProductSearchService } from './search/aimo-product-search.service';
import { AimoSearchBoxComponentService } from './search/aimo-search-box-component.service';
import { AimoSearchPageMetaResolver } from './search/aimo-search-page-meta.resolver';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature(AimoProductSearchEffects)],
    providers: [
        provideDefaultConfig(<ViewConfig>aimoProductViewConfig),
        provideDefaultConfig(aimoOccProductConfig),
        {
            provide: SearchBoxComponentService,
            useClass: AimoSearchBoxComponentService,
        },
        {
            provide: PRODUCT_NORMALIZER,
            useExisting: AimoProductNormalizer,
            multi: true,
        },
        {
            provide: ProductService,
            useClass: AimoProductService,
        },
        {
            provide: ProductSearchService,
            useClass: AimoProductSearchService,
        },
        {
            provide: CurrentProductService,
            useClass: AimoCurrentProductService,
        },
        {
            provide: ProductSearchAdapter,
            useClass: AimoOccProductSearchAdapter,
        },
        {
            provide: ProductAdapter,
            useClass: AimoOccProductAdapter,
        },
        {
            provide: ProductSearchConnector,
            useClass: AimoProductSearchConnector,
        },
        {
            provide: ProductConnector,
            useClass: AimoProductConnector,
        },
        {
            provide: ProductFacetService,
            useClass: AimoProductFacetService,
        },
        {
            provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
            useExisting: AimoOccProductSearchPageNormalizer,
            multi: true,
        },
        {
            provide: PageMetaResolver,
            useExisting: AimoProductPageMetaResolver,
            multi: true,
        },
        {
            provide: PageMetaResolver,
            useExisting: AimoCategoryPageMetaResolver,
            multi: true,
        },
        {
            provide: PageMetaResolver,
            useExisting: AimoSearchPageMetaResolver,
            multi: true,
        },
        {
            provide: ProductListComponentService,
            useClass: AimoProductListComponentService,
        },
    ],
})
export class AimoProductOccModule extends ProductOccModule {}
