import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy } from '@angular/core';
import { ProductImageZoomDialogComponent } from '@spartacus/product/image-zoom/components';
import { LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
    selector: 'aimo-product-image-zoom-dialog',
    templateUrl: 'aimo-product-image-zoom-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimoProductImageZoomDialogComponent extends ProductImageZoomDialogComponent implements OnDestroy {
    subscription: Subscription = new Subscription();

    constructor(protected launchDialogService: LaunchDialogService, protected el: ElementRef) {
        super(launchDialogService, el);
    }

    close(): void {
        this.subscription.add(this.launchDialogService.data$.subscribe((data) => data?.onClose?.next()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
